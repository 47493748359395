.create-attributes-container,
.reorder-attributes-container {
  .attribute-row {
    padding: 24px 0 16px 0;
    // max-height: 41px;
    max-width: 712px;
  }

  .cur-grab {
    border-bottom: 1px solid $white-9;

    &:last-of-type {
      border: none;
    }

    &:last-of-type .attribute-row {
      padding-bottom: 24px;
    }
  }

  .cur-grabbing .attribute-row {
    padding: 16px 0;
  }
}

.selected-file-icon {
  top: 40px;
  left: 28px;
}
