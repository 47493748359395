.date-input-field-wrap {
  position: relative;
  cursor: pointer;
  border: solid 1px $white-2;
  color: $black-3;
  padding: 5px 12px;
  border-radius: 4px;
  @include display-flex(center, flex-start);
  background-color: #ffffff;
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }
  &:hover {
    background-color: #f7f7f7;
  }
  .calendar-text-wrap {
    flex: 1;
    display: inline-flex;
    align-items: center;
    min-width: fit-content;
    > input {
      width: 100%;
      border: none;
      box-shadow: none;
      outline: none;
      &.padding-0 {
        padding: 0px;
      }
    }
  }
  img,
  svg {
    margin-left: 8px;
  }
  .calendar-icon {
    @include content-size(18, 18);
    margin-right: 8px;
  }
  .calendar-placeholder {
    color: $gray-2;
  }
}

.dk__date-range-list {
  padding: 16px 8px;
  .dk__date-range-list-item {
    color: $body-color;
    padding: 4px 8px !important;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    &:active,
    &:focus,
    &.active {
      font-weight: 500;
      color: $purple-1;
    }
    &:hover {
      background-color: $white-4 !important;
      border-radius: 4px;
    }
  }
}

.date-range-picker {
  min-width: 258px;
}

.static-date-range-picker-drowdown {
  min-width: 178px;
}

.custom-range-picker {
  padding: 8px;
  padding-bottom: 0px;
  .MuiPickersDay-daySelected {
    background-color: $purple-1 !important;
  }
  .custom-range-header {
    @extend .d-flex-c-s;
    padding: 8px 16px;
    padding-bottom: 0;
    border-radius: 4px;
    color: $black-1;

    .date-range-picker-close-icon {
      @extend .cur-p, .d-flex-c-c;
      border-radius: 6px;
      padding: 8px;
      &:hover {
        background-color: #f2f2f2;
      }
    }
    img,
    svg {
      @include content-size(16, 16);
      cursor: pointer;
    }
  }
  .date-and-btn-display-wrap {
    position: relative;
    margin: 0 8px;
    padding: 12px 0;
    border-top: 1px solid $white-5;
    text-align: right;
    .display-date-wrap {
      @include font-size(14, 20);
      margin-right: 20px;
    }
  }
  .MuiPickersStaticWrapper-staticWrapperRoot {
    padding: 0 12px;
    .MuiPickersDesktopDateRangeCalendar {
      &-rangeCalendarContainer {
        border: none;
        margin-left: 48px;
        &:first-child {
          margin-left: 0;
        }
      }
      &-calendar {
        min-width: 240px;
        min-height: 250px;
      }
      &-arrowSwitcher {
        padding: 16px 0 20px;
        .MuiPickersArrowSwitcher-iconButton {
          color: $black-3;
          padding: 3px;
        }
        .MuiTypography-subtitle1 {
          @include font-size-weight(15, 22, 500);
          color: $black-3;
        }
      }
    }
    .MuiPickersCalendar {
      &-weekDayLabel {
        @include content-size(30, 22);
        color: $gray-1;
        @include font-size-weight(13, 22, 500);
      }
      &-week {
        margin: 6px 0;
      }
    }
    .MuiPickersDateRangeDay {
      &-day {
        @include font-size(13, 22);
        @include content-size(30, 30);
        &.MuiPickersDay-dayDisabled {
          color: $gray-8;
        }
        &:focus.MuiPickersDay-daySelected {
          background-color: $purple-2;
        }
        > * {
          transform: scale(1);
        }
      }
      &-rangeIntervalDayHighlight {
        background-color: rgba($purple-1, 0.1);
        .MuiPickersDay-day {
          color: $body-color;
          &.MuiPickersDay-daySelected {
            color: #ffffff;
            font-weight: 500;
            background-color: $purple-1;
          }
        }
        &:last-child,
        &:first-child {
          border-radius: 0;
        }
      }
      &-rangeIntervalDayHighlightEnd {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
      }
    }
  }
}

.dk__static-date-range-calendar-wrap {
  .date-input-field-wrap {
    width: 178px;
    margin-bottom: 16px;
    padding: 2px 10px 2px 6px;
  }
}

.datepicker-sm {
  .date-input-field-wrap {
    padding: 5px 12px;
  }
}

.datepicker-xs {
  .date-input-field-wrap {
    @extend .text-3;
  }
}

.dk__static-date-range-calendar {
  margin-bottom: 16px;
  .MuiPickersStaticWrapper-staticWrapperRoot {
    background-color: transparent;
  }
  .MuiPickersDesktopDateRangeCalendar {
    &-rangeCalendarContainer {
      border: none !important;
      @include box-style;
      margin-left: 16px;
      flex: 1;
      &:first-child {
        margin-left: 0;
      }
      .MuiPickersCalendar-daysHeader {
        margin: 0 16px;
      }
      .MuiPickersCalendar-weekDayLabel {
        min-width: 28px;
        width: 100%;
        color: $gray-1;
        @include font-size-weight(14, 22, 500);
      }
    }
    &-arrowSwitcher {
      padding: 16px 12px 8px;
      .MuiPickersArrowSwitcher-iconButton {
        color: $black-3;
        padding: 3px;
        &.Mui-disabled {
          opacity: 0.5;
        }
      }
      .MuiTypography-subtitle1 {
        @include font-size-weight(16, 24, 500);
      }
    }
    &-calendar {
      min-height: 288px;
      margin: 0 16px;
      min-width: 280px;
      .MuiPickersCalendar {
        &-weekContainer {
          width: 100%;
          background: #fff;
        }
        &-week {
          margin: 0;
          .MuiPickersDateRangeDay {
            &-root {
              margin-bottom: 6px;
              flex: 1;
            }
            &-dayInsideRangeInterval {
              color: $body-color;
            }
            &-rangeIntervalDayHighlightStart,
            &-rangeIntervalDayPreviewStart {
              margin-left: 0;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
            }
            &-rangeIntervalDayHighlightEnd,
            &-rangeIntervalDayPreviewEnd {
              margin-right: 0;
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px;
            }
            &-day {
              > * {
                transform: none;
              }
              @include font-size(14, 22);
              &.Mui-disabled {
                color: $gray-4;
              }

              .MuiPickersDay-dayLabel {
                @include font-size(14, 20);
                width: 100%;
                & > * {
                  transform: scale(1);
                }
                .day-label-text {
                  @include font-size(14, 20);
                }
              }

              &:focus.Mui-selected {
                color: #fff !important;
                background-color: $purple-2;
              }
              &.Mui-selected {
                color: $body-color !important;
                background-color: transparent;
              }
            }
            &-rangeIntervalDayHighlight {
              background-color: rgba($purple-1, 0.1);
              position: relative;
              .MuiPickersDay-day {
                color: $body-color;
                &.Mui-selected {
                  color: #ffffff;
                  font-weight: 400;
                }
              }
            }
          }
          .MuiPickersDay-root {
            min-width: 28px;
            min-height: 28px;
          }
        }
      }
    }
  }
}

.date-picker-popper {
  width: 360px !important;
  .react-datepicker {
    button {
      margin-top: 16px;
    }
    .react-datepicker__navigation--previous {
      margin-left: 70px;
      .react-datepicker__navigation-icon::before {
        border-width: 2px 2px 0 0;
        border-color: $black-3;
      }
    }
    .react-datepicker__navigation--next {
      margin-right: 70px;
      .react-datepicker__navigation-icon::before {
        border-width: 2px 2px 0 0;
        border-color: $black-3;
      }
    }
    .react-datepicker__month-container {
      padding-top: 24px;
    }
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
    font-size: 14px;
    line-height: 20px;
    border: none;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 20px 20px rgba(152, 152, 152, 0.3);
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__month-container {
      .react-datepicker__header {
        background-color: $white;
        width: 309px;
        border-bottom: none;
        padding: 0;
        .react-datepicker__current-month {
          font-size: 16px !important;
          line-height: 24px !important;
          font-weight: 400;
          margin: auto;
          border-bottom: 1px solid $white-2;
          padding-bottom: 11px;
        }
        .react-datepicker__day-names {
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          padding-bottom: 8px;
          .react-datepicker__day-name {
            margin: 0 !important;
            color: $gray-1;
            width: 38px;
            line-height: 20px;
          }
        }
      }
      .react-datepicker__month {
        margin: 0;
        .react-datepicker__week {
          display: flex;
          justify-content: space-between;
          .react-datepicker__day--keyboard-selected {
            color: $white !important;
            background-color: $purple-1;
          }
          .react-datepicker__day--selected {
            width: 38px;
            color: $white !important;
          }
          .react-datepicker__day--outside-month {
            visibility: hidden;
          }
          .react-datepicker__day {
            width: 38px;
            border-radius: 15px;
            margin-top: 12px;
            margin-bottom: 0;
            line-height: 20px;
            padding: 4px 0;
          }
          .react-datepicker__day--today {
            font-weight: normal;
          }
        }
      }
    }
  }
}
.input-date-picker {
  width: 100%;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid $white-2;
  height: 48px;
}
.input-date-picker-disabled {
  background-color: $gray-9;
}

.picker-text-content {
  @extend .d-flex;
  @extend .align-center;
  @extend .text-2;
}

.date-picker-arrow-icon {
  padding-top: 5px;
}

.calendar-popper-container {
  color: $black-3;
  text-align: center;
  padding-top: 24px;
  @include font-size-weight(18, 26, 500);
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 10px 20px 0 rgba(152, 152, 152, 0.3);
}

.calender-icon-datepicker {
  filter: invert(28%) sepia(16%) saturate(45%) hue-rotate(321deg)
    brightness(87%) contrast(81%);
}
.calender-icon-datepicker-gray {
  filter: invert(9%) sepia(7%) saturate(1081%) hue-rotate(218deg)
    brightness(94%) contrast(97%);
}

.custom-day-picker {
  padding: 8px;

  .MuiPickersCalendarHeader {
    &-switchHeader {
      padding: 0 12px;
      justify-content: center;
      .MuiTypography-subtitle1 {
        @include font-size-weight(15, 22, 500);
        color: $black-3;
      }
      .MuiPickersArrowSwitcher {
        &-root {
          position: absolute;
          left: 0;
          right: 0;
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          z-index: 0;
        }
        &-previousMonthButtonMargin {
          margin-right: 0;
        }
        &-iconButton {
          svg {
            path {
              fill: $black-3;
            }
          }
        }
      }
    }
    &-monthTitleContainer {
      align-items: center;
      margin: 0;
      z-index: 1;
    }
  }

  .MuiPickersCalendar {
    &-weekDayLabel {
      @include content-size(30, 22);
      color: $gray-1;
      @include font-size-weight(13, 22, 500);
    }

    &-week {
      margin: 6px 0;
      .MuiPickersDay {
        &-daySelected {
          background-color: $purple-1 !important;
        }
        &-dayLabel {
          @include font-size(13, 22);
          @include content-size(30, 30);
          display: flex;
          align-items: center;
          justify-content: center;

          &.MuiPickersDay-dayDisabled {
            color: $gray-8;
          }

          &:focus.MuiPickersDay-daySelected {
            background-color: $purple-2;
          }

          > * {
            transform: scale(1);
          }
        }
      }
    }
  }

  .custom-day-header {
    font-weight: 500;
    @include font-size(13, 18);
    padding: 8px 16px;
    border-radius: 4px;
    color: $black-1;
    text-align: center;
    position: relative;

    img,
    svg {
      position: absolute;
      right: 16px;
      top: 10px;
      @include content-size(16, 16);
      cursor: pointer;
    }
  }

  .date-and-btn-display-wrap {
    position: relative;
    margin: 0 8px;
    padding: 12px 0;
    border-top: 1px solid $white-5;
    text-align: right;

    .display-date-wrap {
      @include font-size(14, 20);
      margin-right: 20px;
    }
  }
}

.attribute-container {
  .date-input-field-wrap > svg {
    display: none !important;
  }
  .select-file-btn {
    height: 48px;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
