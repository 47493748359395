.number-stepper-container {
  width: 89px;
  border: solid 1px $white-8;
  border-radius: 4px;
  display: flex;
  flex-direction: row;

  .stepper-divider {
    border-left: solid 1px $white-8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px 0;
  }
}

.number-stepper-focus {
  border-color: $purple-1 !important;
  box-shadow: $focus-blue-box-shadow !important;
}

.timepicker-time {
  color: $black-3;
  padding: 8px 11px;
  width: 65px;
  text-align: center;
  @include font-size-weight(32, 40, 500);

  .input-wrap {
    padding: 0;
    margin: 0;
  }

  .form-group input {
    @include font-size-weight(32, 40, 500);
    color: $black-3;
    text-align: center;
    padding: 0 !important;
    border: none !important;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}
.timepicker-stepper-btn {
  outline: none;
  border: none;
  font-size: 24px;
  line-height: 24;
  background-color: transparent;
  &.up {
    background-image: url('../images/icons/arrow-up-picker.svg');
    height: 24px;
    width: 24px;
    margin-left: -1px;
  }
  &.down {
    background-image: url('../images/icons/arrow-down-picker.svg');
    height: 24px;
    width: 24px;
    margin-left: -1px;
  }
}

.time-picker-close-icon {
  width: 24px;
  height: 24px;
}

.time-picker-div-input {
  width: 100%;
  max-width: 344px;
  height: 48px;
  border: 1px solid $white-2;
  border-radius: 4px;
  padding: 12px 16px;
  &.focused {
    border-color: $purple-1;
    box-shadow: $focus-blue-box-shadow;
  }
}

.time-picker-div-input-disabled {
  background-color: $gray-9;
}

.timepicker-arrow-icon {
  padding-top: 5px;
}
.time-picker-button {
  width: 121px;
  height: 48px;
}

.timepicker-editable-input {
  .input-group-wrap {
    input {
      @extend .section-text-18;
      border: none;
      padding: 0;
      color: $black-3;
    }
  }
}
