.bad-rating,
.avg-rating,
.ok-rating,
.perfect-rating {
  @include display-flex(center, space-between);
  padding: 2px 6px;
  border-radius: 3px;
  width: 48px;
  height: 24px;
  @include font-size-weight(14, 20.02, 500);
  vertical-align: middle;
  svg{
    vertical-align: middle;
    width: 12px;
    height: 12px
  }
}

@mixin rating-box-style($bgColor, $opacity ) {
    background-color: rgba($color: $bgColor, $alpha: $opacity);
    color: $bgColor;
    svg{
        fill: $bgColor;
    }
  }

.bad-rating{
    @include rating-box-style($red-1, 0.2)
}

.avg-rating{
    @include rating-box-style($orange-1, 0.2)
}

.perfect-rating{
    @include rating-box-style($green-1, 0.2)
}