.create-order-layout {
  @include d-grid(768px 400px, 1fr, 24px, flex-start);
  margin: 32px auto;
  .left-grid {
    @include d-grid(1fr, auto, 24px);
    width: 760px;
  }
  .right-grid {
    @include d-grid(1fr, auto, 24px);
    width: 400px;
    position: sticky;
    top: 32px;
  }
}

.create-order-card-shimmer {
  height: 145px;
}

.selected-products-grid {
  @include d-grid(1fr, auto, 20px, flex-start, flex-start);
}

.selected-product-grid {
  @include d-grid(96px 1fr, auto, 20px, flex-start, flex-start);
}

.selected-product-image {
  @include content-size(96, 96);
  border: 1px solid $white-9;
  border-radius: 6px;
  object-fit: contain;
}

.selected-product-counter {
  border: 1px solid $purple-1;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  .decrement-icon {
    path {
      fill: $purple-1;
    }
  }
  .count {
    background-color: rgba(20, 110, 180, 0.1);
    color: $purple-1;
    @extend .section-text-8;
    padding: 4px 0px;
    width: 38px;
    display: inline-flex;
    justify-content: center;
  }
}

.select-products-list {
  max-height: calc(100vh - 352px);
  overflow-y: overlay;
  padding-right: 24px;
  margin-right: -24px;
  @include d-grid(1fr, auto, 20px);
}

.parent-product-grid {
  @include d-grid(1fr, auto, 16px);
}

.sub-product-grid {
  @include d-grid(1fr, auto, 12px);
  padding-left: 16px;
}

.product-grid {
  .checkbox-label {
    padding: 0;
  }
  .product-option {
    @include d-grid(48px 1fr, 1fr, 16px, center, flex-start);
    .product-option-image {
      @include content-size(48, 48);
      object-fit: contain;
      border-radius: 4px;
      border: 1px solid $white-9;
    }
    .product-option-desc {
      .product-cost {
        font-weight: 500;
      }

      .original-cost {
        font-weight: 400;
        text-decoration: line-through;
        color: $gray-4;
        margin-left: 4px;
      }
    }
  }
}

.sku-color {
  width: 14px;
  height: 9px;
  border-radius: 2px;
  border: 1px solid $white-9;
  display: inline-block;
  padding-bottom: 12px;
  margin: -2.3px 0;
}

.sku-attribute:not(:last-of-type) {
  position: relative;
  padding-right: 6px;
  border-right: 1px solid $black-3;
  margin-right: 6px;

  &::after {
    content: '';
    border: 3.5px solid $white;
    position: absolute;
    padding: 4px 4px;
    border-left: none;
    border-right: none;
    top: -1px;
    bottom: -1px;
  }
}

.selected-customer-details {
  @include d-grid(1fr, auto, 4px, flex-start, flex-start);
}

.customer-form {
  max-height: calc(100vh - 236px);
  overflow-y: overlay;
  padding-right: 24px;
  margin-right: -24px;
  padding-left: 24px;
  margin-left: -24px;
  padding-bottom: 3px;
  margin-bottom: -3px;
  @include d-grid(1fr 1fr, auto, 24px);
  .col-span-2 {
    grid-column: 1 / span 2;
  }
}

.create-order-select-customer-modal {
  .dialog-content {
    padding: 24px 0 !important;
  }
}

.create-order-select-customer-modal-table {
  padding: 0px 12px;
  overflow-y: hidden;
  max-height: calc(100vh - 295px) !important;
}

.create-order-select-customer-modal-table-header {
  padding: 10px 12px;
}

.create-order-select-customer-modal-table-container {
  overflow-y: overlay;
  max-height: calc(100vh - 336px);
  & .table-data-row {
    padding-left: 12px;
    padding-right: 12px;
  }
  &:last-child {
    border-bottom: none !important;
  }

  a {
    color: $black-1;
  }
}

.select-customer-address-grid {
  max-height: calc(92vh - 236px);
  overflow-y: overlay;
  padding-right: 24px;
  margin-right: -24px;
  @include d-grid(1fr 1fr, auto, 14px, stretch);
}

.select-customer-address-button {
  background-color: $white;
  border-radius: 6px;
  border: 1px solid $white-9;
  padding: 16px;
  @include d-grid(20px 1fr, 1fr, 8px, flex-start, flex-start);
  cursor: pointer;
}

.create-order-country-dropdown {
  .custom-select__menu-list {
    max-height: 250px;
  }
}

.customer-addtional-information {
  @include d-grid(1fr, auto, 16px, flex-start, flex-start);
  .customer-addtional-information-file {
    max-width: 344px;
    padding: 10px 16px;
    background-color: $white-6;
    border-radius: 4px;
    cursor: pointer;
  }
}

.additional-information-dropdown-field {
  .custom-select__control {
    height: 50px;
  }
}

.additional-information-time-picker-field {
  .time-picker-div-input {
    max-width: 100%;
  }
  .timepicker-arrow-icon svg {
    @include content-size(16, 16);
    path {
      fill: $black-3;
    }
  }
}

.additional-information-file-field {
  .form-input-container {
    position: relative;

    .image-file-name {
      position: absolute;
      right: 10px;
      top: 43px;
      background-color: white;
      width: calc(100% - 150px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.attribute-file-text {
    .uploaded-file-name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: left;
      word-break: break-all;
    }
  }
  .cross-icon-abs {
    right: 12px;
  }
}

.payment-details-grid {
  @include d-grid(1fr 40%, auto, 8px, center);
}

.payment-method-grid {
  @include d-grid(1fr, auto, 12px);
}

.payment-disclaimer {
  background-color: $white-9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 12px 24px;
  margin-top: 24px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
}

.source-select {
  .custom-select__control {
    font-size: 14px;
    line-height: 20px;
  }
}

.variant-wrapper {
  .source-select {
    .custom-select__control {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.white-download-icon {
  background-color: $white;
  border: 1px solid $gray-3;
  svg {
    path {
      fill: $black-3;
    }
  }
}

.manual-order-coupon-modal {
  &.has-coupon .dialog-content {
    padding-bottom: 16px;
  }
}

.coupon-divider {
  height: 4px;
  width: 64px;
  background-color: $orange-1;
  margin-top: 8px;
  margin-bottom: 20px;
}

.all-coupons-grid {
  @include d-grid(1fr, auto, 16px, flex-start, flex-start);
  margin: 0 -24px;
  padding: 0 24px;
  overflow-y: overlay;
  max-height: calc(92vh - 300px);
  &.has-coupon {
    max-height: calc(92vh - 350px);
  }
}

.coupon-grid {
  @include d-grid(1fr, auto, 10px, flex-start, flex-start);
  &:not(:nth-last-of-type(2)) {
    padding-bottom: 16px;
    border-bottom: 1px solid $white-9;
  }

  .coupon-name {
    position: relative;
    overflow: hidden;

    .code {
      padding: 2px 12px;
      border: 1px solid $orange-1;
      border-radius: 2px;
      background-color: rgba($orange-1, 0.1);
    }

    .circle {
      height: 12px;
      width: 12px;
      background-color: $white;
      border: 1px solid $orange-1;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      z-index: 1;

      &.right {
        left: calc(100% - 5px);
      }

      &.left {
        left: -7px;
      }
    }
  }
  .coupon-accordion {
    &.coupon-accordion-root {
      border-radius: 0;
      box-shadow: none;

      &:last-child {
        padding: 0;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
      }

      & .MuiAccordionSummary-root {
        padding: 0px;
        min-height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        & .MuiAccordionSummary-content {
          margin: 0px;
          flex-grow: 0;
        }

        & .MuiAccordionSummary-expandIcon {
          padding: 0;
        }
      }

      .MuiAccordionDetails-root {
        padding: 0px;
        margin-top: 8px;

        .coupon-ul {
          .coupon-li {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 6px;

            .disc {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: $gray-1;
              margin: 6px 8px 0px;
            }
          }
        }
      }
    }
  }
}
