.overview-primary-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  background-color: $purple-1;
  overflow: none;

  .footer {
    @extend .d-flex-c-s, .px24, .py16, .c-white-6;
    background-color: $purple-hover;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.payment-overview-cards-container {
  flex: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 20px;
}

.tabs-scroller {
  border-bottom: solid 1px $white-2;
}

.payment-provider-additional-setup {
  background-color: $white-10;
  border-radius: 8px;
  border: solid 1px $white-9;
  padding: 12px 16px;
}