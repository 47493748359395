// size chart card
@mixin common-category-text {
  width: -webkit-fill-available;
  @include font-size-weight(16, 20, 400);
  position: absolute;
  top: 30px;
  background-color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 12px 16px;
  .category-name-not-selected {
    color: $gray-1;
  }
}

.size-chart-card-section {
  max-height: calc(100vh - 316px);
  overflow: overlay;
  margin: 24px -24px 0px;
  padding: 0 24px;
  .size-chart-card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }
}

.size-chart-card {
  width: 344px;
  height: 104px;
  background: $white-10;
  border: 1px solid $white-9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
}

.size-chart-table-container {
  overflow-x: scroll;
}

.size-chart-card-heading {
  position: sticky;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .size-chart-name-section {
    width: 70%;
    justify-content: flex-start;
    h6 {
      @extend .section-text-17;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      align-items: center;
      margin-bottom: 12px;
      font-style: normal;
      color: $black-1;
    }
  }
  .size-chart-card-chip {
    padding: 1px 6px;
    height: 16px;
    background: $gray-1;
    border-radius: 2px;
    margin-left: 8px;
    margin-top: 2px;
    p {
      color: $white;
      @include font-size-weight(10, 14, 500);
    }
  }
}

.size-chart-card-text {
  display: flex;
  h6 {
    @extend .section-text-7;
    font-style: normal;
    color: $black-3;
    display: inline-block;
  }
  p {
    font-style: normal;
    @extend .section-text-14;
    color: $black-3;
    display: inline-block;
    margin-left: 8px;
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.heading {
  @extend .section-text-5;
  display: inline-block;
  font-style: normal;
  color: $black-1;
}

.template-select {
  width: 50%;
  .custom-select__control {
    height: 50px;
  }
}
.template-fields {
  .size-chart-name {
    margin-top: 4px;
    margin-bottom: 0;
  }
  .template-details-fields {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
}

.size-chart-input-section {
  width: 50%;
  height: 50px;
  position: relative;
  .template-category-text {
    @include common-category-text;
  }
}

.size-chart-table {
  width: 100%;
  th,
  td {
    border: 1px solid $white-9;
    border-collapse: collapse;
    position: relative;
    height: 36px;
    min-height: 36px;
    display: table-cell;
    &.focused-cell {
      outline: 1px solid $purple-1;
    }
  }
}

.size-chart-table-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: sticky;
  left: 0;
  margin-bottom: 24px;
  h6 {
    @extend .section-text-17;
    display: flex;
    align-items: center;
    font-style: normal;
    color: $black-1;
  }
  .measurement {
    border: 1px solid $white-2;
    border-radius: 4px;
    padding: 4px 8px;
    height: 32px;
    cursor: pointer;
    .chevron-size {
      height: 18px;
      width: 18px;
    }
  }
}

.measurement-menu {
  .MuiMenuItem-root {
    padding: 8px 0px;
    .menu-label .content-grp {
      @extend .text-2;
    }
  }
}

.size-chart-input {
  width: 100%;
  min-width: 125px;
  margin-bottom: -6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: $black-1;
  resize: none;
  padding: 4px;
  background: border-box;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  border: none;
}

.heading-input {
  textarea {
    font-weight: 500;
  }
}

// Settings popover
.settings-popover-container {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: calc(102% + 15px);
  z-index: 100;
  > div {
    background-color: white;
  }
  margin-bottom: 1px;
}
.settings-popover {
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding: 8px 10px;
  border: 1px solid $white-2;
  border-radius: 5px;
  position: relative;
  width: 140px;
  height: 45px;
}

.settings-popover-arrow {
  height: 15px;
  width: 15px;
  top: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
}

.settings-popover-arrow,
.settings-popover-arrow::before {
  display: block;
  box-sizing: border-box;
  border-right: 3px solid transparent;
}

.settings-popover-arrow::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-left: 1px solid $white-2;
  border-top: 1px solid $white-2;
  border-bottom: 1px solid transparent;
  transform: rotate(225deg) skew(10deg, 10deg);
  bottom: 5px;
  background-color: $white;
}

.settings-popover-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px;
  z-index: 10;
  width: 100%;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    svg:nth-child(2) {
      margin-left: 6px;
    }
  }
  span:hover {
    background-color: $white-6;
    border-radius: 5px;
  }
}

.settings-popover-menu {
  position: absolute;
  top: 80%;
  background: $white;
  width: 253px;
  z-index: 99;
  background: $white;
  box-shadow: 0px 10px 20px rgba(152, 152, 152, 0.3);
  border-radius: 4px;
}

.settings-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px !important;
}

.settings-dropdown-item:hover {
  background-color: $white-4 !important;
  border-radius: 4px;
  cursor: pointer;
}

.highlight-header {
  background: $white-6;
  color: $black-1;
}

.size-chart-modal {
  .dialog-content {
    & > div:first-child {
      @include display-flex(center, space-between);
      height: 26px;
      margin-bottom: 24px;
      .dialog-title-text {
        @extend .section-text-4;
        position: static;
        cursor: unset;
      }
      .dialog-close-icon {
        position: static;
        margin-right: -12px;
      }
    }
    .size-chart-input-modal {
      position: relative;
      .category-text-modal {
        @include common-category-text;
      }
    }
    .create-btn {
      @include display-flex(center, center);
    }
  }
  .template-caption {
    color: $black-3;
  }
}

.create-template-btn {
  p {
    color: $purple-1;
    margin-left: 9px;
  }
}

.change-background {
  background: $white-6;
}
