@import '../mixins';
@import '../variables';

.auth-layout  {
  background-color: $purple-1;
  @include display-flex;
  .auth-left-background {
    position: absolute;
    left: 0;
    top: 0;
  }
  .auth-right-background {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: rotate(180deg);
  }
  .auth-bottom-text {
    color: $white;
  }
  &.has-errors {
    height: calc(100% + 64px);
  }
  .auth-wrap {
    padding: 48px;
    width: 448px;
    .logo {
      @include content-size(200, 52);
      margin-bottom: 24px;
    }
    &.max-height{
      max-height: 90vh;
    }
  }
  .form-group-lg {
    .field-label {
      @include font-size-weight(16, 24, 400);
      color: $black-3;
    }
    &.has-prepend-text .input-group-wrap .prepend-txt {
      @include font-size(18, 26);
      top: 0;
    }
    input {
      @include font-size(18, 26);
      padding: 11px 16px;
    }
  }
  .error-alert {
    border: 1px solid $red-1;
    @include font-size(16, 24);
    padding: 11px 16px;
    border-radius: 4px;
  }
  .success-alert {
    border: 1px solid $green-1;
    @include font-size(16, 24);
    padding: 11px 16px;
    border-radius: 4px;
  }
}

.otp-verfication-form {
  .otp-input-wrap {
    .form-group {
      margin-bottom: 0;
    }
    .field-label {
      @include display-flex(center, space-between);
    }
    input {
      height: 50px;
      border-radius: 6px;
      border: 1px solid $white-2;
      color: $black-1;
      width: 50px !important;
      @include font-size-weight(32, 38, 500);
      margin-right: 10px;
      padding: 11px 8px;
      &:focus {
        outline: none;
        border-color: $purple-1;
      }
    }
  }
  &.invalid-otp-verfication-form {
    .otp-input-wrap {
      input {
        border-color: $red-1 !important;
        box-shadow: $focus-red-box-shadow;
      }
    }
  }
  .resend-otp-timer, .resend-otp-link {
    @include font-size-weight(14, 20, 500);
  }
}

.login-other-options {
  // margin: 0 -6px;
  button {
    @include display-flex;
    @include font-size-weight(16, 24, 500);
    margin: 0 6px;
    flex: 1;
  }
}

.email-phone-input-wrap {
  .input-group-wrap {
    position: relative;

    .phone-code-select {
      position: absolute;
      right: 0;
      top: 0;
      .custom-select__control {
        border: 0 !important;
        background-color: transparent;
      }
    }
  }

  .custom-select__menu {
    width: 352px !important;
    right: 0;
    top: 55px;
  }
}

.country-select {
  .registration-select {
    &.left .custom-select__menu { left: 0; }
    &.right .custom-select__menu { right: 0; }
    .custom-select {
      &__menu {
        width: 352px;
        &-list {
          max-height: 236px;
        }
      }
    }
    .custom-select__value-container{
      .css-b8ldur-Input{
        input{
          box-shadow: none;
        }
      }
    }
  }
}

.login-loader-container {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($black-1, 0.1);
}

.env-switch {
  position: absolute;
  top: 24px;
  left: 24px;
}
