.store-locator-plugin {
  @include dynamicMaxWidths(728 760);

  .actions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .offline-store-card-parent {
    display: grid;
    grid-row-gap: 16px;
    .offline-store-card {
      background-color: $white-10;
      border: 1px solid $white-9;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 12px 16px;
      .offline-store-card-column-components{
        @extend .d-flex-fs-stretch;
        flex-direction: column;
        gap: 16px;
        margin-right: 12px;
      }
     
      .action-menu-icon {
        margin-right: -12px;
        .icon {
          color: $black-3;
        }
      }
      .black-clock-icon {
        path {
          fill: $black-3;
        }
      }
      .gray-clock-icon{
        path {
          fill: $gray-1;
        }
      }
      .rotateArrow {
        transform: rotate(180deg);
      }
      .timings-grid-view {
        display: grid;
        justify-items: start;
        column-gap: 16px;
        row-gap: 8px;
        grid-template-columns: 40px auto;
      }
    }
  }
  .store-locator-search-filter {
    max-width: 200px;
  }
}

.store-locator-form-container {
  @include dynamicMaxWidths(728 760);
  .address-select-wrapper{
    position: relative;
    .address-select-content{
    position: absolute;
    width: 100%;
    bottom: 8px;
    transform: translateY(100%);
    background: $white;
    border: 1px solid $white-6;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(26, 24, 30, 0.24);
    border-radius: 4px;
    z-index: 1030;
    .address-list{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .address-list-item{
        border-radius: 4px;
        &:hover {
          background-color: $white-6;
          padding-left: 8px;
          margin-left: -8px;
        }
      }
    }
    }
  }
}
