.home-page {
  padding: 32px;
  padding-top: 20px;
  .share-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .action-bar {
      width: 100%;
      padding: 20px;
      background-color: rgba($black-1, 0.1);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      justify-self: flex-end;
      &.buffer-time-view {
        color: $red-1;
        background-color: rgba($red-1, 0.1);
      }
      &.domain-processing {
        color: $orange-1;
        background-color: rgba($orange-1, 10%);
      }
    }
  }
  .overview-cards-section {
    .overview-list-filter-drowdown {
      width: 140px;
      display: flex;
      justify-content: space-between;
    }
    .overview-cards {
      display: flex;
      .overview-card-item {
        flex: 1;
        padding: 20px;
        transition: box-shadow 0.2s;
        .overview-count {
          @include font-size-weight(32, 38, 500);
        }
        svg {
          cursor: pointer;
        }
        &:hover {
          box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.1);
        }
      }
    }
  }
  .home-dynamic-layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 32px;
    .left-section {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 32px;
      grid-column: 1 / span 2;
    }
    .right-section {
      flex: 1 1 auto;
      .active-orders {
        position: sticky;
        top: 32px;
        .card-hover {
          transition: box-shadow 0.15s ease-in-out;
          &:hover {
            box-shadow: 0px 4px 12px rgba(26, 24, 30, 0.1);
          }
        }
        .pending-orders,
        .delivery-icon {
          g path {
            fill: $black-1;
          }
        }
      }
    }
    &.isEnterprise {
      .left-section {
        grid-column: 1 / span 3;
      }
    }
  }
}

.store-name-concat {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-word;
  -webkit-box-orient: vertical;
}

// 980px and below
@media (max-width: 980px) {
  .store-name-container {
    display: none !important;
  }
}

// 980px and up
@media (min-width: 980px) {
  .store-name-container {
    width: 8vw;
  }
}

// 1000px and up
@media (min-width: 1000px) {
  .store-name-container {
    width: 10vw;
  }
}

// 1100px and up
@media (min-width: 1100px) {
  .store-name-container {
    width: 15vw;
  }
}

// 1200px and up
@media (min-width: 1200px) {
  .store-name-container {
    width: 17vw;
  }
}

// 1300px and up
@media (min-width: 1300px) {
  .store-name-container {
    width: 18vw;
  }
}

// 1400px and up
@media (min-width: 1400px) {
  .store-name-container {
    width: 20vw;
  }
}

// 1600px and up
@media (min-width: 1600px) {
  .store-name-container {
    width: 24vw;
  }
}

// 2100px and up
@media (min-width: 2100px) {
  .store-name-container {
    width: 30vw;
  }
}

// 2600px and up
@media (min-width: 2600px) {
  .store-name-container {
    width: 33vw;
  }
}

.share-via {
  @include display-flex(center, flex-start);
  > ul {
    margin-left: 20px;
    display: inline-flex;
    li {
      display: inline-flex;
      margin-right: 12px;
      > a {
        display: inline-flex;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.verify-icon {
  @include content-size(20, 20);
  margin-left: 8px;
}

.overview-list-filter-select {
  width: 140px;
}

.onboarding-card {
  max-width: 760px;
}

.onboarding-step-detail {
  flex: 1 1 62.5%;
}

.onboarding-icon-container {
  flex: 1 1 37.5%;
  background-color: $white-13;
  height: 140px;
  padding: 24px 32px 0px;
  overflow: hidden;
}

.onboarding-step-icon {
  width: 158px;
  height: 140px;
  transition: transform 0.3s ease-out;
  &:hover {
    transform: translateY(-12px);
  }
}

.onboarding-product-importer {
  @extend .cur-p, .d-flex-c-c;
  padding: 7px;
  border-radius: 4px;
  border: 1px solid $white-2;
  &:hover {
    background-color: $white-10;
  }
}

.setup-payment-card {
  @include box-style;
  @include display-flex(center, space-between);
  background-color: white;
  padding-right: 16px;
  border-radius: 6px;

  .btn-outline-warning:hover {
    color: $orange-1;
  }

  .orange-stripe {
    background-image: url('../../images/icons/orange-stripe.svg');
    min-height: 52px;
    height: 100%;
    min-width: 269px;
    display: flex;

    .header {
      @extend .section-text-5;
      color: white;
      padding: 14px 16px;
    }
  }
}

.marketing-stripe {
  @include box-style;
  @include display-flex(center, space-between);
  background-color: white;
  padding-right: 16px;
  border-radius: 6px;

  &.sm {
    padding-right: 8px;
  }

  .btn-outline-warning:hover {
    color: $orange-1;
  }

  .stripe {
    min-height: 52px;
    height: 100%;
    min-width: 269px;
    display: flex;

    &.sm {
      min-height: 48px;
      min-width: 242px;
    }
    &.abandoned-cart {
      background-image: url('../../images/icons/abandoned-cart-stripe.svg');
      min-width: 317px;
    }

    .header {
      @extend .section-text-5;
      // color: white;
      padding: 14px 16px;

      &.font-14 {
        font-size: 14px !important;
      }

      &.sm {
        @extend .text-2;
        padding: 12px 16px;
      }
    }
  }
}

.video-banner {
  @include box-style;
  @include display-flex(center, space-between);
  background-color: white;
  padding-right: 16px;
  border-radius: 6px;

  .video-banner-stripe {
    position: relative;
    @extend .d-flex-c-c;
  }

  .video-title {
    position: absolute;
    margin-left: 8px;
    width: fit-content;
  }
}

.dukaan-delivery-banner {
  background-color: #e9bc95;
  border-radius: 6px;
  background-image: url('../../images/dukaan-delivery-banner-bg.svg');
  background-position: bottom 0px right 10px;
  background-repeat: no-repeat;
}

.offer-banner {
  height: 65px;
  background-color: $orange-4;
  &.danger {
    background-color: $red-2;
    color: $white;
  }
  padding: 12px 8px;
  button {
    border: none;
  }
  @extend .d-flex-c-c;

  .timer-data {
    @extend .section-text-3;
    margin-bottom: -4px;
  }

  .countdown-row {
    height: 40px;
  }

  .timer-period {
    @include font-size(10, 10);
  }
  .offer-badge-close {
    position: absolute;
    right: 40px;
    height: 24px;
  }
}

.dialog-paper.select-store-modal {
  margin: 0;
  max-height: 90vh;
  .dialog-content {
    max-height: none;
    .dialog-close-icon {
      background-color: #fff;
    }
  }
}

.store-list-container {
  padding: 0 12px;
  margin: 0 -12px;
  overflow-y: auto;
  gap: 16px;
  padding-bottom: 2px;
  max-height: calc(90vh - 130px);
  &.max-height {
    max-height: calc(90vh - 271px);
  }
  .store-info {
    width: 100%;
    @include display-flex(center, space-between);
    padding: 12px 16px;
  }
  .primary-store {
    background: $white-6;
    background: #f2f2f2;
    width: calc(100% + 1px);
    margin-left: -0.5px;
    margin-bottom: -0.5px;
    border-radius: 0px 0px 2.7px 2.7px;
  }
}

.store-list-item {
  @include display-flex(flex-start, space-between);
  border-radius: 4px;
  border: 1px solid $white-9;
  cursor: pointer;
  flex-direction: column;
  box-sizing: border-box;
  &:hover {
    box-shadow: 0px 4px 12px 0px #1a181e1a;
    transition-timing-function: ease-in-out;
    transition-delay: 0.03s;
  }
  .store-description {
    &.current {
      width: 75%;
    }
    width: 90%;
  }
  .current-label {
    width: 22%;
    background-color: #ee741f26;
    opacity: 15;
    border-radius: 3px;
    padding: 2px 6px;
    color: #ee741f;
    @extend .text-10, .text-semibold;
    text-transform: uppercase;
  }
  &.first-order {
    order: -1;
  }
}

.dialog-paper.delete-store-modal {
  max-height: calc(100vh - 140px);
  .dialog-content {
    padding: 0;
  }
  .delete-store-content {
    padding: 24px;
    .msg {
      background-color: rgba($orange-1, 10%);
      color: $orange-1;
      border: 1px solid $orange-1;
      @extend .section-text-13;
      border-radius: 4px;
    }
    .store-card-list {
      max-height: calc(100vh - 481px);
      overflow: auto;
      .store-card {
        border: 1px solid $white-9;
        border-radius: 4px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .footer {
    border-top: 1px solid $white-2;
  }
}

.getting-started-section {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;

  .content-item-card {
    @extend .card, .d-flex;
    min-height: 204px;
    align-items: stretch;
  }

  .image-container {
    border: 0 !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 100%;
    min-height: 204px;
    min-width: 204px;
    max-width: 204px;
    @extend .cur-p;
    position: relative;
    .content-play-icon {
      position: absolute;
    }
  }
  .video-container {
    &:hover {
      filter: brightness(0.9);
    }
  }

  .content-item-image {
    overflow: hidden;
    height: 100%;
    min-height: 204px;
    min-width: 204px;
    max-width: 204px;
    object-fit: contain;
    background-color: $white-10;
  }

  .content-item-body {
    padding: 20px;
    @extend .d-flex-start-s;
    flex-direction: column;
  }
}

.recommendations {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 24px;

  &__card {
    @extend .card;
    position: relative;
    &__image-container {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 180px;
      width: 180px;
      @extend .cur-p;
      position: relative;
      float: left;
      margin-right: 16px;

      .content-image {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        height: 180px;
        width: 180px;
      }

      .content-play-icon {
        position: absolute;
        z-index: 1;
        top: 58px;
        left: 58px;
      }

      &:hover {
        filter: brightness(0.9);
      }
    }
    .plugin-icon {
      height: 52px;
      width: 52px;
      border-radius: 4px;
      float: left;
    }
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.shortcuts-grid {
  @extend .card;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  &-item {
    display: grid;
    grid-template-rows: 40px 24px;
    border: 1px solid $white-9;
    padding: 16px;
    gap: 12px;
    border-radius: 6px;
    transition: background-color 0.15s ease-in-out;
    &:hover {
      background-color: $white-10;
    }
    .icon {
      @include content-size(40, 40);
      border-radius: 4px;
    }
    &.add-new {
      border: 1px dashed $white-2;
      gap: 12px;
      grid-template-rows: 76px;
      grid-template-columns: 18px auto;
      align-items: center;
      justify-content: center;
    }
  }
  &.isEnterprise {
    grid-template-columns: repeat(4, 1fr);
  }
}

.shortcuts-reordering-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 16px;
  margin-right: -6px;
  .shortcuts-reordering-card {
    display: grid;
    grid-template-columns: 20px 40px 1fr 32px;
    gap: 16px;
    align-items: center;

    .drag-icon {
      div {
        background: $black-3;
        border-radius: 4px;
        width: 20px;
        height: 2px;
        margin: 2px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0px;
        top: -6px;
        height: 24px;
      }
    }

    .shortcut-icon {
      border-radius: 4px;
    }

    .cross-icon {
      border-radius: 50%;
      height: 32px;
      width: 32px;
      cursor: pointer;
      @extend .d-flex-c-c;

      &:hover {
        background-color: $white-6;
      }
    }
  }
}

.hr-line {
  height: 1px;
  border: none;
  margin-left: -24px;
  margin-right: -24px;
  background-color: $white-3;
  &.mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
}

.add-shortcuts-category-grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  gap: 16px;
  .add-shortcuts-category-row {
    display: grid;
    gap: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid $white-9;
    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.add-shortcut-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 16px;
  .add-shortcut-row {
    display: grid;
    grid-template-columns: 40px 1fr 60px;
    grid-template-rows: 1fr;
    align-items: center;
    gap: 16px;
    .shortcut-icon {
      border-radius: 4px;
    }
    .add-shortcut-btn {
      justify-self: flex-end;
      @extend .anchor-1, .section-text-7;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &.disabled {
        opacity: 0.4;
        text-decoration: none;
        cursor: not-allowed;
      }
    }
  }
}

.add-shortcuts-results {
  max-height: calc(100vh - 400px);
  overflow-y: overlay;
  padding-right: 24px;
  margin-right: -24px;
}

.shortcut-icon {
  @include content-size(40, 40);
  border-radius: 4px;
}

.info-v2-stripe {
  height: 36px;
  border-radius: 4px;
  &.red {
    background-color: $red-6;
    border: 1px solid $red-1;
    color: $red-1;
    button {
      color: $red-1;
      &:hover {
        color: $red-2;
        background: none;
      }
    }
  }
  p {
    @extend .text-8;
    @extend .text-normal;
  }
  button {
    background: none;
    text-decoration: underline;
    padding: 0;
    &:hover {
      background: none;
    }
  }
  svg {
    width: 10px;
    height: 10px;
    margin-left: 10px;
  }
  &.rejected-by-vendor .warning-icon {
    width: 20px;
    height: 20px;
    margin-left: 0;
  }
}

.danger-stripe {
  border: solid 1px rgba(229, 11, 32, 0.1);
  background-color: rgba(229, 11, 32, 0.1);
}

.warning-stripe {
  border: solid 1px rgba($orange-1, 0.1);
  background-color: rgba($orange-4, 0.2);
}

// hover color class for redirect store link
.redirect-hover path {
  fill: $orange-2;
}

.store-link-redirect {
  @extend .text-medium, .anchor-4, .d-flex-c-c;
  text-decoration: underline;
  word-break: break-all;
}

.animate-icon-hover {
  transition: all;
  .animated-icon {
    transform: translateX(0px);
    transition: transform 0.3s ease;
  }
  .left-icon {
    color: black;
  }
  &:hover {
    .animated-icon {
      transform: translateX(10px);
      transition: transform 0.3s ease;
      &.small-shift {
        transform: translateX(5px);
      }
    }
  }
}

.store-actions-section {
  height: 296px;

  .store-link {
    @extend .d-flex, .space-between, .bottom-xs;
    height: 25px;
  }
}
.onboarding-masterclass{
  max-width: 760px;
  background-color: $white;
}
.masterclass-coach-image{
  width: 214px;
  height: 192px;
  border-radius: 8px 0 0 8px;
}