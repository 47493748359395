.account-page {
  position: relative;
  margin: 32px auto;
  max-width: 760px;
  padding: 12px;
  .account-header {
    @include display-flex(center, flex-start);
    padding: 12px 0 16px;
    margin: 0 12px;
    border-bottom: 1px solid $white-2;
    img {
      @include content-size(96, 96);
      border-radius: 6px;
      margin-right: 20px;
      object-fit: contain;
    }
  }
  .account-action-items {
    padding-top: 20px;
    &.no-padding {
      padding-top: 0;
    }
    > li {
      .account-action-item {
        padding: 10px 12px;
        border-radius: 4px;
        @include font-size(18, 26);
        margin: 3px 0;
        display: flex;
        align-items: flex-end;
        color: $body-color;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          color: $body-color;
          background-color: $white-1;
        }
        &:focus {
          outline: none;
        }
        .account-action-item-action {
          @include display-flex(center, flex-start);
        }
      }
    }
  }
}

.qr-code-page {
  margin: 32px auto;
  max-width: 760px;
  padding: 12px 12px 24px;
}

#final-image {
  background-size: cover;
  max-height: 270px;
  z-index: 2;
  max-width: 1120px;
  width: 100%;
  vertical-align: bottom;
}

#image_container {
  min-height: 270px;
  display: flex;
  align-items: center;
  position: relative;

  .transparent-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.image-tool-container {
  padding: 24px;
  max-width: 760px;
  margin: 32px auto;
  position: relative;

  .suggested-background-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 8px;

    .suggested-background-box {
      cursor: pointer;
      width: 64px;
      height: 64px;
      border: 1px solid $white-9;
      border-radius: 4px;
      background-position: center;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      &.active {
        border: 1px solid $purple-1;
      }

      img {
        width: 100%;
        object-fit: contain;
        height: 100%;
      }
    }
    .transparent-btn {
      background-size: 600%;
    }

    .color-picker-btn {
      img {
        width: auto;
        height: auto;
      }
    }

    .custom-background-btn {
      color: $gray-1;
      position: relative;
      border: 1px solid $white-9;
      background-color: $white-10;
      border-radius: 4px;

      input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: pointer;
      }
      svg {
        path {
          fill: $gray-1;
        }
      }
    }
  }

  .upload-image-container {
    position: relative;
    border: 1px dashed $gray-3;
    width: 100%;
    border-radius: 6px;

    .compare-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;
      align-items: center;
      background-color: white;
      border: 1px solid $white-2;
      border-radius: 4px;
      z-index: 10;
      padding: 6px 16px;
      &:hover {
        background-color: $white-1;
      }
      svg {
        margin-right: 5px;
        path {
          fill: $black-3;
        }
      }
    }

    .background-remover-preview {
      background-size: cover;
    }

    .toggle-button {
      span {
        font-size: 14px !important;
      }
    }

    .upload-input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      input {
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    > button {
      svg {
        width: 20px;
        height: 20px;
        margin-right: 12px;
        path {
          fill: white;
        }
      }
    }
    &:hover > .btn-primary-4 {
      background-color: $purple-2;
      transition: 0.15s ease-in-out;
    }
  }
  .upload-error {
    border-color: $red-1;
  }
  .color-picker-container {
    position: absolute;
    top: 50%;
    left: 25%;
    z-index: 2;
    .color-picker-backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #333;
      opacity: 0.3;
      z-index: -1;
    }
  }
}

.upload-new-image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .upload-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}

.preview-popup {
  .preview-popup-inner {
    position: fixed;
    top: 3%;
    right: 0;
    left: 0;
    height: calc(100% - 40px);
    z-index: 1102;
    display: flex;
    align-items: center;
    .preview-pop-box {
      max-width: 760px;
      max-height: 414px;
      background-color: #fff;
      margin: 0 auto;
      border-radius: 8px;
      padding: 24px;
      width: 100%;
    }
    .preview-pop-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      h4 {
        @include font-size-weight(18, 26, 500);
        color: $black-2;
      }
      .popupClose {
        background-image: url('../../images/icons/cross.svg');
        background-repeat: no-repeat;
        background-position: 0px 0px;
        height: 16px;
        display: inline-block;
        cursor: pointer;
      }
    }
    .preview-pop-content {
      text-align: center;
      border: 1px solid #e6e6e6;
      display: flex;
      justify-content: center;
      height: 246px;
      .preview-pop-inner-img {
        border: 1px solid #e6e6e6;
        display: inline-flex;
        justify-content: center;
        min-height: 246px;

        img {
          max-width: 196px;
          max-height: 196px;
        }
      }
      h4 {
        @include font-size-weight(14, 20, 400);
        color: $black-1;
        margin: 12px 0px 16px 0px;
      }
      .priview-pop-compare {
        img {
          max-width: 690px;
          max-height: 350px;
          height: 100%;
        }
      }
    }
    .preview-pop-footer {
      text-align: center;
      margin-top: 24px;
      .btn-0 {
        background-repeat: no-repeat;
        background-position: 24px 9px;
        padding: 8px 24px;
        @include font-size-weight(16, 24, 400);
        text-indent: 34px;
      }
    }
  }
  .preview-pop-bg {
    display: none;
    &.active {
      background-color: #000;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      opacity: 0.4;
      z-index: 1101;
    }
  }
}

.priview-pop-compare {
  position: relative;
  max-width: 690px;
  max-height: 350px;
  margin: 0 auto;
  height: 100%;
  align-self: center;
}

.bg-remove-slile-img {
  .react-draggable {
    transform: translate(0px, 0px) !important;
    max-width: 100% !important;
    right: 0;
    left: 0 !important;
    border: none !important;
    height: 100% !important;
    background-size: cover;
    max-height: 350px !important;

    div {
      &:nth-child(2) {
        width: 2px !important;
        right: -2px !important;
        background-color: #fff !important;
        cursor: pointer !important;

        &::after {
          background-image: url('../../images/icons/drag-icon.svg');
          content: '';
          position: fixed;
          width: 32px;
          height: 32px;
          right: -18px;
          top: 40%;
          background-repeat: no-repeat;
          background-position: 0px 0px;
          outline: none;
          box-shadow: none;
          margin: 0px;
          padding: 0px;
          cursor: pointer;
          background-size: 100%;
        }
      }
    }
  }

  &.enhance-banner {
    .react-draggable {
      max-width: 97.3% !important;
      max-height: 420px !important;

      div {
        &:nth-child(5)::before {
          content: 'BEFORE';
          position: absolute;
          background: $black-3;
          border-radius: 3px;
          padding: 2px 6px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: $white;
          top: 22px;
          left: -67px;
        }

        &:nth-child(5)::after {
          content: 'AFTER';
          position: absolute;
          background: $black-3;
          border-radius: 3px;
          padding: 2px 6px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: $white;
          top: 22px;
          right: -58px;
        }
      }
    }
  }
}

.enhancer-image-preview {
  @extend .upload-image-container;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 172px;
  .magnification-buttons {
    box-shadow: 0 2px 8px rgb(26 24 30 / 6%);
    background: hsla(0, 0%, 100%, 0.9);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 100;
    padding: 4px;
    .zoom-in,
    .zoom-out {
      display: flex;
      background-color: transparent;
      padding: 10px 4.5px 6px;
      border: none;
      cursor: pointer;
    }
    .zoom-in {
      border-bottom: 0.8px solid $white-2;
      padding-bottom: 10px;
      padding-top: 6px;
    }
  }
  .preview-pop-content {
    position: relative;
    overflow: hidden;
    text-align: center;
    border: 1px solid #e6e6e6;
    display: flex;
    justify-content: center;
    max-height: 420px;
    .preview-pop-compare {
      position: relative;
      margin: 0 auto;
      align-self: center;
      overflow: hidden;
      max-height: 420px;
      transition: 1s ease;
      .enhanced-image {
        max-width: 690px;
        max-height: 420px;
        height: 100%;
        vertical-align: bottom;
      }
      .image-breathing-width {
        max-width: 100%;
        border-radius: 6px;
      }
    }
  }
}

.product-description-container {
  padding: 24px;
  max-width: 760px;
  margin: 32px auto;

  .product-description-input {
    position: relative;
    button {
      cursor: pointer;
      position: absolute;
      border: 0;
      background-color: white;
      top: 17px;
      right: 18px;
      svg {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
  }
  .product-description-textarea {
    border: 1px solid $white-2;
    border-radius: 4px;
    width: 100%;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 20px;
    resize: none;
    outline-width: 0;
    &:focus {
      border: 1px solid $purple-1;
      box-shadow: $focus-blue-box-shadow;
    }
  }
  .product-highlight-keyword {
    line-break: anywhere;
  }
}

.manage-page {
  margin: 32px;

  .payment-menu {
    margin: -10px -12px 10px -12px;
    .payment-menu-item {
      cursor: pointer;
      padding: 10px 12px;
      border-radius: 4px;
      &:hover {
        background-color: $white-1;
      }
    }
  }
}

.delete-account-page {
  margin: 32px auto;
  max-width: 760px;
  padding: 24px;
}

.otp-button {
  @extend .btn-outline-4;
  @include font-size-weight(14, 20, 500);
}

.qr-code-page-content {
  display: flex;
  justify-content: center;
}

.pdf-content {
  height: 432px;
  width: 288px;
  border: solid 1px $white-8;
  border-radius: 8px;
  overflow: hidden;
}

.additional-info-page {
  margin: 32px auto;
  max-width: 760px;
  padding: 12px 12px 24px;
  .additional-info-items {
    margin-bottom: 28px;
    > li {
      .additional-action-item {
        color: $body-color;
        display: block;
        padding: 10px 12px;
        border-radius: 4px;
        @include font-size(18, 26);
        margin: 3px 0;
        &:hover {
          color: $body-color;
          background-color: $white-1;
        }
      }
    }
  }
  .support-help-text {
    letter-spacing: 1.43px;
    @include font-size(14, 20);
    color: $gray-4;
    margin-bottom: 6px;
  }
  .support-number-text {
    @include font-size-weight(16, 24, 500);
    letter-spacing: 1.6px;
    color: $black-3;
  }
}

.delivery-charges-page {
  .form-group {
    // max-width: 344px;
    margin-bottom: 0;
  }

  .row {
    margin: 0 -12px;
    .col-md-6 {
      padding: 0 12px;
    }
  }
  .add-extra-charges {
    display: flex;
    justify-content: center;
    .add-extra-charges-btn {
      border: solid 1px #d9d9d9;
      cursor: pointer;
      margin-top: 12px;
    }
  }
}

.edit-detail-page {
  margin: 32px auto;
  max-width: 760px;
}

.store-image-section {
  margin-bottom: 24px;
  padding: 24px;

  .store-image-wrap {
    @include display-flex(center, flex-start);
    gap: 16px;
    img {
      height: 96px;
      width: 96px;
      object-fit: contain;
    }
    .store-image-label {
      cursor: pointer;
      position: relative;
      .close-ic {
        position: absolute;
        width: 100%;

        height: 100%;
        z-index: 1;
        border-radius: 4px;
        .icon {
          position: absolute;
          top: 6px;
          right: 6px;
          height: 20px;
          visibility: hidden;
        }

        &:hover {
          background: linear-gradient(
            180deg,
            #000000 0%,
            rgba(0, 0, 0, 0) 47.92%
          );
          .icon {
            visibility: visible;
          }
        }
      }
    }
  }
}

.store-detail-section {
  margin-bottom: 24px;
  padding: 24px;
  .form-group {
    margin-bottom: 24px;
    &.store-address-group {
      margin-bottom: 0;
    }
  }
  .row {
    margin: 0 -12px;
    .col-md-6 {
      padding: 0 12px;
    }
  }
}

.upload-image-with-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    // @include content-size(96, 96);
    border-radius: 6px;
    object-fit: contain;
    border: 1px solid $white-9;
  }
  .overlay {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
    cursor: pointer;
  }

  &.large {
    margin-right: 0;
    margin-left: 12px;
    img {
      @include content-size(120, 120);
    }
  }
}

.theme-image-aspect-ratio {
  &.large {
    img {
      @include content-size(120, 150);
    }
  }
}

.dialog-modal.choose-address-modal {
  .dialog-content {
    padding-top: 24px;
    padding-bottom: 18px;
    min-height: 520px;
  }
  .header {
    @include font-size-weight(18, 26, 500);
    margin-bottom: 24px;
  }
  .change-button {
    @include font-size-weight(14, 20, 500);
    padding: 2px 8px;
    border-radius: 3px;
    color: $purple-1;
    background-color: rgba(20, 110, 180, 0.1);
    text-transform: uppercase;
    border: none;
    cursor: pointer;
  }
  .google-map {
    position: relative;
    height: 256px;
    width: 100%;
    margin-bottom: 20px;
    .spinner {
      margin: 0;
    }
  }
  .map-container {
    position: relative;
    height: 256px;
    width: 512px;
    margin-bottom: 20px;
    .map-marker {
      position: absolute;
      left: 256px;
      top: 111px;
    }
  }
  .search-results {
    max-height: 350px;
    overflow: auto;
    .search-option {
      display: flex;
      margin-bottom: 20px;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      .marker-icon {
        align-self: normal;
      }
      .title {
        @include font-size-weight(16, 24, 500);
      }
      .description {
        @include font-size-weight(14, 20, 400);
        color: $black-3;
      }
    }
  }
}
.payment-banner {
  background-color: $purple-3;
  padding: 24px;
  .vertical-divider {
    height: 24px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  .btn {
    padding: 6px 12px;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
  }

  .payment-banner-logo {
    @include content-size(133, 126);
  }
}

.transactions {
  .transaction-orders:last-child {
    .order-item-container {
      margin-bottom: 0;
    }
    .order-item {
      .payments-meta-desc {
        margin-bottom: 0px;
      }
    }
  }
  .transaction-orders:not(:last-child) {
    border-bottom: solid 1px $white-2;
    margin-bottom: 12px;
  }
}

.transaction-orders,
.transaction-orders-card {
  .order-item {
    color: $black-1;
    cursor: pointer;
    &.restaurant-order {
      border-left: 4px solid $red-3;
    }
    .order-id-container {
      @include display-flex(center, space-between);
      margin-bottom: 12px;
    }
    .order-item-container {
      @include display-flex(center, space-between);
      position: relative;
      margin-bottom: 8px;
      img {
        @include content-size(64, 64);
        min-width: 64px;
        min-height: 64px;
        border-radius: 4px;
        margin-right: 16px;
        object-fit: contain;
        border: 1px solid $white-9;
      }
    }
    .order-status-wrap {
      border-top: 1px solid $white-2;
      padding-top: 12px;
      text-align: center;
      @include display-flex(center, space-between);
    }
    .order-detail-btn {
      display: inline-flex;
      align-items: center;
      padding: 4px 8px 4px 12px;
      border-color: $gray-3;
      > span {
        position: relative;
        top: 1px;
      }
    }
  }
}

.transaction-orders-card {
  border: 1px solid $white-2;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 12px;
  .payments-meta-container {
    @include display-flex(center, space-between);
    .orders-count {
      @include display-flex(center, center);
    }
  }
  &:hover {
    background-color: $white-10;
  }
}

.payment-profile-card {
  background-color: #f3f8fb;
  padding: 14px 0;
  border: solid 1px rgba(20, 110, 180, 0.5);
  border-radius: 6px;
}

.no-transactions-card {
  padding: 48px;
  border: solid 1px $white-8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sticky-toggle-group {
  @extend .d-flex-c-s;
  padding-bottom: 12px;
  padding-top: 12px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;

  &.active-orders {
    background-color: $white-1;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.subscription-cancellation-modal {
  .checkbox-label-wrap {
    margin-bottom: 12px !important;
    .custom-checkbox {
      margin-right: 12px;
    }
  }
  .checkbox-label {
    @include font-size(16, 24);
  }

  .dialog-footer {
    justify-content: center;
  }

  button {
    @include font-size(16, 24);
    padding: 8px 24px;
  }
}

.promise-banner-wrapper {
  width: 100%;
  position: absolute;
  bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-app-screen {
  max-width: 564px;
  margin: 32px auto;
}

.get-app-screen-image {
  height: 264px;
  width: 516px;
}

.delivery-zone-banner {
  user-select: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  background-color: $white-10;
  border: solid 1px $white-2;
  border-radius: 4px;
}

.extra-charges-modal {
  .dialog-close-icon {
    height: 26px !important;
    width: 26px !important;
  }
}

.account-navigation-card {
  @extend .card;
  padding: 24px;
  border-radius: 4px;
  min-width: 270px;
  height: fit-content;
  position: sticky;
  top: 32px;

  .account-navigation-item {
    @extend .d-flex, .align-center, .cur-p;
    padding-top: 12px;
    padding-bottom: 12px;

    color: $black-3;
    &.active,
    &:hover {
      color: $purple-1;
    }
  }
  .account-navigation-item:first-child {
    padding-top: 0;
  }
  .account-navigation-item:last-child {
    padding-bottom: 0;
  }
  .account-navigation-item:not(:last-child) {
    border-bottom: solid 1px $white-8;
  }

  .account-navigation-item-label {
    @extend .text-medium;
  }
}

.staff-account-modal {
  & .body {
    margin-top: 24px;

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 4px;

    .field-label-text {
      display: inline-block;

      @extend .text-8;
      color: $black-1;
    }
    .role-select-option {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;

      & .title {
        @extend .section-text-5;
        color: $black-3;

        &.active {
          color: $purple-1;
        }
      }
      & .desc {
        @extend .text-8;
        color: $gray-1;
      }
    }
  }
  & .actions {
    margin-top: 32px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  .country-select {
    .registration-select {
      &.left .custom-select__menu {
        left: 0;
      }
      &.right .custom-select__menu {
        right: 0;
      }
      .custom-select {
        &__menu {
          width: 352px;
          &-list {
            max-height: 236px;
          }
        }
      }
    }
  }
}

.staff-account-modal-delete {
  & .body {
    // margin-top: 8px; 

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    & .meta {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;

      text-align: center;

      & .title {
        @extend .section-text-4;
        color: $black-1;
      }
      & .desc {
        @extend .text-2;
        color: $black-3;
      }
    }
  }
  & .actions {
    margin-top: 32px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
}

.know-why-badge {
  background-color: $white-1;
  opacity: 0.2;
  width: 93px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  .know-why-badge-text {
    color: $white-1;
  }
}

.know-why-button-container {
  position: relative;
  .know-why-button {
    position: relative;
    padding: 4px 8px;
    width: 93px;
    height: 24px;
    background-color: $white-1;
    opacity: 0.2;
    border-radius: 2px;
  }
  .know-why-text {
    position: absolute;
    top: 4px;
    left: 8px;
  }
}

.know-why-modal-container {
  .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 63px);
  }
  .know-why-modal-image-wrapper {
    max-height: calc(92vh - 252px);
  }
}

.payment-tab-style {
  white-space: nowrap !important;
  max-width: none !important;
}

.know-why-modal-tab-style {
  .MuiTab-wrapper {
    @extend .text-2;
  }
}

.know-why-first-tab {
  margin-left: -7px !important;
}

.export-payment-details {
  .MuiTooltip-popper {
    top: -8px !important;
  }
}

.view-tnc {
  &:hover {
    @extend .underline;
  }
}

.tnc-apply {
  right: 0;
}
.payments-divider {
  margin-left: -24px;
  margin-right: -24px;
  width: calc(100% + 48px);
}

.payment-card {
  .date-input-field-wrap {
    padding: 5px 12px;
  }
}

.preference-section {
  .MuiTooltip-tooltip {
    max-width: 480px;
  }
}

.auto-accept-order-text {
  margin-left: 3px;
}

.email-phone-input-wrap {
  .input-group-wrap {
    position: relative;

    .phone-code-select {
      position: absolute;
      right: 0;
      top: 0;
      .custom-select__control {
        border: 0 !important;
        background-color: transparent;
      }
    }
  }

  .custom-select__menu {
    width: 352px !important;
    right: 0;
  }
}

.auto-accept-order-radio-group {
  .MuiFormControlLabel-label {
    .selected {
      color: $purple-1;
    }
  }
}

.counter-container {
  .counter-count {
    width: 24px;
    margin: 0 8px;
  }
  .inactive-decrement-btn {
    svg {
      path {
        fill: $gray-3;
      }
    }
  }
  .increment-decrement-btn {
    border: 1px solid $gray-3;
    display: flex;
    border-radius: 50%;
    padding: 7px;
    cursor: pointer;
  }
}

.expand-icon-container-wrapper {
  // height: 24px;
  .expand-icon-container {
    padding: 8px;
    border-radius: 50%;
    margin-right: -8px;
    &:hover {
      background-color: $white-1;
    }
  }
}

.expand-modal {
  .MuiDialog-container {
    .dialog-content {
      max-height: 90vh;
    }
    .ck-content {
      max-height: calc(90vh - 203px);
    }
  }
}

.hsn-code-finder-card {
  @extend .card;
  margin: 32px auto;
  padding: 24px;
  max-width: $fixed-container-width-sm;
  position: relative;

  .hsn-search-filter {
    input {
      @extend .text-2;
      padding: 12px 32px 12px 16px;
      height: 48px;

      &:focus-visible,
      &:focus {
        outline: none;
        border-color: $purple-1;
      }
    }
    .remove-search {
      top: 16px;
      right: 16px;
    }
  }

  .hsn-search-results {
    @extend .card;
    overflow-y: auto;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    max-width: 712px;
    max-height: 348px;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: $white;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background: $white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #a1a1a188;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #706f6fbd;
    }

    &-grid {
      display: grid;
      grid-template-rows: 1fr;
      padding: 10px 8px;
    }
    &-row {
      cursor: pointer;
      padding: 6px 8px;
      display: grid;
      grid-template-columns: 104px 1fr;
      column-gap: 12px;
      border-radius: 4px;
      align-items: center;
      &:hover {
        background-color: $white-6;
      }
      .hsn-code {
        background-color: rgba($purple-1, 0.1);
        padding: 12px 8px;
        border-radius: 2px;
        @extend .text-center;
        @extend .section-text-5;
      }
      .hsn-name {
        @extend .text-8;
      }
    }
    &-no-result {
      @extend .text-8;
      padding: 16px;
    }
  }

  .grid-table-head,
  .grid-table-row {
    display: grid;
    grid-template-columns: 1.5fr 6fr 1.5fr;
    justify-content: start;
    align-content: stretch;

    .grid-item {
      padding: 8px 12px;
    }
  }

  .grid-table-head {
    background-color: $white-6;
    border-radius: 4px 4px 0px 0px;

    .grid-item {
      @extend .section-text-5;
      color: $black-3;
    }
  }

  .grid-table-row {
    border: 1px solid $white-9;
    border-bottom-width: 0px;

    .grid-item {
      @extend .text-8;
      justify-self: start;
      align-self: center;

      &:first-of-type {
        @extend .section-text-5;
      }

      &:nth-of-type(2) {
        border: 1px solid $white-9;
        border-top-width: 0px;
        border-bottom-width: 0px;
        align-self: stretch;
        justify-self: stretch;
      }
    }

    &:last-of-type {
      border-radius: 0px 0px 4px 4px;
      border-bottom-width: 1px;
    }
  }

  .grid-no-result {
    margin-top: 48px;
    margin-bottom: 24px;
    color: $black-3;
    @extend .text-2;
    @extend .text-center;
  }
}
.product__details-modal {
  width: 760px !important;
  max-width: 760px !important;
  & > div {
    padding: 0 !important;
  }
}
.product__details-container {
  position: relative;
  height: 148px;
  border-radius: 6px;
  padding: 16px 16px 28px 16px;
  border: 1px solid $white-2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 16px;
  &-block {
    flex-basis: 30%;
    flex-shrink: 0;
    flex-grow: 1;

    & h4 {
      @include font-size-weight(13, 16, 500);
      color: $gray-4;
    }
  }
  &-depositedto {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid $white-2;
    border-radius: 32px;
    padding: 8px 16px;
    background-color: white;
    color: $gray-1;
    display: flex;
    align-items: center;
    &-icon {
      margin-right: 5px;
    }
  }
}
.product__details-orders {
  .table-header {
    width: calc(100% / 5);
  }
  .table-header:first-child {
    text-align: left;
  }
  .table-data-payments {
    width: calc(100% / 5);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $black-1;
    position: relative;
    .order-id {
      color: $purple-1;
    }
    & .payment-mode {
      right: 14px;
    }
  }
  .table-data-payments:not(:first-child) {
    text-align: right;
  }
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
}
.sticky-head {
  position: sticky;
  top: 62px;
}

.checkbox-pt4 {
  .MuiButtonBase-root {
    padding-top: 4px;
  }
}

.delete-account-checkbox-pt2 {
  max-height: 24px;
  .MuiButtonBase-root {
    padding-top: 2px;
  }
}

.deletion-reason-li-pb0 {
  .MuiListItem-root .MuiListItem-gutters {
    padding-bottom: 0px !important;
    margin-left: 0px !important;
  }
}

.custom-modal-pb0 {
  .MuiDialogContent-root {
    padding-bottom: 0px !important;
  }
}

.resend-text-container {
  height: 20px;
}

.delete-reason-ml0 .MuiListItem-button {
  margin-left: 0px !important;
}

.heading-custom-margin {
  margin-bottom: 0px !important;
  margin-top: 29px !important;
}

.delete-verify-account {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 24px;
}

.marg-t278 {
  margin-top: 278px;
}

.country-input {
  input {
    @extend .cur-p;
  }
}


.smtp-form-wrapper {
  input:focus {
    box-shadow: 0 0 0 30px white inset, $focus-blue-box-shadow !important;
  }
}

.delivery-charge-radio {
  .MuiFormControlLabel-label {
    @extend .text-2;
  }
}

.weight-based-delivery {
  display: grid;
  gap: 20px;

  .each-row {
    display: grid;
    align-items: center;
    grid-template-columns: 152px 8px 152px 20px 236px;
    gap: 16px;
  }
}

.delivery-based-container {
  .delivery-charge-each-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    gap: 16px;
    &.header {
      margin-bottom: 8px;
      :nth-child(1) {
        width: 128px;
      }
      :nth-child(2) {
        width: 142px;
      }
      :nth-child(3) {
        width: 180px;
      }
      :nth-child(4) {
        width: 180px;
      }
    }
    .from,
    .to {
      max-width: 106px;
      min-width: 106px;
    }
    .delivery-charge {
      width: 180px;
      max-width: 180px;
    }
  }
  :last-child {
    margin-bottom: 0px;
  }
}

.dukaan-whatsapp-card {
  border: solid 1px $white-9;
  border-radius: 6px;
  padding: 16px;

  .dukaan-whatsapp-logo {
    height: 48px;
    width: 48px;
  }

  .footer-note {
    @extend .text-4, .c-black-3;
    background-color: $white-6;
    border-radius: 4px;
    padding: 8px;
    text-align: center;
  }
}

.packages-list {
  margin-top: 24px;

  .package-item:not(:last-child) {
    border-bottom: solid 1px $white-9;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
}

.shipping-method-card {
  .shipping-method-logo {
    height: 88px;
    width: 88px;
    border: solid 1px $white-9;
    border-radius: 8px;
  }
}
