.position-relative {
  position: relative;
}

.code-editor-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  overflow-y: hidden;

  .code-editor-body-wrapper {
    flex: 1 1 100%;
    height: 100%;

    .code-editor-nudge {
      margin: 16px 16px 0 16px;
    }

    .code-editor-iframe-container {
      overflow-y: auto;
      height: 100%;
      @include d-grid(1fr, auto 1fr, 0, flex-start, flex-start);
      padding: 16px 16px 64px 16px;
      .code-editor-view {
        height: 100%;
        background-color: $white;
        padding: 5px 12px 16px 12px;
        overflow: hidden;
        .code-header {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          .actions-panel {
            display: flex;
            align-items: center;
            margin-left: auto;

            .undo-redo {
              display: flex;
              align-items: center;
              gap: 16px;
            }

            .undo,
            .redo {
              .active {
              }
            }
          }
        }

        .code-content {
          height: calc(100% - 48px);
          & *:not(.codicon) {
            font-family: 'IBM Plex Mono';
          }
        }
      }
    }
  }
}

.code-top-nav {
  background-color: $white;
  padding: 8px 12px 6px 12px;
  box-shadow: 0px 2px 6px rgba(26, 24, 30, 0.04);
  display: flex;
  flex-wrap: nowrap;
  overflow-x: overlay;
  border-bottom: 1px solid $white-9;
  &::-webkit-scrollbar {
    background-color: transparent;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $white-2;
    height: 6px;
  }
  .file {
    position: relative;
    padding: 2px 16px 2px 2px;
    margin: 4px 0px;
    min-width: fit-content;
    display: flex;
    align-items: center;
    border-right: 1px solid #e6e6e6;
    color: $gray-1;
    cursor: pointer;

    &:not(:first-child) {
      padding-left: 24px;

      &.active {
        color: $black-1;
        .bottom-line {
          position: absolute;
          bottom: -10px;
          left: 24px;
          right: 16px;
          border: 1px solid $purple-1;
          border-radius: 4px;
        }
      }
    }

    &:last-child {
      border-right: 0px;
    }

    span {
      margin-left: 12px;
    }

    // first-child
    &.active {
      color: $black-1;
      .bottom-line {
        position: absolute;
        bottom: -10px;
        left: 2px;
        right: 16px;
        border: 1px solid $purple-1;
        border-radius: 4px;
      }
    }
  }
}
