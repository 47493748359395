.theme-sidebar--block {
  height: -moz-fit-content;
  height: fit-content;
  position: sticky;
  top: 32px;
  .theme-sidebar-card {
    @extend .card;
    width: 270px;
    height: fit-content;
    .bulb-icon {
      position: absolute;
      right: 8px;
      padding: 8px;
      padding-bottom: 3px;
      border-radius: 50%;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}

.theme-sidebar-section {
  width: calc(100% - 302px);
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 24px;
}

.themes-container {
  padding: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 24px;
}

.theme-card {
  position: relative;
  border: solid 1px $white-9;
  background-color: $white-10;
  .theme-price-stripe {
    position: absolute;
    background-image: url('../../images/price-stripe.svg');
    background-repeat: no-repeat;
    left: 0;
    top: 12px;
    padding-top: 8px;
    padding-left: 10px;
    width: 80px;
    height: 34px;
    z-index: 10;

    span {
      font-size: 18px;
      line-height: 18px;
    }
    .premium {
      margin-left: -4px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
    }
  }

  img {
    border-radius: 8px 8px 0 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    aspect-ratio: 73/68;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }

  .theme-card-img {
    position: relative;
    padding: 8px 8px 0 8px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    svg {
      path {
        fill: white;
      }
    }

    .theme-card-overlay {
      @extend .flex-center;
      animation: {
        name: fade-in;
        fill-mode: both;
        duration: 0.3s;
      }
      height: 100%;
      display: none;
      position: absolute;
      gap: 8px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba($black, 0.8);
      border-radius: 4px 4px 0 0;
      color: white;
    }
    &:hover .theme-card-overlay {
      display: flex;
    }
  }

  .theme-card-footer {
    border-top: solid 1px $white-9;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &.active {
      background-color: $black-1;
      color: white;
      border-top: solid 0px transparent;
    }

    .beta {
      @extend .section-text-13;
      @extend .c-black-3;
      border-radius: 3px;
      background-color: $white-9;
      padding: 2px 6px;
    }

    .btn-outline-7 {
      border: none;
      padding: 6px 16px;
      font-weight: 500;
    }

    .btn-small {
      padding: 5px 12px;
    }
  }
}

.colors-container {
  @extend .d-flex-c-s;
  max-width: 340px;

  & > .form-group {
    margin-bottom: 0px;
  }
}

.add-banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  .own-banner {
    width: 393px;
    height: 166px;
    background: $white-6;
    border-radius: 6px;
    img {
      width: 100%;
      height: 166px;
      border-radius: 6px;
    }
    svg {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
    &:hover {
      opacity: 0.9;
    }
  }
  .choose-template {
    padding: 8px 24px;
    border-radius: 4px;
    color: $black-3;
  }
}

.header-banners-card {
  overflow-y: auto;
}

.store-banners-theme {
  @include d-grid(repeat(4, 227px), auto, 16px, center, start);
  .store-banner-container {
    width: 227px;
    height: 95px;
    border-radius: 6px;
    border: 1px solid $white-9;
    position: relative;

    .breathing {
      height: 100%;
      border-radius: 6px !important;
    }

    .store-banner {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      position: absolute;
      object-fit: contain;
    }

    .card-banner-overlay {
      animation: {
        name: fade-in;
        fill-mode: both;
        duration: 0.8s;
      }

      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba($black, 0.8);
      border-radius: 6px;
      height: 100%;

      .overlay-delete-btn {
        @extend .cur-p;
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .btn-use-banner {
        padding: 6px 16px;
      }
    }

    &:hover .card-banner-overlay {
      display: flex;
    }

    &.isMobile {
      height: 204px;
    }
  }
  .add-banner {
    width: 227px;
    height: 95px;
    border-radius: 6px;
    border: 1px dashed $gray-8;

    &.isMobile {
      height: 204px;
    }

    .add-banner-icon {
      svg {
        path {
          fill: $gray-1;
        }
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.edit-banner {
  display: flex;
  flex-direction: column;
  img {
    border-radius: 4px;
  }
  button {
    align-self: center;
    padding: 8px 24px !important;
  }
  &.choose-banner {
    img {
      width: 480px;
      height: 202px;
    }
  }
}

.add-banner-modal {
  .image-container {
    // container that should have scroll if content exceeds
    // calculation : subtract the height(including padding and margin) of all elements on modal
    // that should be fixed place from the max-height of container(92vh)
    // ex: 92vh - 24px+24px(top bottom padding) + 26px(heading) + 24px(mb) + 168px(form section)
    // for laptop screen few pixels added to avoid showing scroll on modal
    max-height: calc(92vh - 267px);
    overflow-y: auto;
    position: relative;
    .card-banner-overlay {
      animation: {
        name: fade-in;
        fill-mode: both;
        duration: 0.8s;
      }

      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba($black, 0.8);
      border-radius: 6px;
      height: 100%;

      .btn-use-banner {
        padding: 6px 16px;
      }
    }

    &.noImage {
      border: 1px dashed $gray-8;
      border-radius: 6px;
    }

    &.isEdit:hover .card-banner-overlay {
      display: flex;
    }
  }

  .banner-image-cropper {
    width: 100%;
  }
  .custom-banner-wrapper {
    flex: 1;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid $white-9;
    border-radius: 4px;
  }
}

.template-gallery-card {
  @extend .cur-p;
  border: solid 1px $white-8;
  border-radius: 6px;
  padding: 64px 48px;
  text-align: center;
}

.cover-image-wrapper {
  .store-cover-image-container {
    box-sizing: border-box;
    width: 276px;
    height: 117px;
    border-radius: 6px;
    overflow: hidden;
    text-align: center;
    border: 1px dashed $gray-3;
    .placeholder-content {
      top: 50%;
      transform: translateY(50%);
    }

    .store-cover-image-card {
      height: 100%;
      .store-cover-image {
        position: absolute;
        left: -1px;
        top: -1px;
        width: 278px;
        height: 119px;
        object-fit: contain;
        border-radius: 6px;
      }
      .overlay-text {
        display: none;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        transition: background 0.3s ease-in;
      }
      &:hover {
        .overlay {
          background-color: rgba(0, 0, 0, 0.8);
        }
        .overlay-delete-btn {
          display: block;
          position: absolute;
          height: 28px;
          top: 14px;
          right: 14px;
          z-index: 2;
        }
        .overlay-text {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          box-sizing: border-box;
          border-radius: 4px;
          white-space: nowrap;
          animation: {
            name: fade-in;
            fill-mode: both;
            duration: 1s;
          }
          .overlay-button {
            height: 28px;
            display: flex;
            align-items: center;
            padding: 0 16px;
          }
          p {
            @include font-size(12, 16);
            font-weight: 500;
          }
        }
      }
    }
  }
  .store-images-outer-container {
    height: 112px;
  }
}

.placeholder-image-upload {
  height: 112px;
  width: 112px;
  border: 1px dashed $white-2 !important;
}

.store-images-container {
  height: 112px;
  grid-gap: 16px;
  margin-left: 16px;

  .uploaded-image {
    .breathing {
      height: 112px !important;
      width: 112px !important;
      border-radius: 8px !important;
    }
    .uploaded-img-overlay {
      z-index: -1;
      transition: 0s;
      background-image: none;
    }
    &:hover {
      .uploaded-img-overlay {
        z-index: 5;
        &:hover {
          transition: 0.3s;
          display: block;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 8px;
        }
      }
    }

    img {
      width: 112px;
      height: 112px;
      border-radius: 8px;
      object-fit: contain;
      border: 1px solid $white-9;
    }
  }

  &::-webkit-scrollbar {
    display: block !important;
  }
}

.store-images-overflow {
  height: 145px;
  overflow-x: scroll;
}

.dotted-border {
  border: 1px dotted $gray-3;
}

.placeholder-banner {
  @extend .d-flex-c-c;
  user-select: none;
  min-height: 95px;
  height: 100%;
  width: 100%;
  background-color: $white-6;
  color: $black-3;
  border-radius: 6px;
}

.banner-graphics-container {
  padding: 16px;
  padding-top: 0;
  overflow-y: auto;
  &.choose-template {
    width: 420px;
    padding: 0;
    .marketing-grid {
      grid-template-columns: repeat(2, minmax(208px, 1fr));
      grid-gap: 4px;
      .marketing-item {
        @include content-size(200, 84);
        min-width: 200px;
        min-height: 84px;
        border-radius: 4px;
      }

      .marketing-item-div {
        @include content-size(208, 92);
        min-width: 208px;
        min-height: 92px;
        @extend .d-flex-c-c;
        border: solid 2px transparent;
        border-radius: 4px;

        position: relative;

        &.active {
          border-color: $purple-1;
        }

        .text-container {
          @extend .cur-p;
          position: absolute;
          left: 14px;
          height: fit-content;

          .header-text {
            @include font-size-weight(14, 16, 700);
            margin-bottom: 4px;
          }

          .body-text {
            @include font-size-weight(8, 10, 400);
          }
        }
      }
    }
  }
}
.color-preset-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(126px, 1fr));
  grid-gap: 24px 45px;

  &.small {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  }
}

.color-preset-item {
  @extend .cur-p;
  border: solid 1px $white-2;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  .color-preset-item-color {
    @include content-size(24, 24);
    border-radius: 4px;
    border: solid 1px $white-9;
  }
}

.fake-tabbar {
  background-image: url(../../images/tabbar.svg);
  width: 332px;
  height: 45px;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  padding-top: 14px;

  .favicon-preview {
    @include content-size(24, 24);
    margin-left: 88px;
  }

  span {
    display: inline-block;
    margin-left: 8px;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
  }
}

.favicon-main {
  @include content-size(32, 32);
  margin-right: 16px;
}

.theme-carousel-modal {
  .dialog-content {
    height: 680px !important;
  }
}

.carousel-container {
  height: 680px;
  background-color: $white-6;
  .arrowContainerStyle {
    top: 50% !important;
  }
}

.slides-container {
  height: 600px;
  .theme-image {
    max-height: 500px;
    width: 80%;
  }
}

.luxury-sections {
  .reorder-layout-container {
    z-index: 999;
    position: fixed;
    bottom: 16px;
    right: 16px;
    .reorder-layout-fab {
      @extend .d-flex;
      background-color: $black-2;
      border-radius: 30px;
      padding: 14px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      span {
        box-sizing: border-box;
        max-width: 0;
        margin-left: 0;
        overflow: hidden;
        white-space: nowrap;
        transition: all 0.3s;
      }
      &.active {
        padding: 14px 20px;
        span {
          max-width: 200px;
          margin-left: 8px;
        }
      }
      &.disabled {
        background: #a5a8b3;
        cursor: default;
      }
    }
  }
}

.myeditor-container {
  display: grid;
  .canvas-card {
    width: 100%;
    height: 100%;
  }
}

.canvas-wrapper {
  height: 65vh;
  .card {
    flex-grow: 1;
  }
}

.custom-form-select {
  .selected-value {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.footer-description {
  margin-top: -12px;
}

.testimonial-image-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid $white-2;
  border-radius: 4px;
  .choose-img-btn {
    background-color: $white-6;
    border: none;
    margin: 8px;
  }
  .img-file-name {
    width: calc(100% - 150px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.image-container-image-overlay {
  position: relative;
  .image-file-name {
    position: absolute;
    right: 11px;
    top: 43px;
    background-color: white;
    width: calc(96% - 150px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
