.marketing-page-items {
  @include display-flex(center, flex-start);
  margin: 0 -12px;
  .marketing-page-item {
    text-align: center;
    width: 270px;
    padding: 40px;
    height: 230px;
    margin: 0 12px;
    color: $white;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.04);
    &.business-card {
      background-color: $violet-1;
    }
    &.store-banners {
      background-color: #16365a;
    }
    &.whatsApp-stories {
      background-color: $green-3;
    }
    &.trusted-seller {
      background-color: $orange-5;
    }
  }
}

.graphics-container {
  @extend .card;
  padding-left: 16px;
  padding-right: 10px;
  width: 368px;
  height: calc(100vh - 218px);
  &::-webkit-scrollbar {
    display: none;
  }
  &.has-tabs {
    height: calc(100vh - 260px);
  }
}

.marketing-grid {
  display: grid;
  overflow-y: auto;
  height: calc(100vh - 310px);
  &.whatsapp-stories {
    grid-template-columns: repeat(3, minmax(106px, 1fr));
  }
  &.business-cards {
    grid-template-columns: repeat(2, minmax(162px, 1fr));
    height: calc(100vh - 270px);
  }
  &.store-banners {
    grid-template-columns: repeat(2, minmax(162px, 1fr));
  }
}

.graphics-header {
  @extend .section-text-5;
  padding-top: 12px;
  padding-bottom: 12px;
  position: sticky;
  top: 0;
  width: 340px;
  background: white;
  z-index: 10;
}

.marketing-item-wrapper {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  &.active {
    border: 1px solid $purple-1;
    border-radius: 8px;
  }
}

.marketing-item {
  border-radius: 8px;
  padding: 3px;
  cursor: pointer;

  &.whatsapp-stories {
    height: 188px;
    width: 106px;
  }

  &.business-cards {
    height: 102px;
    width: 160px;
  }
  &.store-banners {
    height: 164px;
    width: 160px;
  }
}

.marketing-canvas-container {
  @extend .card;
  padding: 24px;
  color: white;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &.whatsapp-stories {
    height: 564px;
    width: 339px;
    overflow: hidden;
  }

  &.business-cards {
    height: 369px;
    width: 564px;
    overflow: hidden;
  }
  &.store-banners {
    height: 484px;
    width: 484px;
    overflow: hidden;
  }
  &.header-banners {
    padding: 0;
    height: 202px;
    width: 480px;
    margin-bottom: 24px;
  }
}

.image-editor-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 48px 394px;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.image-editor-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  &:not(.marketing-page) {
    height: calc(100vh - 200px);
    min-height: 592px;
  }
  &.has-tabs {
    height: calc(100vh - 260px);
  }
}

.editable-indicator {
}

.image-editor-editable-section {
  margin-left: 20px;
  margin-right: 20px;
  outline: 0px solid transparent;
}

.graphic-container-shimmer {
  position: relative;
  // left: 100px;
  left: 50%;
  top: 1%;

  &.whatsapp-stories {
    left: 100px;
  }
}

.image-editor-shimmer {
  top: 43%;
}

.banner-editor-toolbar {
  display: grid;
  grid-template-columns: 220px 118px 48px;
  gap: 12px;

  .banner-fontsize-select {
    width: 220px;
    margin-bottom: 0;
  }
}

.konva-toolbar {
  @extend .d-flex-c-c;
  width: 100%;
}

.canvas-toolbar {
  @extend .d-flex;
  width: auto;
  flex-direction: row;
  justify-content: space-between;

  height: 32px !important;

  .canvas-toolbar-icon {
    padding: 3px !important;
    &.active {
      border-color: $gray-3 !important;
    }
  }
}

.first-section {
  @extend .d-flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.second-section {
  @extend .d-flex;
  @extend .gap12;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.canvas-toolbar-font-family-select {
  width: 220px;
  min-height: 32px !important;
  .custom-select__control {
    border-color: #e5e5e5 !important;
    min-height: 32px !important;
    height: 32px !important;
    line-height: normal;
  }
}

.canvas-toolbar-font-stepper {
  height: 32px;
}

.canvas-toolbar-divider {
  height: 24px !important;
}

.canvas-toolbar-color-picker {
  @extend .cur-p;
  @extend .row;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: solid 1px $white-8;
  border-radius: 4px;

  svg {
    margin-top: 4px;
  }

  .canvas-toolbar-font-color-preview {
    height: 4px;
    width: 22px;
    border-radius: 1px;
    border: 1px solid $black-1;
  }

  .canvas-toolbar-svg-color-preview {
    height: 24px;
    width: 24px;
    border-radius: 2px;
  }
}

.shapes-dropdown-menu {
  display: flex;
  flex-direction: column;
  .shapes-dropdown-menu-item {
    @extend .cur-p;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 8px;
    border-radius: 4px;
    &:hover {
      background-color: $white-6;
    }
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .label-icon {
      display: flex;
      flex-direction: row;
    }
  }
}

.add-shapes-menu {
  border-radius: 6px;
  background-color: $white;
  padding: 8px;
  width: 180px;
  box-shadow: 0px 10px 20px rgba(152, 152, 152, 0.3);
}

.shapes-menu-item-content {
  padding: 8px 16px 8px 16px;

  &.dropdown-menu {
    border-bottom: none;
  }

  &.alignment-menu {
    padding: 8px 12px 8px 8px;
  }
}

.shapes-popper {
  border-radius: 6px;
  background-color: $white;
  width: 220px;
  box-shadow: 0px 10px 20px rgba(152, 152, 152, 0.3);

  .shapes-popper-inner-container {
    display: grid;
    grid-template-columns: repeat(7, 24px);
    grid-column-gap: 4px;
    grid-row-gap: 12px;
  }
  .shape-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  .shape-wrapper:hover {
    @extend .cur-p;
    background-color: $white-6;
    border-radius: 2px;
  }
}

.shapes-submenu-container {
  width: 220px;
  display: grid;
  grid-template-columns: repeat(7, 24px);
  grid-auto-rows: 24px;
  column-gap: 8px;
  row-gap: 8px;
  svg:hover {
    @extend .cur-p;
    background-color: $white-6;
    border-radius: 2px;
    margin: 4px;
  }
}

.shapes-submenu-align-container {
  display: grid;
  grid-template-columns: repeat(2, 117px);
  grid-template-rows: 32px;
  row-gap: 14px;
  column-gap: 13px;
  .alignment-option {
    display: flex;
    align-items: center;
  }
  .alignment-option:hover {
    @extend .cur-p;
    background-color: $white-6;
    border-radius: 2px;
  }
}

.opacity-slider-menu {
  display: grid;
  grid-template-columns: auto 37px;
  column-gap: 17px;
}
.opacity-value {
  display: flex;
  justify-content: center;
  padding: 6px;
  border: solid 1px $white-9;
  border-radius: 4px;
}

.promotional-design-modal-container {
  display: grid;
  margin-bottom: -12px;
  .promotional-design-item {
    margin: 0 -12px;
    padding: 12px;
    transition: background-color 0.2s ease-in;
    &:hover {
      background-color: $white-6;
      border-radius: 4px;
    }
    .promotional-design-modal-image {
      height: 72px;
    }
  }
}

.custom-customers-divider {
  height: 1px;
  background-color: $white-2;
  border: none;
  margin-left: -24px;
  margin-right: -24px;
}

.custom-customers-modal {
  max-height: 100% !important;
  .dialog-content {
    max-height: 100% !important;
  }
  .select-customers-table {
    max-height: calc(100vh - 296px);
  }
}
