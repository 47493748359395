@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/MaterialIcons/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(../fonts/MaterialIcons/MaterialIcons-Regular.woff2) format('woff2'),
       url(../fonts/MaterialIcons/MaterialIcons-Regular.woff) format('woff'),
       url(../fonts/MaterialIcons/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  @include material-icons;
}

$icon-size: 48;
.material-icons {
  // Icon SIZE 12, 14, 16 ... 48px
  @while $icon-size >= 12 {
    &.md-#{$icon-size} {
      font-size: #{$icon-size}px;
    }
    $icon-size: $icon-size - 2;
  }
}
