@mixin btn-common($color) {
  color: $color;
  cursor: pointer;
  display: inline-block;
  transition: 0.15s ease-in-out;
  background-color: transparent;
  &.disabled,
  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  }
  user-select: none;
}

@mixin hover-effect($bgHover, $color) {
  &:not(.disabled):hover,
  &:not([disabled]):hover {
    color: $color;
    background-color: $bgHover;
    transition: 0.15s ease-in-out;
  }
}

@mixin button-theme($bg, $bgHover, $color: #fff) {
  @include btn-common(#fff);
  background-color: $bg;
  @include hover-effect($bgHover, $color);
}

@mixin button-outline($color, $border-color, $hover-color) {
  @include btn-common($color);
  border: 1px solid $border-color;
  background-color: #fff;
  &:not(.disabled):hover,
  &:not([disabled]):hover {
    background-color: $hover-color;
  }
}

.btn {
  text-align: center;
  outline: none;
  border: none;
  padding: 5px 12px;
  border-radius: 4px;
  @include font-size(14, 20);
  cursor: pointer;
  transition: 0.15s ease-in-out;
  &.btn-primary {
    @include button-theme($purple-1, $purple-2);
  }
  &.btn-success {
    @include button-theme($green-1, $green-2);
  }
  &.btn-warning {
    @include button-theme($orange-1, $orange-2);
  }
  &.btn-danger {
    @include button-theme($red-1, $red-2);
  }
  &.btn-dining {
    @include button-theme($red-3, $red-3);
  }
  &.btn-violet {
    @include button-theme($violet-2, $violet-2);
  }
  &.btn-grey {
    @include button-theme($white-6, $white-5);
    color: $black-3;
    &[disabled] {
      color: $black-3;
      opacity: 1;
    }
  }
  &.btn-text {
    @include button-theme(transparent, transparent, $purple-1);
    color: $purple-1;
    padding: 0;
  }
  &.btn-premium {
    @include button-theme($orange-7, $orange-7, $black-4);
    color: $black-4;
  }
  &.btn-white {
    color: $purple-1 !important;
    @include button-theme($white, $white-1);
  }
  .btn-txt {
    position: relative;
    top: 1px;
    display: inline-flex;
  }
  &.btn-text-code {
    @include button-theme(transparent, transparent, $black-3);
    color: $gray-1;
  }
}

.btn-3 {
  @extend .btn, .section-text-13;
  padding: 8px 16px;
}

.btn-4 {
  @extend .btn;
  font-weight: 500;
  padding: 8px 15px;
}

.btn-icon {
  @include content-size(40, 40);
  @extend .cur-p;
  background-color: #fff;
  border: solid 1px $white-2;
  border-radius: 4px;
  padding: 7px !important;
}

.btn-chip {
  @extend .cur-p;
  border: solid 1px $white-2;
  border-radius: 14px;
  padding: 3px 11px;
}

.btn-primary-0 {
  @extend .btn;
  @extend .btn-primary;
  font-weight: 500;
}
.btn-premium-3 {
  @extend .btn-premium;
  @extend .btn-3;
}
.btn-primary-4 {
  @extend .btn-primary;
  @extend .btn-4;
}
.btn-primary-small {
  @include font-size-weight(14, 20, 500);
  @extend .btn;
  @extend .btn-primary;
  padding: 6px 12px;
}

.btn-dining-4 {
  @extend .btn-dining;
  @extend .btn-4;
}

.btn-danger-4 {
  @extend .btn-danger;
  @extend .btn-4;
}
.btn-success-4 {
  @extend .btn-success;
  @extend .btn-4;
}
.btn-warning-4 {
  @extend .btn-warning;
  @extend .btn-4;
}

.btn-primary-0 {
  // 50px
  @include font-size(16, 24);
  padding: 8px 14px;
}

.btn-primary-48 {
  // 48px
  @extend .btn-primary;
  @include font-size(16, 24);
  padding: 12px 16px;
  font-weight: 500;
}

.btn-primary-8 {
  // 30px height
  @extend .btn;
  @extend .btn-primary;
  border-radius: 3px;
  padding: 4px 16px;
}

.btn-o-4 {
  @include font-size(14, 20);
  padding: 7px 15px;
}

.btn-outline-gray {
  @extend .btn;
  @include button-outline($black-3, $white-2, $white-1);
}

.btn-outline-gray-1 {
  @extend .btn;
  @include button-outline($black-3, $white-2, $white-1);
  @include font-size(14, 20);
  color: $black-3;
  font-weight: 500;
}

.btn-outline-primary {
  @extend .btn;
  @include button-outline($purple-1, $purple-1, rgba($purple-1, 0.05));
  &:hover {
    color: $purple-1;
  }
}
.btn-outline-danger {
  @extend .btn;
  @include button-outline($red-1, $red-1, rgba($red-1, 0.05));
}

.btn-outline-warning {
  @extend .btn;
  @include button-outline($orange-1, $orange-1, rgba($orange-1, 0.05));
}

.btn-outline-violet {
  @extend .btn;
  @include button-outline($violet-1, $violet-1, rgba($violet-1, 0.05));
}

.btn-outline-abandoned-cart {
  @extend .btn-outline-warning;
}
.btn-outline-success {
  @extend .btn;
  @include button-outline($green-1, $green-1, rgba($green-1, 0.05));
}
.marketing-stripe-btn {
  min-width: fit-content;
  padding: 6px 16px !important;
  white-space: nowrap;
}

.btn-outline-black {
  @extend .btn;
  @include button-outline($black-1, $black-1, rgba($black-1, 0.05));
}

.btn-outline-4 {
  // 40px height
  @extend .btn-outline-gray;
  @extend .btn-o-4;
}
.btn-outline-danger-4 {
  // 40px height
  @extend .btn-outline-danger;
  @extend .btn-o-4;
}
.btn-outline-primary-4 {
  @extend .btn-outline-primary;
  @extend .btn-o-4;
  color: $purple-1;
  &:hover {
    color: $purple-2;
  }
}

.btn-outline-6 {
  // 32px height
  @extend .btn-outline-gray;
  @include font-size(16, 24);
  padding: 3px 12px;
}

.btn-outline-7 {
  // 36px height
  @extend .btn-outline-gray;
  border-color: $white-2;
  @include font-size(14, 20);
  padding: 5px 12px;
}

.btn-outline-8 {
  // 36px height
  @extend .btn-outline-danger;
  @include font-size-weight(14, 20, 500);
  padding: 7px 16px;
}

.btn-outline-9 {
  // 36px height
  @extend .btn-outline-primary;
  color: $purple-1;
  @include font-size(14, 20);
  padding: 7px 20px;
}

.btn-grey-upload {
  height: 32px;
  border-radius: 4px;
  background-color: $white-6;
  padding: 6px 12px;
  @include font-size(14, 20);
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: $white-5;
  }
}

.btn-filter {
  // 36px height
  @extend .btn-outline-gray;
  @include font-size(16, 24);
  min-width: 140px;
  display: flex;
  justify-content: space-between;
  padding: 5px 8px 5px 14px;
  color: $black-3;
  border-color: $gray-3;
  > span {
    position: relative;
  }
  > svg {
    position: relative;
    top: 4px;
  }
}

.plugin-detail-buttons {
  color: $black-3;
  &:hover {
    color: $black-1;
  }
}

.border-primary {
  border: 1px solid $purple-1;
}

.border-box {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.btn-no-default {
  @extend .cur-p;
  @extend .border-box;
  border: none;
  background-color: transparent;
  .edit-btn {
    font-size: 14px;
    line-height: 20px;
  }
  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  }
}
.dining-order-btn {
  @include font-size(16, 24);
  display: inline-flex !important;
  align-items: center;
  padding: 4px 12px 4px 12px;
}

.progress-button-wrap {
  @include display-flex;
  position: relative;
  .progress-button-overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: rgba($white, 0.1);
    transition: width 1.5s;
    &.active {
      width: 100%;
    }
    &.complete {
      width: 100%;
      transition: width 0s;
    }
  }
  button.btn-primary-4 {
    &[disabled] {
      opacity: 1;
    }
  }
}

.btn-small {
  @include font-size-weight(14, 20, 500);
  padding: 5px 12px;
}

.edit-btn-orange {
  color: $orange-1;
  @include font-size(14, 20);
  text-decoration: underline;
  cursor: pointer;
}

.scroll-top-button {
  position: fixed;
  bottom: 32px;
  right: 32px;
  cursor: pointer;
  opacity: 0.4;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
  }
}

.fab-button {
  position: absolute;
  bottom: 64px;
  right: 16px;
  cursor: pointer;
  border-radius: 30px;
  box-shadow: 0px 2px 16px rgba(26, 24, 30, 0.16);
  z-index: 3;
  width: fit-content;
}

.adjust-footer {
  bottom: 80px;
}

@keyframes bg-shimmer {
  0% {
    left: -150px;
  }
  70% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.bulk-shipping-fab {
  @extend .d-flex;
  background-color: $black-2;
  border-radius: 30px;
  padding: 14px;
  overflow: hidden;
  position: relative;
  &::after {
    animation: bg-shimmer 1.8s linear infinite;
    background-position: center;
    background: linear-gradient(
      275.65deg,
      rgba(255, 255, 255, 0) 8.15%,
      rgba(255, 255, 255, 0.4) 44.3%,
      rgba(255, 255, 255, 0) 80.04%
    );
    top: 0;
    bottom: 0;
    content: '';
    width: 150px;
    position: absolute;
    opacity: 0.6;
    transform: skewX(-25deg);
  }
  span {
    box-sizing: border-box;
    max-width: 0;
    margin-left: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.3s;
  }
  &.active {
    padding: 14px 20px;
    span {
      max-width: 200px;
      margin-left: 8px;
    }
  }
}

.btn-small {
  padding: 5px 12px;
  @include font-size-weight(14, 20, 500);
}

.btn-small-update {
  padding: 5px 12px;
  @include font-size-weight(12, 16, 500);
}

.btn-mark {
  @extend .btn-no-default;
  background-color: $white-4;
  &:hover {
    opacity: 0.8;
  }
  transition: all;
  border-radius: 12px;
  padding: 6px 12px;
  @include font-size-weight(12, 16, 500);
}

.btn-suggestion {
  @include font-size-weight(12, 16, 500);
  color: $gray-4;
  margin: 4px 0 5px 20px;
}

.btn-dropdown-small {
  display: flex;
  align-items: center;
  margin-right: 12px;
  padding: 5px 12px;
  background-color: #fff;
  border: solid 1px $white-2;

  &.active {
    background-color: rgba($purple-1, 0.1);
    color: $purple-1;
    border-color: $purple-1;
  }
}

.gradient-btn {
  @extend .btn-no-default;
  @extend .cur-p;
  @include font-size-weight(14, 20, 500);
  width: 100%;
  color: $gray-4;
  text-align: left;
  padding: 10px 16px;

  &:hover {
    color: $gray-1;
  }

  &.big {
    @include font-size-weight(14, 20, 400);
    color: $black-1;
  }

  &.violet {
    display: flex;
    background-color: $violet-2;
    color: $white;
    box-shadow: 0px 2px 6px rgba(26, 24, 30, 0.04);
    border-radius: 6px;
    padding: 12px 12px 12px 16px;
  }

  &.active {
    color: $purple-1;
    font-weight: 500;
    background-image: linear-gradient(
      to right,
      #fff 0%,
      rgba(20, 110, 180, 0.08) 100%
    );
    border-right: solid 3px $purple-1;
  }
}

.btn-primary-white {
  background-color: white;
  padding: 8px 24px;
  color: $purple-3;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.button-group {
  display: flex !important;
  border-radius: 4px;
  cursor: pointer;

  &.btn-primary {
    background: $purple-1;
    color: $white;

    .icon-btn {
      color: $white;
      .icon {
        color: $white;
      }
    }
    .MuiIconButton-root,
    .btn-text {
      @include hover-effect($purple-2, $white);
    }
  }

  .btn-text {
    border-radius: 4px;
    padding: 8px 15px;
    &:last-child {
      padding-right: 24px;
      padding-left: 12px;
    }
    &:only-child {
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  .MuiIconButton-root {
    padding: 0 !important;
    font-weight: inherit;
    border-radius: 4px;
    &:hover {
      background: transparent;
    }
    .icon {
      font-size: 24px;
      line-height: 24px;
      font-weight: 500;
    }
    &:first-child {
      padding-left: 16px !important;
      padding-right: 12px !important;
    }
    &:last-child {
      padding-right: 16px !important;
      padding-left: 8px !important;
    }
  }
  .dash {
    align-self: center;
    color: rgba($white-6, 0.23);
    font-weight: 100;
  }

  // & > *:not(:last-child)::after {
  //   content: '|';
  //   font-weight: 100;
  //   margin: 0 12px;
  //   color: rgba($white-6, 0.23);
  // }
}

.btn-product-new {
  .MuiButtonBase-root {
    &:last-child {
      padding-right: 8px !important;
    }
    .MuiIconButton-label {
      width: 24px;
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}
.btn-outline-white {
  text-align: center;
  outline: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  color: $white;
  background-color: transparent;
  border: 1px solid $white;
  &:hover {
    opacity: 0.8;
  }
}

.btn-outline-auth {
  text-align: center;
  outline: none;
  padding: 7px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  color: $black-3;
  background-color: transparent;
  border: 1px solid $white-2;
  &:hover {
    border-color: $gray-6;
  }
}

.reviews-products-btn-label {
  width: 90px;
}

.quick-navigation-button {
  @include font-size-weight(14, 20, 500);
  @extend .btn-no-default, .cur-p;

  padding-left: 0;

  color: $gray-4;
  &.active,
  &:hover {
    color: $purple-1;
  }
}

.credts-btn {
  @extend .d-flex-c-s;
  @extend .cur-p;
  background-color: $orange-4;
  padding: 6px 16px;
  border-radius: 4px;
}

.icon-button {
  @extend .cur-p;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 12px 24px;
  color: $black-3;
  border: solid 1px $white-2;
  border-radius: 4px;
  &.selected {
    background-color: rgba($purple-1, 0.1);
    color: $purple-1;
    border-color: $purple-1;
  }
}

.canva-text-formatting-button {
  @extend .cur-p;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 12px 24px;
  color: $black-3;
  border: solid 1px $white-2;
  border-radius: 4px;
  &.selected {
    background-color: $black-3;
    color: $white;
    border-color: $black-3;
  }
}
.orders-view-toggle {
  @include content-size(40, 40);
  @extend .cur-p;
  padding: 8px;
  border-radius: 4px;

  &.active {
    background-color: rgba($purple-1, 0.15);
  }
}

.orders-report-download {
  @include content-size(40, 40);
  @extend .cur-p;
  padding: 8px;
  border-radius: 4px;
  background-color: $purple-1;
}

.orders-report-download__inverted {
  @include content-size(36, 36);
  @extend .d-flex-c-c;
  background-color: $white;
  border: 1px solid $white-2;
  border-radius: 4px;

  svg {
    height: 22px;
    width: 22px;
  }

  path {
    fill: $black-3 !important;
  }
}

.abandoned-report-download {
  @include content-size(40, 40);
  @extend .cur-p;
  padding: 8px;
  border-radius: 4px;
  background-color: $white;
  span > svg {
    width: 40px;
    height: 40px;
  }
}

.no-hover {
  pointer-events: none;
}

.visibility-hidden {
  visibility: hidden;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  border: 3px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner-btn {
  transition: all 0.3s ease;
}

.spinner-btn.press-effect:active {
  top: 1px;
}

.explore-premium-btn {
  @extend .d-flex, .align-center, .cur-p;
  padding: 8px 12px;
  border-radius: 4px;

  &:hover {
    background-color: $white-6;
  }
}

.explore-premium-btn-divider {
  width: 1px;
  height: 24px;
  background-color: $gray-3;
  margin-left: 12px;
  margin-right: 24px;
}

.btn-brown {
  background-color: $brown-1;
  color: #fff;

  &:hover {
    background-color: $brown-2;
  }
}

.btn-black-2 {
  @extend .btn-primary-4;
  padding: 12px 24px;
  @include button-theme($black-2, $black-1);
}

.btn-black-2-thin {
  @extend .btn-primary-4;
  padding: 12px 16px;
  @include button-theme($black-2, $black-1);
}

.btn-black {
  @extend .btn-primary-4;
  @include button-theme($black-1, $black-2);
}

.btn-transparent-primary {
  @extend .btn-small;
}

.payment-option-button {
  @extend .btn-outline-4;
  @include button-outline($black-3, $white-2, $white-1);
  color: $black-3;
  font-weight: 500;
  padding: 8px 24px;
  background-color: white;
  display: inline-flex !important;
  align-items: center;

  &.selected {
    @include button-outline($black-3, $purple-1, $white-1);
    background-color: rgba($purple-1, 0.1) !important;
    color: $purple-1;
    svg {
      path {
        fill: $purple-1;
      }
    }
  }
}

.button-divider {
  height: 18px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.4);
  margin-left: 12px;
  margin-right: 12px;
}

.undo-redo-btn-wrapper {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $white-6;
    border-radius: 4px;
  }
}
.undo-redo-btn {
  cursor: pointer;
}

.btn-fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  border-top: 1px solid $white-9;
  border-radius: 0 !important;
  z-index: 6;
  button {
    font-size: 18px !important;
    line-height: 26px !important;
    border-radius: 8px !important;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.btn-whatsappComms-banner {
  @extend .btn;
  @extend .section-text-7;
  padding: 8px 16px;
  background-color: $white;
  color: $green-4;
}

.receive-payment-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.select-file-btn {
  width: 390px;
  padding: 12px 16px;
  background-color: $white;
  border: 1px solid $gray-3;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;

  &.file-added span {
    color: $black-1;
  }

  span {
    @include font-size-weight(16, 24, 400);
    color: $gray-4;
  }
}

.edit-customer-button {
  @extend .d-flex-c-c;
  height: 32px;
  min-width: 32px;
  border-radius: 6px;
  &.shift-top {
    margin-top: -4px;
    margin-right: -4px;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}

.report-download-icon {
  @extend .cur-p, .d-flex-c-c;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  padding: 8px;
  &:hover {
    background-color: #f2f2f2;
  }
}
