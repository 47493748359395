.catalogue-page {
  padding-bottom: 32px;
  .MuiTabs-root {
    padding: 16px 16px 0;
  }
  .catalogue-search-tab-container {
    top: 0;
    background-color: $white-1;
    z-index: 2;
    padding: 24px 32px;
  }
}

.catalogue-search-tab-container {
  .search-bar {
    margin-bottom: 24px;
  }
}

.catalogue-ckeditor {
  .ck-editor__editable {
    min-height: 180px !important;
    max-height: 180px !important;
  }
}

.ck-focused:focus {
  border-color: $purple-1 !important;
  box-shadow: $focus-blue-box-shadow !important;
}

.categories-table {
  .checkbox-label {
    padding-left: 0;
  }

  .category-actions {
    padding: 16px 0;
    gap: 12px;
    display: flex;
  }
  .action-row {
    padding: 12px;
    .custom-select {
      width: 140px;
    }
    .custom-select__menu {
      min-width: 340px;
      font-size: 14px !important;
    }
  }
  .table-data-row {
    .reorder-button {
      padding: 0;
      border: 0;
      background-color: transparent;
      cursor: grab;
    }
    .reorder-button svg {
      display: block;
    }
  }
  .product-block {
    gap: 12px;
    img {
      @include content-size(48, 48);
      border-radius: 4px;
      object-fit: contain;
      border: solid 1px $white-9;
    }
    .product-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      word-break: break-word;
      width: 100%;
    }
  }

  .category-row-wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  .subcategory-block {
    img {
      @include content-size(48, 48);
      border-radius: 4px;
      object-fit: contain;
      border: solid 1px $white-9;
    }
    .subcategory-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      word-break: break-word;
    }
  }

  .form-group {
    max-width: 120px;
    width: 120px;
    margin-bottom: 0;

    &.dense {
      max-width: 84px;
    }

    .input-group-wrap {
      .prepend-txt {
        left: 16px;
        width: 24px;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 20px;
      }
      input {
        font-size: 14px;
        line-height: 20px;
        padding: 8px 16px;
      }
    }

    &.has-prepend-text {
      &.prepend-text-1 {
        input {
          padding-left: 24px;
        }
      }
      &.prepend-text-2 {
        input {
          padding-left: 38px !important;
        }
      }
      &.prepend-text-3 {
        input {
          padding-left: 45px !important;
        }
      }
    }
  }
}

.price-disabled-stripe {
  background-color: $white-6;
  border-radius: 4px;
  padding: 8px 16px;
}

.product-filter-reordering-row-wrapper {
  border-top: 1px solid $white-9;
}

.product-filter-reordering-row {
  @extend .d-flex;
  flex-direction: column;
  width: 100%;
  .reorder-button {
    width: fit-content;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: grab;
  }
  .row-data {
    display: flex;
    flex-direction: column;
  }
  .reorder-button svg {
    display: block;
  }
}

.single-category-header {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-image-wrapper {
  height: 48px;
  width: 48px;
  min-width: 48px;
}

.no-border-bottom {
  border-bottom: none !important;
}

.each-category-container:not(:last-child) {
  border-bottom: 1px solid $white-9;
}

.each-category-container,
.each-subcategory-parent {
  position: relative;

  & > .each-li-subcategory {
    position: relative;
  }
  & > .each-li-subcategory::before,
  .each-li-subcategory::after {
    content: '';
    position: absolute;
    left: 41px;
  }
  & > .each-li-subcategory::before {
    border-top: 1px solid $white-9;
    top: 47px;
    width: 16px;
    height: 0;
  }
  & > .each-li-subcategory::after {
    border-left: 1px solid $white-9;
    height: 100%;
    width: 0px;
    top: -8px;
  }
  & > .each-li-subcategory {
    &:last-child {
      &::after {
        height: 56px;
      }
    }
  }
  & > .has-no-subcategories {
    &::before {
      width: 46px;
    }
  }
}

.each-subcategory-parent {
  & > .each-li-subcategory::before,
  .each-li-subcategory::after {
    left: 73px;
  }
  & > .each-li-subcategory::before {
    width: 47px;
  }
}
