.slide_canvas_container {
  position: relative;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  & > canvas {
    border-radius: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -10;
    visibility: hidden;
  }
}

.canvas_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
  color: white;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  background-size: 100% 100%;
  text-align: center;

  & > div {
    position: relative;

    & > .content {
      width: 100%;
      height: 100%;
      position: absolute;
      padding: 24px;
      top: 0;
      left: 0;

      & > .bc_header,
      .wa_header,
      .sb_header {
        position: absolute;
        width: calc(100% - 32px);
        margin-bottom: 78px;
        left: 16px;

        & .bc_h4,
        .wa_h4,
        .wa_header {
          @include font-size-weight(22, 29.92, 600);
          color: #ffffff;
        }

        & > .bc_h4 {
          margin-bottom: 4px;
        }

        & > .wa_h4,
        .sb_h4 {
          margin-bottom: 6px;
        }

        & > .bc_p {
          @include display-flex(center, center);
          margin-top: 4px;
          & img {
            margin-right: 6px;
          }
        }
      }

      & > .bc_header,
      .sb_header {
        top: 24px;
        margin-bottom: 78px;
      }

      & > .wa_header {
        top: 72px;
      }

      & > .bc_footer,
      .wa_footer,
      .sb_footer {
        width: calc(100% - 32px);
        position: absolute;
        left: 16px;
        color: $white;

        & > .wa_h6,
        .bc_h6,
        .sb_h6 {
          @include font-size-weight(12, 15.96, normal);
          margin-bottom: 4px;
        }
        & > .wa_h5,
        .bc_h5,
        .sb_h5 {
          @include font-size-weight(14, 20.02, 500);
        }
      }

      & > .sb_footer {
        top: 190px;
      }

      & > .wa_footer {
        top: 293px;
      }

      & > .bc_footer {
        top: 160px;
      }

      & > .bc_header,
      .wa_header,
      .wa_footer,
      .sb_header,
      .sb_footer,
      .bc_footer {
        &.loading {
          & h4,
          p,
          h6 {
            background-color: $gray-6;
            text-indent: -9999px;
            height: 22px;
            white-space: nowrap;
            width: 100%;
          }
          img {
            display: none;
          }
        }
      }
    }
  }
}

.edit_head {
  margin: 0 auto;
  border-radius: 3px;
  background-color: $green-1;
  width: fit-content;

  & > h5 {
    @include font-size-weight(12, 15.96, normal);
    width: fit-content;
    font-stretch: normal;
    font-style: normal;
    padding: 2px 6px;
    letter-spacing: normal;
    text-align: left;
    color: $white;
    text-transform: uppercase;
  }
}
