.marketing_designs_page {
  @include display-flex(flex-start, center);

  & > div {
    background-color: $white;
    width: 760px;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.04);
    & > .card_btn_container {
      @include display-flex(flex-start, center);
      margin-top: 80px;
      @include font-size-weight(16, 24, 500);
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      & > .edit_card_btn {
        @extend .cur-p;
        @extend .btn-no-default;
        height: 40px;
        margin: 0 12px 0 0;
        padding: 8px 24px;
        border-radius: 4px;
        border: solid 1px #146eb4;
        color: #146eb4;
      }
      & > .share_card_btn {
        @extend .cur-p;
        @extend .btn-no-default;
        font-weight: 500;
        height: 40px;
        padding: 8px 24px;
        border-radius: 4px;
        background-color: #146eb4;
        color: $white;
      }
    }
  }
}
