.badge {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500 !important;
  padding: 2px 6px;
  border-radius: 3px;
  color: $white;
  height: 20px;
  &.white-transparent {
    background-color: rgba($white, 0.3);
  }
  &.success {
    background-color: $green-1;
    &.payment-badge {
      background-color: rgba($green-1, 0.15);
      font-weight: 600 !important;
      color: $green-1;
    }
  }
  &.primary {
    background-color: $purple-1;
  }
  &.warning {
    background-color: $orange-4;
    color: $black-1;
  }
  &.orange {
    background-color: $orange-1;
    color: #fff;
    &.payment-badge {
      background-color: rgba($orange-1, 0.15);
      color: $orange-1;
      font-weight: 600 !important;
    }
  }
  &.return {
    background-color: $red-1;
    color: #fff;
    position: absolute;
    margin-top: 92px;
    margin-left: 18px;
  }
  &.updated {
    background-color: $orange-1;
    color: #fff;
    position: absolute;
    margin-top: 92px;
    margin-left: 14px;
  }
  &.replacement {
    background-color: $red-1;
    color: #fff;
    position: absolute;
    margin-top: 92px;
    margin-left: -4px;
  }

  &.return-dashboard-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 2px 6px;
    font-weight: 600 !important;
    height: 16px;
    &.light-red {
      color: $red-1;
      background: rgba($red-1, 0.1);
    }
    &.orange {
      color: $orange-1;
      background: rgba($orange-1, 0.1);
    }
  }
  &.multiwarehouse-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    height: 16px;
    &.PAID {
      color: $green-1;
      background: rgba($green-1, 0.15);
    }
  }
  &.gray {
    background-color: rgba($black-3, 0.15);
    color: $black-3;
  }
  &.red {
    background-color: $red-1;
  }
  &.brown {
    background-color: rgba($brown-4, 0.15);
    color: $brown-4;
    &.payment-badge {
      @extend .text-semibold;
    }
  }
  &.gradient-new-btn {
    position: absolute;
    right: 16px;
    top: 10px;
  }
  &.warning-regular {
    font-weight: 400;
    background-color: $orange-4;
    color: $black-1;
  }
}

.active .badge {
  &.gradient-new-btn {
    right: 13px;
  }
}

.h-auto {
  height: auto !important;
}

.outline-badge {
  @extend .d-flex, .align-center;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 7px;
  padding-right: 8px;
  border-radius: 3px;
  width: fit-content;

  .status-dot {
    height: 8px;
    width: 8px;
    min-width: 8px;
    &.red {
      background-color: $red-1;
    }
    &.orange {
      background-color: $orange-1;
    }
    &.green {
      background-color: $green-1;
    }
  }
  &.red {
    border: 1px solid $red-1;
  }
  &.orange {
    border: 1px solid $orange-1;
  }
  &.green {
    border: 1px solid $green-1;
  }
}

.beta-badge {
  @extend .section-text-13;
  padding: 2px 6px;
  border-radius: 3px;
  background-color: $white-9;
  color: $black-3;
}
