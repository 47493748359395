* {
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
  -webkit-font-smoothing: antialiased;
  @media (max-width: 767px) {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}
ul,
ol {
  margin: 0;
  list-style: none;
}
button:focus {
  outline: none;
}
a {
  text-decoration: none;
  outline: none;
  &:hover {
    text-decoration: none;
    outline: none;
    color: inherit;
  }
}
blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
ul,
ol,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

input[type='text'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  color: $body-color;
}

html,
body {
  height: 100%;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.42857143;
  color: $body-color;
  background-color: #fff;
  min-height: 500px;
}

:not(.show-scrollbar)::-webkit-scrollbar {
  display: none;
}

:root {
  --animate-duration: 500ms; // animate css default animation duration
  --ck-z-modal: 1301 !important;
}

#nprogress .bar {
  background: $purple-1 !important;
}

.tooltip-arrow-show {
  margin-top: 8px;
  .MuiTooltip-arrow {
    &::before {
      color: $black-3;
    }
  }
}
