.tags-filter-wrapper {

  & > :nth-child(2) {
    margin-left: auto;
  }
}

.customer-table-filters-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.creatable-select {
  .custom-select__control {
    height: 48px;
  }
  .custom-select-input-value {
    @extend .text-ellipsis;
    @extend .text-8;
    direction: rtl;
    color: $black-1;
  }
  .custom-select__option {
    @extend .text-ellipsis;
    font-size: 14px !important;
    line-height: 20px !important;
    max-width: 100%;

    .new-tag-chip-container {
      @extend .text-ellipsis;
      max-width: calc(100% - 50px);
    }
  }
}

.tags-menu-wrapper {
  .menu-label {
    width: 100%;
  }
  .content-grp {
    white-space: normal;
    .checkbox-label-wrap {
      align-items: flex-start;
    }
  }
}
.tag-label {
  word-break: break-all;

  &.product-filter-option {
    max-width: 165px;
  }
}

.tags-modal {
  .tag-label {
    &.product-filter-option {
      max-width: 220px;
    }
  }
}

.customer-tags {
  .tag-label {
    width: max-content;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
