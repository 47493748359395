.plugin-page {
  .plugin-search-bar {
    width: 188px;
    @media (min-width: 1500px) {
      width: 256px;
    }
  }
  .pluginsList {
    padding: 0 32px 32px;
  }
  .search-filter input{
    height: 40px !important;
  }
}

.plugin-container {
  .export-ratings-details {
    background-color: white;
    border-radius: 4px;
    .MuiTooltip-popper {
      top: -8px !important;
    }
    svg {
      rect {
        stroke: $white-2;
      }
    }
  }
}

.pluginIndex {
  // max-width: 956px;
  .plugins-footer {
    width: 100%;
    border-top: 1px solid $white-2;
    margin-top: 32px;
    padding: 16px 0;
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    color: #808080;
  }
}

.btn2 {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  border: none;
  background-color: transparent;
}

.ratingContainer {
  @include display-flex(center, flex-start);

  .ratingFb,
  .ratingCount {
    p {
      @include font-size-weight(14, 20.02, 500);
      margin-left: 9px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $black-3;
    }
  }
  .ratingCount {
    p {
      @include font-size-weight(14, 20.02, normal);
    }
  }
}

.ratingSubmitContainer {
  @include display-flex(center, space-between);
  padding-top: 8px;
  border-top: $white-2 1px solid;
  .submitChar {
    @include display-flex(center, flex-start);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    font-weight: normal;

    .charCount {
      @include font-size-weight(14, 20.02, normal);
      color: $black-3;
    }
    button {
      @extend .btn2;
      @include font-size-weight(16, 24, 500);
      margin-left: 20px;
      padding: 8px 24px;
      border-radius: 4px;
      color: $white;
      background-color: $purple-1;
    }
  }
}
.plugin-activate-btn {
  button {
    @extend .btn2;
    @include font-size-weight(16, 24, 500);
    height: 40px;
    border-radius: 4px;
    background-color: $purple-1;
    color: $white;
    cursor: pointer;
    padding: 8px 24px;
  }
}
.plugin-install-btn,
.plugin-learn-btn {
  padding: 7px 24px;
}
.plugin-deactivate-btn {
  button {
    @extend .btn2;
    @include font-size-weight(16, 24, 500);
    height: 40px;
    border-radius: 4px;
    color: $black-3;
    cursor: pointer;
    border: solid 1px $gray-3;
    background-color: #ffffff;
    padding: 8px 24px;
  }
}

.plugin-title-header {
  background-color: #ffffff;
  // margin-bottom: 16px;
  // padding-top: 32px;
  padding-bottom: 16px;

  .plugin-title {
    @include display-flex(center, space-between);
    flex-direction: column;
    align-items: start;
    .plugin-title-text {
      letter-spacing: normal;
      font-stretch: normal;
      text-align: left;
      font-style: normal;
      h4 {
        @include font-size-weight(20, 28, 500);
        color: $black-1;
      }
      h6 {
        @include font-size-weight(14, 20.02, 500);
        color: $purple-1;
      }

      .plugin-rating {
        @include display-flex(center, flex-start);

        p {
          margin-left: 8px;
          @include font-size-weight(14, 20.02, normal);
          color: $black-3;
        }
      }
    }

    .plugin-logo {
      width: 88px;
      height: 88px;
      border-radius: 6px;
      margin-right: 16px;
      overflow: hidden;

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.plugin-developer,
.plugin-how-to-use,
.plugin-details,
.plugin-tags {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 32px;

  h5 {
    margin-bottom: 10px;
    @include font-size-weight(16, 24, 500);
    color: $black-1;
  }

  .plugin-developer-details {
    @include font-size-weight(14, 20.03, normal);
    p {
      margin-bottom: 4px;
      color: $black-3;
    }
    a {
      color: $purple-1;
    }
  }
}

.plugin-details {
  margin-bottom: 0;
}

.plugin-tags {
  .plugin-tags-list {
    @include display-flex(center, flex-start);
    flex-wrap: wrap;
    width: 100%;
    div {
      height: 32px;
      max-width: 100%;
      @extend .text-ellipsis;
      margin: 0 8px 8px 0;
      padding: 6px 16px;
      border-radius: 40px;
      border: solid 1px $gray-6;
      @include font-size-weight(14, 20.03, normal);
      color: $black-3;
    }
  }
}

.plugin-details {
  .plugin-details-text {
    @include font-size-weight(14, 20.03, normal);
    color: $black-3;
  }
}

.plugin-content {
  width: 100%;
  margin-top: 32px;
  @include display-flex(start, space-between);
  .plugin-dynamic-content {
    width: 70%;
  }
  .plugin-details-content {
    width: 20%;
  }
}

.triangle {
  position: absolute;
  top: -10px;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid $white-8;
}

.pluginReviewResponse {
  position: relative;
  background-color: $white-8;
  padding: 24px 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;

  .pluginReviewResponseMeta {
    @include display-flex(center, space-between);

    h5 {
      margin-bottom: 8px;
      @include font-size-weight(14, 20.03, 500);
      color: $black-1;
    }
    p {
      margin-bottom: 8px;
      @include font-size-weight(12, 15.96, normal);
      color: $black-1;
    }
  }
  .pluginReviewResponseText {
    p {
      @include font-size-weight(14, 20.03, normal);
      color: $black-3;
    }
  }
}

.pluginReviewInput {
  margin-bottom: 32px;
  h4 {
    @include font-size-weight(18, 25.92, 500);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $black-1;
    margin-bottom: 16px;
  }
}

.pluginReviewCard {
  margin-bottom: 32px;

  @include display-flex(flex-start, flex-start);
  .pluginReviewImage {
    margin-right: 11.5px;
    border-radius: 4px;
    width: 56px;
    height: 56px;
    object-fit: contain;
  }
  .pluginReview.pluginText {
    background-color: $white;
  }
  .pluginReview {
    width: calc(100% - (56px + 11.5px));
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;

    textarea {
      width: 100%;
      height: 174px;
      border-radius: 4px;
      border: none;
      resize: none;
      padding: 16px 52px 134px 16px;
    }

    .pluginReviewSubmit {
      padding: 0 16px 8px 16px;
    }

    .pluginReviewMeta {
      height: 56px;
      width: 100%;
      @include display-flex(center, space-between);

      .pluginReviewRating {
        h5 {
          margin-bottom: 6px;
          @include font-size-weight(14, 20.03, 500);
          color: $black-1;
        }

        div {
          @include display-flex(center, flex-start);

          p {
            @include font-size-weight(12, 15.96, normal);
            margin-left: 8px;
            color: $black-3;
          }
        }
      }

      .favReview,
      .notFavReview {
        @include font-size-weight(14, 20.03, 500);
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;

        p {
          @include display-flex(center, space-between);
          width: 36px;
        }
      }
      .favReview {
        color: $purple-1;
        .favReviewIcon {
          display: block;
        }
        .notFavReviewIcon {
          display: none;
        }
      }
      .notFavReview {
        color: $black-3;
        .favReviewIcon {
          display: none;
        }
        .notFavReviewIcon {
          display: block;
        }
      }
    }
    .pluginReviewText {
      @include font-size-weight(14, 20.03, normal);
      margin-bottom: 12px;
      color: $black-3;
    }
  }
}

.pluginTabsRow {
  width: 100%;
  margin-bottom: 40px;
  @include display-flex(center, flex-start);
  flex-wrap: wrap;
}

.pluginTabs {
  @include font-size-weight(16, 24, 500);
  height: 36px;
  margin: 0 12px 12px 0;
  padding: 6px 20px;
  border-radius: 40px;
  background-color: $white-8;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $gray-1;
}

.pluginTabs.tabActive {
  background-color: $purple-1;
  color: $white;
}

.FAQ {
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  background-color: $white;
  border-radius: 4px;
  border: solid 1px $white-8;
  padding: 16px 24px;
  width: 100%;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;

  .faq-query {
    cursor: pointer;
    .faq-toggle-icon {
      padding: 8px;
      width: 34px;
      height: 34px;
      border-radius: 20px;
      transition: background-color 0.1s ease-in-out;
      @include circle(34px, $white);
      &:hover {
        @include circle(34px, $white-8);
      }
    }
  }
}

.slide___3-Nqo {
  height: 500px !important;
  padding-bottom: 0 !important;
}

.pluginCarousel {
  width: 100%;
  // height: 200px;
}

.pluginCarouselDot {
  padding: 0;
  border: none;
  margin: 16px 12px 0 0;
}

.pluginCarouselThumb {
  height: 64px;
}

.horizontalSlider___281Ls {
  height: 256px;
}

.pluginDescription,
.pluginGuide {
  @include font-size-weight(16, 24, normal);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $black-1;
  p {
    margin-bottom: 20px;
    a {
      color: $purple-1;
      cursor: pointer;
      &:hover {
        color: $black-1;
      }
    }
  }
  ul {
    list-style-type: disc;
    padding-left: 24px;
    padding-bottom: 24px;
  }

  h5 {
    @include font-size-weight(16, 24, 500);
    margin-bottom: 20px;
  }
}

.pluginGuide {
  ul {
    li {
      a {
        color: $purple-1;
        cursor: pointer;
        &:hover {
          color: $black-1;
        }
      }
    }
  }
}
.pluginScreenshots {
  list-style: none;
  list-style-type: none !important;
  padding: 0 !important;
  flex-wrap: wrap;
  @include display-flex(center, flex-start);
  li {
    margin-right: 24px;
    img {
      width: 100%;
    }
  }
}

.truncate-overflow {
  position: relative;
  max-height: 40.04px;
  overflow: hidden;
}

.pluginCard {
  position: relative;
  padding: 20px;
  border-radius: 4px;
  background-color: $white;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
  transition: box-shadow 0.2s;
  box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.04);
  border: solid 1px transparent;
  &:hover {
    border: solid 1px $white-2;
  }
  .pluginCardDesc {
    @extend .d-flex, .space-between, .flex-column;
    .plugin-image {
      min-width: 58px;
      height: 58px;
      object-fit: contain;
      border-radius: 6px;
    }
    div {
      h4 {
        @include font-size-weight(16, 24, 500);
        color: $black-1;
        margin-bottom: 4px;
      }
      p {
        max-width: 100%;
        @extend .truncate-overflow;
        @include font-size-weight(14, 20.02, normal);
        color: $black-3;
      }
    }
  }
  .pluginCardActivation {
    @include display-flex(center, space-between);
    padding: 12px 0 0 0;
    margin-top: 12px;

    a {
      @extend .btn2;
      padding: 8px 24px;
      border-radius: 4px;
      background-color: $purple-1;
      @include font-size-weight(16, 24, 500);
      color: $white;
    }
  }
  .plugin-enterprise-stripe {
    position: absolute;
    background: $orange-4;
    background-repeat: no-repeat;
    right: -6px;
    top: -10px;
    padding-left: 8px;
    width: 87px;
    height: 20px;
    border-radius: 3px 3px 0 3px;
  }

  .badge-triangle {
    width: 6px;
    height: 8px;
    background: linear-gradient(
      to bottom right,
      #f7f7f7 0%,
      #f7f7f7 50%,
      #c77c34 50%,
      #c77c34 100%
    );
    rotate: 180deg;
    position: absolute;
    top: 10px;
    right: -6px;
  }
}

.pluginsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(358px, 1fr));
  grid-gap: 24px;
}

.no-plugins-card {
  margin: 0 32px;
  padding: 48px 0;
  text-align: center;
}

.mx-12 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.my-12 {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}

.mx-8 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.centered-card {
  max-width: 760px;
  margin: 32px auto;
  width: 100%;
}

.brand-information-plugin,
.stock-override-plugin,
.product-scarcity-plugin,
.duplicate-order-plugin,
.csi-container,
.pincode-delivery-plugin,
.faq-plugin,
.all-in-one-seo-container {
  margin: 32px;
  .create-zone-btn {
    @include font-size(14, 20);
    padding: 5px 12px !important;
  }
  .zone-card {
    background-color: $white-10;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .seo-image-wrapper {
    border: 1px solid $white-2;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    .seo-image-upload {
      background: $white-10;
      box-sizing: border-box;
      border-radius: 4px;
      min-width: 247px;
      max-width: 247px;
      min-height: 129px;
      max-height: 129px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        object-fit: contain;
        border-radius: 4px;
        height: 129px;
        width: 247px;
      }
      .overlay-delete-btn,
      .overlay-text {
        display: none;
      }
      &:hover {
        .overlay {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.8);
          height: 100%;
          width: 100%;
          border-radius: 4px;
          z-index: 1;
        }
        .overlay-delete-btn {
          display: block;
          position: absolute;
          top: 14px;
          right: 14px;
          z-index: 2;
        }
        .overlay-text {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          padding: 6px 16px;
          border: 1px solid $white;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }
    }
    .dotted-border {
      border: 1px dashed $gray-3;
    }
    .preview-link {
      cursor: default !important;
    }
  }
  textarea {
    display: block;
    resize: vertical;
  }
  .mt-12 {
    margin-top: -12px;
  }

}
.custom-css-switch {
  > div:first-child {
    align-items: center;
  }
}
.custom-css-input {
  font-family: 'IBM Plex Mono', monospace;
  display: block !important;
}

.csi-container {
  .form-group textarea,
  .form-group input {
    padding: 11px 16px;
  }
  .tag {
    background: $white-6;
    padding: 2px 6px;
  }
}

.create-zone-modal {
  .zone-modal-content {
    .pincode-method {
      margin-left: 2px;
      flex-direction: row;
    }
    .input-group-wrap {
      width: 50%;
    }
  }
  .hr-line {
    height: 1px;
    background-color: $white-2;
    border: none;
    margin-left: -24px;
    margin-right: -24px;
  }
  .form-group.has-prepend-text .input-group-wrap .prepend-txt-block ~ input {
    padding-left: 28px;
    width: 50%;
  }
  .field-info {
    font-weight: 500;
    font-size: 14px;
    color: $black-3;
  }
  .zone-pincodes-list {
    .form-group textarea {
      display: block;
    }
  }
  .zonal-range {
    margin-left: 6px;
    margin-right: 16px;
  }
  .MuiSlider-root.MuiSlider-colorPrimary {
    color: $purple-1;
    span[data-index='0'] {
      &[aria-hidden='true'] {
        left: 16px !important;
      }
    }
    .MuiSlider-track,
    .MuiSlider-rail {
      height: 4px;
      border-radius: 4px;
    }
    .MuiSlider-mark {
      background-color: transparent;
    }
    .MuiSlider-thumb {
      height: 16px;
      width: 16px;
      margin-top: -7px;
    }
  }
  .pincode-upload {
    position: relative;
    margin-top: -12px;
    display: flex;
    justify-content: space-between;
    .pincode-upload-label {
      position: absolute;
      right: 0;
    }
    .csv-reader-input {
      height: 22px;
      width: 80px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      z-index: 6;
      opacity: 0;
      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        padding-left: 240px;
        margin-right: -240px;
        cursor: pointer;
      }
    }
  }
}

.trust-badges-plugin {
  margin: 32px auto;
  max-width: 760px;

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .badge-card {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px 16px;
    margin-top: 16px;
    .trust-plugin-image-container {
      position: relative;
      width: 226px;
      height: 72px;
      border: 1px solid $white-2;
      border-radius: 4px;

      .trusted-badges-img {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 40px;
      }
      .overlay-text {
        display: none;
      }
      &:hover {
        .overlay {
          animation: {
            name: fade-in;
            fill-mode: both;
            duration: 0.8s;
          }
          position: absolute;
          background-color: rgba(0, 0, 0, 0.8);
          height: 100%;
          width: 100%;
          border-radius: 4px;
          z-index: 1;
        }
        .overlay-delete-btn {
          display: block;
          position: absolute;
          height: 28px;
          top: 14px;
          right: 14px;
          z-index: 2;
        }
        .overlay-text {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          box-sizing: border-box;
          border-radius: 4px;
          white-space: nowrap;
          animation: {
            name: fade-in;
            fill-mode: both;
            duration: 1s;
          }
          .overlay-button {
            height: 28px;
            display: flex;
            align-items: center;
            padding: 0 16px;
          }
          p {
            @include font-size(12, 16);
            font-weight: 500;
          }
        }
      }
    }
    .cursor-drag {
      cursor: grab;
      &.dragging {
        cursor: grabbing;
      }
    }
  }
}

.placeholder-badge {
  @extend .d-flex-c-c;
  user-select: none;
  min-height: 72px;
  width: 226px;
  height: 100%;
  background-color: $white-6;
  color: $black-3;
  border-radius: 4px;
}

.sitemap-url {
  background-color: $white-10;
  border-radius: 4px;
  @include font-size(16, 22);
  color: $purple-1;
  svg {
    margin-left: 10px;
  }
  a {
    display: inline-flex !important;
    align-items: center;
    color: $purple-1;
    &:hover {
      color: $purple-1;
    }
  }
}

//whatsapp comms plugin
.whatsapp-banner {
  background-color: $green-4;
  ul {
    list-style: disc;
    margin-left: 13px;
    li {
      margin-top: 4px;
    }
  }
}

.wha-verify-acc-status {
  .count-dot {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $white-9;
    @extend .d-flex-c-c;
  }
  .pending-status {
    @extend .text-10;
    border-radius: 3px;
    padding: 2px 6px;
    &.pending {
      background-color: $orange-4;
    }
    &.active {
      background-color: $green-1;
      color: $white;
    }
  }
  .active-status {
    .description {
      width: 60%;
    }
    .logo > img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      object-fit: fill;
    }
  }
}

.verified-whatsapp-trans {
  .low-balance-msg {
    background-color: $orange-4;
    position: absolute;
    top: 65px;
  }
  .info-strip {
    width: 760px;
    border: 1px solid $white-2;
    border-radius: 4px;
    text-align: center;
    @extend .section-text-13;
    @extend .d-flex-c-c;
    svg {
      vertical-align: middle;
    }
  }
}

.header-layout-page-link {
  a {
    font-weight: 500;
    &:hover {
      color: $gray-1;
    }
  }
  svg {
    height: 14px;
  }
}

.warning-stripe-wrapper {
  display: inline-block;
  text-align: center;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 4px;

  &.danger {
    border: 1px solid $red-1;
    background-color: rgba($red-1, 10%);
    color: $red-1;
  }

  &.warning {
    border: 1px solid $orange-1;
    background-color: rgba($orange-1, 10%);
    color: $orange-1;
  }

  &.center-align {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.plugin-beta-label {
  @extend .section-text-13;
  padding: 2px 6px;
  color: $black-3;
  background-color: $white-9;
  border-radius: 3px;
  width: fit-content;
  height: fit-content;
}

.min-order-value-plugin {
  max-width: 760px;
  margin: 32px auto;
  .min-order-value-plugin-forminput {
    margin: 0 !important;
    width: 344px !important;
  }
}

.shopify-product-importer {
  max-width: 760px;
  margin: 32px auto;
}
.country-flag-select {
  width: 100%;
  .custom-select__menu {
    width: 352px;
    left: -2px;
  }
  &.country-flag-select-plugin {
    width: auto;
    .custom-select__menu {
      width: auto;
      left: -2px;
    }
  }
}
.import-successful-section > div{
  max-width: 355px;
  margin: 0 auto;
}
.shopify-importer-form li{
  list-style-type: disc !important;
}
.shopify-importer-steps-modal-wrapper .accordion-number{
  // padding: 12px;
  border-radius: 99999px;
  min-width: 32px;
  min-height: 32px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  color: $gray-1;
  border: 1px solid $white-9;
  z-index: 1;
  position: relative;
  background-color: $white;
}
.shopify-importer-steps-modal-wrapper .status-vericle-line{
  flex-grow: 1;
  width: 1px;
  background-color: $white-9;
  }
.accordion-number-wrapper.active .accordion-number{
  background-color: $purple-1;
  color: $white;
}
.accordion-number-wrapper.active .status-vericle-line{
  background-color: $purple-1;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.rotate-infinite {
  animation: rotating 2s linear infinite;
}

.shopify-importer-steps-modal{
  border-radius: unset !important;
  box-shadow: unset !important;
  max-width: 472px !important;
}

.shopify-importer-steps-modal .MuiAccordionSummary-root{
  padding: 0 !important;
}

.shopify-importer-steps-modal.MuiAccordion-root.Mui-expanded{
  margin: 0 !important;
  border: 1px solid $purple-1;
  border-radius: 4px !important;
  background-color: #F3F8FB;
}
.shopify-importer-steps-modal .MuiAccordionSummary-root.Mui-expanded{
  min-height: unset !important;
}
.shopify-importer-steps-modal .MuiAccordionSummary-content{
  margin: 8px 0 !important;
  padding: 12px 16px;
  border: 1px solid $white-2;
  border-radius: 4px;
}
 .shopify-importer-steps-modal .MuiAccordionSummary-content.Mui-expanded{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 0;
}

.whatsapp-country-select {
  width: 352px;
  .field-label {
    font-size: 16px !important;
    line-height: 24px !important;
    @extend .text-normal;
  }
  .input-group-wrap input {
    @extend .text-1;
    @extend .text-normal;
    height: 50px;
  }
  &.country-1 {
    input[name='business_mobile'],
    input[name='mobile'],
    input[name='mobile_number'] {
      padding-right: 90px;
    }
  }
  &.country-2 {
    input[name='business_mobile'],
    input[name='mobile'],
    input[name='mobile_number'] {
      padding-right: 103px;
    }
  }
  &.country-3 {
    input[name='business_mobile'],
    input[name='mobile'],
    input[name='mobile_number'] {
      padding-right: 113px;
    }
  }
}

.selected-country {
  display: flex;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
  user-select: none;
  img {
    height: 16px;
    width: 24px;
    object-fit: contain;
  }
  img,
  span {
    margin-right: 8px;
  }
}

.whatsapp-send-otp-btn {
  width: 100%;
  padding: 12px 24px;
  height: 50px;
  span {
    @extend .text-1;
    @extend .text-medium;
  }
}

.sidebar-filters-card {
  @extend .card, .ml32, .p24;
  max-width: 270px;
  height: fit-content;
  position: sticky;
  top: 24px;
  .radio-filter-wrap {
    p {
      white-space: nowrap;
    }
    .MuiFormControlLabel-root {
      margin-bottom: 16px;
      height: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background-color: transparent;
      }
      .MuiTypography-body1 {
        @extend .text-medium;
      }
    }
  }
}

.rto-optimiser-page {
  max-width: 760px;
  margin: 32px auto;
}

.topup-balance-banner {
  background-color: $orange-4;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.header-actions {
  .advanced-hellobar-header {
    max-width: 1152px;
  }
}

.hellobar-plugin {
  max-width: 1152px;
  margin: 32px auto;
  width: 100%;

  &.customize-template {
    max-width: 760px;
  }

  .template-card {
    background-color: $white-10;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .selected-value {
    padding-left: 0;
    margin-left: 4px;
  }
}

.font-select-radio-container {
  span {
    font-size: 16px;
  }
  .MuiFormControlLabel-root {
    margin-right: 22px;
  }
}

.hellobar-customize-template-preview {
  position: sticky;
  top: 0px;
  z-index: 99;
  .hellobar-template {
    border: 0;
  }
}

.hellobar-template-container {
  .hellobar-template {
    border: 0;
  }
}

.hellobar-template {
  padding: 16px;
  border: solid 1px $white-2;
  border-radius: 4px;

  .customize-btn-container {
    min-height: 34px;
  }

  .customize-btn {
    border: 0;
    background-color: $white;
    color: $black-3;
    display: flex;
    align-items: center;
    border: 1px solid $white-2;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  &.preview {
    padding: 0;
    width: 100%;
  }

  &.selected {
    border: solid 1px $purple-1;
  }

  .hellobar-container {
    padding: 8px 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 2px;
    background-color: $black-1;
    word-break: break-all;

    .banner-text {
      @extend .section-text-5;
    }

    .banner-text-without-timer {
      max-width: 645px;
    }
    .banner-text-without-timer-and-link {
      max-width: 957px;
    }

    .action-link {
      background-color: $white;
      padding: 8px 16px;
      border-radius: 2px;
      @extend .section-text-7;
    }

    .action-link-without-timer {
      max-width: 187px;
    }

    &.hellobar-container-1 {
      .countdown-container {
        width: 280px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .time-container {
          text-align: center;
          span {
            font-size: 18px;
          }
          .time-unit {
            @extend .section-text-13;
            text-transform: uppercase;
          }
        }
        .time-divider {
          @extend .section-text-5;
          margin-bottom: 12px;
        }
      }
    }
    // hellobar 2
    &.hellobar-container-2 {
      background-size: contain;
      .countdown-container {
        width: 251px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .time-container {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2px;
          width: 100%;

          .time-block {
            span {
              background-color: $white;
              color: $black;
              padding: 2px 4px;
              border-radius: 2px;
              width: 33px;
              display: block;
              font-size: 18px;
            }
          }
          .time-unit {
            @extend .section-text-13;
          }
        }
        .time-divider {
          @extend .section-text-5;
          margin-bottom: 12px;
        }
      }
    }

    // hellobar 3
    &.hellobar-container-3 {
      .countdown-container {
        width: 251px;
        color: $red-8;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .time-container {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2px;
          width: 100%;

          .time-block {
            span {
              border: 1px solid $red-8;
              padding: 2px 4px;
              border-radius: 2px;
              width: 33px;
              display: block;
            }
          }
          .time-unit {
            @extend .section-text-13;
          }
        }
        .time-divider {
          @extend .section-text-5;
          margin-bottom: 12px;
        }
      }
    }

    // hellobar 4
    &.hellobar-container-4 {
      background-size: contain;

      .countdown-container {
        width: 251px;
        color: $red-8;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .time-container {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            font-size: 18px;
          }
          .time-block {
            span {
              color: $red-9;
              padding: 2px 4px;
              border-radius: 2px;
              width: 33px;
              display: block;
            }
          }
          .time-unit {
            color: $red-9;
            @extend .section-text-13;
          }
        }
        .time-divider {
          @extend .section-text-5;
          margin-bottom: 12px;
        }
      }
    }
  }
}

// Countdown Timer v3

.countdown-timer-preview-container {
  margin-left: 32px;
  padding-bottom: 16px;
  border-radius: 4px;
  width: 270px;
  height: fit-content;
  position: sticky;
  top: 32px;
}

.select-template-container {
  max-width: 760px;
  width: 100%;

  .select-template-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(344px, 1fr));
    grid-gap: 24px;
  }
}

.countdown-timer {
  .customize-btn {
    border: 0;
    background-color: $white;
    display: flex;
    align-items: center;
    color: $black-3;
  }

  &.preview {
    padding: 0;
    border: 0;
    &.pluginpage {
      height: 88px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .date-container {
        margin: 0 !important;
        &.preview {
          transform: scale(1);
        }
      }
    }
  }

  padding: 16px;
  border-radius: 4px;
  border: solid 1px $white-2;

  &.selected {
    border: solid 1px $purple-1;
  }

  .date-container {
    display: flex;
    flex-direction: row;

    &.preview {
      transform: scale(0.76);
    }
  }

  .time-container {
    display: flex;
    width: 36px;
    flex-direction: column;
    align-items: center;
  }

  .time-container-for-7-8 {
    display: flex;
    max-width: max-content;
    flex-direction: column;
    align-items: center;
  }

  .template-font {
    color: $black-3;
    font-size: 14px;
    line-height: 20px;
    .MuiTypography-body1 {
      font-weight: 500;
    }

    .MuiIconButton-label {
      .custom-radio-icon {
        margin-left: 2px;
      }
    }
  }

  .time-block {
    padding: 4px 8px;
    border-radius: 4px;
    border: solid 1px transparent;
    width: fit-content;
    margin-bottom: 4px;
  }
  .time-block-w36 {
    text-align: center;
    width: 36px;
  }

  .time-unit {
    @include font-size(10, 12);
    color: $gray-1;
    text-align: center;
  }

  .time-divider {
    @include font-size-weight(16, 24, 500);
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 4px;
    margin-top: 4px;
  }

  .date-container-1 {
    display: flex;
    flex-direction: row;
    padding: 0 16px 0 0;
    border-radius: 4px;

    .time-block {
      background-color: $orange-1;
      span {
        color: $white-12;
      }
    }

    .time-unit {
      font-size: 10px;
      color: $gray-1;
    }
  }

  .date-container-2 {
    display: flex;
    flex-direction: row;
    padding: 0 16px 0 0;
    border-radius: 4px;

    .time-block {
      background-color: transparent;
      border: 1px solid $white-9;
      span {
        color: $black-1;
      }
    }

    .time-unit {
      font-size: 10px;
      color: $gray-1;
    }
  }

  // Template 3
  .date-container-3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $white-11;
    padding: 16px;
    border-radius: 4px;

    .time-block-3 {
      background-color: $red-7;
      span {
        color: $white-12;
      }
    }

    .time-unit-3 {
      font-size: 12px;
      color: $black-1;
      opacity: 0.86;
    }
  }

  // Template 4
  .date-container-4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $white;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid $white-9;
    .time-block {
      background-color: $white-6;
      span {
        color: $black-1;
      }
    }

    .time-unit {
      font-size: 12px;
      color: $gray-1;
    }
  }

  // Template 5
  .date-container-5 {
    justify-content: center;
    background-color: $black-1;
    padding: 16px;
    border-radius: 4px;

    .time-block {
      margin-bottom: 0;
      padding: 4px 8px 0 8px;
      span {
        color: $orange-8;
        font-size: 20px;
      }
    }

    .time-unit {
      font-size: 10px;
      line-height: 12px;
      color: $white;
      opacity: 0.86;
      text-transform: uppercase;
    }
    .time-divider {
      color: $white;
    }
  }

  // Template 8
  .date-container-8 {
    justify-content: center;
    align-items: baseline;
    background-color: $magenta-1;
    padding: 15px;
    border-radius: 4px;

    .time-block {
      display: flex;
      gap: 2px;
      margin-bottom: 0;
      padding: 0px 8px 4px 8px;
      .time-digit-divider {
        color: $orange-9;
        font-size: 16px;
        border: 1px solid $orange-9;
        padding: 3px 6px;
        border-radius: 4px;
        width: 24px;
        text-align: center;
      }
    }

    .time-unit {
      font-size: 12px;
      color: $orange-9;
    }
    .time-divider {
      color: $orange-9;
      font-weight: 600;
      font-size: 18px;
      margin: 0;
    }
  }
  // Template 6
  .date-container-6 {
    justify-content: center;
    position: relative;
    align-items: baseline;
    background-color: $magenta-1;
    padding: 16px 16px 8px 16px;
    border-radius: 4px;

    .svg-background-container {
      position: absolute;
      top: 0;
    }

    .time-block {
      display: flex;
      gap: 2px;
      padding: 4px 8px 6px 8px;
      border: 1px solid $brown-3;
      z-index: 1;
      .time-digit-divider {
        color: $brown-3;
        font-size: 16px;
        border: 1px solid $brown-3;
        padding: 4px 6px;
        border-radius: 4px;
      }
    }

    .time-unit {
      font-size: 12px;
      font-weight: 500;
      color: $brown-3;
      z-index: 1;
    }
    .time-divider {
      color: $brown-3;
      font-weight: 600;
      font-size: 18px;
      z-index: 1;
    }
  }

  // Template 7
  .date-container-7 {
    justify-content: center;
    position: relative;
    align-items: baseline;
    background-color: $magenta-1;
    padding: 16px 16px 8px 16px;
    border-radius: 4px;

    .svg-background-container {
      position: absolute;
      top: 0;
    }

    .time-block {
      display: flex;
      gap: 2px;
      padding: 0 8px 2px 8px;
      z-index: 1;
      .time-digit-divider {
        width: 24px;
        text-align: center;
        background-color: $white;
        color: $green-6;
        font-size: 16px;
        padding: 4px 6px;
        border-radius: 4px;
      }
    }

    .time-unit {
      font-size: 12px;
      font-weight: 500;
      color: $white;
      z-index: 1;
    }
    .time-divider {
      color: $white;
      font-weight: 600;
      font-size: 18px;
      z-index: 1;
      margin: 0;
    }
  }
}

.font-select-radio-container {
  span {
    font-size: 16px;
  }
  .MuiFormControlLabel-root {
    margin-right: 22px;
  }
}

.font-select-dropdown {
  .selected-value {
    padding: 0;
    margin: 0;
  }
}

.header-action-container {
  &.countdown-timer-header {
    max-width: 1060px;
  }
}

.store-method-options {
  .MuiFormControlLabel-root {
    margin-right: 24px;
  }
}

.cd-timer .form-color-select .color-select-value-container .color-box {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: $white-11;
  border: 1px solid $white-2;
  border-radius: 2px;
}

.loyalty-points {
  max-width: 760px;
  margin: 32px auto;

  .form-container {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
  .lp-input {
    width: 344px;
  }
}
.woo-commerce-product-importer {
  max-width: 760px;
  margin: 32px auto;
}
.out-of-stock-orders > .form-group {
  margin-bottom: 0;
}

.brand-information-plugin {
  margin: auto auto 32px;
  .brand-items-scroll {
    overflow-y: scroll;
    max-height: calc(100vh - 308px);
  }
  .brand-segment {
    margin-bottom: 24px;
    background: $white-10;
    border: 1px solid $white-9;
    width: 344px;
    height: 52px;
    border-radius: 4px;
    padding: 12px 16px;
    gap: 12px;
  }

  .brand-field-input {
    -ms-flex-preferred-size: 201px;
    flex-basis: 201px;
    max-width: 201px;
    margin-top: 12px;

    .form-group.input-wrap.form-group-lg {
      margin-bottom: 0;
    }
  }

  .brand-value-input {
    -ms-flex-preferred-size: 451px;
    flex-basis: 451px;
    max-width: 451px;
    margin-top: 12px;

    .form-group.input-wrap.form-group-lg {
      margin-bottom: 0;
    }
  }

  .svg-delete-icon {
    height: 24px;
    width: 24px;
    margin-top: 12px;
    fill: none;
  }

  .brand-info-banner {
    max-height: 20px;
    width: 150px;
    align-items: center;
  }

  .brand-desc-editor {
    border-radius: 4px;
  }

  .form-group-brand-info {
    margin-bottom: 32px;
  }

  .hr-line.brand-plugin-hr {
    margin-top: -12px;
  }
}
.webhook-items,
.faq-items {
  display: grid;
  grid-row-gap: 16px;
  .webhook-item-card,
  .faq-item-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $white-10;
    border: 1px solid $white-9;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;
    .content {
      display: grid;
      grid-row-gap: 4px;
    }
    .action-menu-icon {
      margin-right: -12px;
      .icon {
        color: $black-3;
      }
    }
  }
}

.webhook-credentials {
  padding: 16px;
  border-radius: 6px;
  background-color: $white-6;
  font-family: 'IBM Plex Mono', monospace;
}

.textarea-wrap,
.faq-plugin {
  .faq-text-area {
    min-height: 120px;
    resize: none;
  }
}

.faq-items {
  &.accordion-faq-items {
    grid-row-gap: 17px;
  }
}

.faq-item-card {
  &.accordion-faq-design {
    background-color: $white;
    border: none;
    padding: 0px;
    .content {
      grid-row-gap: 5px;
    }
  }
}

.faq-input-field > .form-group {
  margin-bottom: 0px;
}

.shippo-plugin-country-select {
  .custom-select__value-container {
    .css-b8ldur-Input {
      input {
        box-shadow: none;
      }
    }
  }
}

.enterprise-external-link {
  color: white !important;
}

.unicommerce-plugin {
  .form-row {
    padding-top: 20px;

    &:not(:nth-last-child(2)) {
      padding-bottom: 20px;
      border-bottom: 1px solid $white-9;
    }
  }

  .form-group-md {
    margin-bottom: 0;
  }

  .picker-text-content {
    @extend .section-text-14;
  }
}
.plugins-banner-wrapper
{
  max-height: 238px;
  background-color: #141571;
  img{
    max-height: 238px;
  }
}
@media only screen and (max-width: 1200px) {
  .plugins-banner-wrapper .banner-title{
    font-size: 24px !important;
    line-height: 32px !important;
  }
  .plugins-banner-wrapper .banner-sub-title{
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .plugins-banner-wrapper img{
    max-height: 164px;
  }
}
@media only screen and (max-width: 992px) {
  .plugins-banner-wrapper{
    display: none !important;
  }
}
.plugins-title-wrapper{
  position: sticky;
  top: 64px !important;
}
.plugins-content-wrapper{
  border-left: 1px solid $white-11;
  top: 64px !important;
}
.plugin-info-modal-title{
  border-bottom: 1px solid $white-2 ;
  position: sticky;
  z-index: 999;
  top: 0;
}
.plugin-info-modal-wrapper .MuiDialog-container .MuiDialog-paperScrollPaper{
  height: 720px !important;
  max-height: 720px !important;
}
.plugin-info-modal-wrapper .MuiDialogContent-root.dialog-content{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}