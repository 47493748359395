.reviews-importer-plugin {
  .max-w-1062 {
    max-width: 1062px;
  }
  .max-w-760 {
    max-width: 760px;
  }
  .max-w-370 {
    max-width: 370px;
  }
  .max-w-270 {
    max-width: 270px;
  }
  .w-370 {
    width: 370px;
  }
  .w-270 {
    width: 270px;
  }
  .filters-card {
    @extend .card;
    padding: 24px;
    margin-right: 32px;
    max-width: 270px;
    width: 270px;
    height: fit-content;
  }
  .MuiFormControlLabel-root {
    margin-bottom: 12px;
  }
  .form-group {
    margin-bottom: 0;
  }
  .two-line-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .rating-checkbox {
    .MuiFormControlLabel-root {
      margin-bottom: 0;
    }
  }
  .checkbox-label-wrap .checkbox-label {
    color: $black-1;
    @extend .text-2;
  }
  .external-checkbox-label {
    margin-left: 4px;
    color: $black-1;
    @extend .text-2;
  }
  .cross-btn {
    background: none;
    border: none;
  }
  .cross-btn-light {
    cursor: default;
    svg {
      path {
        fill: $gray-3;
      }
    }
  }
  .cross-btn-dark {
    cursor: pointer;
    svg {
      path {
        fill: $black-3;
      }
    }
  }
  .custom-select{
    &__single-value{
      color: $black-1;
    }
    &__control--is-disabled{
      background: $white-10;
    }
    svg{
      path{
        fill: $gray-1;
      }
    }
  }
}
