.no-coupons-container {
  height: calc(100vh - 278px);
  &.add-footer-height {
    height: calc(100vh - 278px - 102px); //SUBSCRIPTION_EXPIRY_FOOTER_HEIGHT
  }
  flex-direction: column;
  .title {
    @include font-size-weight(20, 28, 500);
    text-align: center;
    color: $black-1;
  }
  .description {
    @include font-size-weight(16, 24, 400);
    width: 564px;
    text-align: center;
    color: $black-3;
  }
}
.coupon-form-wrap {
  margin: 32px;
  .actions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.coupon-form-container {
  margin-top: 32px;
  .card {
    margin-bottom: 24px;
    .form-group {
      margin-bottom: 24px;
    }
  }

  .flat-discount-helper-text {
    @include font-size-weight(14, 20, 500);
    margin-top: -15px;
    color: $black-3;
    min-height: 20px;
  }

  .show-coupons-text {
    @include font-size-weight(16, 24, 400);
    color: $black-1;
  }
  .instruction-text {
    @include font-size-weight(14, 20, 400);
    color: $gray-1;
  }
  .MuiFormGroup-root.discount-radio-group {
    flex-direction: row;
  }
}

.coupons-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
  grid-gap: 24px;
  .coupon-item-container {
    position: relative;
    height: 100%;
    .coupon-info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      padding-bottom: 16px;
      height: 100%;
    }
  }
  .coupon-item {
    padding: 16px 16px 12px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    a {
      height: calc(100% - 36px);
    }
    &:hover {
      box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.1);
    }
    .code {
      @include font-size-weight(20, 28, 500);
      color: #1a181e;
    }
    .description {
      @include font-size-weight(14, 20, 400);
      color: #4d4d4d;
      margin-bottom: 16px !important;
    }
    .show-hide-switch {
      position: absolute;
      top: 0;
      right: 0;
    }
    .card {
      color: initial;
      .product-item-container {
        @include display-flex(center, flex-start);
      }
    }
    .stats-wrap {
      display: flex;
      div {
        flex: 1;
      }
      span {
        @include font-size-weight(12, 16, 400);
        color: $gray-1;
      }
      p {
        @include font-size-weight(24, 32, 500);
        color: $black-2;
      }
    }

    .share-coupon-link {
      position: relative;
      cursor: pointer;
      @extend .d-flex-c-c;
      &::after {
        position: absolute;
        top: 0;
        width: 100%;
        height: 1.5px;
        content: '';
        background: repeating-linear-gradient(
          to right,
          #e6e6e6 0,
          #e6e6e6 5px,
          transparent 5px,
          transparent 9px
        );
        background-repeat: no-repeat;
      }
      padding-top: 12px;
      .share-link-icon {
        margin: -4px 8px 0 0;
      }
      .circle {
        cursor: default;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        position: absolute;
        background-color: #f7f7f7;
        &.circle-left {
          top: -12px;
          right: unset;
          left: -28px;
        }
        &.circle-right {
          top: -12px;
          left: unset;
          right: -28px;
        }
      }
    }
  }
}

.customer-list-container {
  .customer-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
    grid-gap: 24px;
    margin-top: 12px;

    .customer-item {
      @include display-flex(center, flex-start);
      padding: 16px;
      color: $black-1;
      box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.04);
      transition: box-shadow 0.2s;

      &:hover {
        box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.1);
      }

      .customer-name-initials {
        @include content-size(56, 56);
        @include font-size-weight(32, 40, 600);
        background-color: $white-1;
        text-transform: uppercase;
        border-radius: 4px;
        margin-right: 12px;

        &.smaller-initials {
          @include font-size-weight(24, 32, 600);
        }
      }
      .customer-info {
        min-width: 0;
        .customer-name {
          @extend .text-ellipsis;
          text-transform: capitalize;
        }
        .customer-orders {
          color: $gray-1;
        }
      }
    }
  }

  .export-customer-details {
    background-color: white;
    border-radius: 4px;
    .MuiTooltip-popper {
      top: -8px !important;
    }
    svg {
      rect {
        stroke: $white-2;
      }
    }
  }
}

.coupon-item-wrap {
  padding: 16px;
}

.amount-card {
  border-radius: 4px;
  padding: 28px 32px;

  &.green {
    background-color: $green-1;
  }

  &.orange {
    background-color: $orange-1;
  }
}

.manage-grid {
  padding: 32px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
}

.extra-charge-header {
  position: relative;

  & > .action-menu-icon {
    position: absolute;
    top: -8px;
    right: -14px;
    z-index: 1;
  }
}

.order-form-fields,
.subcategory-fields {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, minmax(344px, 1fr));
}

.order-form-field {
  padding: 10px;
  border: solid 1px $white-2;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.field-type-modal .dialog-content.MuiDialogContent-root{
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.field-type-items {
  padding-top: 24px;
  display: grid;
  gap: 10px;
}
.field-type-item{
  padding: 10px;
}
.field-type-item:hover{
  background-color: $white-10;
}
.field-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.manage-item-card {
  @extend .card;
  @extend .cur-p;
  padding: 16px;

  &:hover {
    box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.1);
  }
}

.invoice-layout {
  background-color: $white;
  padding: 32px;

  .invoice-divider {
    height: 1px;
    background-color: $white-2;
    margin: 16px 0;
  }

  .invoice-editable-component {
    cursor: pointer;
    display: inline-block;
    padding: 0;
    border-radius: 2px;
    border: 1px solid transparent;
    outline: none;
  }

  .text-right {
    text-align: right;
  }
  textarea.invoice-editable-component,
  .invoice-editable-component.textarea {
    width: 100%;
    resize: none;
    min-height: 156px;
    border-color: $purple-1;
  }
  .invoice-editable-component.textarea {
    border-color: transparent;
  }
  input.invoice-editable-component {
    border-color: $purple-1;
  }
  input.invoice-meta {
    min-width: 400px;
  }
  .company-name {
    @include font-size-weight(24, 32, 600);
  }
  .invoice-label {
    @include font-size-weight(24, 32, 600);
  }
  .invoice-default-label {
    @include font-size(14, 20);
  }

  .invoice-default-label-sm {
    @include font-size(14, 20);
    min-width: 200px;
  }

  .add-new-item {
    cursor: pointer;
    @include display-flex(center, flex-start);
    @include font-size-weight(12, 16, 600);
    color: $purple-1;
    margin-top: 16px;
  }

  table.invoice-table {
    width: 100%;
    th,
    td {
      padding: 8px;
      text-align: left;
      width: 110px;
      &:last-child {
        text-align: right;
        padding-right: 0;
      }
      &.description {
        width: auto;
      }
      &.number {
        width: 20px;
      }
    }
    thead {
      tr {
        border-bottom: 1px solid $white-2;
        th {
          @include font-size-weight(14, 20, 600);
        }
      }
    }

    tbody {
      tr {
        td {
          .total-cell {
            @include display-flex(center, flex-end);
            position: relative;
          }
        }
      }
    }
  }

  .remove-item {
    cursor: pointer;
    position: absolute;
    right: -24px;
    top: 1px;
  }

  .invoice-totals {
    .sub-total,
    .sales-tax {
      @include font-size-weight(14, 20, 500);
    }
    .sales-tax {
      position: relative;
    }
    .grand-total {
      @include font-size-weight(16, 24, 600);
    }
  }
}

.store-timings-card {
  @extend .card;
  padding: 24px;
}

.store-timing-day-item {
  @extend .d-flex;
  @extend .align-center;
  margin-bottom: 16px;
  height: 36px;
  .day {
    width: 120px;
  }
}
.days-container {
  .store-timing-day-item:last-child {
    margin-bottom: 0;
  }
}

.store-timing-time-picker-btn {
  @extend .btn, .btn-o-4, .btn-outline-gray;
  @include font-size(14, 20);
  padding: 8px 16px;
  width: 100px;

  &.time-picker-focus{
    border: 1px solid $purple-1;
    box-shadow: $focus-blue-box-shadow;
  }
}

.menu-items-divider {
  border-bottom: solid 1px $white-8 !important;
  margin-top: 2px;
  margin-bottom: 2px;
}
.selected-time-slot {
  color: $purple-1 !important;
  font-weight: 500 !important;
}

.MuiListItem-button {
  margin-left: 8px !important;
  margin-right: 8px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;

  &:hover {
    border-radius: 4px;
  }
}

.kyc-banner {
  border-radius: 6px;
  background-color: $black-1;
  color: $white;
  width: 100%;
}

.abandoned-orders-advance-settings {
  display: grid;
  .abandoned-orders-save-btn {
    width: 760px;
    text-align: end;
    margin: 32px auto 0px;
  }
}

.abandoned-cart-container {
  width: 760px;
  margin: auto;
  .toggle-abandoned-cart-page {
    @include display-flex($justify: space-between);
  }
  .abandoned-cart-info {
    .form-group {
      margin-bottom: 24px;
    }
    .send-after-container {
      display: flex;
      .MuiFormControlLabel-root {
        margin-left: -9px;
      }
      .MuiRadio-root.custom-radio {
        padding: 0 8px !important;
      }
      label:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.select-field-preference {
  .select-field-preference-radio-group {
    .select-field-preference-radio-item {
      display: flex;
      align-items: flex-start;
      .custom-radio {
        margin-top: 2px;
        padding-right: 12px !important;
      }
    }
  }
}

.radio-item{
  .MuiFormControlLabel-label{
    @include font-size(16, 24);
  }
  .custom-radio{
    padding-right: 8px !important;
  }
}

.recovery-msg-select {
  padding: 10px 16px;
  border: 1px solid $white-2;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  .indicators {
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .recovery-menu {
    position: absolute;
    bottom: 0;
    top: calc(100% + 8px);
    padding: 16px;
    box-shadow: 0px 4px 12px rgba(26, 24, 30, 0.1);
    border-radius: 4px;
    width: 100%;
    left: 0;
    background: $white;
    height: fit-content;
    z-index: 9999;
    max-height: 420px;
    overflow-y: overlay;
    .rec-menu-item:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.rec-menu-item {
  padding: 10px 16px;
  border-radius: 4px;
  background: $white;
  border: 1px solid $white-9;
  border-radius: 4px;
  .MuiFormControlLabel-root {
    margin-right: 0px;
    margin-left: 0px;
    .MuiRadio-root.custom-radio {
      padding: 0px !important;
    }
  }
}

.selected-rec-menu-item {
  background: rgba(20, 110, 180, 0.1);
  border: 1px solid $purple-1;
}
