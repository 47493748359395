.__rechart_default-tooltip {
  padding: 12px;
  color: $white;
  background-color: $black-3;
  border-radius: 4px;
  .label {
    @include font-size-weight(10, 12, 400);
  }
  .value {
    @include font-size-weight(12, 14, 600);
  }
}

.progress-bar-primary {
  background-color: $purple-1 !important;
}

.progress-bar-warning {
  background-color: $orange-1 !important;
}

.progress-bar-success {
  background-color: $green-1 !important;
}

.progress-bar-danger {
  background-color: $red-1 !important;
}

.progress-bar-empty {
  background-color: $white-9 !important;
}

.progressbar-mini {
  width: 80px;
}
