.subscription-container {
  position: relative;
  max-width: 1400px;
  margin: 32px auto;
  .plan-cost {
    @include font-size-weight(36, 42, 600);
  }

  .plan-original-cost {
    @include font-size-weight(24, 32, 500);
  }

  .subscription-divider {
    width: 40px;
    height: 4px;
    background-color: $purple-1;
  }

  .youtube-player-wrap {
    border-radius: 6px;
    overflow: hidden;
    height: 152px;
    width: 272px;
  }

  .features {
    margin: -12px 0;
    @include display-flex(flex-start, flex-start);
    flex-wrap: wrap;
    .feature-item {
      flex: 0 0 33%;
      padding: 12px 0;
      .feature-active-status-icon {
        @include content-size(20, 20);
      }
    }
  }

  .faq-item {
    cursor: pointer;
    padding: 24px 0 12px 0;
    &:first-child {
      padding: 0 0 12px 0;
    }
    &:last-child {
      padding: 24px 0 0 0;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $white-2;
    }

    .faq-toggle-icon {
      padding: 8px;
      @include content-size(34, 34);
      border-radius: 20px;
      transition: background-color 0.1s ease-in-out;
      &:hover,
      &:focus {
        background-color: $white-8;
      }
    }
    .faq-toggle-icon-mobile {
      min-height: 24px;
      min-width: 24px;
    }

    a {
      text-decoration: underline;
    }
  }

  .domestic-plans-container {
    display: grid;
    grid-template-columns: 30% 35% 30%;
    grid-template-rows: 1fr;
    column-gap: 24px;
    .domestic-plan-card-container {
      margin-top: 44px;
      margin-bottom: 16px;

      .domestic-plan-card {
        @extend .card;
        border-radius: 6px;
        padding: 24px;
        border: 1px solid $white-9;
        min-height: 96%;
        .plan-price-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: baseline;
          margin-bottom: 8px;
          .plan-price {
            @include font-size-weight(40, 48, 600);
          }
        }
        .domestic-plan-features {
          // margin-top: 40px;
          @extend .d-flex;
          flex-direction: column;
          gap: 12px;
          .feature {
            @extend .d-flex;
            gap: 10px;
            .list-disc {
              border-radius: 50%;
              height: 6px;
              width: 6px;
              background-color: $black-3;
              margin-top: 7px;
              min-width: 6px;
            }
          }
        }
        button {
          @extend .section-text-5;
          width: 100%;
        }
        .btn-container {
          height: 40px;
        }
      }
      &:nth-of-type(even) {
        margin: 0px;
        .domestic-plan-card {
          height: calc(100% - 28px);
          padding-top: 40px;
          border-color: $orange-4;
          border-radius: 0px 0px 4px 4px;
        }
        button.primary {
          @extend .btn-primary;
        }
      }
    }
  }

  .feature-show-hide-icon {
    margin-right: 8px;
    g g {
      fill: $purple-1;
    }
  }
  .features-grid {
    position: sticky;
    top: 0px;
    background-color: $white;
    display: grid;
    grid-template-columns: 400px 1fr 1fr 1fr 1fr;
    .feature-head-grid-item {
      padding: 16px;
      button {
        @extend .section-text-5;
        width: 100%;
        height: 40px;
      }
      &.web {
        &:nth-of-type(odd) {
          button.primary {
            @extend .btn-primary;
          }
        }
      }
    }
    &.mobile-flow {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .feature-head-grid-item {
        padding: 0 4px;
        button {
          display: none;
          height: 32px;
          @extend .d-flex-c-c;
          font-size: 14px;
          line-height: 20px;
          padding-top: 6px;
          padding-bottom: 6px;
        }
        &:nth-of-type(even) {
          button.primary {
            @extend .btn-primary;
            display: flex !important;
          }
        }
      }
    }
  }

  .features-comparison-grid {
    display: grid;
    grid-template-rows: 1fr;
    margin-top: 32px;
    .feature-comparison-row {
      display: grid;
      grid-template-columns: 400px 1fr 1fr 1fr 1fr;
      padding: 18px 0px 18px 0px;
      border-bottom: 1px solid $divider-color;
      align-items: center;
      &:nth-child(1) {
        padding-top: 0;
      }
      & > :nth-child(n + 2) {
        justify-self: center;
      }
      &.web {
        &:last-of-type {
          // border: none;
          // padding-bottom: 0;
        }
      }
      // :last-child {
      //   padding-bottom: 0;
      // }
      &.mobile-flow {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 16px 0px 12px 0px;
        & > :nth-child(n + 1) {
          justify-self: center;
        }
        & > :nth-child(2) {
          margin: 0 8px;
        }
      }
    }
    :last-child {
      // border-bottom: 0;
      margin-bottom: 0;
    }
    &.mobile-flow {
      margin-top: 16px;
    }
  }
  .dukaan-enterprise-card {
    @extend .card;
    @extend .d-flex, .flex-1;
    // justify-content: space-between;
    background: linear-gradient(153deg, #fff9ee 10.64%, #ffeae9 109.87%);
    padding: 40px;
    margin-bottom: 24px;
    &.inactive {
      @extend .d-flex-c-c;
      border-radius: 6px;
      border: solid 1px $white-9;
      background: #fff;
    }
    @media screen and (max-width: 768px) {
      justify-content: unset;
      flex-direction: column;
      margin-left: 16px;
      margin-right: 16px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 32px;
      padding-bottom: 32px;
    }

    button {
      white-space: nowrap;
    }
    .btn-outline-primary-4 {
      background-color: transparent;
    }
  }
  .dukaan-plugins-card {
    @extend .card;
    min-height: 230px;
    background-image: url('../../../public/images/plans/dukaan-plugins-desktop.webp');
    background-size: 100% 230px;
    background-repeat: no-repeat;
    background-position: center bottom; /* Position the background image at the bottom */
    padding: 32px 40px;
    margin-bottom: 24px;
    background-color: #ebf5ff;

    @media screen and (max-width: 768px) {
      background-image: url('../../../public/images/plans/dukaan-plugins-mobile.webp');
      min-height: 392px;
      background-size: 100% 392px;
    }
  }
  &.mbl-view {
    margin-bottom: 32px;
  }
}

.popular-badge {
  background-color: $orange-4;
  height: 32px;
  border-radius: 6px 6px 0px 0px;
  @extend .d-flex-c-c;
  @extend .section-text-15;
}

table.payment-history-table {
  margin: 0 -12px;
  &.domain > thead > .forwards-email-head {
    th:first-child {
      width: 14.9%;
    }
    th:nth-child(2) {
      width: 24.1%;
    }
    th:nth-child(3) {
      width: 4.7%;
    }
  }
  & > thead {
    & > tr {
      & > th {
        @include font-size-weight(14, 20, 500);
        padding: 10px 12px;
        text-align: left;
        background-color: $white-6;
        width: 18%;
        &:first-child {
          border-radius: 4px 0 0 4px;
          width: 50%;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
          width: 14%;
        }
      }
    }
  }

  &.history {
    & > thead {
      & > tr {
        & > th {
          &:first-child {
            width: 22%;
          }
          &:last-child {
            width: 5%;
          }
        }
      }
    }
  }

  & > tbody {
    & > tr {
      & > td {
        @include font-size-weight(14, 20, 400);
        text-align: left;
        padding: 8px 12px;
        &:first-child:not(.text-normal) {
          font-weight: 500;
        }
        & > a {
          color: $purple-1;
          font-weight: 500;
          text-decoration: underline;
        }
      }
      &:first-child {
        & > td {
          padding-top: 16px;
        }
      }
    }
  }
  &.domain {
    & > thead {
      & > tr {
        & > th {
          &:nth-child(1) {
            width: 38%;
          }
          &:nth-child(3) {
            width: 30%;
          }
        }
      }
    }
    & > tbody {
      & > tr {
        & > td {
          &:nth-child(3) {
            button {
              height: 24px;
            }
          }
        }
      }
    }
  }
}

.cancel-subscription {
  textarea.feedback {
    display: block;
    min-height: 120px;
  }
  .password-wrap {
    input {
      width: 50%;
    }

    .field-info {
      @include font-size(14, 20);
      color: $gray-1;
    }
  }
}

.product-info-card {
  position: relative;

  .offer-image {
    position: absolute;
    top: 0;
    right: 24px;
    width: 72px;
  }
}

.cancel-subscription-otp-verification-form {
  .mobile-input-form-group {
    margin-bottom: 0;
  }
  .password-wrap {
    input {
      width: 50%;
    }
    .field-info {
      @include font-size(14, 20);
      color: $gray-1;
    }
  }
}

.invoice-detail-form {
  .row {
    margin: 0 -12px;
    .col-md-6,
    .col-md-12,
    .col-xs-12 {
      padding: 0 12px;
    }
  }
  textarea {
    display: block;
  }
}

.plan-item-header {
  min-width: 152px;
  width: 152px;
}

.plan-item-header-active {
  border: solid 1px $purple-1;
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.plan-item-footer-active {
  border: solid 1px $purple-1;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  min-width: 152px;
  width: 152px;
}

.international-plan-features {
  display: grid;
  gap: 16px 68px;
  grid-template-columns: repeat(2, 1fr);
}

.international-plan-toggle {
  @extend .d-flex, .align-center;
  background-color: $white-6;
  padding: 4px;
  border-radius: 100px;
  border: solid 1px $white-6;
  margin-bottom: 16px;
  border: 1px solid $white-2;
  background-color: $white;
  margin-bottom: 0;

  .plan-item {
    padding: 6px 12px;
    position: relative;
    @extend .cur-p;

    &.disabled {
      cursor: not-allowed;
    }

    &.with-discount {
      padding: 12px 24px;
    }
    .period-name {
      // @extend .section-text-5;
      color: $black-3;
      text-align: center;
      span {
        @extend .section-text-13;
      }
    }

    .discounted-price {
      color: $black-1;
    }
    &.selected {
      border-radius: 100px;
      color: white;
      background-color: $purple-1;
      animation-name: fadeIn;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      .period-name {
        color: white;
      }
      .discounted-price {
        color: $white-6;
      }
    }

    @keyframes fadeIn {
      0% {
        background-color: $white-6;
      }
      100% {
        background-color: $purple-1;
      }
    }
    .plan-discount-badge {
      position: absolute;
      left: calc(50% - 30px);
      top: -12px;
      height: 18px;
      width: 60px;
      @extend .d-flex-c-c;
      color: $black-1;
      background-image: url('../../images/icons/discount-rectangle.svg');

      > span {
        letter-spacing: -0.6px;
      }
    }
  }
}
.mobile-plan-discount-container {
  position: relative;
}

.dicount-container {
  .plan-discount-badge {
    height: 18px;
    width: 60px;
    @extend .d-flex-c-c;
    color: $black-1;
    background-image: url('../../images/icons/discount-rectangle.svg');

    > span {
      letter-spacing: -0.6px;
    }
  }
}

.mobile-plan-info {
  border: solid 1px $orange-1;
  color: $orange-1;
  border-radius: 4px;
  padding: 6px 8px;
  background-color: rgba($orange-1, 0.1);
  @include font-size-weight(12, 16, 500);
  text-align: center;

  &.desktop {
    padding: 8px 16px;
    width: fit-content;
  }
}

.mobile-plan-discount-arrow {
  position: absolute;
  left: 56px;
  bottom: 0;
  min-width: 100px;
}

.plan-discount-arrow {
  position: absolute;
  left: 56px;
  bottom: 4px;
  width: max-content;
}

.mobile-plan-fetures-container {
  margin-top: 24px;
  // padding: 0px 16px;

  .mobile-plan-features-header {
    @extend .d-flex;
    width: 100%;
    text-transform: uppercase;
    position: sticky;
    top: -1px;
    background-color: white;
    padding: 0 16px;

    &.is-pinned {
      -webkit-box-shadow: 0px 3px 3px rgba($white-6, 0.75);
      -moz-box-shadow: 0px 3px 3px rgba($white-6, 0.75);
      box-shadow: 0px 3px 3px rgba($white-6, 0.75);
    }

    > :first-child {
      width: 100%;
    }
    > :not(:first-child) {
      @extend .d-flex-c-c;
      min-width: 98px;
      padding-top: 24px;
      padding-bottom: 16px;
    }
    > .selected {
      padding-top: 24px;
      padding-bottom: 16px;
      background-color: $white-10;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .mobile-plan-featues-feature {
    @extend .d-flex;
    width: 100%;
    padding: 0 16px;

    > :first-child {
      width: 100%;
    }
    > :not(:first-child) {
      @extend .d-flex-c-c;
      min-width: 98px;
    }
    > .selected {
      background-color: $white-10;
    }
  }

  .mobile-plan-featues-feature:not(:last-child) {
    border-bottom: solid 1px $white-6;
  }
  .mobile-plan-featues-feature:last-child {
    > :last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
.mobile-plan-footer-container {
  padding: 12px 16px;
  position: absolute;
  bottom: 0;
  background-color: white;
  z-index: 1000;
  width: 100%;

  -webkit-box-shadow: 0px -4px 3px rgba($white-6, 0.75);
  -moz-box-shadow: 0px -4px 3px rgba($white-6, 0.75);
  box-shadow: 0px -4px 3px rgba($white-6, 0.75);

  .plan-select-button {
    padding: 8px 12px;
    border: solid 1px $white-9;
    border-radius: 4px;
    position: relative;
    width: 100%;

    &.selected {
      color: $purple-1;
      border-color: $purple-1;
      background-color: rgba($purple-1, 0.1);
    }
  }

  .plan-select-current-plan {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: $orange-1;
    font-size: 10px;
    line-height: 14px;
    padding: 1px 4px;
    font-weight: 600;
    color: $white;
    border-radius: 2px;
  }

  .plan-subscribe-button {
    @extend .btn-primary-4, .full-w;
    padding: 12px 24px;
    margin-bottom: 14px;
    border-radius: 8px;

    .taxes-desc {
      color: rgba($white, 0.8);
    }
  }

  .plan-select-button-title {
    color: $gray-1;
    font-weight: 500;
    &.selected {
      color: $purple-1;
    }
  }
}

.mobile-plan-container {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 160px;
  user-select: none;

  .close-button {
    background-color: white;
    padding: 4px;
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 20;
    height: 24px;
    width: 24px;
  }
}

.mobile-plan-contact-item {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: solid 1px $white-9;
  @extend .d-flex-c-c, .flex-column;
}

.trial-expired-banner {
  @extend .d-flex-c-c;
  background-color: $red-1;
  width: 100%;
  padding: 16px;
}

.plan-expired-badge {
  background-color: $red-1;
  padding: 8px 24px;
  border-radius: 24px;
}

.plan-stat-card {
  border: solid 1px $white-9;
  border-radius: 6px;
  padding: 16px;
  width: 100%;
}
.plan-stats-container {
  .plan-stat-card:not(:last-child) {
    margin-right: 12px;
  }
}

.border-bottom {
  border-bottom: 1px solid $divider-color;
}

.dialog-paper.subscribe-modal-mobile-flow {
  width: 100% !important;
  max-width: 100% !important;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0 !important;
  .dialog-content {
    padding: 24px 16px;
  }
  .dialog-close-icon {
    top: 15px !important;
    right: 4px !important;
    &.subscription-plan-top-banner {
      top: 52px !important;
    }
  }
}
.dialog-paper.renew-plan {
  .dialog-close-icon {
    top: 18px !important;
    right: 12px !important;
    &.subscription-plan-top-banner {
      top: 52px !important;
    }
  }
}
.dialog-paper.plan-purchase {
  .dialog-close-icon {
    top: 18px !important;
    right: 12px !important;
    &.subscription-plan-top-banner {
      top: 52px !important;
    }
  }
}

.mobile-flow-referral-code {
  input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 0 0 6px;
    border-radius: 0;
  }
  span.append-txt {
    padding-right: 0 !important;
    padding-bottom: 6px;
  }
}

.subscription-cta {
  width: 134px;
}

.intl-mweb-container {
  height: 100vh;
  position: relative;
  overflow: auto;
  padding-bottom: 70px;
  .title-container {
    position: absolute;
    top: 0;
    background-color: $purple-1;
    height: 203px;
  }
  .intl-mweb-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    position: relative;
  }
  .intl-mbl-cs {
    margin-bottom: 80px;
  }
  .intl-button-container {
    border-top: 1px solid $white-9;
    position: fixed;
    bottom: 0;
    padding: 12px 16px;
    background-color: $white;
    width: 100%;
  }
  .close-button-intl {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 99999;
    transform: rotateY(180deg);
  }
}

.break-up-modal {
  border-bottom: 1px dashed $white-2;
  .text-8 {
    width: 70%;
  }
}

.store-balance {
  width: 70%;
}

.mweb-purchase-success {
  width: 100vw;
  height: 100vh;
  background-color: $purple-1;
  .content {
    height: calc(100% - 86px);
  }
  .btn-container {
    position: absolute;
    bottom: 32px;
  }
}

.seller-account-progressbar {
  width: 80px;
}

.plan-limits {
  flex-wrap: wrap;
  > div {
    width: 47.5%;
    &:nth-child(even) {
      margin-right: 0 !important;
    }
  }
}

.plan-downgrade-causes-list {
  .title {
    @extend .section-text-11;
  }
  .cause-card {
    @extend .section-text-5;
    padding: 32px;
    border: 1px solid $white-9;
    border-radius: 4px;
    position: relative;
    .serial-num {
      @extend .d-flex-c-c;
      width: 40px;
      height: 40px;
      border: 1px solid $white-9;
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      top: -20px;
      left: calc(50% - 20px);
    }
  }
  .mweb {
    .title {
      @include font-size-weight(18, 26, 500);
    }
    .cause-card {
      @include font-size-weight(14, 20, 500);
    }
    .mweb-cta {
      @extend .btn-primary-4;
      border-radius: 8px;
      padding: auto 14px;
    }
  }
}

.intl-subscription-page {
  flex-direction: column;
  width: 960px;
  .cards-container {
    @extend .d-flex;
    .plan-card {
      border: 1px solid $white-9;
      border-radius: 6px;
      padding: 24px;
      position: relative;
      width: calc(100% / 3);
      &:nth-child(2) {
        margin-left: 24px;
        margin-right: 24px;
      }
      .btn-container {
        position: absolute;
        bottom: 24px;
        width: calc(100% - 48px);
      }
    }
  }
}

.cards-container {
  &.mobile-view {
    .plan-card {
      border: 1px solid $white-9;
      border-radius: 6px;
      padding: 24px;
      position: relative;
      .btn-container {
        position: absolute;
        bottom: 24px;
        width: calc(100% - 48px);
      }
    }
  }
}

// v2 mobile flow subscription page

.plan-cards-mobile {
  .plan-card {
    border: 1px solid $white-2;
    border-radius: 6px;
    padding: 16px;
    margin-bottom: 24px;
    button {
      @extend .btn-primary-4;
      width: 100%;
    }
    .domestic-plan-features {
      @extend .d-flex;
      flex-direction: column;
      gap: 8px;
      .feature {
        @extend .d-flex;
        gap: 10px;
        .list-disc {
          border-radius: 50%;
          height: 6px;
          width: 6px;
          background-color: $black-3;
          min-width: 6px;
          margin-top: 7px;
        }
      }
    }
    &.gold {
      border-color: $orange-4;
      padding: 0;
    }
  }
  .plan-details {
    .border-gray {
      border-width: 0.5px;
    }
    .down-arrow {
      height: 20px;
      svg {
        margin-top: -2px;
      }

      &.open {
        transform: rotateX(180deg);
      }
    }
  }
}

.plan-section-divider {
  height: 4px;
  width: 100%;
  background-color: $white-9;
}

.mbl-dukaan-enterprise {
  a,
  button {
    display: flex;
    height: 32px;
  }
}

.nudge {
  border-radius: 4px;
  color: $black-3;

  .info-icon {
    @extend .d-flex-c-c;
  }

  .text-content {
    .cta-label {
      @extend .text-medium;
      text-decoration: underline;
      cursor: pointer;
    }

    .close-icon {
      margin-left: 12px;
      align-self: center;
      cursor: pointer;
    }
  }

  &-size {
    &-default {
      padding: 12px;
    }

    &-small {
      padding: 8px 16px;
    }
  }

  &-type {
    &-primary {
      @extend .d-flex-c-c;

      .text-content {
        @extend .text-8;
        margin-left: 8px;
      }
    }

    &-secondary {
      @extend .d-flex-c-start;

      .info-icon {
        padding-right: 9px;
      }

      .text-content {
        @extend .text-8;
        display: flex;
        width: 100%;

        .cta-label {
          margin-left: auto;
        }
      }
    }
  }

  &-variant {
    &-warning {
      background-color: $gray-7;
      border: 1px solid $orange-4;
    }

    &-info {
      background-color: $white-10;
      border: 1px solid $white-2;

      .text-content {
        font-size: 13px !important;
        line-height: 17px;
      }
    }
    &-primary {
      background-color: rgba($purple-1, 0.1);
    }
    &-error {
      background-color: #fde7e9;
      border: 1px solid $red-1;
      color: $red-1;
      .cta-label {
        a {
          color: $red-1;
        }
      }
    }
  }
}

.store-link-stripe {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  @extend .text-center, .py12, .section-text-5;
  background-color: $white-10;
  border-bottom: solid 1px $white-9;
}

// v3 plan info

.plans-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
  grid-template-rows: 1fr;
  column-gap: 24px;

  .plan-card-container {
    border: solid 1px $white-9;
    border-radius: 6px;

    .plan-description {
      min-height: 40px;
    }

    &.popular {
      background-color: $orange-10;
      border: solid 1px $orange-4;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      @media screen and (max-width: 768px) {
        margin-top: 24px;
      }
    }
    .plan-card {
      .plan-name {
        @extend .section-text-11;
        @media screen and (max-width: 768px) {
          @include font-size-weight(20, 28, 500);
        }
      }

      .plan-savings {
        @extend .section-text-20, .c-green-1, .text-medium;
        border-radius: 4px;
        border: 1px solid $green-1;
        background: rgba(23, 179, 27, 0.1);
        padding: 4px 8px;
      }
    }
  }

  .popular-badge {
    margin-top: -32px;
    margin-left: -1px;
    margin-right: -1px;
  }

  .feature-highlights {
    list-style-type: disc;
    margin-left: 16px;
    min-height: 228px;
    li {
      @extend .text-2;
      margin-bottom: 10px;
    }
    li::marker {
      color: $black-3;
    }
  }

  .plugin-spacer {
    min-height: 30px;
  }

  .plugin-container {
    padding: 16px 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .worth-stripe {
      padding: 6px 8px;
      margin-left: -24px;
      margin-right: -24px;
      color: white;
      background-color: $purple-1;

      svg {
        path {
          fill: white;
        }
      }

      &.recommended {
        background-color: $purple-plan;
      }
    }
  }
}

.plan-plugins-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  gap: 5px;
  img {
    @extend .cur-p;
    height: 30px;
    width: 30px;
    border: soild 1px $white-9;
    border-radius: 4px;
  }

  &.large {
    grid-template-columns: repeat(10, 1fr);
    img {
      height: 64px;
      width: 64px;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.see-more {
  @extend .cur-p, .section-text-20, .underline;
  min-width: 60px;
  width: max-content;
}
