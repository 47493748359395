.stepper-step {
  position: relative; 
  display: flex;
  text-align: center;
  padding: 9px;
  padding-left: 0;
}

.stepper-indicator {
  position: relative; 
  display: block;
  z-index: 2;
  width: 32px;
  height: 32px;
  @media (max-width: 767px) {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
}

.stepper-label {
  position: relative; 
  display: block;
  color: #808080;
  z-index: 2;
  .stepper-title.text-completed {
    color: #1a181e;
  }
  .stepper-title.text-pending {
    color: #146eb4;
  }
}

.stepper-info {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 2px solid #d9d9d9;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 1.25rem;
  line-height: 2.5rem;
  text-align: center;
  color: #fff;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    width: 24px;
    height: 24px;
  }
}

.stepper-dot {
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  border-radius: 50%;
  @media (max-width: 767px) {
    width: 10px;
    height: 10px;
  }
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(20, 110, 180, 0.4);;
  }
  70% {
      -webkit-box-shadow:0 0 0 10px rgba(20, 110, 180, 0);;
  }
  100% {
      -webkit-box-shadow:0 0 0 0 rgba(20, 110, 180, 0);;
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(20, 110, 180, 0.4);;
    box-shadow: 0 0 0 0 rgba(20, 110, 180, 0.4);;
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(20, 110, 180, 0);;
      box-shadow: 0 0 0 10px rgba(20, 110, 180, 0);;
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(20, 110, 180, 0);;
      box-shadow: 0 0 0 0 rgba(20, 110, 180, 0);;
  }
}

@media (max-width: 767px) {
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(20, 110, 180, 0.4);;
    }
    70% {
        -webkit-box-shadow:0 0 0 6px rgba(20, 110, 180, 0);;
    }
    100% {
        -webkit-box-shadow:0 0 0 0 rgba(20, 110, 180, 0);;
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(20, 110, 180, 0.4);;
      box-shadow: 0 0 0 0 rgba(20, 110, 180, 0.4);;
    }
    70% {
        -moz-box-shadow: 0 0 0 6px rgba(20, 110, 180, 0);;
        box-shadow: 0 0 0 6px rgba(20, 110, 180, 0);;
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(20, 110, 180, 0);;
        box-shadow: 0 0 0 0 rgba(20, 110, 180, 0);;
    }
  }
}

.stepper {
  position: relative;
  display: table; 
  width: 100%;
  table-layout: fixed; 
  border-collapse: collapse;
  
  .stepper-step:after {
    content: " ";
    position: absolute; 
    left: 50%;
    top: 1.75rem;
    width: 1px;
    height: 0.125rem;
    background-color: #e3e8ec;
    z-index: 1;
    @media (max-width: 767px) {
      top: 22px;
      height: 0.125rem;
    }
  }
  .stepper-completed.stepper-step::after {
    background-color: #146eb4;
  }

  .stepper-step.stepper-completed .stepper-info {
    background-color: #146eb4;
    border: 2px solid #146eb4;
    &.stepper-danger {
      background-color: #ffffff;
      border: 2px solid #e50b20;
    }
  }

  .stepper-step.stepper-pending {
    .stepper-dot {
      background-color: #146eb4;
    }
    .stepper-info {
      border: none;
      animation: pulse 2s infinite;
      width: 14px;
      height: 14px;
      &.stepper-danger {
        background-color: #ffffff;
        border: 2px solid #e50b20;
        width: 32px;
        height: 32px;
        @media (max-width: 767px) {
          width: 24px;
          height: 24px;
        }
      }
    }
    .stepper-indicator {
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
    }
  } 
    
  .stepper-step:last-child:after {
    display: none;
  }
  
  &.stepper-vertical .stepper-step {
    display: flex;
    text-align: left;
  }
  
  &.stepper-vertical .stepper-label {
    padding-left: 10px;
  }
  
  &.stepper-vertical .stepper-indicator,
  &.stepper-vertical .stepper-label {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  
  &.stepper-vertical .stepper-panel {
    margin-left: 3.5rem;
  }
  
  
  &.stepper-vertical .stepper-step:after {
    content: " ";
    position: absolute;
    left: 16px;
    bottom: -12px;
    width: 2px;
    height: auto;

    @media (max-width: 767px) {
      left: 12px;
      bottom: -24px;
      width: 2px;
    }
  }
  
}
