.categories-not-found {
  height: calc(100vh - 266px);
  &.add-footer-height {
    height: calc(100vh - 256px - 102px); //SUBSCRIPTION_EXPIRY_FOOTER_HEIGHT
  }
  @include display-flex;
  flex-direction: column;
  img {
    @include content-size(324, 192);
  }
}

.categories-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
  grid-gap: 24px;
  .category-item {
    position: relative;
    padding: 16px;
    box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.04);
    transition: box-shadow 0.2s;
    cursor: pointer;
    &:hover {
      box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.1);
    }
    .action-menu-icon {
      z-index: 1;
      position: absolute;
      top: 8px;
      right: 6px;
    }
    .category-item-top-container {
      position: relative;
      display: flex;
      flex-direction: row;
      .show-hide-category-switch {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .reorder-button-container {
        display: flex;
        align-items: center;
      }
      .reorder-button {
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: grab;
      }
      .reorder-button svg {
        display: block;
      }
    }
    .category-item-top-container-reorder-mode {
      justify-content: space-between;
    }
    .category-item-container {
      @include display-flex(center, flex-start);
      img {
        @include content-size(80, 80);
        border-radius: 6px;
        margin-right: 16px;
        object-fit: contain;
        border: 1px solid $white-9;
      }
      .cat-name {
        text-transform: capitalize;
        max-width: 220px;
        color: $black-1;
        &:hover {
          color: $black-3;
        }
      }
    }
    .share-product-link {
      cursor: pointer;
      @extend .d-flex-c-c;
      border-top: 1px solid $white-2;
      margin-top: 12px;
      padding-top: 12px;
      .share-link-icon {
        margin: -4px 8px 0 0;
      }
    }
  }
  .category-item-reorder-mode {
    cursor: grab;
  }
}

.category-form-wrap {
  margin-bottom: 32px;
  .category-image-section {
    margin-bottom: 24px;
    padding: 24px;
    .upload-image-with-overlay {
      margin: 0;
    }
  }
  .category-information-section {
    margin-bottom: 32px;
    padding: 24px;
    .form-group {
      margin-bottom: 0;
    }
    .row {
      margin: 0 -12px;
      .col-md-6 {
        padding: 0 12px;
      }
    }
  }
  .MuiAccordion-root.custom-accordion {
    margin: 24px 0px 0px;
  }
}

.category-information-wrapper {
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: stretch;
}

.category-select-wrapper {
  img {
    border: 1px solid $white-9;
    border-radius: 4px;
  }
  .category-details-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.product-row {
  display: flex;
  align-items: center;
  img {
    width: 48px;
    height: 48px;
    border: 1px solid $white-9;
    border-radius: 4px;
    object-fit: contain;
  }
  button {
    height: 32px;
    width: 32px;
  }
}

.category-form-wrap {
  .category-information-wrapper {
    .category-name-wrapper,
    .parent-category-wrapper {
      width: 344px;
    }
    .parent-category-select {
      .custom-select__control {
        height: 50px;
      }
      .custom-select__option--is-selected {
        div:first-child {
          color: $purple-1;
        }
      }
    }
  }
}

.MuiAccordion-root.custom-accordion.new-cat-form {
  .MuiAccordionSummary-content {
    margin-bottom: 20px;
  }
  .MuiAccordionDetails-root {
    padding: 0 24px 24px;
  }
  &.MuiAccordion-rounded {
    border-radius: 4px;
  }
}

.category-filter-menu {
  .MuiMenuItem-root {
    width: 42%;
  }
  .menu-group {
    border-bottom: 1px solid $white-2;
    padding: 16px;
  }
}
