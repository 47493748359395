.referral-page {
  .referral-banner {
    background-color: $purple-3;
    color: $white;
    .main-title {
      @extend .section-text-5;
      font-weight: 600;
    }
  }
  .copy-container {
    display: flex;
    border-bottom: 1px solid $white-8;
    padding-bottom: 16px;
    .copy-content {
      height: 40px;
      border: 1px dashed $orange-4;
      background-color: rgba(250, 183, 59, 0.1);
      border-radius: 4px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 75%;
    }
  }
  .referral-faq-card-layout {
    h4 {
      margin-bottom: 24px;
    }
    .faq-item {
      margin-top: 24px;
      border-bottom: 1px solid $white-2;
      cursor: pointer;
      &:last-child {
        border: none;
        padding: 0;
      }
      .faq-toggle-icon {
        padding: 8px;
        @include content-size(34, 34);
        border-radius: 20px;
        transition: background-color 0.1s ease-in-out;
        &:hover,
        &:focus {
          background-color: $white-8;
        }
      }
    }
  }
}

.premium-plan-table {
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $white-2;
    flex: 1 1;
    div {
      width: 100%;
    }
  }
  .premium-plan-body {
    div {
      width: 100%;
      @extend .text-10;
      color: $black-3;
    }
  }
}

.successful-referrals {
  .successfull-body-table {
    display: flex;
    justify-content: space-between;
    .loaderContainer {
      position: relative;
    }
  }
  .referral-addBankAccount {
    button.btn-primary-4 {
      @extend .btn-outline-primary;
      padding: 8px 16px !important;
      line-height: 20px !important;
      &:not(.disabled):hover {
        background: rgba(20, 110, 180, 0.05);
        color: $purple-1;
      }
    }
    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
    svg {
      margin-right: 8px !important;
    }
  }
  .total-earned-container {
    height: 60px;
  }
}
