.content-wrap {
  height: 100%;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
.store-created-screen {
  height: 100%;
  width: 100%;
  z-index: 100000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  animation: {
    name: fade-out;
    fill-mode: both;
    duration: 0.8s;
    delay: 4s;
  }
}

.suspense-loader {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #f7f7f7;
  &.dashboard {
    margin-left: $sidebar-width;
    width: calc(100vw - #{$sidebar-width});
  }
  @media (max-width: 767px) {
    width: 100vw !important;
    margin-left: 0 !important;
  }
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.h-40 {
  height: 40px;
}

.w-100 {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.w-50 {
  width: 50%;
}
.w-100-i {
  width: 100% !important;
}

.multiwarehouse-container {
  margin: 32px auto;
  width: 1054px;
}

.w-1118 {
  max-width: 1118px;
}

.min-h-100 {
  min-height: 100px;
}

.min-h-250 {
  min-height: 250px;
}

.min-h-284 {
  min-height: 284px;
}

.pt8-i {
  padding-top: 8px !important;
}

.circular-progress-20 {
  height: 20px !important;
  width: 20px !important;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-layout-center {
  position: absolute;
  left: 223px;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordian-divider {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  border: none;
  margin-top: -20px;
  margin-bottom: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  border: none;
}

.divider-dashed {
  width: 100%;
  border-bottom: 1px dashed #d9d9d9;
}

.line-break {
  line-break: anywhere;
}

.vertical-divider {
  width: 1px;
  background-color: $gray-3;
  height: 24px;
  &.black {
    background-color: rgba($black-1, 0.4);
  }
  &.white-2 {
    background-color: $white-2;
  }
}

.bg-almost-white {
  background-color: rgba(255, 255, 255, 0.9);
}

.bg-purple-1 {
  background-color: $purple-1;
}

.bg-white-2 {
  background-color: $white-2;
}

.bg-white-6 {
  background-color: $white-6;
}

.bg-white {
  background-color: $white;
}

.bg-white-i {
  background-color: $white !important;
}

.editor-height {
  min-height: 120px !important;
}
.editor-height-page {
  min-height: 300px !important;
  ul,
  ol {
    display: block;
    list-style-type: unset;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ol {
    list-style-type: decimal;
  }
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.logo {
  display: inline-flex;

  > img {
    @include content-size(150, 44);
  }
}

.card {
  @include box-style;
  &.attribute-card {
    width: 760px;
  }
}

.error-alert {
  @include alert-style($red-1);
}

.success-alert {
  @include alert-style($green-1);
}

.min-width-100 {
  min-width: 100px !important;
  top: 10px !important;
}
.width-400 {
  min-width: 400px;
  max-width: 400px;
}

.snackbar-container {
  @media (min-width: 768px) {
    max-width: calc(100% - 224px) !important;
    width: 100% !important;
  }
}

.snackbar {
  min-width: 100px !important;
  top: -7px !important;
  .snackbar-content {
    padding: 8px 12px 8px 8px;
    border: 1px solid $black-3;
    background-color: #eeeeee;
    min-width: 100px !important;
    justify-content: center;
    color: #fff;
    filter: drop-shadow(0px 4px 12px rgba(26, 24, 30, 0.1));
    box-shadow: 0 0 0;
    border-radius: 6px;
    margin-bottom: 10px;
    flex-wrap: nowrap;

    .snackbar-message {
      @extend .section-text-7;
      width: 100%;
      padding: 0px;
      color: $black-1;
      gap: 12px;
      margin-right: 64px;
    }

    .snackbar-action {
      margin-right: 0;
      padding-left: 0;
      svg {
        path {
          fill: $black-3;
        }
      }
      .snackbar-close-button {
        color: $white;
      }
    }

    &.snackbar-success {
      border: 1px solid $green-1;
      background-color: #e8f8e9 !important;
    }

    &.snackbar-error {
      border: 1px solid $red-1;
      background-color: #fde7e9 !important;
    }
  }
}

.icon-btn {
  .icon {
    color: $gray-2;
  }
}

// snackbar root
.makeStyles-root-24 {
  min-width: 100px !important;
}

.status-text-wrap {
  color: $gray-1;
  font-weight: 500;
  @include font-size(16, 24);
  width: fit-content;

  .status-badge {
    @include circle(10px, $gray-1);
    margin-right: 6px;
    position: relative;
    display: inline-block;
    top: 0px;
  }

  &.pending,
  &.processing {
    border: solid 0.5px rgba($orange-4, 0.6);
    padding: 2px 10px;
    border-radius: 16px;
    color: $orange-4;
    background-color: rgba($orange-4, 0.1);
    .status-badge {
      background-color: $orange-4;
    }
  }

  &.accepted {
    border: solid 0.5px rgba($green-1, 0.6);
    padding: 2px 10px;
    border-radius: 16px;
    color: $green-1;
    background-color: rgba($green-1, 0.1);
    .status-badge {
      background-color: $green-1;
    }
  }

  &.successful {
    border: solid 0.5px rgba($green-1, 0.6);
    padding: 2px 10px;
    border-radius: 16px;
    color: $green-1;
    background-color: rgba($green-1, 0.1);
    .status-badge {
      background-color: $green-1;
    }
  }

  &.rejected,
  &.cancelled,
  &.failed {
    border: solid 0.5px rgba($red-1, 0.6);
    padding: 2px 10px;
    border-radius: 16px;
    color: $red-1;
    background-color: rgba($red-1, 0.1);
    .status-badge {
      background-color: $red-1;
    }
  }

  &.shipped,
  &.all {
    border: solid 0.5px rgba($orange-1, 0.6);
    padding: 2px 10px;
    border-radius: 16px;
    color: $orange-1;
    background-color: rgba($orange-1, 0.1);
    .status-badge {
      background-color: $orange-1;
    }
  }

  &.delivered {
    border: solid 0.5px rgba($purple-1, 0.6);
    padding: 2px 10px;
    border-radius: 16px;
    color: $purple-1;
    background-color: rgba($purple-1, 0.1);
    .status-badge {
      background-color: $purple-1;
    }
  }

  &.served {
    border: solid 0.5px rgba($red-3, 0.6);
    padding: 2px 10px;
    border-radius: 16px;
    color: $red-3;
    background-color: rgba($red-3, 0.1);
    .status-badge {
      background-color: $red-3;
    }
  }
  &.refunded {
    border: solid 0.5px rgba($red-1, 0.6);
    padding: 2px 10px;
    border-radius: 16px;
    color: $red-1;
    background-color: rgba($red-1, 0.1);
    .status-badge {
      background-color: $red-1;
    }
  }
}

.status-dot {
  @include circle(10px, $gray-1);
  min-width: 10px;
  &.success {
    background-color: $green-1;
  }
  &.undelivered,
  &.returned,
  &.error,
  &.high {
    background-color: $red-1;
  }

  &.ready,
  &.pending,
  &.request {
    background-color: $orange-4;
  }

  &.pick,
  &.successful,
  &.accepted,
  &.approved,
  &.low {
    background-color: $green-1;
  }

  &.rejected,
  &.returned,
  &.cancelled,
  &.failed {
    background-color: $red-1;
  }

  &.in,
  &.shipped,
  &.all,
  &.medium {
    background-color: $orange-1;
  }

  &.delivered,
  &.completed {
    background-color: $purple-1;
  }

  &.served {
    background-color: $red-3;
  }
  &.processing {
    background-color: $orange-4;
  }
}
.search-filter {
  width: 256px;
}
.search-bar {
  @include display-flex(flex-start, space-between);
  margin-bottom: 16px;

  &.product-searchbar {
    margin-bottom: 0;
  }
  &.category-searchbar {
    margin-bottom: 0;
  }
  .reorder-btn {
    background-color: transparent;
    cursor: pointer;
    border: 0;
    font-size: 14px;
  }
}

.return-reason-filter {
  height: 300px;
  overflow: scroll;
}

.file-button-upload,
.file-image-upload {
  position: relative;

  input {
    position: absolute;
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

.file-image-upload {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  @include content-size(120, 120);
  min-width: 120px;
  cursor: pointer;
  &.sm {
    @include content-size(94, 94);
    min-width: 94px;
  }
  &.brand-logo {
    @include content-size(94, 94);
    min-width: 94px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:not(.has-image) {
    border: 1px solid $gray-3;
  }

  > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid $white-9;
    object-fit: contain;
  }
}

.file-image-upload-aspect-ratio-same {
  &.sm {
    @include content-size(112, 112);
    min-width: 112px;
  }
}

.file-image-upload-aspect-ratio-different {
  &.sm {
    @include content-size(112, 140);
    min-width: 112px;
  }
}

.toggle-button-group {
  @include display-flex(center, flex-start);
  flex-wrap: wrap;
  grid-gap: 12px;

  .toggle-button {
    word-break: normal;
    @include font-size-weight(14, 20, 500);
    @supports (-webkit-backdrop-filter: blur(1px)) {
      // hack for safari browser not respecting css grid gap
      margin-right: 12px;
      margin-bottom: 12px;
    }
    color: $gray-1;
    border: 1.5px solid $white-8;
    background-color: $white-8;
    padding: 8px 15px;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s;
    @extend .d-flex-c-c;

    &:last-child {
      margin-right: 0;
    }

    &:not(.disabled) {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.7;
    }

    // &:not(.disabled):focus,
    &.active,
    &:not(.disabled):hover {
      background-color: #ffffff;
      color: $black-1;
      border-color: rgba(#1e2640, 0.5);
      box-shadow: 0 2px 6px 0 rgba(26, 24, 30, 0.04);
    }
    &:not(.active):hover {
      .toggle-button-badge {
        background-color: $white-8;
      }
    }
    &.invoices {
      @extend .section-text-7;
    }
  }
}

@media only screen and (max-width: 767px) {
  .toggle-button-group {
    padding: 16px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    .toggle-button {
      width: max-content;
      font-size: 14px;
      line-height: 20px;
      padding: 6px 16px;
      border: 0;
      font-weight: normal;
    }
  }
}

.filter-popover-content {
  display: flex;
  flex-direction: column;

  .filter-popover-body {
    margin: 11px 0px;

    .MuiFormControlLabel-root {
      margin: 0px;
      padding: 5px 16px 5px 8px;

      &:hover {
        background: $white-6;
      }

      .MuiRadio-root {
        padding: 0px;
        margin-right: 2px;
        margin-left: -2px;
      }

      .MuiIconButton-colorPrimary {
        &.Mui-checked {
          color: $purple-1;
        }
      }

      .MuiSvgIcon-root {
        width: 18px;
        height: 18px;
      }
    }
  }

  .filter-popover-footer {
    margin: 0px 16px;
    padding: 12px 0px;
    border-top: 1px solid $white-2;
  }
}

.MuiRadio-root.custom-radio {
  padding: 0 9px !important;

  &.payment-option {
    padding-left: 0 !important;
  }

  &:hover {
    background-color: transparent !important;
  }

  .custom-radio-icon {
    &.disabled {
      fill: $white-6;
      stroke: $gray-8;
    }
  }
}

.filter-list-filter {
  @include font-size(16, 24);

  &--item {
    padding: 5px 16px;
    cursor: pointer;

    &:hover {
      background: $white-6;
    }
  }
}

.order-status-filter-content {
  .filter-popover-body {
    margin: 0;

    .status-filter-list-filter {
      padding: 11px 8px;
      @include font-size(16, 24);

      &--item {
        min-width: 148px;
        border-radius: 4px;
        padding: 5px 8px;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 2px;
        }

        &:hover,
        &.active {
          background: $white-6;

          .status-text-wrap {
            color: $black-1;
          }
        }

        .status-text-wrap {
          @include display-flex(center, flex-start);
          @include font-size-weight(15, 22, 400);

          .status-badge {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.MuiPopover-paper {
  margin-top: 8px;
  border-radius: 6px !important;
  box-shadow: 0 6px 20px 0 rgba(26, 24, 30, 0.16) !important;
}

.header-actions {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;

  .header-action-container {
    margin: 0 auto;
    // max-width: 760px;
    display: flex;
    justify-content: flex-end;

    &.pull-right {
      max-width: unset;
    }

    button {
      margin-left: 12px;
    }
  }
}

.new-order-custom-snackbar {
  padding: 16px;
  margin-right: 32px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: $black-2;
  width: 302px;

  &.restaurant {
    background-color: $red-3;
  }
  @include display-flex(center, space-between);
  .snackbar-text {
    & > div {
      color: $white;
    }
  }
}

.MuiAccordion-root.custom-accordion {
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.04);

  &::before {
    background-color: transparent;
  }

  .MuiAccordionSummary-root {
    padding: 0 24px;
  }

  .MuiAccordionSummary-content {
    margin: 24px 0;
    &.Mui-expanded {
      margin: 24px 0;
    }
  }
}

.strikethrough {
  text-decoration: line-through;
}

.h50 {
  height: 50%;
}

.subscription-plan-text {
  padding-bottom: 4px;
  letter-spacing: 2px;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fill-available {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.or-separator {
  height: 1px;
  margin: 40px 25px;
  background-color: $gray-3;
}
.card-layout {
  margin: 32px;
}

.ml16n {
  margin-left: -16px;
}

.mr10n {
  margin-right: -10px;
}

.w-96 {
  width: 96px;
}

.sidebar-stepper-container {
  @extend .card;
  padding: 24px;
  min-width: 270px;
  width: 270px;
  height: max-content;

  display: grid;
  grid-column: auto;
  gap: 20px;
}

.sidebar-stepper-indicator {
  @include content-size(32, 32);
  @extend .section-text-5;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-4;
  border: solid 1px $gray-4;

  .stepper-icon {
    @include content-size(16, 16);
  }

  &.active {
    background-color: $purple-1;
    border-color: transparent;
    color: white;
  }
  &.completed {
    background-color: $black-1;
    border-color: transparent;

    .completed-step {
      color: $white;
    }
  }
}

.sidebar-stepper-label {
  @extend .section-text-5;
  color: $gray-4;

  &.active {
    color: $purple-1;
  }
  &.completed {
    color: $black-1;
  }
}

.campaign-banner {
  background-color: $purple-3;
  padding: 32px;
  border-radius: 8px;
}

.status-dot {
  @include circle(10px, $gray-1);
  &.success {
    background-color: $green-1;
  }
  &.error {
    background-color: $red-1;
  }
}

@keyframes counter-animate {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  15% {
    opacity: 1;
    transform: scale(1.5);
  }
  30% {
    opacity: 1;
    transform: scale(1);
  }
}

.view-downloads-icon {
  @extend .d-flex-c-c;
  height: 40px;
  width: 40px;
  position: relative;
  .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .counter {
    top: -8px;
    left: 24px;
    &.animated-counter {
      animation: counter-animate 1s ease-in-out forwards;
    }
  }
}

.counter {
  @extend .d-flex-c-c;
  @include content-size(20, 20);
  background-color: $red-1;
  border-radius: 10px;
  color: white;
  position: absolute;
  right: -6px;
  top: -10px;
  z-index: 1;
}

.view-download-dropdown {
  min-width: 500px;
  width: 500px;
  height: 100%;

  &.notification {
    max-width: 420px;
    min-width: 420px;
  }

  @extend .card;
  box-shadow: 0 6px 20px rgba($black-1, 0.161);
  max-height: calc(97vh - var(--header));
  overflow-y: overlay;
  display: flex;
  flex-direction: column;

  .reports-grid {
    overflow-y: overlay;
    @include d-grid(1fr, 1fr, 20px, center, stretch);

    .date-wise-reports-grid {
      @include d-grid(1fr, 1fr, 8px, center, stretch);

      &.notification {
        @include d-grid(1fr, 1fr, 12px, center, stretch);
      }

      .report-div {
        @include d-grid(40px 1fr, 1fr, 4px, center, stretch);
        padding: 2px 0px;
        @extend .cur-p;

        .retry-button {
          opacity: 0;
        }

        &:hover:not(.notification) {
          border-radius: 4px;
          background-color: $white-6;
          .retry-button {
            opacity: 1;
          }
        }

        &.notification {
          @include d-grid(40px 1fr, 1fr, 12px, center, stretch);

          .notification-action {
            opacity: 0;
          }

          &:hover {
            .notification-action {
              opacity: 1;
            }
          }
        }

        .report-icon {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          padding: 8px;
          display: inline;
        }

        .notification-icon {
          align-self: flex-start;
        }

        .notification-unread {
          @include circle(10px, $purple-1);
        }

        .report-title {
          @include d-grid(300px 1fr, 1fr, 0, center, start);

          .report-cancel-icon {
            justify-self: end;
            cursor: pointer;
          }
        }

        .notification-message {
          width: 294px;
        }

        .report {
          @extend .section-text-7, .c-black-3, .word-break;
        }

        .report-progress {
          @include d-grid(196px 1fr, 1fr, 0, center, start);

          .report-progress-percentage {
            color: $black-3;
            @include font-size(12, 16);
            justify-self: end;
          }
        }

        .report-error {
          border: solid 1px rgba($red-1, 0.1);
          border-radius: 4px;
          background-color: rgba($red-1, 0.1);
          color: $red-1;
          padding: 2px 7px;
        }
      }
    }
  }
}

.announcement-grid {
  &.announcement-dropdown-menu {
    max-height: calc(97vh - var(--header));
    overflow-y: scroll;
    max-width: 400px;
    min-width: 400px;
    min-height: 300px;
    @extend .card;
    box-shadow: 0 6px 20px rgba($black-1, 0.161);
    padding: 24px;
  }

  > .announcement-item:not(:last-child) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: solid 1px $white-2;
  }

  .announcement-item {
    img {
      @include content-size(352, 166);
      object-fit: contain;
      border-radius: 6px;
      margin-bottom: 4px;
    }
    .announcement-learn-more {
      @extend .section-text-7, .underline;
      color: $black-3 !important;
    }
  }

  &.dashboard-announcement-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 24px;
    padding: 0;
    margin: 0;
    &.show-3-cards {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .announcement-item {
      @extend .card;
      padding: 20px;
      margin: 0;
      display: flex;
      flex-direction: column;
      border: none;
      .image-container {
        height: auto;
        width: 100%;

        .image {
          height: 100%;
          width: 100%;
          object-fit: fill;
          border-radius: 6px;
        }
      }
      .announcement-description {
        flex: 1 1 auto;
      }
    }
  }
}

#special-filter-menu.vendors-product-filter {
  .MuiMenu-list {
    margin: 10px 0 12px;
  }
}

#special-filter-menu {
  position: relative;

  .filter-actions {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid $white-2;
    background-color: $white;

    display: flex;
  }

  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  .menu-item-content {
    padding: 8px 16px;
  }

  &.dashboard-filter {
    .menu-label {
      width: 100%;
    }
    .checkbox-label-wrap {
      padding: 0;
    }
  }

  .radio-filter-wrap {
    padding: 8px;

    &.orders-sort-filter {
      padding: 3px 8px;
    }

    &.payouts-sort {
      width: 250px;
    }

    .MuiFormControlLabel-root {
      margin: 0;
      width: 100%;
    }

    .MuiRadio-root.custom-radio {
      padding: 0 8px !important;
    }
  }
}

#settings-popover-menu {
  .menu-item-content {
    padding: 8px;
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.store-menu-container {
  padding: 16px 8px;
  min-width: 220px;
  @extend .card;
  box-shadow: 0 6px 20px rgba($black-1, 0.161);
  .back-arrow {
    svg {
      fill: $black-3;
    }
  }
  .store-menu-items {
    display: grid;
    gap: 6px;
    & > .store-menu-items-item {
      @extend .d-flex, .align-center, .cur-p;
      padding: 4px 8px;
      border-radius: 4px;
      img {
        height: 20px;
        width: 20px;
      }

      &:hover {
        background-color: $white-6;
      }
    }
  }
}
.underline-anchor-section {
  .underline-anchor-text {
    &:hover {
      color: $black-3;
      text-decoration: underline;
    }
  }

  .app-store-icons {
    & a:hover {
      opacity: 0.8;
      transition: 0.15s ease-in-out;
    }
  }
}

.multiple-order-ship-header-container {
  svg {
    margin-left: 6px;
    path {
      fill: $purple-1;
    }
  }
}

.dukaan-logo-cap {
  position: absolute;
  left: -16px;
  top: -12px;
}

.share-cards-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  &.only-two {
    grid-template-columns: 1fr 2fr;
  }
}

.warehouse-faq-container {
  .faq-item {
    cursor: pointer;
    padding: 24px 0 12px 0;
    .faq-query .section-text-5 {
      max-width: 80%;
    }
    &:first-child {
      padding: 0 0 12px 0;
    }
    &:last-child {
      padding: 24px 0 0 0;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $white-2;
    }
    .faq-toggle-icon {
      padding: 6px;
      @include content-size(26, 26);
      border-radius: 20px;
      transition: background-color 0.1s ease-in-out;
      &:hover,
      &:focus {
        background-color: $white-8;
      }
    }
    .faq-toggle-icon-mobile {
      min-height: 24px;
      min-width: 24px;
    }

    a {
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 767px) {
  .warehouse-faq-container {
    .section-text-11 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    .faq-item {
      cursor: pointer;
      padding: 20px 0 10px 0;

      .faq-query .section-text-5 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }

      &:first-child {
        padding: 0 0 12px 0;
      }

      &:last-child {
        padding: 21px 0 0 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $white-2;
      }

      .faq-toggle-icon {
        padding: 8px;
        @include content-size(32, 32);
        border-radius: 20px;
        transition: background-color 0.1s ease-in-out;

        &:hover,
        &:focus {
          background-color: $white-8;
        }
      }

      .faq-toggle-icon-mobile {
        min-height: 24px;
        min-width: 24px;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.r-24 {
  right: 24px;
}

.l-24 {
  left: 24px;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: 0.5;
}

.thumbnail-bottom {
  position: 'absolute';
  left: '50%';
  transform: 'translateX(-50%)';
  bottom: '36px';
}

.arrowContainerStyle {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(26, 24, 30, 0.04);
  padding: 10px 10px 4px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
}

.duplicate-order-text-container {
  background: $white-6;
  border-radius: 44px;
}

.duplicate-order-table-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 999;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.warehouse-gst-number-input {
  .form-group {
    margin-bottom: 8px !important;
  }
}

.card.shipping-partners {
  @media screen and (min-width: 768px) {
    .form-group {
      margin-bottom: 24px;
    }
  }
}

.shipping-partner-layout {
  .form-group {
    margin-bottom: 0px;
  }
}
.error-boundary-wrapper {
  background-color: white !important;
}

.inner-error {
  width: calc(100% - 224px);
  margin-left: auto;
  background-color: white !important;
}

.return-refund-mode > .form-group {
  margin-bottom: 0;
}

.return-and-exchange-segment {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 12px;
  background: $white-10;
  border: 1px solid $white-9;
  border-radius: 4px;
  width: 100%;
  .segment-name {
    word-break: break-all;
  }
}

.shipping-partner-container {
  .partner-image {
    width: 64px;
    height: 64px;
    padding: 4px;
    border-radius: 4px;
    object-fit: contain;
  }
}

.connect-shipping-partner-modal {
  overflow: auto;
  width: 516px;
  max-height: calc(100vh - 220px);

  &.show-scrollbar {
    padding-bottom: 0;
  }
}

.add-variants-modal {
  max-width: 760px;
  .form-color-select {
    .color-popper {
      padding: 9px 16px;
      color: $gray-4;
    }
  }
  &.show-scrollbar {
    padding-bottom: 0;
  }
}

.iphone-frame {
  background-color: $white;
  padding: 16px 16px 0px;
  height: 100%;
  overflow: hidden;

  .frame-container {
    height: calc(100% - 44px);
    position: relative;
    text-align: center;
    .frame {
      text-align: center;
      position: relative;
      .phone-frame {
        background: transparent;
        z-index: 1;
        position: relative;
      }
      .charger {
        margin-top: -54px;
        animation-name: slideCharger;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
      }
      @keyframes slideCharger {
        from {
          margin-top: 200%;
        }
        to {
          margin-top: -54px;
        }
      }
      .bg-black {
        background-color: $black;
        position: absolute;
        width: 280px;
        height: 600px;
        top: 2px;
        bottom: 0;
        left: 10px;
        right: 0;
        z-index: 0;
        border-radius: 36px;
      }
    }
  }
}

.gst-layout {
  :nth-child(3),
  :nth-child(4) {
    max-height: 20px;
  }
}

.file-image-upload {
  &.attribute-image {
    height: 96px;
    width: 96px;
    min-width: 96px;
    border: 1px solid $white-2;
  }
  &.update-attribute-image {
    height: 108px;
    width: 108px;
    min-width: 108px;
    border-radius: 4px;
    border: 1px solid $white-2;
  }
}

.uploaded-image {
  &.uploaded-product-image {
    img {
      width: 108px;
      height: 108px;
      border-radius: 4px;
    }
    max-height: 108px;
  }
  &:hover {
    .uploaded-img-overlay {
      &:hover {
        border-radius: 4px;
      }
    }
  }
  &.attribute-upload-image {
    img {
      width: 96px;
      height: 96px;
      border-radius: 8px;
    }
    .uploaded-img-overlay {
      border-radius: 8px;
    }
    &:hover {
      .uploaded-img-overlay {
        border-radius: 8px;
      }
    }
    .breathing {
      border-radius: 8px;
    }
    border-radius: 8px;
    max-height: 96px;
    width: 96px;
  }
}

.product-page-layout {
  padding: 32px;

  .product-form-wrap {
    width: calc(100% - 302px);
  }

  .choose-variant-image-trigger .uploaded-image {
    position: relative;
    display: inline-flex;
    height: 64px;
  }

  .uploaded-image img {
    width: 88px;
    height: 88px;
  }

  .file-image-upload-aspect-ratio-same {
    &.sm {
      @include content-size(88, 88);
      min-width: 112px;
    }
  }
}

.product-images-section {
  margin-bottom: 24px;
  padding: 24px 24px 8px;

  .product-images-wrap {
    overflow: hidden;
    overflow-x: auto;
    display: flex;

    .image-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 15.2px;
      width: 100%;
      margin-top: 16px;
      border: 0;
    }

    .file-image-upload {
      height: 106px;
      width: 106px;
      min-width: 106px;
    }

    .uploaded-image {
      height: 106px;
      width: 106px;
      img {
        border-radius: 8px;
        height: 106px;
        width: 106px;
        object-fit: contain;
      }
      div {
        height: 100%;
        width: 100%;
      }
      .video-icon {
        height: fit-content;
        width: fit-content;
      }
      .video-icon {
        position: absolute;
        bottom: 8px;
        right: 8px;
        display: flex;
      }
      .uploaded-img-overlay {
        z-index: -1;
        transition: 0s;
        background-image: none;
      }
      &:hover {
        .uploaded-img-overlay {
          z-index: 5;
          &:hover {
            transition: 0.3s;
            display: block;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 8px;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .product-theme-aspect-ratio-uploaded-image {
      height: 132px;
      img {
        height: 132px;
        object-fit: contain;
      }
    }
  }
  .product-images-wrap.product-theme-aspect-ratio {
    .file-image-upload {
      height: 132px;
      width: 106px;
      min-width: 106px;
    }
  }

  .show-product-image-scrollbar {
    &::-webkit-scrollbar {
      display: inline !important;
      height: 8px;
    }
  }
}

.uploaded-image {
  position: relative;
  display: inline-flex;
  .uploaded-img-overlay {
    position: absolute;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    border-radius: 8px;
    background-image: linear-gradient(
      to bottom,
      #333333 0%,
      rgba(0, 0, 0, 0) 48%
    );
  }
  .uploaded-img-overlay-selected {
    @include content-size(20, 20);
    @extend .d-flex-c-c;
    background-color: $purple-1;
    border-radius: 10px;
    border: solid 1px white;
    position: absolute;
    top: 4px;
    right: 4px;
    &.multiple-img-select {
      background: rgba(255, 255, 255, 0.6);
      &.img-index {
        background-color: $purple-1;
      }
    }
    span {
      @include font-size(14, 20);
      color: white;
    }
  }

  .overlay-msg {
    z-index: 2;
  }

  img {
    @include content-size(108, 108);
    border-radius: 4px;
    object-fit: contain;
    border: 1px solid $white-9;
  }
  &.sm {
    img {
      @include content-size(64, 64);
    }
  }
  .remove-uploaded-image {
    position: absolute;
    cursor: pointer;
    top: 4px;
    right: 4px;
    z-index: 2;
  }
}
.file-upload {
  width: 96px;
  height: 96px;
  border-radius: 4px;
  border: 1px solid $white-9;
  &.product-file-upload {
    width: 108px;
    height: 108px;
  }
}

.product-rating-dropdown {
  max-width: 344px;
}

.digital-product {
  display: flex;
  justify-content: space-between;

  :nth-child(1),
  :nth-child(2),
  :nth-child(3) {
    display: inline-block;
    max-width: 245px;
  }

  :nth-child(2) .field-label {
    margin-bottom: 27px !important;
  }

  :nth-child(2) .select-file-btn {
    max-height: 50px;
  }
}

.purple-warning-card {
  background: rgba(20, 110, 180, 0.1);
  color: $purple-1;
  border-radius: 4px;
}

.yellow-warning-card {
  background: rgba(250, 183, 59, 0.1);
  color: $black-3;
  border-radius: 4px;
}

.hide-box-shadow {
  box-shadow: none;
}

.max-w-1062 {
  max-width: 1062px;
}
.max-w-760 {
  max-width: 760px;
}
.max-w-370 {
  max-width: 370px;
}
.max-w-270 {
  max-width: 270px;
}
.w-370 {
  width: 370px;
}
.w-270 {
  width: 270px;
}
.w-8{
  width: 8px;
}
.h-8{
  height: 8px;
}
.min-w-unset{
  min-width: unset !important;
}
.line-height-15{
  line-height: 15px;
}