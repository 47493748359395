header.header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: $purple-1;
  transition: background-color 0.15s ease-in-out;
  .sign-in-link {
    color: $white !important;
  }
  &.scrolling-header {
    position: unset;
    top: unset;
    background-color: $purple-1;
  }
  &.box-shadow {
    box-shadow: 0 4px 20px 0 rgba(152, 152, 152, 0.2);
  }
  .header-container-wrap {
    @include display-flex(center, space-between);
    @include font-size(18, 26);
    padding: 20px 0;
    .logo-link {
      > img {
        @include content-size(156, 44);
      }
    }
    .header-actions {
      .sign-in-link {
        display: visible;
      }
      .download-app-btn {
        margin-left: 32px;
      }
    }
    .back-ic {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .download-app-btn {
    @include button-theme($white, $white-1, $purple-1);
    color: $purple-1;
  }
  &.white-header {
    background-color: $white;
    .sign-in-link {
      color: $black-1 !important;
    }
    .download-app-btn {
      @include button-theme($purple-1, $purple-2);
      color: $white;
    }
  }
  &.transparent-header {
    background-color: transparent;
    &.box-shadow {
      background-color: $white;
    }
    .sign-in-link {
      color: $black-1 !important;
    }
    .download-app-btn {
      @include button-theme($purple-1, $purple-2);
      color: $white;
    }
  }
}

.animate-this {
  -webkit-animation: fadeInFromNone 0.5s ease-out;
  -moz-animation: fadeInFromNone 0.5s ease-out;
  -o-animation: fadeInFromNone 0.5s ease-out;
  animation: fadeInFromNone 0.5s ease-out;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.header-dropdown-icon {
  display: grid;
  place-items: center;
}

.header-title-wrap {
  &.fixed {
    width: 100%;
    margin: 0 auto;
    position: unset !important;
    .navbar-header {
      position: absolute;
      left: 32px;
      top: 0;
      bottom: 0;
    }
    .header-actions {
      position: relative;
      margin: 0;
      width: 100%;
    }
  }

  &.fullwidth {
    max-width: 100%;
  }
}

.edit-product-shimmer {
  margin: 0px auto;
}

.back-hover:hover {
  @include font-size-weight(18, 20, 500);
  color: $white-1;
}

.help-menu {
  @extend .cur-p, .d-flex-c-c;
  border-radius: 4px;
  padding: 6px 12px;

  &:hover {
    background-color: $white-6;
  }
}

.more-menu {
  &:hover {
    border-radius: 4px;
    background-color: $white-6;
  }
}

.live-webinar-link {
  @extend .d-flex, .align-center, .cur-p;
  width: 180px;
}

.live-pulse {
  @include content-size(14,14);
  min-width: 14px;
  border: none;
  animation: redPulse 2s infinite;
  background-color: $red-2;
  border-radius: 100%;

  @-webkit-keyframes redPulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 10, 29, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204, 10, 29, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 10, 29, 0);
    }
  }
  @keyframes redPulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 10, 29, 0.4);
      box-shadow: 0 0 0 0 rgba(204, 10, 29, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(204, 10, 29, 0);
      box-shadow: 0 0 0 10px rgba(204, 10, 29, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 10, 29, 0);
      box-shadow: 0 0 0 0 rgba(204, 10, 29, 0);
    }
  }
}
