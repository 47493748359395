.br2 {
  border-radius: 2px;
}

.br4 {
  border-radius: 4px;
}

.br6 {
  border-radius: 6px;
}

.br8 {
  border-radius: 8px;
}
