.wishlist-plugin-container {
  .wishlist-grid{
    display: grid;
    grid-template-columns: 1fr 31% 11% 12% 11%;
    gap: 8px;
    align-items: center;
    img {
      @include content-size(48, 48);
      border-radius: 4px;
      object-fit: contain;
      border: solid 1px $white-9;
    }
    .wishlist-attribute:not(:last-of-type){
      position: relative;
      padding-right: 6px;
      border-right: 1.5px solid $black-1;
      margin-right: 6px;
      &::after{
      content: '';
      border: 3px solid $white;
      position: absolute;
      padding: 7px 4px;
      border-left: none;
      border-right: none;
    }}
  }
  .intl-wishlist-grid{
    grid-template-columns: 1fr 31% 11% 12%;
  }
  .wishlist-table-header{
    @include font-size-weight(14,20,500);
    border-radius: 4px;
    background-color: $white-6;
    padding: 10px 12px;
    color: $black-3;
  }
  .wishlist-product-block{
    padding: 12px 0;
    border-bottom: 1px solid $white-9; 
    display: grid;
    gap: 8px;
    @extend .break-word;
    .color {
      width: 14px;
      height: 9px;
      border-radius: 2px;
      border: 1px solid $white-9;
      display: inline-block;
      padding-bottom: 12px;
      margin: -2.3px 0; 
    }
    &:last-of-type{
      border-bottom: none;
    }
  }
}
