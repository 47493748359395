.layout-wrap,
.box-layout-wrap {
  position: relative;
  height: 100%;
  overflow: auto;
}

.box-layout-wrap {
  background-color: $white-1;
  position: relative;
  .box-layout-panel {
    padding: 134px 0 80px;
  }
  .blue-box {
    height: 328px;
    position: absolute;
    background-color: $purple-1;
    width: 100%;
    z-index: 0;
    left: 0;
    top: 0;
  }
  .box-layout-content {
    position: relative;
    z-index: 1;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(26, 24, 30, 0.06);
    background-color: $white;
  }
  .container {
    padding: 0;
    width: 1144px;
    margin: 0 auto;
  }
}

.multiwarehouse-container-box {
  background-color: $white-10;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  border-radius: 4px;
  z-index: 1;
  cursor: default;

  .icon-btn .icon {
    color: $black-3;
  }
  &.deactivate-warehouse {
    color: $gray-1 !important;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.box-layout-section-simple {
  max-width: 784px;
  padding: 64px 8px;
  margin: 0 auto;
}

.box-layout-section {
  max-width: 784px;
  padding: 64px 24px;
  margin: 0 auto;

  .box-layout-form {
    text-align: left;
    .row {
      margin: 0 -16px;
      .col-md-6,
      .col-md-12 {
        padding: 0 16px;
      }
    }

    .form-group {
      margin-bottom: 32px;
    }
  }

  .form-section-header {
    @include font-size-weight(20, 28, 500);
    margin-bottom: 32px;
    &:not(:first-child) {
      margin-top: 48px;
    }
  }

  .section-header {
    @include font-size-weight(36, 42, 600);
    letter-spacing: -1.44px;
    margin-bottom: 16px;
  }
  .section-info {
    color: $black-3;
    @include font-size(20, 28);
    margin-bottom: 24px;
    a {
      color: $purple-1;
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        color: $purple-2;
      }
    }
  }
  .layout-list {
    margin-top: 40px;
    .layout-list-item {
      background-color: $white-3;
      margin-bottom: 16px;
      border-radius: 4px;
      &:last-child {
        margin-bottom: 0;
      }
      .layout-list-header {
        cursor: pointer;
        padding: 16px 24px;
        position: relative;
        h6 {
          @include font-size-weight(18, 26, 500);
          width: 90%;
        }
        .expand-action {
          position: absolute;
          right: 16px;
          top: 10px;
          width: 38px;
          height: 38px;
          border-radius: 19px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover,
          &:active,
          &:focus {
            background-color: $white-4;
          }
        }
      }
      .layout-list-content {
        padding: 8px 24px 16px;
        @include font-size(16, 24);
        a {
          color: $purple-1;
          cursor: pointer;
          text-decoration: underline;
          &:hover {
            color: $purple-2;
          }
        }
      }
    }
  }
}
