.table-header-wrapper {
  background-color: $white-6;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  border-radius: 4px;
  z-index: 1;
  cursor: default;

  &.pages-table {
    display: grid;
    grid-template-columns: 1fr 200px 160px ;
    gap: 16px;
  }

  &.payouts-table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    align-items: center;
    justify-content: space-between;
    grid-gap: 40px;

    > .right-align {
      justify-self: end;
    }
  }

  &.payments-table {
    display: grid;
    grid-template-columns: 180px 1fr 92px 109px;
    align-items: center;
    justify-content: space-between;
    grid-gap: 24px;
  }

  &.campaigns-table {
    padding: 10px 12px;
    @include d-grid(
      4fr 2fr 2fr 2fr 2fr 3fr 3fr,
      1fr,
      16px,
      flex-start,
      flex-start
    );
  }

  &.credits-table {
    & > :nth-child(1) {
      min-width: 120px;
      width: 120px;
      margin-right: 15px;
    }
    & > :nth-child(2) {
      width: 100%;
      margin-right: 42px;
    }
    & > :nth-child(3) {
      min-width: 120px;
      width: 120px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      min-width: 120px;
      width: 120px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      min-width: 120px;
      width: 120px;
    }
  }

  &.campaign-orders-table {
    & > :nth-child(1) {
      min-width: 120px;
      width: 120px;
      margin-right: 15px;
    }
    & > :nth-child(2) {
      width: 100%;
      margin-right: 42px;
    }
    & > :nth-child(3) {
      min-width: 60px;
      width: 60px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      min-width: 80px;
      width: 80px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      min-width: 90px;
      width: 90px;
    }
  }

  &.script-table {
    justify-content: flex-start;
    & > :first-child {
      // title
      max-width: 288px;
      min-width: 288px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // position
      min-width: 200px;
      max-width: 200px;
      margin-right: 20px;
    }

    & > :nth-child(3) {
      // Status
      width: 100%;
    }
  }

  &.courier-partners-table {
    & > :nth-child(1) {
      width: 20px;
      margin-right: 12px;
    }
    & > :nth-child(2) {
      width: 100%;
      margin-right: 12px;
    }
    & > :nth-child(3) {
      width: 94px;
      margin-right: 66px;
    }
    & > :nth-child(4) {
      min-width: 130px;
      width: 130px;
      margin-right: 32px;
    }
    & > :nth-child(5) {
      min-width: 108px;
      width: 108px;
      margin-right: 20px;
    }
  }

  &.reviews-table {
    & > :first-child {
      min-width: 26px;
      margin-right: 16px;
    }
    & > :nth-child(2) {
      min-width: 126px;
    }
    & > :nth-child(3) {
      width: 100%;
    }
    & > :nth-child(4) {
      min-width: 180px;
    }
    & > :nth-child(5) {
      min-width: 125px;
    }
  }

  &.product-table {
    padding: 10px 12px;
    & > :nth-child(1) {
      width: 100%;
    }
    & > :nth-child(2) {
      min-width: 200px;
    }
    & > :nth-child(3) {
      min-width: 200px;
    }
    & > :nth-child(4) {
      min-width: 206px;
    }
    & > :nth-child(5) {
      min-width: 141px;
      padding-left: 6px;
    }
    &.resturant-type {
      & > :nth-child(3) {
        min-width: 206px;
      }
      & > :nth-child(4) {
        min-width: 217px;
      }
      & > :nth-child(5) {
        min-width: 65px;
      }
    }
    &.basic-cat-restaurant-type {
      & > :nth-child(4) {
        min-width: 65px;
      }
    }
  }

  &.category-table {
    padding: 10px 12px;
    & > :nth-child(1) {
      width: 100%;
    }
    & > :nth-child(2) {
      min-width: 160px;
    }
    & > :nth-child(3) {
      min-width: 206px;
    }
    & > :nth-child(4) {
      min-width: 140px;
    }
    &.restaurant-category-table {
      & > :nth-child(4) {
        min-width: 65px;
      }
    }
    &.is-vendor {
      & > :nth-child(3) {
        min-width: 100px;
      }
    }
  }

  &.reorder-enabled {
    padding-left: 38px;
  }

  &.customers-table-grid {
    display: grid;
    grid-template-columns: 20px 1fr 200px 260px 1fr 100px 100px;
    &.select-single {
      grid-template-columns: 1fr;
      .column-2 {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
      }
    }
  }

  &.customers-table {
    &.sticky-header {
      position: sticky;
      top: 0;
    }
    & > :first-child {
      flex: 1 0 2.5%;
      max-width: 36px;
    }
    & > :nth-child(2) {
      flex: 1 1 0;
      width: 0;
    }
    & > :nth-child(3) {
      width: 260px;
    }
    & > :nth-child(4) {
      width: 0;
      flex: 1 1 0;
    }
    & > :nth-child(5) {
      width: 180px;
    }
    & > :nth-child(6) {
      width: 100px;
    }
  }
  &.customers-table-email {
    &.sticky-header {
      position: sticky;
      top: 0;
    }
    & > :first-child {
      min-width: 20px;
      margin-right: 16px;
    }
    & > :nth-child(2) {
      width: 100%;
    }
    & > :nth-child(3) {
      width: 140px;
      min-width: 140px;
      margin-right: 120px;
      padding-left: 12px;
    }
    & > :nth-child(4) {
      width: 100%;
    }
  }
  &.orders-table {
    & > :first-child {
      // order id / table name
      min-width: 100px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // order timestamp
      // margin-left: -4px;
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      // customer name
      min-width: 100px;
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      //items
      min-width: 55px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      // payment
      min-width: 63px;
      margin-right: 50px;
    }
    & > :nth-child(6) {
      // status
      min-width: 120px;
      margin-right: 20px;
    }
    & > :nth-child(7) {
      // amount
      min-width: 100px;
    }
    & > :nth-child(8) {
      // channel
      min-width: 100px;
    }
    & > :nth-child(9) {
      // source
      min-width: 100px;
    }
    & > :nth-child(10) {
      // source
      min-width: 100px;
    }
  }
  &.non-serviceable-orders-table {
    & > :first-child {
      // order id / table name
      min-width: 168px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // order timestamp
      // margin-left: -4px;
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      // customer name
      min-width: 100px;
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      //items
      min-width: 55px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      // payment
      min-width: 63px;
      margin-right: 50px;
    }
    & > :nth-child(6) {
      // status
      min-width: 120px;
      margin-right: 20px;
    }
    & > :nth-child(7) {
      // amount
      min-width: 100px;
    }
  }
  &.order-return-table {
    justify-content: flex-start;
    padding: 10px 12px;
    & > :first-child {
      // Item
      min-width: 280px;
      max-width: 280px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // Reason
      word-break: break-word;
      width: 100%;
    }
    & > :nth-child(3) {
      // images
      min-width: 75px;
    }
  }

  &.order-replacement-table {
    padding: 12px;

    &.returned-table {
      & > :first-child {
        // Item
        width: 100%;
        max-width: 100%;
        margin-right: 20px;
      }
      & > :nth-child(2) {
        min-width: 120px;
        max-width: 120px;
      }
    }

    & > :first-child {
      // Item
      min-width: 290px;
      max-width: 290px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // Reason
      word-break: break-word;
      width: 100%;
    }
    & > :nth-child(3) {
      // Images
      min-width: 85px;
    }

    &:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  .view-images-btn {
    background-color: transparent;
  }
  &.returns-table {
    justify-content: flex-start;
    & > :first-child {
      // order id / table name
      max-width: 104px;
      min-width: 104px;
      margin-right: 20px;

      @media screen and (min-width: 1440px) {
        min-width: 114px;
        max-width: 114px;
        margin-right: 24px;
      }
    }
    & > :nth-child(2) {
      // Date
      min-width: 86px;
      max-width: 160px;
      margin-right: 20px;

      @media screen and (min-width: 1440px) {
        min-width: 180px;
        max-width: 180px;
        margin-right: 24px;
      }
    }
    & > :nth-child(3) {
      // Return items
      width: 100%;
      max-width: 40px;
      margin-right: 20px;

      @media screen and (min-width: 1440px) {
        max-width: 96px;
        margin-right: 24px;
      }
    }
    & > :nth-child(4) {
      //Refund amount
      width: 100%;
      max-width: 72px;
      margin-right: 20px;

      @media screen and (min-width: 1440px) {
        max-width: 120px;
        margin-right: 24px;
      }
    }
    & > :nth-child(5) {
      // Reason
      width: 100%;
      margin-right: 20px;

      @media screen and (min-width: 1440px) {
        margin-right: 24px;
      }
    }
    & > :nth-child(6) {
      // Status
      width: 100%;
      min-width: 180px;
      max-width: 180px;
    }
  }

  &.duplicate-order-table {
    justify-content: flex-start;
    & > :first-child {
      // order id / table name
      max-width: 114px;
      min-width: 114px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // Duplicate order
      min-width: 140px;
      max-width: 140px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      // Date
      max-width: 163px;
      min-width: 163px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      // Amount
      min-width: 113px;
      max-width: 113px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      // Status
      width: 100%;
    }
  }

  &.abandoned-table {
    & > div {
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 250px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      min-width: 250px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      min-width: 63px;
    }
  }

  &.hyperlocal-pickup-failed-table {
    & > :first-child {
      min-width: 200px;
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      min-width: 300px;
      max-width: 300px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 100px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      min-width: 100px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      min-width: 88px;
    }
  }

  &.successfullReferral-table {
    & > div {
      min-width: 176px;
      margin-right: 24px;
    }
    & > :nth-child(2) {
      min-width: 200px;
      margin-right: 24px;
    }
    & > :last-child {
      min-width: 112px;
    }
  }

  &.whatsappComms-plugin-table {
    & > div {
      min-width: 80px;
    }
    & > th {
      min-width: 80px;
      text-align: left;
      &:last-of-type {
        text-align: right;
      }
    }
    & > :nth-child(2) {
      min-width: 130px;
    }
  }

  &.invoices-table {
    padding: 10px 12px;
    & > div {
      min-width: 120px;
      margin-right: 24px;
    }
    & > :nth-child(2) {
      min-width: 180px;
      margin-right: 24px;
    }
    & > :last-child {
      min-width: 70px;
    }
  }

  &.multiple-ship-table {
    & > :first-child {
      // order id / table name
      min-width: 220px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // items
      // margin-left: -4px;
      min-width: 100px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      // customer name
      min-width: 140px;
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      //product weight
      min-width: 250px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      // Delivery charge
      min-width: 128px;
      // margin-right: 20px;
    }
  }

  &.shipping-partners-table {
    padding: 10px 0;
    & > :first-child {
      width: 100%;
      min-width: 220px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 148px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      min-width: 128px;
      padding-right: 20px;
    }
  }

  &.new-shipping-partners-table {
    padding: 10px 0;
    & > :first-child {
      width: 100%;
      min-width: 220px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 230px;
    }
  }

  &.replacement-shipping-partner-table {
    padding: 10px 0;
    & > :first-child {
      width: 100%;
      min-width: 220px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 150px;
    }
    & > :nth-child(4) {
      min-width: 150px;
    }
  }

  &.product-weight-table {
    // padding: 10px 0;
    padding-right: 26px;
    & > :first-child {
      width: 100%;
      min-width: 220px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      min-width: 190px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 128px;
    }
    & > :nth-child(4) {
      min-width: 116px;
      margin-right: 0;
    }
  }

  &.dukaan-delivery-table {
    & > :first-child {
      // order id / table name
      flex: 1 1;
      max-width: 180px;
      min-width: 180px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // order timestamp
      flex: 3 1;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      // customer name
      flex: 3 1;
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      //items
      flex: 1 1;
      margin-right: 16px;
    }
    & > :nth-child(5) {
      // payment
      flex: 1 1;
      min-width: 150px;
      max-width: 150px;
      margin-right: 18px;
    }
    & > :nth-child(6) {
      // status
      flex: 1 1;
      min-width: 160px;
      max-width: 160px;
    }
  }

  &.vendor-dukaan-delivery-table {
    padding: 10px 12px;
    & > :first-child {
      // Product name
      flex: 3 1;
      margin-right: 20px;
      max-width: 350px;
    }
    & > :nth-child(2) {
      // Vendor
      flex: 2 1;
      margin-right: 20px;
      max-width: 232px;
    }
    & > :nth-child(3) {
      // Warehouse
      flex: 2 1;
      max-width: 232px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      // Shipping partner
      flex: 2 1;
      max-width: 232px;
    }
  }

  .order-analytics-table {
    & > :nth-child(1) {
      width: 47%;
    }

    & > :nth-child(2) {
      width: 17%;
    }

    & > :nth-child(3) {
      width: 17%;
    }

    & > :nth-child(4) {
      width: 17%;
    }
  }

  .store-views-analytics-table {
    & > :nth-child(1) {
      width: 50%;
    }

    & > :nth-child(2) {
      width: 25%;
    }

    & > :nth-child(3) {
      width: 25%;
    }
  }

  .sales-by-region-analytics-table {
    th {
      flex: 1 1 14%;
      &:nth-of-type(2) {
        flex: 1 1 22%;
      }
      &:nth-of-type(3) {
        flex: 1 1 22%;
      }
    }
  }
  .sessions-by-page-analytics-table {
    th {
      flex: 1 1 70%;

      &:nth-of-type(2) {
        flex: 1 1 22%;
        padding-left: 16px;
      }
    }
  }
  .traffic-analytics-table {
    th {
      flex: 1 1 25%;

      &:nth-of-type(1) {
        flex: 1 1 50%;
      }
    }
  }
  .sales-by-product-analytics-table {
    th {
      flex: 1 1 20%;
      &:first-of-type {
        flex: 1 1 40%;
      }
    }
  }
  &.rto-optimiser-table {
    justify-content: start;
    column-gap: 214px;

    & > :nth-child(1) {
      min-width: 74px;
      max-width: 74px;
      word-break: break-word;
    }

    & > :nth-child(2) {
      min-width: 135px;
      max-width: 135px;
      word-break: break-word;
    }

    & > :nth-child(3) {
      min-width: 45px;
      max-width: 45px;
      word-break: break-word;
    }
  }

  &.media-table-header {
    display: grid;
    grid-template-columns: 1fr 160px 204px;
    gap: 16px;
    padding: 10px 12px;
  }
}

.table-header {
  @include font-size-weight(14, 20, 500);
  color: $black-3;

  .not-active {
    visibility: hidden;
  }

  &:hover .not-active {
    opacity: 0.4;
    visibility: visible;
  }
}

.table-data-container {
  & > :not(:last-child) {
    border-bottom: solid 1px $white-9;
  }
  & > :first-child {
    padding-top: 16px;
  }
  .each-category-container {
    padding: 7px 0 !important;
  }
  & .no-customer-image {
    padding-top: 48px !important;
    padding-bottom: 36px;
    border-bottom: none;
  }
  & .no-customers-state-modal {
    padding-top: 4px !important;
    padding-bottom: 12px;
  }

  &.upsell-crosssell-manual-table {
    > :not(:last-child) {
      border-bottom: none;
    }
    > :first-child {
      padding-top: 0px;
    }
  }
}

.new-shipment-modal {
  .table-data-container {
    & > :first-child {
      padding-top: 0;
    }
  }
}
.customers-modal-table {
  & > :nth-last-child(2) {
    border-bottom: none;
  }
}

.table-data-container-email {
  & > div {
    border-bottom: solid 1px $white-9;
  }
  & .no-customer-image {
    padding-top: 48px !important;
    padding-bottom: 36px;
  }
}

.variants-table-container {
  width: fit-content !important;
}

.variants-table-container {
  width: fit-content !important;
}

.table-data-container.partners-table-container {
  max-height: 220px;
  overflow: scroll;
  padding-bottom: 16px;
  & > :last-child {
    padding-bottom: 0px !important;
  }
}

.table-data-row {
  padding-bottom: 8px;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  flex: 1;

  &.table-data-rows {
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  &.no-padding {
    padding-top: 0;
    padding-bottom: 12px;
  }
  &.pages-table:hover{
    background-color: $white-10;
  }
  &.pages-table {
    display: grid;
    grid-template-columns: 1fr 200px 160px ;
    gap: 16px;
    align-items: center;
    img {
      height: 48px;
      width: 48px;
      border-radius: 4px;
      object-fit: contain !important;
    }
    &.blogs {
      grid-template-columns: 48px 1fr 160px 160px ;
    }
    &.media {
      grid-template-columns: 48px 1fr 160px 200px;
      img {
        height: 48px;
        width: 48px;
        border-radius: 4px;
      }
    }
    &:last-of-type {
      padding-bottom: 0;
    }

    .disabled-view {
      opacity: 0.4;
    }
  }

  &.payouts-table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    align-items: center;
    justify-content: space-between;
    grid-gap: 40px; /* Set the gap between grid items */
    padding-top: 16px;
    padding-bottom: 16px;

    &:hover {
      @extend .cur-p;
      background-color: $white-10;
    }

    > .right-align {
      justify-self: end;
    }
    &.row-error {
      background-color: rgba(229, 11, 32, 0.06);
      margin-left: -12px;
      margin-right: -12px;
      padding: 12px 14px;
    }
  }


  &.payments-table {
    display: grid;
    grid-template-columns: 180px 1fr 92px 109px;
    align-items: center;
    justify-content: space-between;
    grid-gap: 24px;
  }

  &.courier-partners-table {
    & > :nth-child(1) {
      // radio and courier partner
      width: 100%;
    }
    & > :nth-child(2) {
      // pickup
      min-width: 94px;
      width: 94px;
      margin-right: 22px;
    }
    & > :nth-child(3) {
      // estimated delivery
      min-width: 108px;
      width: 108px;
      margin-right: 56px;
    }
    & > :nth-child(4) {
      // delivery charge
      min-width: 108px;
      width: 108px;
      margin-right: 32px;
    }
  }

  &.subscription-features-table-row {
    padding-top: 0;
    padding-bottom: 0;
    box-shadow:
      /* top "inner border" */ inset 0 1px 0 0 $white-8;

    & > :first-child {
      width: 100%;
    }

    & > .free-plan {
      @extend .d-flex-c-c;
      min-width: 128px;
      width: 128px;
    }

    & > :not(:first-child):not(.free-plan) {
      @extend .d-flex-c-c;
      min-width: 152px;
      width: 152px;
    }

    & > .active {
      box-shadow:
    /* left "inner border" */ inset 1px 0 0 0 $purple-1,
        /* right "inner border" */ inset -1px 0 0 0 $purple-1,
        /* top "inner border" */ inset 0 1px 0 0 $white-8;
    }
  }

  &.campaigns-table {
    padding: 14px 12px;
    @include d-grid(
      4fr 2fr 2fr 2fr 2fr 3fr 3fr,
      1fr,
      16px,
      flex-start,
      flex-start
    );
    cursor: pointer;
    &:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  &.credits-table {
    margin-left: 12px;
    margin-right: 12px;
    & > :nth-child(1) {
      min-width: 120px;
      width: 120px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 120px;
      width: 120px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      min-width: 120px;
      width: 120px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      min-width: 120px;
      width: 120px;
    }

    .abadoned-desc {
      width: 208px;
      display: inline-block;
    }
  }

  &.campaign-orders-table {
    margin-left: 12px;
    padding-bottom: 16px;

    & > :nth-child(1) {
      min-width: 120px;
      width: 120px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 60px;
      width: 60px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      min-width: 80px;
      width: 80px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      min-width: 90px;
      width: 90px;
    }

    .payment-mode {
      position: relative;
      right: unset;
      width: fit-content;
    }
  }

  &.orders-table {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    & > :first-child {
      // order id / table name
      min-width: 100px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // order timestamp
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      // customer name
      min-width: 100px;
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      //items
      min-width: 55px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      // payment
      min-width: 63px;
      margin-right: 50px;
    }
    & > :nth-child(6) {
      // status
      min-width: 120px;
      margin-right: 20px;
    }
    & > :nth-child(7) {
      // amount
      min-width: 100px;
    }
    & > :nth-child(8) {
      // channel
      min-width: 100px;
    }
    & > :nth-child(9) {
      // source
      min-width: 100px;
    }
    & > :nth-child(10) {
      // source
      min-width: 100px;
    }

    &:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  &.non-serviceable-orders-table {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    & > :first-child {
      // order id / table name
      min-width: 168px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // order timestamp
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      // customer name
      min-width: 100px;
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      //items
      min-width: 55px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      // payment
      min-width: 63px;
      margin-right: 50px;
    }
    & > :nth-child(6) {
      // status
      min-width: 120px;
      margin-right: 20px;
    }
    & > :nth-child(7) {
      // amount
      min-width: 100px;
    }
  }

  &.returns-table {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    & > :first-child {
      // order id / table name
      min-width: 104px;
      max-width: 104px;
      margin-right: 20px;

      @media screen and (min-width: 1440px) {
        min-width: 114px;
        max-width: 114px;
        margin-right: 24px;
      }
    }
    & > :nth-child(2) {
      // date
      min-width: 86px;
      max-width: 160px;
      margin-right: 20px;
      word-break: break-word;

      @media screen and (min-width: 1440px) {
        min-width: 180px;
        max-width: 180px;
        margin-right: 24px;
      }
    }
    & > :nth-child(3) {
      // Return items
      width: 100%;
      max-width: 40px;
      margin-right: 20px;

      @media screen and (min-width: 1440px) {
        max-width: 96px;
        margin-right: 24px;
      }
    }
    & > :nth-child(4) {
      //Refund Amount
      width: 100%;
      max-width: 72px;
      margin-right: 20px;

      @media screen and (min-width: 1440px) {
        max-width: 120px;
        margin-right: 24px;
      }
    }
    & > :nth-child(5) {
      // Reason
      width: 100%;
      word-break: break-word;
      margin-right: 20px;

      @media screen and (min-width: 1440px) {
        margin-right: 24px;
      }
    }
    & > :nth-child(6) {
      // status
      width: 100%;
      min-width: 180px;
      max-width: 180px;
      word-break: break-word;
    }
    &:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  &.duplicate-order-table {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    &.content-loading > :first-child {
      min-width: auto;
      max-width: initial;
      margin-right: auto;
    }
    & > :first-child {
      // order id / table name
      min-width: 114px;
      max-width: 114px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // Duplicate order
      min-width: 140px;
      max-width: 140px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      // Date
      max-width: 163px;
      min-width: 163px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      // Amount
      min-width: 113px;
      max-width: 113px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      // Status
      width: 100%;
    }

    &:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  &.order-return-table {
    padding: 12px;

    .item-container {
      position: relative;

      svg {
        position: absolute;
        left: 235px;
        top: 40%;
      }
    }

    & > :first-child {
      // Item
      min-width: 280px;
      max-width: 280px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // Reason
      word-break: break-word;
      width: 100%;
    }
    & > :nth-child(3) {
      // Images
      min-width: 75px;
    }

    &:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  &.order-replacement-table {
    padding: 0 12px;

    &.returned-table {
      & > :first-child {
        // Item
        width: 100%;
        max-width: 100%;
        margin-right: 20px;
      }
      & > :nth-child(2) {
        min-width: 120px;
        max-width: 120px;
      }
    }

    & > :first-child {
      // Item
      min-width: 290px;
      max-width: 290px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // Reason
      word-break: break-word;
      width: 100%;
    }
    & > :nth-child(3) {
      // Images
      min-width: 85px;
    }
  }

  &.abandoned-table {
    padding: 16px 16px 12px;
    justify-content: space-between;
    & > div {
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 250px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      min-width: 250px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      min-width: 63px;
      margin-right: 20px;
    }
    &:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  &.hyperlocal-pickup-failed-table {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    & > :first-child {
      min-width: 200px;
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      min-width: 300px;
      max-width: 300px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 100px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      min-width: 100px;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      min-width: 88px;
    }

    &:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  &.successfullReferral-table {
    padding: 16px 16px 12px;
    & > div {
      min-width: 176px;
      margin-right: 24px;
      overflow-wrap: break-word;
    }
    & > :nth-child(2) {
      min-width: 200px;
      margin-right: 24px;
      word-break: break-all;
    }
    & > :last-child {
      min-width: 112px;
    }
  }

  &.whatsappComms-plugin-table {
    padding: 16px 16px 12px;
    & > div {
      min-width: 80px;
      border-bottom: $white-9;
    }
    & > td {
      min-width: 80px;
      border-bottom: $white-9;
      text-align: left;
      &:last-of-type {
        text-align: right;
      }
    }
    & > :nth-child(2) {
      min-width: 130px;
      word-break: break-all;
    }
  }

  &.invoices-table {
    justify-content: space-between;
    padding: 8px 12px 8px;
    border-bottom: 0;
    & > div {
      min-width: 120px;
      margin-right: 24px;
      overflow-wrap: break-word;
    }
    & > :nth-child(2) {
      min-width: 180px;
      margin-right: 24px;
      word-break: break-all;
    }
    & > :last-child {
      min-width: 70px;
    }
    &:first-child {
      padding-top: 16px;
    }
    .status-dot {
      @include circle(10px, $gray-1);
      min-width: 10px;

      &.pending {
        background-color: $orange-4;
      }
      &.completed {
        background-color: $green-1;
      }
    }
  }

  &.dukaan-delivery-table {
    padding-left: 16px;
    padding-right: 12px;
    padding-bottom: 16px;
    span {
      white-space: initial;
    }
    & > :first-child {
      // order id / table name
      flex: 1 1;
      max-width: 180px;
      min-width: 180px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // order timestamp
      flex: 3 1;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      // customer name
      flex: 3 1;
      width: 100%;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      //items
      flex: 1 1;
      margin-right: 20px;
    }
    & > :nth-child(5) {
      // payment
      flex: 1 1;
      min-width: 150px;
      max-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(6) {
      // status
      flex: 1 1;
      min-width: 160px;
      max-width: 160px;
    }

    &:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  &.vendor-dukaan-delivery-table {
    padding: 16px 12px 0;
    border-bottom: 0;

    .form-group {
      width: 100%;
      height: 36px;
      margin: 0;
      .custom-select__control {
        min-height: 36px !important;
        height: 36px !important;
      }
      .MuiFormHelperText-root.field-info {
        margin-top: 2px;
      }
    }

    & > :first-child {
      // Product name
      flex: 3 1;
      margin-right: 20px;
      max-width: 350px;
      img {
        width: 64px;
        height: 64px;
        border-radius: 4px;
        margin-right: 12px;
        object-fit: contain;
        border: 1px solid #e6e6e6;
      }
    }
    & > :nth-child(2) {
      // Vendor
      flex: 2 1;
      margin-right: 20px;
      max-width: 232px;
    }
    & > :nth-child(3) {
      // Warehouse
      flex: 2 1;
      max-width: 232px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      // Shipping partner
      flex: 2 1;
      max-width: 232px;
    }
  }

  &.multiple-ship-table {
    padding: 24px 16px;
    .product {
      min-height: 48px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    & > :first-child {
      // order id / table name
      min-width: 220px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // items
      min-width: 100px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      // Products
      min-width: 140px;
      width: 100%;
      margin-right: 20px;
      .product-name {
        min-width: 140px;
        max-width: 80%;
      }
      img {
        border-radius: 4px;
        margin-right: 8px;
      }
    }
    & > :nth-child(4) {
      //product weight
      min-width: 250px;
      margin-right: 20px;
      position: relative;
      input {
        padding-top: 5px;
        padding-bottom: 5px;
        width: 110px;
      }
      &.right-horizontal-line {
        &::before {
          width: 24px;
          height: 1px;
          content: '';
          position: absolute;
          top: 50%;
          right: -12px;
          background: $white-9;
        }
        &::after {
          content: '';
          width: 24px;
          position: absolute;
          right: 12px;
          border: 1px solid $white-9;
          border-left: 0;
          height: calc(100% - 48px);
          top: 24px;
        }
      }
    }
    & > :nth-child(5) {
      // Delivery charge
      min-width: 128px;
    }
  }

  &.reviews-table {
    margin-left: 16px;
    margin-right: 12px;
    & > :first-child {
      min-width: 26px;
      margin-right: 16px;
    }
    & > :nth-child(2) {
      min-width: 126px;
    }
    & > :nth-child(3) {
      width: 100%;
    }
    & > :nth-child(4) {
      min-width: 180px;
    }
    & > :nth-child(5) {
      min-width: 125px;
    }
  }

  &.category-table {
    padding: 7px 12px;

    & > :nth-child(1) {
      width: 100%;
    }
    & > :nth-child(2) {
      min-width: 160px;
    }
    & > :nth-child(3) {
      min-width: 206px;
    }
    & > :nth-child(4) {
      min-width: 140px;
      .MuiTooltip-popper {
        top: 6px !important;
        left: 6px !important;
      }
      .action-icon {
        @include display-flex;
        padding: 6px;
        border-radius: 50%;
        cursor: pointer;
        &.action-menu-icon {
          padding: 8px 14px;
          margin-left: 7px;
        }
        &:hover {
          background-color: $white-8;
        }
      }
    }
    &.restaurant-category-table {
      & > :nth-child(4) {
        min-width: 65px;
      }
    }
    &.is-vendor {
      & > :nth-child(3) {
        min-width: 100px;
      }
    }
  }

  &.product-table {
    padding: 10px 12px;

    & > * {
      padding-right: 20px;
    }
    & > :nth-child(1) {
      width: 100%;
    }
    & > :nth-child(2) {
      min-width: 200px;
      display: flex;
      align-items: center;
      &.product-cost {
        font-weight: 500;
      }
      .original-cost {
        font-weight: 400;
        text-decoration: line-through;
        color: $gray-4;
        margin-left: 6px;
      }
    }
    & > :nth-child(3) {
      min-width: 200px;
      display: flex;
      align-items: center;
      &.product-cost {
        font-weight: 500;
      }
      .original-cost {
        font-weight: 400;
        text-decoration: line-through;
        color: $gray-4;
        margin-left: 6px;
      }
    }
    & > :nth-child(4) {
      min-width: 206px;
      .status-text {
        white-space: nowrap;
      }
      &.resturant-type {
        min-width: 141px;
        .action-icon {
          @include display-flex;
          padding: 6px;
          border-radius: 50%;
          cursor: pointer;
          &.action-menu-icon {
            padding: 8px 14px;
            margin-left: 12px;
          }
          &:hover {
            background-color: $white-8;
          }
        }
      }
    }
    & > :nth-child(5) {
      min-width: 141px;
      .MuiTooltip-popper {
        top: 6px !important;
        left: 6px !important;
      }
      .action-icon {
        @include display-flex;
        padding: 6px;
        border-radius: 50%;
        cursor: pointer;
        &.copy {
          svg {
            path {
              fill: $black-3;
            }
          }
        }
        &.action-menu-icon {
          padding: 8px 14px;
          margin-left: 12px;
        }
        &:hover {
          background-color: $white-8;
        }
      }
    }
    &.resturant-type {
      & > :nth-child(3) {
        min-width: 206px;
      }
      & > :nth-child(4) {
        min-width: 217px;
        .MuiTooltip-popper {
          top: 6px !important;
          left: 6px !important;
        }
        .action-icon {
          @include display-flex;
          padding: 6px;
          border-radius: 50%;
          cursor: pointer;
          &.action-menu-icon {
            padding: 8px 14px;
            margin-left: 12px;
          }
          &:hover {
            background-color: $white-8;
          }
        }
      }
      & > :nth-child(5) {
        min-width: 65px;
      }
    }
    &.basic-cat-restaurant-type {
      & > :nth-child(4) {
        min-width: 65px;
      }
    }
  }
  &.reorder-enabled {
    padding-left: 0;
  }

  &.customers-table-grid {
    display: grid;
    grid-template-columns: 20px 1fr 200px 260px 1fr 100px 100px;
    gap: 16px;
    &.select-single {
      grid-template-columns: 20px 1fr;
      .column-2 {
        display: grid;
        grid-template-columns: calc(32.75% - 35px) 1fr 1fr 1fr;
        grid-template-rows: auto;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
      }
    }
  }

  &.customers-table {
    margin-left: 16px;
    margin-right: 12px;
    & > :first-child {
      flex: 1 0 2.5%;
      max-width: 36px;
    }
    & > :nth-child(2) {
      flex: 1 1 0;
      width: 0;
    }
    & > :nth-child(3) {
      width: 260px;
      span {
        display: block;
        padding-right: 20px;
      }
    }
    & > :nth-child(4) {
      width: 0;
      flex: 1 1 0;
    }
    & > :nth-child(5) {
      width: 180px;
    }
    & > :nth-child(6) {
      width: 100px;
      span {
        padding-right: 7px;
      }
    }
  }

  &.shipping-partners-table {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    & > :first-child {
      width: 100%;
      min-width: 220px;
      margin-right: 20px;
      margin-left: -9px;
    }
    & > :nth-child(2) {
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 148px;
      margin-right: 20px;
    }
    & > :nth-child(4) {
      min-width: 128px;
      padding-right: 20px;
      .product-cost {
        font-weight: 500;
      }
      .original-cost {
        font-weight: 400;
        text-decoration: line-through;
        color: $gray-4;
        margin-left: 4px;
      }
    }
  }

  &.new-shipping-partners-table {
    padding-top: 20px !important;
    padding-bottom: 20px;
    & > :first-child {
      width: 100%;
      min-width: 220px;
      margin-right: 20px;
      margin-left: -9px;
    }
    & > :nth-child(2) {
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 230px;
      .product-cost {
        font-weight: 500;
      }
      .original-cost {
        font-weight: 400;
        text-decoration: line-through;
        color: $gray-4;
        margin-left: 4px;
      }
    }
  }

  &.replacement-shipping-partner-table {
    padding: 20px 0;
    padding-bottom: 20px;

    & > :first-child {
      width: 100%;
      min-width: 220px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      min-width: 150px;
      margin-right: 20px;
    }
    & > :nth-child(3) {
      min-width: 150px;
    }
    & > :nth-child(4) {
      min-width: 150px;
    }
  }

  &.script-table {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    & > :first-child {
      // title
      min-width: 288px;
      max-width: 288px;
      margin-right: 20px;
    }
    & > :nth-child(2) {
      // position
      min-width: 200px;
      max-width: 200px;
      margin-right: 20px;
    }

    & > :nth-child(3) {
      // Status
      width: 100%;
    }

    &:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  &.product-weight-table {
    padding: 20px 16px !important;
    .product {
      min-height: 48px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    & > :first-child {
      width: 100%;
      min-width: 220px;
      margin-right: 20px;
      .product-name {
        min-width: 140px;
        max-width: 80%;
      }
      img {
        border-radius: 4px;
        margin-right: 8px;
        border: 1px solid $white-9;
      }
    }
    & > :nth-child(2) {
      min-width: 190px;
      margin-right: 20px;
      .form-group {
        margin: 0;
      }
      input {
        padding: 7px 12px;
        width: 104px;
        font-size: 14px;
        line-height: 20px;
      }
      &.right-horizontal-line {
        position: relative;
        &::before {
          width: 24px;
          height: 1px;
          content: '';
          position: absolute;
          top: 50%;
          right: -13px;
          background: $white-9;
        }
        &::after {
          content: '';
          width: 24px;
          position: absolute;
          right: 10px;
          border: 1px solid $white-9;
          border-left: 0;
          height: calc(100% - 48px);
          top: 24px;
        }
      }
    }
    & > :nth-child(3) {
      min-width: 128px;
    }
    & > :nth-child(4) {
      min-width: 116px;
      margin-right: 0;
      .product-cost {
        font-weight: 500;
      }
      .original-cost {
        font-weight: 400;
        text-decoration: line-through;
        color: $gray-4;
        margin-left: 4px;
      }
    }
  }

  &.order-analytics-table {
    & > :nth-child(1) {
      width: 47%;
    }
    & > :nth-child(2) {
      width: 17%;
    }
    & > :nth-child(3) {
      width: 17%;
    }
    & > :nth-child(4) {
      width: 17%;
    }
  }

  &.store-views-analytics-table {
    & > :nth-child(1) {
      width: 50%;
    }

    & > :nth-child(2) {
      width: 25%;
    }

    & > :nth-child(3) {
      width: 25%;
    }
  }

  &.sales-by-region-analytics-table {
    td {
      flex: 1 1 14%;
      &:nth-of-type(2) {
        flex: 1 1 22%;
      }
      &:nth-of-type(3) {
        flex: 1 1 22%;
      }
    }
  }

  &.traffic-analytics-table {
    td {
      word-break: break-all;
      flex: 1 1 25%;
      &:nth-of-type(1) {
        flex: 1 1 50%;
      }
    }
  }

  &.sessions-by-page-analytics-table {
    td {
      flex: 1 1 70%;

      &:nth-of-type(2) {
        flex: 1 1 22%;
        padding-left: 16px;
      }

      .product-name {
        @extend .c-purple-1;
        @extend .section-text-7;

        &:hover {
          color: $purple-2;
        }
      }
    }
  }

  &.sales-by-product-analytics-table {
    td {
      .product-name {
        @extend .c-purple-1;
        @extend .section-text-7;
        &:hover {
          color: $purple-2;
        }
      }
      flex: 1 1 20%;
      &:first-of-type {
        flex: 1 1 40%;
      }
    }
  }

  &.rto-optimiser-table {
    column-gap: 214px;
    padding-left: 16px;
    & > :nth-child(1) {
      min-width: 74px;
      max-width: 74px;
      word-break: break-word;
    }

    & > :nth-child(2) {
      min-width: 135px;
      max-width: 135px;
      word-break: break-word;
    }

    & > :nth-child(3) {
      min-width: 45px;
      max-width: 45px;
      word-break: break-word;
    }
  }
}

@media only screen and (max-width: 767px) {
  .table-data-row {
    &.new-shipping-partners-table {
      padding-top: 16px !important;
      padding-bottom: 16px !important;
      &:last-child {
        padding-bottom: 0 !important;
      }
    }
  }
}

.whatsapp-subscription-table {
  position: relative;

  &__header-column {
    flex-basis: 150px;
    &:first-child {
      margin-right: 24px;
    }
  }

  &__row-title {
    width: auto !important;
    flex: 1 1 0;
  }

  &__row-data {
    width: auto !important;
    min-width: auto !important;
    flex-basis: 150px;
  }

  &__selected-column {
    position: absolute;
    width: 150px;
    height: 100%;
    border: 1px solid $purple-1;
    border-radius: 6px;
    bottom: 0;
    right: 0;
  }

  &__plan-discount-badge {
    position: absolute;
    left: calc(50% - 30px);
    top: -10px;
    height: 18px;
    width: 60px;
    background-image: url('../../images/icons/discount-rectangle.svg');

    & > p {
      color: $black-1;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: -0.6px;
    }
  }

  .yearly-button {
    position: relative;
    z-index: 40;
  }
}

.order-return-table-data {
  padding-top: 4px;
  .table-data-container {
    border-bottom: 1px solid $white-2;
    &:last-of-type {
      padding-bottom: 12px;
    }
  }
}

.order-replacement-table-data {
  .reason-container {
    padding-left: 72px;
  }
}

.subscription-features-table {
  border-bottom: solid 1px $white-8;
}

.border-none {
  border: none !important;
  padding-bottom: 0;
}
.more-table-row {
  border: none !important;
  height: 100px;
}

.table-sticky-header {
  position: sticky;
  top: 0;
  z-index: 99999;
  background-color: white;
}

.select-customers-table {
  max-height: 400px;
  overflow-y: scroll;
  .customers-table-grid {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 16px;
    .column-2 {
      display: grid;
      grid-template-columns: 1fr 200px 260px 1fr 100px 100px;
      grid-template-rows: auto;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
    }
  }
  .customers-table-grid:hover{
    background-color: $white-10;
    border-radius: 4px;
  }
}

.staff-accounts-table {
  width: 100%;

  & .table-header-wrapper,
  .table-data-row {
    width: 100%;
    padding: 12px 0px 12px 12px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    & > * {
      flex: 7 7;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > :nth-child(2) {
      max-width: 120px;
    }

    & > :last-child {
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  & .table-data-container {
    & > :first-child {
      padding-top: 12px !important;
    }
    & > :last-child {
      padding-bottom: 0px;
    }
    & .table-data-row {
      & > :first-child {
        display: flex;
        & .ellipsis-start {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  & .table-data-container {
    & > :first-child {
      padding-top: 12px !important;
    }
    & > :last-child {
      padding-bottom: 0px;
    }
    & .table-data-row {
      & > :first-child {
        display: flex;
        & .ellipsis-start {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .table-header-wrapper {
    &.attribute-table-header {
      & > :first-child {
        width: 368px;
        flex: none;
      }
    }
  }
  .table-data-row {
    &.attribute-row {
      & > :first-child {
        width: 368px;
        flex: none;
      }
    }
  }
  .table-data-row {
    &.attribute-row {
      gap: 24px;
      & > :last-child {
        justify-content: space-between;
      }
    }
  }
  .attribute-col {
    width: 140px;
    flex: none;
  }
  .table-header-wrapper {
    &.attribute-table-header {
      gap: 24px;
      padding-right: 12px;
      & > :last-child {
        margin-right: -26px;
      }
    }
  }
}

.shipping-modal-table-container {
  min-height: 160px;
}

.modal-width {
  .MuiPaper-root {
    width: 760px !important;
    max-width: 760px !important ;
  }
}
.border-bottoms {
  border-bottom: 1px solid $black-3;
}
.mobile-length {
  text-align: center;
}
.mobile-lengths {
  padding-left: 21px;
  text-align: center;
}

.abandoned-order-table-padding {
  position: sticky;
  top: 0;
  background-color: $white-1;
  padding-top: 24px;
}

.abandoned-order-table-header {
  top: 24px !important;
}

.product-row-kebab-icon {
  .MuiIconButton-root:hover {
    background-color: transparent;
  }
}

.warehouse-inventory-header {
  width: 540px;
  margin-left: -12px;
  background-color: $white-6;
  padding: 10px 12px;
  border-radius: 4px;
}

.subcategory-row-kebab-icon {
  .MuiIconButton-root:hover {
    background-color: transparent;
  }
  margin-left: 68px;
}

.d-flex-w-g-16 {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.advance-bulk-products-table-wrapper {
  @extend .d-flex;
  margin-right: -24px;
  overflow-x: scroll;
  .first-sticky-table-wrapper {
    min-width: 280px;
    max-width: 280px;
    .table-header-wrapper {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .table-data-container {
      &.scrolled {
        border-right: solid 1px $white-9;
      }
      .table-data-row {
        padding-top: 16px;
        padding-bottom: 12px;
        .prod_bulk_title_1 {
          all: unset;
          margin-left: 12px;
        }
      }
    }
  }
  .second-scrollable-table-wrapper {
    width: max-content;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 24px;
    .table-header-wrapper {
      @extend .gap64;
      width: max-content;
      justify-content: start;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 0px;
      margin-right: 12px;
      .table-header {
        @extend .gap64;
        &.product-name {
          min-width: 160px;
          max-width: 160px;
        }

        &.categories {
          min-width: 160px;
          max-width: 160px;
        }

        // &.subcategories {
        //   min-width: 160px;
        //   max-width: 160px;
        // }

        &.original-price {
          min-width: 100px;
          max-width: 100px;
        }

        &.selling-price {
          min-width: 100px;
          max-width: 100px;
        }

        &.variants {
          min-width: 59px;
          max-width: 59px;
        }

        &.inventory {
          min-width: 65px;
          max-width: 65px;
        }

        &.action {
          min-width: 103px;
          max-width: 103px;
        }
      }
    }

    .table-data-container {
      width: max-content;
      .table-data-row {
        min-height: 104.5px;
        padding-top: 16px;
        padding-bottom: 12px;
        align-items: center;
        @extend .d-flex, .gap64;
        > * {
          @extend .d-flex, .align-center;
          height: 64px;
        }

        .product-name {
          word-break: break-word;
          min-width: 160px;
          max-width: 160px;
          word-break: break-word;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box !important;
          height: 100% !important;
        }

        .categories {
          word-break: break-word;
          min-width: 160px;
          max-width: 160px;
          word-break: break-word;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box !important;
          height: 100% !important;
        }

        // .subcategories {
        //   min-width: 160px;
        //   max-width: 160px;
        //   word-break: break-word;
        //   -webkit-line-clamp: 3;
        //   -webkit-box-orient: vertical;
        //   overflow: hidden;
        //   display: -webkit-box !important;
        //   height: 100% !important;
        // }

        .original-price {
          word-break: break-word;
          min-width: 100px;
          max-width: 100px;
        }

        .selling-price {
          word-break: break-word;
          min-width: 100px;
          max-width: 100px;
        }

        .variants {
          word-break: break-word;
          min-width: 59px;
          max-width: 59px;
        }

        .inventory {
          word-break: break-word;
          min-width: 65px;
          max-width: 65px;
        }

        .action {
          min-width: 103px;
          max-width: 103px;
          .remove-product {
            color: $red-1;
            padding-right: 10px;
            margin-top: 20px;
            padding-top: 4px;
            padding-bottom: 4px;
            margin-bottom: 24px;
            &:hover {
              color: #bf091b;
            }
          }
        }
      }
    }
  }
}
.warehouse-inventory-container {
  max-height: calc(100vh - 340px);
  overflow-y: scroll;
}

// scrollable table classes
.scrollable-table-wrap {
  position: relative;
  max-width: 100%;
  overscroll-behavior: contain;
}

.scrollable-table-header {
  overflow-x: hidden;
  background-color: $white-6;
  border-radius: 4px;
  padding: 10px 0px;

  & > :first-child {
    position: sticky;
    left: 0;
    background-color: $white-6;
    padding-left:8px;
    height: 100%;
  }
}

.scrollable-table-container {
  @include d-grid(1fr, auto, 0px, center, flex-start);
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.scrollable-table-conatiner-row {
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid $white-9;
  color: $black-1;

  .product-title,
  .column-2 {
    color: $black-1;
  }

  & > :first-child {
    position: sticky;
    left: 0;
    // background-color: $white;
    padding-left: 12px;
    height: 100%;
    background-color: white;
  }

  & > :last-child {
    padding-right: 12px;
  }

  &.show-onscroll-border {
    & > :first-child::after {
      content: '';
      position: absolute;
      right: 0;
      top: -16px;
      height: calc(100% + 30px);
      border-right: 1px solid $white-9;
    }
  }

  &:nth-last-child(2):not(
      .scrollable-orders-grid,
      .scrollable-customers-grid,
      .scrollable-inventory-grid,
      .scrollable-products-grid,
      .scrollable-attributes-table
    ) {
    border-bottom: none;
  }
}

.scrollbar-div {
  position: sticky;
  z-index: 2;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  background-color: transparent;
  grid-template-rows: 1px !important;

  &::-webkit-scrollbar {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $white-2;
    height: 8px;
  }

  bottom: 0;

  &.pagination-active {
    bottom: 59px;
  }
}

// grid class to be applied to table header and each table row
.scrollable-orders-grid {
  @include d-grid(
    165px 200px minmax(240px, 1fr) 80px 120px 180px 120px 120px 120px 200px,
    1fr,
    16px,
    center,
    flex-start
  );

  &:last-child {
    border-bottom: none;
  }

  &.scrollable-table-conatiner-row:hover {
    background-color: $white-10 !important;
    border-radius: 4px;
  }
  &.scrollable-table-conatiner-row:hover {
    background-color: $white-10 !important;
    border-radius: 4px;
  }

  &.scrollable-table-conatiner-row:hover > :first-child {
    background-color: $white-10 !important;
  }
}

.scrollable-customers-grid {
  @include d-grid(48px 1fr, auto, 0px, start, flex-start);

  .column-2 {
    @include d-grid(
      minmax(220px, 1fr) 160px 260px 160px 120px 120px 200px,
      1fr,
      16px,
      start,
      flex-start
    );
    &.no-tags {
      @include d-grid(
        minmax(220px, 1fr) 160px 260px 160px 120px 120px,
        1fr,
        16px,
        start,
        flex-start
      );
    }
  }

  &.scrollable-table-header {
    .column-2 {
      padding-right: 12px;
      & > :first-child {
        position: sticky;
        left: 48px;
        background-color: $white-6;
        height: 100%;
        padding-right: 16px;
      }
    }

    .column-1,
    .column-2 {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &.scrollable-table-conatiner-row {
    padding-top: 0;
    padding-bottom: 0;

    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: $white-10;
      border-radius: 4px;
      .column-1 {
        background-color: $white-10;
      }
      .column-2 {
        & > :first-child {
          background-color: $white-10;
        }
      }
    }
    .column-1 {
      height: 100%;
      padding-top: 14px;
      padding-bottom: 14px;
    }
    .column-2 {
      padding-top: 14px;
      padding-bottom: 14px;
      & > :first-child {
        position: sticky;
        left: 48px;
        background-color: $white;
        height: 100%;
      }
      &.show-onscroll-border {
        & > :first-child::after {
          content: '';
          position: absolute;
          right: 0;
          top: -16px;
          height: calc(100% + 30px);
          border-right: 1px solid $white-9;
        }
      }
    }
  }
}

.scrollable-products-grid {
  & > :first-child {
    z-index: 2;
  }
  &:not(.scrollbar-div) {
    border-bottom: 1px solid $white-9;
    &:nth-last-child(2) {
      border-bottom: 1px solid $white-9;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  &.scrollable-table-conatiner-row {
    &:hover:not(.is-read-only) {
      background-color: $white-10;
      border-radius: 4px;

      & > :first-child {
        background-color: $white-10;
      }
    }
  }

  &.basic {
    @include d-grid(
      minmax(280px, 1fr) 160px 180px 180px 160px 150px,
      1fr,
      16px,
      center,
      flex-start
    );
    &.no-tags {
      @include d-grid(
        minmax(280px, 1fr) 160px 180px 180px 150px,
        1fr,
        16px,
        center,
        flex-start
      );
    }
    &.restaurant-type {
      grid-template-columns: minmax(280px, 1fr) 180px 180px 160px 70px;
      &.no-tags {
        grid-template-columns: minmax(280px, 1fr) 180px 180px 70px;
      }
    }
  }

  &.advance {
    @include d-grid(
      minmax(280px, 1fr) 180px 160px 180px 160px 150px,
      1fr,
      16px,
      center,
      flex-start
    );
    &.no-tags {
      @include d-grid(
        minmax(280px, 1fr) 180px 160px 180px 150px,
        1fr,
        16px,
        center,
        flex-start
      );
    }
    &.restaurant-type {
      grid-template-columns: minmax(280px, 1fr) 180px 160px 180px 160px 70px;
    }
  }

  &.reorder-enabled {
    .reorder-button {
      padding: 0;
      border: 0;
      background-color: transparent;
      cursor: grab;
    }
    .reorder-button svg {
      display: block;
    }
  }

  img {
    @include content-size(48, 48);
    border-radius: 4px;
    object-fit: contain;
    border: solid 1px $white-9;
  }

  .product-cost {
    .original-cost {
      font-weight: 400;
      text-decoration: line-through;
      color: $gray-4;
      margin-left: 6px;
    }
  }

  .MuiTooltip-popper {
    top: 6px !important;
    left: 6px !important;
  }

  .action-icon {
    @include display-flex;
    padding: 6px;
    border-radius: 50%;
    gap: 12px;
    cursor: pointer;
    &.copy {
      svg {
        path {
          fill: $black-3;
        }
      }
    }
    &.action-menu-icon {
      padding: 8px 14px;
      // margin-left: 12px;
    }
    &:hover {
      background-color: $white-8;
    }
  }

  .product-row-kebab-icon {
    .MuiIconButton-root:hover {
      background-color: transparent;
    }
  }
}

.scrollable-commission-report-grid {
  @include d-grid(
    200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px,
    1fr,
    16px,
    start,
    flex-start
  );
}
.scrollable-table-container .scrollable-table-conatiner-row:hover{
  background-color: $white-10; 
}
.scrollable-inventory-grid {
  @include d-grid(
    minmax(500px, 1fr) repeat(var(--columns), 120px),
    1fr,
    16px 24px,
    center,
    flex-start
  );

  &.scrollable-table-header {
    & > :last-child {
      padding-right: 12px;
    }
  }

  &.scrollable-table-conatiner-row {
    grid-template-rows: minmax(48px, 1fr);
    .product-image {
      @include content-size(48, 48);
      object-fit: contain;
      border-radius: 4px;
      border: 1px solid $white-9;
    }

    & > :first-child {
      grid-row: 1 / span var(--rows);
      align-self: flex-start;
      align-items: flex-start;
      z-index: 2;
    }

    .inventory-form-row {
      grid-column: 2 / span var(--columns);
      @include d-grid(
        repeat(var(--columns), 120px),
        1fr,
        24px,
        center,
        flex-start
      );
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
.scrollable-table-conatiner-row.scrollable-inventory-grid:hover > :first-child ,
.scrollable-table-conatiner-row.scrollable-bundles-grid:hover > :first-child ,
.scrollable-table-conatiner-row.scrollable-attributes-table:hover > :first-child ,

{
  background-color: $white-10
}
.scrollable-reports-grid {
  @include d-grid(1fr 160px 270px, 1fr, 32px 16px, start, flex-start);
}

.scrollable-transaction-grid {
  @include d-grid(320px 1fr 1fr, 1fr, 16px, center, flex-start);
  & > :last-child {
    padding-right: 12px;
  }
}
.scrollable-bundles-grid {
  @include d-grid(1fr 288px 176px 106px, 1fr, 16px, center, flex-start);

  .action-icon,
  .product-row-kebab-icon {
    cursor: 'pointer';
    &:hover {
      background-color: $white-9;
      border-radius: 100%;
    }
  }

  .product-row-kebab-icon {
    padding: 6px 12px;
  }
}
.scrollable-bundles-grid:hover {
  background-color: $white-10;
  border-radius: 4px;
}

//end of scrollable table classes

.ship-order-modal {
  overflow-y: auto;
  max-height: calc(92vh - 210px);
}

.reports-table-loader {
  padding: 100px 0;
}

.categories-table {
  .table-data-container {
    margin: 0 32px;
    padding-bottom: 12px;

    .each-category-container {
      padding: 12px 12px !important;

      &:first-child {
        padding-top: 20px !important;
      }

      .each-li-subcategory {
        padding-top: 24px;
      }

      .table-data-row {
        padding: 0;
      }
    }
    .each-category-container:hover {
      background-color: $white-10;
      border-radius: 4px;
    }
  }
}

.url-redirection-table {
  border-radius: 4px;
  background-color: $white;

  .table-data-container {
    & > :not(:last-child) {
      border-bottom: solid 1px $white-9;
    }
    & > :first-child {
      max-height: 52px;
      padding: 18px 12px 14px 12px;
    }
  }

  .table-header-wrapper,
  .table-data-row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) 20px;
    column-gap: 12px;
  }

  .table-data-row {
    padding: 14px 12px 14px 12px;
    max-height: 48px;
    border: none;

    .action-menu-icon {
      margin-right: -8px;
    }
    .cell-container {
      @extend .d-flex-c-s;
      @extend .section-text-7;
      @extend .c-purple-1;
      max-height: 20px;

      .cell {
        @extend .cur-p;
        @extend .text-ellipsis;
      }
    }
  }
}

.attributes-table {
  .scrollable-attributes-table {
    @include d-grid(
      minmax(280px, 1fr) minmax(176px, 360px) 172px minmax(280px, 1fr),
      1fr,
      16px,
      center,
      flex-start
    );

    &:last-child {
      border-bottom: none;
    }
    &:hover{
      background-color: $white-10;
      border-radius: 4px;
    }
  }

  .scrollable-table-container {
    .scrollable-attributes-table {
      &:last-child {
        padding-bottom: 4px;
      }
    }
  }
}
