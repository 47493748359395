.menu-sidebar {
  &.MuiPaper-root {
    width: $sidebar-width;
    background-color: $black-5;
    color: $white;
    z-index: 1100;
    border-right: none;
  }
  .menu-list-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .menu-logo {
      padding: 18px 14px;
      margin-bottom: 12px;
      line-height: 0;
      background-color: $black-5;
      border-bottom: solid 1px rgba(#d9d9d9, 0.1);
      transition: background-color 0.2s;

      .parent-logo {
        width: 40px;
        height: 40px;
        background-color: $white;
        border-radius: 4px;
        padding: 1px;

        img {
          width: 16px;
          height: 16px;
          border-radius: 4px;
          object-fit: contain;
        }
      }
      .business-name {
        @extend .section-text-5;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
      .business-link {
        @extend .text-9;
        @extend .cur-p;
        display: inline-block;
        padding-bottom: 2px;
        padding-right: 6px;

        svg {
          path {
            fill: white;
          }
        }

        &:hover {
          opacity: 0.8;
          transition: opacity 0.2s;
        }
      }
      .store-switch {
        padding: 4px;
        border-radius: 4px;
        background-color: rgba($white-1, 0.1);

        svg {
          height: 20px;
          width: 20px;
          path {
            fill: #ccc;
          }
        }
        &:hover {
          @extend .cur-p;
          background-color: rgba($white, 0.05);
        }
      }
    }
    .store-header-item {
      svg {
        path {
          fill: white;
        }
      }
    }
    .menu-list-wrap {
      flex: 1;
      overflow: auto;
      .free-badge {
        margin-left: auto;
        position: absolute;
        right: 16px;
        background-color: $green-1;
        color: $white;
        @include font-size-weight(12, 16, 500);
        text-align: center;
        padding: 1px 6px;
        border-radius: 2px;
      }
      > ul > div:first-of-type {
        padding-top: 10px;
      }
      > ul > li > a {
        color: $gray-3;
        text-decoration: none;
        cursor: pointer;
        @include display-flex(center, flex-start);
        position: relative;
        &.active {
          span.title {
            font-weight: 500;
            position: relative;
            bottom: 1px;
          }
        }
      }
      > ul > li > a {
        @include font-size-weight(15, 22, 500);
        padding: 8px 16px;
        margin: 4px 8px;
        border-radius: 4px;
        .nav-span-content {
          flex: 1;
          display: inline-flex;
          align-items: center;
          .beta-badge {
            @extend .section-text-13;
            margin-left: auto;
            padding: 2px 6px;
            border-radius: 3px;
            background-color: rgba($white, 0.2);
            color: $white;
          }
        }
        .content {
          padding-top: 12px;
          padding-left: 64px;
        }
        svg {
          @include content-size(20, 20);
          margin-right: 12px;
          &.nav-inactive-icon {
            display: block;
          }
          &.nav-active-icon {
            display: none;
          }
        }
        &:hover,
        &:active,
        &.active,
        &:focus {
          text-decoration: none;
          background-color: rgba($white, 0.05);
        }
        &.active {
          color: $white;
          background-color: rgba($white, 0.1);
          svg {
            &.nav-inactive-icon {
              display: none;
            }
            &.nav-active-icon {
              display: block;
            }
          }
        }
      }
      > ul > li:first-child {
        > a {
          margin-top: 0px;
        }
      }
      &::-webkit-scrollbar {
        display: inline !important;
        width: 8px;
        background-color: transparent !important;
      }
      &::-webkit-scrollbar-track {
        background: transparent !important;
      }
    }
  }
  .menu-list-sub-item {
    margin: 16px 0px;
    .available-credits-text {
      opacity: 0.8;
    }
    .upgrade-plan {
      display: block;
      cursor: pointer;
      border-radius: 4px;
      margin: 12px 16px;
      padding: 6px 18px 6px 12px;
      color: $white-1;
      .text-8 {
        opacity: 0.8;
      }
      &:hover {
        background-color: rgba($white, 0.05);
      }

      svg {
        opacity: 0.8;
        margin-left: 4px;
      }
    }
    .dukaan-credits {
      display: block;
      cursor: pointer;
      background-color: rgba($white-1, 0.1);
      border-radius: 4px;
      margin: 0px 16px;
      padding: 6px 18px 6px 12px;
      color: $white-1;
      .text-8 {
        opacity: 0.8;
      }
      &:hover {
        background-color: rgba($white, 0.05);
      }
    }
  }
}
.menu-sidebar-wrap {
  @media (min-width: 600px) {
    width: $sidebar-width;
    flex-shrink: 0;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: #ffffff;
  }
}

.menu-badge {
  margin-left: auto;
  position: absolute;
  right: 10px;
  background-color: $orange-1;
  color: $white;
  @include font-size-weight(13, 16, 500);
  text-align: center;
  padding: 2px 8px;
  border-radius: 4px;

  &.transparent {
    right: 16px;
    color: unset;
    background-color: rgba(#fff, 0.1);
  }
  &.toggle-button-badge {
    margin-right: -8px;
    margin-left: 4px;
    position: static;
    color: unset;
    background-color: rgba(#fff, 0.5);
    &.active {
      background-color: #1e2640;
      color: white;
    }
  }
}
