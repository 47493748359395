.attribute-list-modal-options {
  @extend .d-flex, .flex-column, .show-scrollbar;
  gap: 8px;
  max-height: calc(100vh - 352px);
  padding: 12px 24px;
  margin: 0 -24px;
  overflow-y: overlay;
}

.attribute-loader-contents {
  @extend .d-flex-c-c;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  gap: 12px;
  width: 100%;
  height: 100%;
}

.attribute-loader-text {
  @extend .section-text-13, .text-ellipsis;
  padding-left: 13px;
  padding-right: 7px;
  width: 100%;
  text-align: center;
}

.img-upload-overlay-br {
  border-radius: 4px !important;
}

.cross-icon-abs {
  position: absolute;
  background-color: $white;
  z-index: 1;
}
