.campaign-design-container {
  @include d-grid(repeat(auto-fill, minmax(222px, 1fr)), auto, 24px);

  .campaign-design-card {
    cursor: pointer;

    .campaign-design-image-card {
      width: 100%;
      height: 250px;
      overflow: hidden;
      @include display-flex(start);
      border-radius: 6px;
      border: 1px solid $white-9;
      background-color: $white;

      &.fallback {
        align-items: center;
        background-color: $white-6;
      }

      .campaign-design-image {
        width: 100%;
        height: auto;
      }

      .campaign-design-image-fallback {
        height: 60px;
        width: 60px;
      }
    }

    .campaign-design-name {
      @extend .section-text-7, .text-center;
      padding-top: 12px;
    }
  }
}

.campaign-design-modal {
  .campaign-design-modal-header {
    position: relative;
    @include display-flex();

    .header-text {
      position: absolute;
      left: 0px;
    }
  }
}

.email-design-container {
  &:not(.preview-send) {
    margin: 0 -24px;
    padding: 24px;
    overflow-y: overlay;
    max-height: calc(92vh - 274px);
  }

  .email-design-image-container {
    border-radius: 4px;
    background-color: $white-9;
    @include display-flex();

    .email-mobile-preview {
      height: 100vh;
      width: 360px;
    }

    .email-web-preview {
      height: 100vh;
      width: 600px;
    }
  }
}

.device-icons {
  display: flex;

  .device-icon {
    padding: 3px;
    border-radius: 4px;
    border: 1px solid $white-2;
    background-color: transparent;
    display: flex;
    cursor: pointer;

    &.is-active {
      border-color: $purple-1;
      background-color: rgba($color: $purple-1, $alpha: 0.1);
      cursor: default;

      svg {
        path {
          stroke: $purple-1;
        }
      }
    }
  }
}

.email-marketing-details {
  border-radius: 4px;
  border: 1px solid $white-9;
  background-color: $white-10;
  padding: 16px;
}
