@import '../mixins';
@import '../variables';

.not-available-page {
  height: 100%;
  flex-direction: column;
  padding: 16px;
  .all-set-text {
    margin-bottom: 8px
  }
  .get-started-text {
    color: $black-3;
    margin-bottom: 32px;
  }
  .download-button {
    padding: 8px 16px;
  }
}
