// majorly used for overriding css

.media-search {
  input {
    margin: auto;
  }
  .search-img {
    top: 10px;
  }
  .remove-search {
    top: 10px;
  }
}

.media-img {
  img {
    border: solid 1px $white-9;
    border-radius: 4px;
    object-fit: contain;
  }
}

.icon-gray1 {
  svg {
    path {
      fill: $gray-1;
    }
  }
}

.uploaded-media-card {
  .csv-replace {
    padding: 0;
    margin: 0 !important;
  }
}

.media-img-size {
  .store-image-wrap {
    img {
      height: 120px;
      width: 120px;
      object-fit: contain;
      border-radius: 4px;
    }
  }
}

.media-card-80 {
  min-height: 80vh;
}

.media-table {
  & > :first-child {
    padding-top: 16px !important;
  }
  & > :last-child {
    padding-bottom: 12px !important;
  }
  .table-data-row {
    padding: 14px 12px;
  }
}
