.tutorials-page {
  .card-banner {
    height: 236px;
    margin: 32px;
    background: {
      image: url(../../images/tutorials-bg.svg);
      position: 0 0;
      repeat: no-repeat;
      size: cover;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .search-container {
      position: relative;
      min-width: 564px;

      .search-suggestions {
        animation: {
          name: fade-in;
          fill-mode: both;
          duration: 0.5s;
        }
        position: absolute;
        left: 0;
        right: 0;
        z-index: 99;
        border-radius: 4px;
        max-height: 364px;
        overflow-y: auto;

        .suggestion {
          flex: 1;
          border-radius: 4px;
          img {
            max-width: 120px;
            border-radius: 4px;
          }
          p {
            flex: 1;
            align-self: center;
          }
          &:hover {
            background-color: $white-6;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .card-banner .search-container {
      min-width: calc(100vw - 50vw);
    }
  }
}
.tutorials-wrapper {
  margin: 0 32px;
  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.video-wrapper-md {
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 1fr));
  grid-gap: 24px;

  img {
    min-height: 198px;
    min-width: 352px;
  }
}

.video-wrapper-sm {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));
  grid-gap: 24px;

  &.auto-verification {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
    grid-gap: 48px;
    @media (max-width: 767px) {
      grid-template-columns: auto;
      grid-gap: 32px;
    }
    .card-video {
      overflow: visible;
      &:first-child {
        &::after {
          content: '';
          border-right: 1px solid #e6e6e6;
          height: 100%;
          position: absolute;
          right: -25px;
          top: 0;
          @media (max-width: 767px) {
            width: 100%;
            border-right: 0;
            border-bottom: 1px solid #e6e6e6;
            bottom: -16px;
            right: 0;
            top: auto;
          }
        }
      }
    }
    img {
      min-height: 189px;
      min-width: 336px;
      @media (max-width: 767px) {
        min-height: auto;
        min-width: auto;
      }
    }
  }
  .play-button-wrapper svg {
    width: 48px;
    height: 48px;
  }
  img {
    min-height: 146px;
    min-width: 258px;
  }
}

.filter-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.card-video {
  border-radius: 4px;
  overflow: hidden;

  .video-tumbnail {
    position: relative;
    line-height: 0;

    img {
      width: 100%;
    }
    .play-button-wrapper {
      position: absolute;
      background: rgba(black, 0.2);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &:hover .play-button-wrapper {
    background: rgba(black, 0.3);
  }
}

$animationDelay: 0.5;
@for $i from 1 through 18 {
  .search-suggestion .suggestion:nth-of-type(#{$i}),
  .tutorials-wrapper .card-video:nth-of-type(#{$i}) {
    animation-delay: #{0.3+ ($i)/5}s;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.youtube-player {
  width: 100vw;
  height: 100vh;
  .MuiPaper-root {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .modal-close-icon {
    @include content-size(32, 32);
    position: fixed;
    cursor: pointer;
    top: 32px;
    right: 32px;
    @media (max-width: 767px) {
      top: 16px;
      right: 16px;
    }
  }
}

.youtube-player-item {
  padding: 124px;
  @media (max-width: 767px) {
    padding: 0;
    width: 100% !important;
    height: 240px !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
