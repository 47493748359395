.international-payments-page {
  margin: 32px auto;
  max-width: 760px;

  .payment-item {
    cursor: default;

    &:last-child {
      margin-bottom: 0;
    }

    .payment-item-image {
      height: 88px;
      width: 88px;
    }
    .activated-btn {
      @include font-size-weight(14, 20, 500);
      @include display-flex;
      padding: 8px 16px;
      border-radius: 4px;
      border: solid 1px $gray-3;
    }
  }
}

.amount-saved-stripe {
  background-color: $white-6;
  border-radius: 4px;
  padding: 8px;
  @extend .d-flex-c-c;
}

.append-text-input {
  background-color: white;
}

.inai {
  .payment-partner-item {
    display: flex;
    align-items: center;
    margin: 16px 0;
    &:last-child {
      margin-bottom: 0px;
    }
    .payment-partner-image {
      height: 64px;
      width: 64px;
      object-fit: contain;
      border: 1px solid $white-9;
      border-radius: 4px;
      margin-right: 16px;
    }
    .payment-item-child-wrapper {
      display: flex;
      align-items: center;
      max-width: 550px;
      flex-wrap: wrap;
      .payment-item-child {
        display: flex;
        margin-right: 8px;
        padding: 0 8px;
        border: 1px solid $white-9;
        border-radius: 2px;
        .payment-item-child-img {
          height: 24px;
          width: 38px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .payment-partner-item-activated {
    &:first-child {
      margin-top: 0px;
    }
  }
  .bounce-loader-wrapper {
    margin: 100px;
  }
}

.payment-method-child-wrapper {
  .payment-method-child-inner-wrapper {
    display: grid;
    grid-gap: 16px;
    margin-bottom: 32px;
    .payment-method-child {
      display: flex;
      align-items: center;
      .payment-method-img {
        background-color: $white-6;
        padding: 4px;
        border-radius: 4px;
        margin-right: 16px;
      }
    }
  }
}

.refund-payment-strip {
  border: 1px solid $white-2;
  padding: 12px 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.dukaan-pay-container {
  position: relative;
}

.dukaan-pay-features {
  background: rgba(20, 110, 180, 0.04);
  padding: 16px;
  border-radius: 4px;
  border: 1px solid rgba(20, 110, 180, 0.30);
}

.dukaan-pay-trusted-by {
  position: absolute;
  top: -12px;
  transform: translateX(100%);
}

.dukaan-pay-logo {
  height: 38px;
}

.how-it-works-modal {
  position: relative;
  .tnc-link {
    position: absolute;
    bottom: -20px;
    right: -16px;
  }
}

.payouts-search {
  padding-left: 4px;
  @extend .text-8;
  width: 250px;
}