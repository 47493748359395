.ee_container {
  color: #ffffff;

  & > button {
    @extend .btn-no-default;
    color: #ffffff;
  }

  & > textarea {
    border: none;
    background-color: transparent;
    color: #ffffff;
    text-align: center;
    resize: none;
    border: solid 1px $white;
    width: 100%;
    height: 60px;
  }

  & > .h4 {
    padding: 0 32px;
    @include font-size-weight(24, 31.92, 600);
  }

  & > .h6 {
    @include font-size-weight(12, 15.96, normal);
  }

  & > .h5 {
    @include font-size-weight(14, 20.02, 500);
    overflow-wrap: break-word;
  }

  & > .p {
    @include font-size-weight(14, 20.02, normal);
  }
}
