.custom-stepper {
  &-step {
    @include display-flex(flex-start, flex-start);
    height: 100%;
    &-status-indicator {
      @include circle(40px);
      @include display-flex;
      @include font-size-weight(20, 20, 500);
      border: 2px solid transparent;
      padding: 8px;
      user-select: none;
      min-height: 40px;

      &.active {
        color: $purple-1;
        background-color: rgba($purple-1, 0.1);
        border-color: $purple-1;
      }
      &.disabled {
        color: $gray-4;
        background-color: $white;
        border-color: $gray-3;
      }
      &.checked {
        background-color: $purple-1;
        border-color: $purple-1;
      }
    }
    &-connector {
      width: 2px;
      height: 100%;
      &.active {
        background-color: $purple-1;
      }
      &.disabled {
        background-color: $gray-3;
      }
      &.hidden {
        background-color: transparent;
      }
    }
    &-indicator {
      @include display-flex(center, space-between);
      align-self: stretch;
      flex-direction: column;
    }
    &-details {
      @include display-flex(flex-start, center);
      flex-direction: column;
      min-height: 40px;
      margin-left: 16px;
      margin-bottom: 28px;
    }
    &:last-child &-details {
      margin-bottom: 0;
    }
  }
  .stepper-gap {
    margin-bottom: 15px;
  }
  .attribute-stepper {
    min-height: 0 !important;
  }
}

.stepper-indicator-md {
  height: 32px !important;
  width: 32px !important;
  min-height: 32px !important;
  padding: 6px;
}
