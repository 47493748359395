@font-face {
  font-family: GalanoGrotesque;
  font-weight: 100;
  font-display: swap;
  src: url('../fonts/GalanoGrotesque/galanogrotesque-extralight.woff2')
      format('woff2'),
    url('../fonts/GalanoGrotesque/galanogrotesque-extralight.woff')
      format('woff');
}
@font-face {
  font-family: GalanoGrotesque;
  font-weight: 200;
  font-display: swap;
  src: url('../fonts/GalanoGrotesque/galanogrotesque-light.woff2')
      format('woff2'),
    url('../fonts/GalanoGrotesque/galanogrotesque-light.woff') format('woff');
}
@font-face {
  font-family: GalanoGrotesque;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/GalanoGrotesque/galanogrotesque-thin.woff2')
      format('woff2'),
    url('../fonts/GalanoGrotesque/galanogrotesque-thin.woff') format('woff');
}
@font-face {
  font-family: GalanoGrotesque;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/GalanoGrotesque/galanogrotesque-regular.woff2')
      format('woff2'),
    url('../fonts/GalanoGrotesque/galanogrotesque-regular.woff') format('woff');
}
@font-face {
  font-family: GalanoGrotesque;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/GalanoGrotesque/galanogrotesque-medium.woff2')
      format('woff2'),
    url('../fonts/GalanoGrotesque/galanogrotesque-medium.woff') format('woff');
}
@font-face {
  font-family: GalanoGrotesque;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/GalanoGrotesque/galanogrotesque-semibold.woff2')
      format('woff2'),
    url('../fonts/GalanoGrotesque/galanogrotesque-semibold.woff') format('woff');
}
@font-face {
  font-family: GalanoGrotesque;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/GalanoGrotesque/galanogrotesque-bold.woff2')
      format('woff2'),
    url('../fonts/GalanoGrotesque/galanogrotesque-bold.woff2') format('woff');
}
@font-face {
  font-family: GalanoGrotesque;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/GalanoGrotesque/galanogrotesque-extrabold.woff2')
      format('woff2'),
    url('../fonts/GalanoGrotesque/galanogrotesque-extrabold.woff')
      format('woff');
}
