.checkbox-label-wrap {
  &.MuiFormControlLabel-root {
    margin: 0;
  }
  .custom-checkbox {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
    &.Mui-checked {
      color: $purple-1;
    }
    &.Mui-focusVisible {
      border-radius: 4px;
      border: 1px solid $purple-1;
    }
  }
  .MuiFormControlLabel-label {
    width: inherit;
  }
  .checkbox-label {
    color: $black-3;
    @include font-size(14, 20);
    position: relative;
    top: 1px;
    display: flex;
    align-items: center;
    padding-left: 8px;
  }
}
