.switch-label.MuiFormControlLabel-root {
  margin: 0;
  .MuiFormControlLabel-label {
    @include font-size(13, 18);
  }
}
.switch {
  &.MuiSwitch-root {
    @include content-size(36, 22);
    margin: 0;
    padding: 0;
    .MuiSwitch-input {
      visibility: hidden;
      @include content-size(0, 0);
      left: 0;
    }
    .MuiSwitch-track {
      border: 1px solid $white-5;
      opacity: 1;
      transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-radius: 12px;
      background-color: $white-5;
    }
    &.MuiSwitch-light-off-theme {
      .MuiSwitch-track {
        border: 1px solid $white-5;
        background-color: $white-5;
      }
    }
    &.always-blue {
      .MuiSwitch-track {
        border-color: $purple-1;
        background-color: $purple-1;
        opacity: 1;
        border: none;
      }
    }
    &.red-green-switch {
      .MuiSwitch-track {
        border: 1px solid $red-1;
        background-color: $red-1;
      }
    }
    &.red-green-switch {
      .MuiSwitch-switchBase {
        padding: 2px;
        &:hover {
          background-color: transparent;
        }
        &.Mui-checked {
          transform: translateX(14px);
          color: #fff;
        }
        &.Mui-checked + .MuiSwitch-track {
          border-color: $green-1;
          background-color: $green-1;
          opacity: 1;
          border: none;
        }
      }
    }
    .MuiSwitch-thumb {
      @include content-size(18, 18);
      box-shadow: none;
    }
    .MuiSwitch-switchBase {
      padding: 2px;
      &:hover {
        background-color: transparent;
      }
      &.Mui-checked {
        transform: translateX(14px);
        color: #fff;
      }
      &.Mui-checked + .MuiSwitch-track {
        border-color: $purple-1;
        background-color: $purple-1;
        opacity: 1;
        border: none;
      }
      &.Mui-disabled {
        &.Mui-checked + .MuiSwitch-track {
          opacity: 0.5;
        }
      }
    }
  }
}
