.menu {
  .menu_list {
    padding: 8px 0px;
    min-width: 160px;
    .menu_list_item {
      padding: 0;
      margin: 0 8px;
      border-radius: 4px;
      &:not(.menu-no-hover):hover {
        background-color: $white-4 !important;
      }
      &.menu-no-hover:hover {
        background-color: transparent !important;
        cursor: default;
      }
      .menu-link,
      .menu-label {
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;
        color: $black-1;
        display: flex;
        align-items: center;
        flex: 1;
        @include font-size(15, 22);
      }
      &.delete-menu-item {
        .menu-link,
        .menu-label {
          color: $red-1;
        }
      }
      &.download-menu-item {
        font-weight: 500;
        .menu-link,
        .menu-label {
          color: $purple-1;
        }
      }
    }
  }
}

.menu-label {
  width: 100%;

  .content-grp {
    max-height: 20px;
  }

  label {
    width: 100%;
  }
}

.menu-content-max-h {
  .menu-label {
    .content-grp {
      max-height: 24px;
    }
  }
}

.menu-item-lh {
  .MuiMenuItem-root {
    line-height: normal;
  }
}
