.crosssell-card {
  .products-list {
    @include d-grid(1fr, auto, 0, flex-start, flex-start);
    & > {
      :not(:last-child) {
        border-bottom: solid 1px $white-9;
      }
    }
  }
}

.each-product-upsell-crosssell {
  @include d-grid(1fr 143px 20px, auto, 12px, center, flex-start);
  padding: 16px 12px 12px 12px;
  .product-information {
    display: flex;
    gap: 16px;
    align-items: center;

    .selected-product-image {
      @include content-size(48, 48);
      border: 1px solid $white-9;
      border-radius: 6px;
      object-fit: contain;
    }

    .selected-product-name {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  input {
    padding: 8px 16px;
  }
}
