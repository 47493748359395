@import '../mixins';
@import '../variables';
@import '../typography';

.staff-verify-layout {
  min-height: 100%;
  padding: 32px;

  text-align: center;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  .page {
    flex: 1 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .body {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      gap: 32px;

      .main {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        gap: 6px;

        .title {
          @extend .section-text-3;
        }
        .subtitle {
          @extend .text-2;

          color: $black-3;

          & > span {
            font-weight: 500;
          }
        }
      }
    }
    .action {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      gap: 32px;

      .description {
        @extend .text-2;
        color: $black-3;

        & > span {
          font-weight: 500;
        }
      }
      .mobile-description {
        @extend .text-2;

        & > span {
          font-weight: 500;
        }
      }
      .btn {
        min-width: 160px;
      }
    }
  }

  .action-timeline {
    margin: 0;
    align-items: flex-start !important;
    gap: 0 !important;
    width: 100%;
    .ellipses {
      width: 20px;
      &:after {
        display: none;
      }
      &::before {
        width: 1px;
        background-color: $purple-1;
      }
    }
    .timeline {
      &:last-of-type {
        .ellipses {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  .paragraph {
    height: 20px;
    width: 20px;
    color: $purple-1;
    border: 1px solid $purple-1;
    border-radius: 100px;
    background: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .horizontal-border {
    width: 80px;
    height: 1px;
    border: 1px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: $white-2;
  }
  .text-font {
    font-weight: 500;
  }
  .staff-warn-badge {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 8px;

    border-radius: 16px;

    @extend .text-10;
    font-weight: 600;
    padding: 8px 12px;

    background-color: hsla(39, 95%, 61%, 0.1);
  }

  &.mobile {
    padding: 16px;

    .page {
      justify-content: space-between;

      .body {
        flex: 1 1;
        gap: 24px;

        .main {
          gap: 8px;
        }
      }
    }
  }
}
