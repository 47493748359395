.info-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 12px;
    line-height: 12px;
    color: white;
    margin-left: 6px;
  }

  .rupee-icon {
    @include content-size(24, 24);
  }
}

.domain-list {
  .domain-list-item {
    @extend .d-flex-c-s, .cur-p;
    border: solid 1px $white-8;
    border-radius: 4px;
    padding: 10px 16px;

    &.selected {
      background-color: #f3f8fb;
      border-color: $purple-1;
      color: $purple-1;
    }

    .MuiFormControlLabel-root {
      margin-right: 0;
    }
  }

  .MuiFormGroup-root {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .MuiRadio-root.custom-radio {
    padding: 0 !important;
  }
}

.email-domain-list {
  .MuiFormGroup-root {
    grid-template-columns: repeat(1, 1fr);
  }
}

.premium-domain-list {
  .MuiFormGroup-root {
    display: flex;
    flex-direction: column;
  }
}

.custom-domain-loader {
  height: 200px;
  position: relative;
}

.www-logo {
  height: 74px;
  width: 160px;
}

.www-logo-large {
  height: 192px;
  width: 178px;
  margin-left: 76px;
}

.custom-domain-banner {
  background-color: $purple-3;
  padding: 24px;
  border-radius: 4px;

  &.credits-banner {
    padding: 16px;
  }

  .vertical-divider {
    height: 24px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.custom-domain-header {
  background: url(../../images/custom-domain-background.png) 100%;
  border-radius: 8px;
  padding: 24px 24px 20px;
  box-shadow: 0 2px 6px rgba($black-1, 0.04);

  &__features {
    border-left: 1px solid rgba($purple-1, 0.2);
    border-right: 1px solid rgba($purple-1, 0.2);
  }
}

.custom-domain-show-more-button {
  svg {
    height: 18px;
  }
  g {
    // To override the inline style
    fill: $purple-1 !important;
  }
}

.store-link {
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.domain-search-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  .form-group {
    margin-bottom: 0;
    min-width: 523px;
    margin-right: 12px;

    input {
      padding: 7px 16px;
    }
  }
  .search-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background-color: $purple-1;
    border-radius: 4px;
  }
}

.purchase-premium-domain {
  background: url(../../images/premium-domain-background.png) 100%;
  border-radius: 4px;
}

.free-domain-badge {
  background-color: rgba($green-1, 0.1);
  border-radius: 3px;
  padding: 1px 8px;
}

.custom-status-domain-progress {
  &__root {
    min-width: 80px;
    height: 8px !important;
  }

  &__progress {
    background-color: $purple-1 !important;
  }

  &__background {
    background-color: $white-9 !important;
  }

  & .light-blue-background {
    background-color: #146eb433 !important;
  }
}

.domain-setup-details {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 12px;

  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);

  .setup-field {
    padding: 8px;
    background-color: $white-1;
    border-radius: 4px;
  }
}

.custom-domain-opt {
  .info-card {
    @extend .text-10;
    padding: 12px;
    border-radius: 6px;
    background-color: rgba($gray-7, 0.1);
    color: $black-3;
    span {
      font-weight: 500;
    }
    ul {
      list-style: disc;
      margin-left: 12px;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.rotate-infinte {
  animation: rotating 2s linear infinite;
}
