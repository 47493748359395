.pages-container {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
  grid-gap: 24px;
}

.page-card {
  @extend .card;
  padding: 12px;

  & > .page-thumbnail {
    min-height: 136px;
    min-width: 344px;
    height: auto;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 12px;
  }
}

.edit-page-link-field {
  border-radius: 4px;
  border: 1px solid $white-2;
  width: 100%;
  color: $black-1;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  margin-bottom: 32px;
  word-break: break-all;
  &.show-focus {
    border-color: $purple-1;
    box-shadow: $focus-blue-box-shadow;
  }
  &.show-error {
    border-color: $red-1;
    box-shadow: $focus-red-box-shadow;
  }
  &--text-area {
    &:focus-visible {
      outline: none;
    }
  }
}

.page-link {
  @extend .text-8, .c-purple-1, .underline;
  margin-right: 8px;
  word-break: break-word;

  &:hover {
    color: $purple-hover;
    text-decoration: underline;
  }
}

.ck-focused {
  box-shadow: none !important;
  border-color: var(--ck-color-base-border) !important;
}

.ck-editor__editable_inline {
  min-height: 300px;
  max-height: 500px;

  color: $black-1;

  > :first-child {
    margin-top: 16px !important;
  }

  > :last-child {
    margin-bottom: 16px !important;
  }

  .ck-link_selected {
    background: transparent !important;
  }

  a {
    color: $purple-1 !important;
    text-decoration: underline;
    font-weight: 500;
    strong {
      font-weight: 600 !important;
    }
  }

  h2 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 500 !important;
    margin: 16px 0px 0px;
    padding-bottom: 6px;
    strong {
      font-weight: 600 !important;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500 !important;
    margin: 16px 0px 0px;
    padding-bottom: 6px;
    strong {
      font-weight: 600 !important;
    }
  }

  h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500 !important;
    margin: 16px 0px 0px;
    padding-bottom: 6px;
    strong {
      font-weight: 600 !important;
    }
  }

  p {
    @include font-size-weight(16, 24, 400);
    margin: 6px 0px 0px;
    padding-bottom: 6px;
    strong {
      font-weight: 500;
    }
  }

  blockquote {
    border-left: solid 6px $purple-1 !important;
    padding-left: 16px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    @include font-size-weight(20, 28, 400);
    color: $black-2 !important;
    margin: 16px 0px !important;
    font-style: normal !important;
  }

  pre {
    padding: 24px 16px;
    background-color: $white-4;
    color: $black-2;
    font-family: Arial, Helvetica, sans-serif;
    @include font-size-weight(20, 28, 400);
  }

  &.ck-content .image {
    margin: 16px auto;
  }

  ul,
  ol {
    margin: 16px;
  }
  ul {
    list-style: unset !important;
  }
  ol {
    list-style: decimal;
  }
  li {
    word-break: break-word;
    @include font-size-weight(16, 24, 400);
    margin: 0px;
    strong {
      font-weight: 500;
    }
  }
  &::-webkit-scrollbar {
    display: block !important;
  }
}
