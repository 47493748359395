.orders-not-found {
  height: calc(100vh - 286px);
  &.add-footer-height {
    height: calc(100vh - 286px - 102px); //SUBSCRIPTION_EXPIRY_FOOTER_HEIGHT
  }
  @include display-flex;
  flex-direction: column;
  img {
    @include content-size(324, 192);
  }
  .share-via {
    margin: 32px;
  }
}

.rto-category {
  @extend .d-flex, .align-center;
  .outline-badge {
    margin-left: auto;
  }

  .unanalysed-status-text {
    @extend .text-8;
    font-style: italic;
    margin-left: auto;
    color: $black-3;
  }

  .order-count {
    @extend .text-2, .c-black-1, .cur-p;
  }
}

.orders-not-found-without-svg {
  border-radius: 4px;
  margin: 24px 0;
  margin-top: 0;
  background-color: $white;
  .no-orders-text {
    padding: 48px 0;
    text-align: center;
    box-shadow: 0 2px 8px 0 rgba(26, 24, 30, 0.04);
  }
}

.animate-slide-right {
  animation-name: slide-right;
  animation-duration: 1s;
}

@keyframes slide-right {
  0% {
    right: -4px;
  }
  100% {
    right: -1000px;
  }
}

.youtube-thumbnail-container {
  position: fixed;
  bottom: 12px;
  cursor: pointer;
  display: flex;
  right: -4px;
  &.subscription-footer {
    bottom: 80px;
  }
  .youtube-thumbnail-close {
    position: relative;
    top: 20px;
    right: 48px;
    height: 24px;
    &:hover {
      transform: scale(1.05);
    }
  }
}

.new-order-tag {
  @include font-size-weight(12, 16, 500);
  background-color: $green-1;
  color: $white;
  height: 20px;
  padding: 2px 6px;
  border-radius: 3px;
  margin-left: 6px;
}

.orders-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
  grid-gap: 24px;
  .order-item {
    padding: 16px 16px 10px;
    color: $black-1;
    cursor: pointer;
    box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.04);
    transition: box-shadow 0.2s;
    border-left: 4px solid transparent;
    &.restaurant-order {
      border-left: 4px solid $red-3;
    }
    &:hover {
      box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.1);
    }
    .order-id-container {
      @include display-flex(center, space-between);
      margin-bottom: 12px;
    }
    .order-item-container {
      @include display-flex(center, flex-start);
      position: relative;
      margin-bottom: 12px !important;
      img {
        @include content-size(80, 80);
        border-radius: 4px;
        margin-right: 16px;
        object-fit: contain;
        border: 1px solid $white-9;
      }
    }
    .order-status-wrap {
      border-top: 1px solid $white-2;
      padding-top: 12px;
      text-align: center;
      @include display-flex(center, space-between);
    }
    .order-detail-btn {
      display: inline-flex;
      align-items: center;
      padding: 4px 8px 4px 12px;
      border-color: $gray-3;
      > span {
        position: relative;
        top: 1px;
      }
    }
  }
}

.active-orders {
  .order-item {
    .order-item-container {
      margin-bottom: 6px;
      position: relative;
      .order-detail-btn {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .order-actions {
      display: flex;
      margin: 0 -6px;
      padding: 7px 0 10px;
      > button {
        flex: 1;
        margin: 0 6px;
      }
    }
  }
  .orders-not-found {
    height: calc(100vh - 640px);
    min-height: 400px;
  }
}

.special-filter-btn {
  position: relative;
  cursor: pointer;
  border: solid 1px $white-2;
  font-size: 14px;
  line-height: 20px;
  color: $black-3;
  padding: 5px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;
  &.orders-view-btn {
    padding: 7px 8px !important;
  }
  &.inventory-warehouse-filter {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  &.active {
    background: rgba(20, 110, 180, 0.1);
    border: 1px solid $purple-1;
    color: $purple-1;
  }
  &:hover{
    background-color: #f7f7f7;
  }
}

.orders-list-scroll {
  overflow-y: overlay;
}

.order-list-wrapper {

  .input-wrap {
    margin: 0;
    .dropdown-btn {
      display: flex;
    }
  }
  .order-list-header-filter {
    padding: 16px 32px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white-1;
  }
  .order-list-header {
    @extend .d-flex-c-s;
  }
  .order-filter-wrap {
    @include display-flex(center, space-between);
  }
  .abandoned-order-filter-wrap {
    @include display-flex(flex-start, flex-end);
  }
  .order-status-filter-dropdown {
    @extend .text-capitalize;
    @include font-size(15, 22);
    padding: 6px 12px;
    display: flex;
    justify-content: space-around;
  }
  .orders-list {
    padding: 0 32px;
  }
  .pincode-unserviceable {
    background: rgba(229, 11, 32, 0.1);
    border: 1px solid $red-1;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .abandoned-filters {
    .date-input-field-wrap {
      height: 40px;
    }
  }
}

.text-border-right {
  border-right: 1px solid $white-8;
  margin-right: 4px;
}

.payment-mode {
  position: absolute;
  right: 0;
  padding: 1px 8px;
  border-radius: 3px;
  @include font-size-weight(14, 20, 600);
  color: $red-1;
  background: rgba(229, 11, 32, 0.15);
  &.COD {
    color: $orange-1;
    background: rgba(238, 116, 31, 0.15);
  }
  &.PAID {
    color: $green-1;
    background: rgba($green-1, 0.15);
  }
  &.UNPAID {
    color: $black-3;
    background: rgba($black-3, 0.15);
  }
  &.MANUAL {
    color: $brown-4;
    background: rgba($brown-4, 0.15);
  }
}

.payment-mode-static {
  padding: 1px 8px;
  border-radius: 3px;
  @include font-size-weight(14, 20, 600);
  color: $red-1;
  background: rgba(229, 11, 32, 0.15);
  &.COD {
    color: $orange-1;
    background: rgba(238, 116, 31, 0.15);
  }
  &.CASH {
    color: $red-3;
    background: rgba($red-3, 0.15);
  }
  &.PAID,
  .PREPAID {
    color: $green-1;
    background: rgba($green-1, 0.15);
  }
  &.UNPAID {
    color: $black-3;
    background: rgba($black-3, 0.15);
  }
  &.MANUAL {
    color: $brown-4;
    background: rgba($brown-4, 0.15);
  }
}


.total-orders-text {
  min-width: 120px;
}

.delivery-information-card {
  min-width: 270px;
  width: 270px;
  height: fit-content;
}

.confirm-multiple-ship-container {
  display: flex;
  padding: 32px;
  .border-bottom {
    border-bottom: 1px solid $white-8;
  }
  .left-container {
    width: 100%;
    .multiple-ship-banner {
      background-color: $white;
      border: 1px solid $white-2;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px 24px;
    }
    .order-card {
      margin-top: 0;
      padding: 24px;
      .payment-mode {
        bottom: 2px;
      }
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
    .dot {
      position: relative;
      &::before {
        content: '';
        width: 4px;
        height: 4px;
        background-color: $black-1;
        position: absolute;
        top: 50%;
        left: 0;
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .right-container {
    max-width: 368px;
    width: 100%;
    height: max-content;
    @media (max-width: 767px) {
      max-width: initial;
    }
    .info-icon > path {
      fill: $black-3;
    }
  }
}

.bottom-download {
  background-color: #fff;
  padding: 16px 32px;
  min-height: 72px;
  z-index: 1100;
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 224px);
  margin-left: 224px;
  box-shadow: inset 0 1px 0 0 #d9d9d9;
}

.status-shimmer {
  right: 0;
  padding: 16px;
}
.orders-time-picker {
  > .dk__date-range-list > .dk__date-range-list-item {
    font-size: 14px;
    line-height: 20px;
  }
}

.customer-detail-wrapper {
  .customer-details-outer-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;

    .customer-details-container {
      flex: 1;
      .details-card {
        display: flex;
        flex-direction: column;
        padding: 20px;
        @include box-style;
        p {
          @extend .text-2;
        }
        & > p {
          margin-bottom: 20px;
        }
        span {
          align-self: center;
        }
        .info-section {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(min(40%, 400px), 1fr));
          grid-template-rows: auto;
          gap: 20px 24px;
          .detail-col {
            @extend .word-break;
            color: $black-1;
            p:first-child {
              font-weight: 500;
            }
          }
          .address-col {
            grid-column: 1 / 3;
            grid-row: 2;
          }
        }
      }
    }
    .customer-info-cards {
      width: 320px;
      position: sticky;
      top: 32px;
    }
  }

  .customer-order-container {
    .customer-order-list-wrapper {
      .orders-list {
        padding: 0;
        grid-template-columns: repeat(auto-fill, 368px);
      }
      .no-orders-list {
        .orders-not-found {
          height: 100%;
          padding: 48px 0;
        }
      }
    }
  }
}

.order-filter-menu,
.products-filter-menu,
.category-filter-menu {
  max-width: 420px;
  max-height: calc(100vh - 220px);
  overflow: scroll;
  .hr-line {
    margin: 8px 0;
  }
  .row {
    margin-left: -8px;
    margin-right: -8px;
    .menu_list_item {
      margin: 0 !important;
      @extend .col-xs-6;
      padding: 6px 8px !important;
    }
  }
  .order-filter-options {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .menu_list_item {
      display: inline-block;
      width: 45%;

      &:nth-of-type(even) {
        padding-left: 0 !important;
      }
    }
  }
}

.order-filter-menu {
  .dropdown-options-grid {
    @include d-grid(1fr 1fr, 1fr, 16px);
  }
}

.products-filter-menu {
  max-height: calc(100vh - 240px);
  .hr-line {
    margin: 10px 0 8px;
  }
}

.commission-report-wrapper {
  .commission-report-filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 99;
    background: rgb(247, 247, 247);
  }

  .commission-table-container {
    min-height: 200px;
  }
}

.loyalty-points-radio-group {
  .MuiFormControlLabel-label {
    @include font-size-weight(16, 24, 500);
    color: $black-1;
  }
}

.radio-filter-wrap {
  .hr-line {
    background-color: $white-9;
  }

  .MuiFormControlLabel-root:hover {
    background-color: $white-6;
    border-radius: 4px;
  }
}

.no-orders-list {
  .orders-not-found {
    max-height: calc(100vh - 417px);
  }
}

.shift-down {
  margin-bottom: -8px;
  z-index: 2;
}
