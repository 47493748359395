.menu-list-card {
  @extend .card;
  min-width: 270px;
  width: 400px;
  padding: 16px;
  height: fit-content;
  position: sticky;
  top: 24px;
}

.draggable-menu {
  display: flex;
  margin-bottom: 0;
  &:last-of-type {
    .draggable-item:last-of-type {
      margin-bottom: 0;
    }
  }
}

.expanded-icon{
  margin-right: 13px;
  margin-top: 14px;
  margin-bottom: 14px;
  right:0;
  :hover{
    cursor: pointer;
  }
}
.draggable-item {
  width: 416px;
  border: solid 1px $white-2;
  border-radius: 4px;
  margin-bottom: 12px;
  &.dragging {
    box-shadow: 0 2px 6px 0 rgba(26, 24, 30, 0.12);
    border: dashed 1px $white-2;
  }
}

.draggable-item-header {
  @extend .d-flex-c-end;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 4px;

  &.open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1px solid $white-2;
  }

  &:hover {
    background-color: $white-10;
  }
}

.draggable-item-content {
  padding: 16px 16px 8px 16px;
  .form-group {
    .field-label {
      @extend .text-10;
    }
    .input-group-wrap input {
      @extend .text-10;
      padding: 8px 12px;
    }
  }
}

.menu-item-header-wrap {
  &:last-child {
    .menu-item-content {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }
}

.menu-item-header {
  padding-bottom: 12px;
  border-bottom: solid 1px $white-8;
  @extend .cur-p;
  margin-top: 12px;
  &.hide-bottom-border {
    border-bottom: none;
    padding-bottom: 0px;
  }
}

.menu-item-content {
  padding: 16px 8px;
  border-bottom: solid 1px $white-8;

  &.dropdown-menu {
    padding: 16px;
    padding-bottom: 8px;
    border-bottom: none;

    &.store-menu {
      padding: 8px;
    }
  }

  .item-list {
    display: flex;
    flex-direction: column;

    &:last-of-type {
      margin-bottom: 0;
    }

    & > .checkbox-label-wrap {
      margin-bottom: 10px;
      word-break: break-all;
      &:last-of-type {
        margin-bottom: 1px;
      }
    }
    max-height: 300px;
    overflow-y: auto;
  }

  .form-group {
    .field-label {
      @include font-size(12, 16);
    }
    .input-group-wrap input {
      @extend .text-10;
      padding: 8px 12px;
    }
  }

  .menu-search {
    .search-img {
      top: 6px;
    }
    .remove-search {
      top: 5px;
    }
    input {
      @extend .text-10;
      padding-top: 8px;
      padding-bottom: 8px;
      height: 32px;
    }
  }
}

.btn-menu-small {
  @extend .btn-outline-primary;
  padding: 6px 12px;
  @include font-size-weight(12, 16, 500);
}

.menu-structure-container {
  @extend .card;
  padding: 24px;
  min-height: 188px;
}

.blinker {
  animation: blinker 1.5s ease-in-out;
}

@keyframes blinker {
  50% {
    background-color: cornsilk;
  }
}

.align-checkbox-top {
  align-items: flex-start !important;
}

.select-product-item {
  @extend .cur-p;
  border: solid 1px $white-8;
  border-radius: 4px;
  padding: 10px 16px;

  &.selected {
    background-color: rgba($purple-1, 0.1);
    border-color: $purple-1;
    box-shadow: $focus-blue-box-shadow;
  }
}

.email-input-with-verify input {
  padding-right: 89px;
}

.card-image-container {
  min-width: 64px;
  min-height: 64px;
  max-height: 66px;
  max-width: 66px;
  border-radius: 4px;
  border: 1px solid $white-9;
}

.menu-img-upload {
  height: 64px;
  width: 64px;
  min-width: 64px;
  border-radius: 4px;
  img,
  .uploaded-img-overlay {
    height: 64px;
    width: 64px;
    border-radius: 4px !important;
  }
}

.nested-checkbox {
  margin-bottom: 0 !important;
}
