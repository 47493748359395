.search-filter {
  position: relative;
  &.attribute-search {
    min-width: 200px;
    max-width: 200px;
  }
  .search-img {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .remove-search {
    @extend .cur-p;
    @include content-size(14, 14);
    position: absolute;
    top: 10px;
    right: 13px;
  }

  input {
    @include font-size(14, 20);
    height: 32px; 
    border-radius: 4px;
    border: 1px solid $white-2;
    padding: 2px 28px 2px 32px;
    color: $black-1;
    width: 100%;

    &::placeholder {
      color: $gray-4;
    }
    &:focus {
      outline: none;
      border-color: #146eb4;
      box-shadow: $focus-blue-box-shadow;
    }
  }
}

.dashboard-search {
  position: relative;

  .placeholder {
    position: absolute;
    top: 26%;
    left: 41px;
    color: $gray-1;
    font-size: 14px;

    &.hide-placeholder {
      display: none;
    }

    &.fadeOutUp {
      animation-duration: 500ms;
      animation-fill-mode: both;
      animation-name: fadeOutUp;
    }
    &.fadeInUp {
      animation-duration: 500ms;
      animation-fill-mode: both;
      animation-name: fadeInUp;
    }
    @keyframes fadeOutUp {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: translate3d(0, -65%, 0);
      }
    }
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translate3d(0, 65%, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .search-img {
    position: absolute;
    top: 13px;
    left: 16px;
  }

  .remove-search {
    @extend .cur-p;
    @include content-size(16, 16);
    position: absolute;
    top: 12px;
    right: 13px;
  }
  
  .keyboard-shortcut {
    font-weight: 500;
    color: $gray-4;
    z-index: 2000;
    position: absolute;
    top: 8px;
    right: 13px;
    padding: 2px 4px;
    background-color: white;
    border-radius: 6px
  }

  input {
    @extend .text-3;
    height: 40px;
    border-radius: 6px;
    border: 1px solid $white-9;
    padding: 2px 28px 2px 40px;
    color: $black-1;
    width: 400px;
    background-color: $white-6;

    &::placeholder {
      color: $gray-1;
    }
    &:focus {
      outline: none;
    }
  }
}

.dashboard-search-menu {
  width: 400px;
  // 690px is approximately 70% of 992px
  // 992px is the height taken for the screen size on figma
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: $white;

  .results-container {
    max-height: inherit;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    padding: 16px 8px;
  }
  .actions {
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    justify-content: center;
    .action-btn {
      @extend .cur-p;
      color: $black-3;
      background-color: $white-6;
      border-radius: 4px;
      height: 32px;
      padding: 6px 12px 6px 12px;
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      align-items: center;
      justify-content: center;
      border: 0;
      &:hover {
        background-color: $white-9;
      }
      &[aria-selected='true'] {
        background-color: $white-9;
      }
      svg {
        flex: 20px;
      }
      div {
        flex: auto;
      }
    }
  }
  .description {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $gray-1;
    padding-bottom: 40px;
  }
  .recent-searches {
    padding: 0 8px;
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $gray-1;
      .clear-all {
        @extend .cur-p, .text-10;
        color: $purple-1;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      .history-item {
        display: flex;
        flex-direction: row;
        flex: 1;
        column-gap: 8px;
        word-break: break-word;
        align-items: center;
        height: 36px;
        padding: 8px;
        border-radius: 4px;
        svg {
          flex: 16px;
        }
        div {
          flex: 320px;
        }

        &:hover {
          @extend .cur-p;
          background-color: $white-6;
        }
      }
    }
  }
  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .title {
      @extend .section-text-5;
    }
    .content {
      @extend .text-8;
      color: $gray-1;
    }
  }
  .quick-actions {
    @extend .section-text-13;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $gray-1;
  }
  .menu-title {
    @extend .section-text-13;
    color: $gray-1;
    word-break: break-word;
  }

  .menu-item {
    @extend .cur-p;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    border: 0;
    background-color: white;
    width: 100%;
    &[aria-selected='true'] {
      background-color: $white-6;
    }
    &:hover {
      background-color: $white-6;
    }
    &:focus {
      background-color: $white-6;
    }
    svg {
      flex-shrink: 0;
    }
    .menu-item-name {
      @extend .section-text-7;
      color: $black-1;
      word-break: break-word;
      text-align: start;
    }
    .menu-item-description {
      @extend .text-10;
      color: $black-3;
      word-break: break-word;
      text-align: start;
    }
  }

  .more-results {
    @extend .cur-p, .section-text-13;
    text-align: center;
    color: $purple-1;
    width: 100%;
    background-color: white;
    border: 0;
    padding: 8px;
    border-radius: 4px;
    &[aria-selected='true'] {
      background-color: $white-6;
    }
    &:hover {
      background-color: $white-6;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $white-9;
    border: none;
  }
}

.shortcuts-search {
  border-radius: 4px;
  border: 1px solid $white-2;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .search-img {
    position: relative;
    top: 0;
    left: 0;
  }

  .remove-search {
    @extend .cur-p;
    @include content-size(16, 16);
    position: relative;
    top: 0;
    right: 0;
  }

  input {
    margin-left: 8px;
    border: none;
    width: 100%;
    padding: 0;
    height: auto;
    border-radius: 0;
    background-color: transparent;
    font-size: 14px;
    line-height: 20px;
    &:focus-visible {
      outline: none;
    }
  }
}
