.bundle-information-panel-row {
  gap: 24px;
  .bundle-information-panel-col {
    max-width: calc(50% - 24px);
    @include calculated-spacer(0 0 calc(50% - 12px));
  }
}

.bundle-form-wrap {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .line-through {
    @include font-size-weight(14, 20, 400);
    text-decoration: line-through;
    margin-left: 4px;
    color: $gray-1;
  }
}

.bundle-table-image {
  height: 48px;
  width: 48px;
  border-radius: 4px;
  border: 1px solid $white-9;
}

#special-filter-menu.bundles-sort-filter {
  .MuiPaper-root.MuiMenu-paper {
    width: 204px !important;
    top: 134px !important;
    left: 1204px !important;
  }
}
