.analytics {
  padding: 32px;

  &__overview-cards-wrapper {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 18px));
    grid-gap: 24px;
    &.min-height-166{
      display: flex;
      height: 166px;
      div {
        flex: 1 1 auto;
      }
      @media (min-width: 1635px) {
        height: 142px;
      }
    }
  }

  &__store-overview-cards-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    &.min-height-126 {
      display: flex;
      div {
        flex: 1 1 auto;
      }
      height: 142px;
    }
  }

  &__product-overview-cards-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    &.min-height-126{
      display: flex;
      div {
        flex: 1 1 auto;
      }
      height: 142px;
    }
    &.min-height-276{
      display: flex;
      div {
        flex: 1 1 auto;
      }
      height: 276px;
    }
  }

  &__overview-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__overview-card-title {
    width: 100%;
    @media (min-width: 1200px) {
      max-width: 85%;
    }
    @media (min-width: 1400px) {
      max-width: 80%;
    }
    @media (min-width: 1480px) {
      max-width: 65%;
    }
    @media (min-width: 1635px) {
      max-width: 100%;
    }
    .info-icon{
      cursor: pointer;
      margin: 0px 0px -2px 4px;
    }
  }

  &__overview-card-stat {
    color: $black-1;
    padding-right: 5px;
    @include font-size-weight(32, 36, 600);
  }

  &__overview-card-percentage {
    @extend .d-flex-c-c;
    padding-left: 5px;
    padding: 2px 7px;
    user-select: none;
    font-weight: bold;
    @extend .text-5;

    &--success {
      border: solid 1px rgba($green-1, 0.1);
      border-radius: 4px;
      background-color: rgba($green-1, 0.1);
      color: $green-1;
    }

    &--warning {
      border: solid 1px rgba($red-1, 0.1);
      border-radius: 4px;
      background-color: rgba($red-1, 0.1);
      color: $red-1;
    }
  }

  &__overview-chart-card {
    flex: 1 1 0;
    width: 50%;

    .overview-chart-wrap {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;

      &-top-products {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr auto;
        gap: 16px;
      }
    }

    .recharts-default-legend {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 64px;
      li svg.recharts-surface{
        margin-bottom: 2.5px;
      } 
    }
    .recharts-responsive-container
      *:not(.recharts-tooltip-wrapper, .recharts-legend-item, .recharts-surface) {
      width: 100% !important;
    }

    &.overview-card-conversion-rate{
      .recharts-responsive-container *:not(.recharts-tooltip-wrapper, .recharts-legend-item, .recharts-surface) {
        line-height: 16px;
      }
    }
  }

  &__overview-progress-bar {
    // To override default 10px height
    height: 6px !important;

    &--progress {
      background-color: $purple-1 !important;
    }

    &--background {
      background-color: $white-9 !important;
    }
  }
  &__individual-top-product {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      height: 52px;
      width: 52px;
      object-fit: contain;
      border: 1px solid $white-9;
      border-radius: 4px;
    }
    .description{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 1 auto;
      text-align: start;
      margin-bottom: 0;
      p:first-of-type{
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .min-height-422 {
    min-height: 422px;
  }

  .min-height-343 {
    min-height: 343px;
  }

  .min-height-408 {
    min-height: 408px;
  }

  .height-256 {
    height: 256px;
  }

  .min-height-432 {
    min-height: 432px;
  }

  .height-118 {
    height: 118px;
  }

  .height-142 {
    height: 142;
  }

  .table-change-wrapper{
    svg{
      height: 12px;
      width: 12px;
    }
  }
  .view-detailed-report{
    @extend .section-text-7;
    @extend .c-black-3;
    // @extend .underline;
    // @extend .d-block;
    @extend .text-center;
    @extend .mt-auto;
    &:hover{
      @extend .underline;
      color : $gray-1;
    }
  }
}
