.restaurant_dining_page_empty {
  width: calc(100% - 64px);
  height: calc(100% - 80px);
  @include display-flex(center, center);

  & > .empty_content {
    max-width: 564px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;

    & > h4 {
      margin: 8px 0;
      @include font-size-weight(20, 28, 500);
      color: #1a181e;
    }

    & > p {
      margin-bottom: 32px;
      @include font-size-weight(16, 24, normal);
      color: #4d4d4d;
    }
  }
}

.table_add_container {
  @include display-flex(flex-start, center);
}

.table_info_container {
  display: grid;
  grid-template-columns: 3fr 368px;
  grid-gap: 24px;
}

.restaurant_dining_table_info {
  width: 100%;
  & > .table_form {
    & > .delete_table_container {
      width: 100%;
      padding-top: 24px;
      @include display-flex(center, center);

      & > button {
        @extend .btn-no-default;
        @include font-size-weight(16, 24, 500);
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #e50b20;
      }
    }
  }
  & > .table_form,
  .table_form_submit {
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.04);
    background-color: $white;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;

    & > h5 {
      @include font-size-weight(16, 24, 500);
      margin-bottom: 24px;
      color: #1a181e;
    }
    & > .online_payments_info {
      & > .online_payments_switch {
        @include display-flex(flex-start, space-between);
        margin-top: 28px;

        & > h6 {
          @include font-size-weight(16, 24, 500);
          color: #1a181e;
        }
      }

      & > p {
        @include font-size-weight(14, 20.04, normal);
        color: #808080;
      }
    }
  }

  & > .table_form_submit {
    @include display-flex(center, flex-end);
    background-color: transparent;
    box-shadow: none;
    margin-top: 32px;
    padding: 0;
    &.invalid {
      & > button {
        opacity: 0.4;
      }
    }
    &.valid {
      & > button {
        opacity: 1;
      }
    }
    & > button {
      @extend .btn-no-default;
      color: $white;
      padding: 8px 24px;
      border-radius: 4px;
      background-color: #146eb4;
    }
  }
}

.table-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
  grid-gap: 24px;
}

.table-list-card {
  border-radius: 8px;
  padding: 16px 16px 0;
  box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.04);
  background-color: $white;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.10);
  }

  & > .card-info {
    padding: 0 0 12px 0;
    position: relative;
    @include display-flex(center, space-between);
    & > .action-menu-icon {
      position: absolute;
      top: -8px;
      right: -14px;
      z-index: 1;
    }
    & > .card-details {
      @include display-flex(center, flex-start);
      width: 100%;
      cursor: pointer;
      & > .card-name-icon {
        @include font-size-weight(32, 40, 600);
        @include content-size(56,56);
        text-align: center;
        text-transform: uppercase;
        padding: 8px 15px;
        border-radius: 4px;
        background-color: #df1e5b;
        margin-right: 12px;
        color: $white;
      }
      & > .card-name-status {
        text-align: left;
        & > .card-name {
          @include font-size-weight(20, 28, 500);
        }
        & > .card-status {
          @include font-size-weight(14, 20.04, normal);
          color: $gray-1;
        }
      }
    }
  }

  & > a {
    @include display-flex(center, center);
    @extend .btn-no-default;
    @include font-size-weight(16, 24, normal);
    color: #1a181e;
    border-top: #d9d9d9 1px solid;
    padding: 12px;
    text-align: center;
    width: 100%;
    vertical-align: middle;

    svg {
      width: 20px;
      height: 20px;
      margin: 0 8px 0 0;
    }
  }

}

.restaurant-search-btn {
  @include font-size-weight(16, 24, normal);
}

.qr_preview_card_container {
  border-radius: 8px;
  background-color: $white;
  padding: 24px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  & > div {
    h4 {
      @include font-size-weight(16, 24, 500);
      color: #1a181e;
      margin-bottom: 24px;
    }
    & > .qr_preview_card {
      border-radius: 8px;
      height: 480px;
      position: relative;

      & > img {
        border-radius: 8px;
        border: solid 1px #e5e5e5;
      }
    }
  }
}
