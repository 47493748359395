.component_carousel_container {
  position: relative;
  padding: 14px 0;
  & > .dot_container {
    width: fit-content;
    height: 24px;
    margin-top: 24px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 40px;
    background-color: #f2f2f2;
    padding: 8px;
    @include display-flex(center, space-between);

    & > .dot {
      &:not(:last-of-type) {
        margin-right: 8px;
      }
      @extend .btn-no-default;
      width: 8px;
      height: 8px;
      padding: 0;
      border-radius: 50%;
      background-color: #b2b2b2;
      &.dot_active {
        background-color: #146eb4;
      }
    }
  }
  & > .carousel_prev_button,
  .carousel_next_button {
    @extend .btn-no-default;
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    z-index: 1001;
    box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.1);
    @include display-flex(center, center);

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  & > .carousel_prev_button {
    left: -20px;
    & > svg {
      transform: rotate(180deg);
      width: 20px;
      height: 20px;
    }
  }

  & > .carousel_next_button {
    right: -20px;
  }
}
.component_carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: auto;

  & * {
    @extend .border-box;
  }
  & > .carousel {
    width: 100%;
    height: fit-content;
    position: relative;
    z-index: 99;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;

    & > .carousel_item {
      opacity: 0;
      position: absolute;
      top: 0;
      width: fit-content;
      margin: auto;
      z-index: 100;
      transition: transform 0.5s, opacity 0.5s, z-index 0.5s;
    }

    & > .carousel_item.initial,
    .carousel_item.current {
      opacity: 1;
      position: relative;
      z-index: 900;
    }
    & > .carousel_item.current {
      transform: translate(0%, 0%);
    }
    & > .carousel_item.prev,
    .carousel_item.next {
      opacity: 1;
      z-index: 800;
    }

    & > .carousel_item.prev.wa_slide {
      transform: translateX(-10px);
    }
    & > .carousel_item.next.wa_slide {
      transform: translateX(483px);
    }

    & > .carousel_item.prev.sb_slide {
      transform: translateX(-60px);
    }
    & > .carousel_item.next.sb_slide {
      transform: translateX(500px);
    }

    & > .carousel_item.prev.bc_slide {
      transform: translateX(-220px);
    }
    & > .carousel_item.next.bc_slide {
      transform: translateX(549px);
    }
  }
}

.marginRight {
  margin-right: 32px !important;
}

.carousel-btn-left {
  $left: $sidebar-width + 16px;
  position: fixed;
  top: 50%;
  left: $left;
  cursor: pointer;
}

.carousel-btn-right {
  position: fixed;
  top: 50%;
  right: 16px;
  cursor: pointer;
}

.carousel-thumbnail {
  width: 48px;
  height: 48px;
  margin: 4px;
  cursor: pointer;
  &.opacity-100 {
    border: 2px solid #e5e5e5 !important;
    transform: scale(1.3);
    margin-right: 8px;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.carousel-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 4px;
  cursor: pointer;
}
