.uses-per-customer-menu {
  .MuiListItem-root.Mui-selected {
    color: $purple-1;
    background-color: $white;
    font-weight: 500;
  }
  .MuiMenuItem-root {
    font-size: 16px;
    line-height: 24px;
  }
}

.MuiMenuItem-root.send-recovery-msg {
  white-space: normal;
  @extend .text-2
}
