.languages-section{
    .language{
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
        &:first-child{
            padding-top: 0px;
        }
        &:not(:last-child){
            padding-bottom: 12px;
            border-bottom: 1px solid $white-9;
        }

        .language-preview-section{
            width: 74px;
            button{
                margin-right: -10px;
            }
        }
        .primary-badge{
            color: $green-1;
            background: rgba(23, 179, 27, 0.1);
            padding: 2px 6px;
            border-radius: 4px
        }
        }
}

.available-languages{
    @extend .show-scrollbar;
    max-height: calc(100vh - 352px);
    overflow-y: overlay;
    padding-right: 24px;
    margin-right: -24px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.available-language{
    padding: 5px;
}
.available-language .checkbox-label{
    padding-left: 0 !important;
}

.available-language:hover{
    background-color: $white-10;
}

.store-settings-header{
.store-settings-header-wrapper{
    @extend .full-w
}
}

.language-dropdown-btn{
    cursor: pointer;
    border: solid 1px $white-2;
    font-size: 14px;
    line-height: 20px;
    color: $black-3;
    padding: 6px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $white;
}

.language-ckeditor{
    .ck-editor__editable {
        min-height: 500px !important;
        max-height: 500px !important;
      }
}

.languages-info-icon{
    display: inline-flex;
    svg{
        path{
            fill: $black-1;
        }
    }
}



.bulk-update-language-modal{
    .product-bulk-footer{
        width: 328px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .modal-title{
        position: absolute;
        left: 0;
        top: 0;
        background-color: $white;
        z-index: 2;
        padding-top: 24px;
        width: 100%;
    }
    .content-modal{
        padding-top: 52px;
    }
}
