.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.form-group {
  margin-bottom: 20px;

  &.input-m0 {
    margin: 0;
  }

  &.accordion-tax-input {
    .input-group-wrap {
      width: 50%;
    }
  }

  .field-label {
    display: block;
    @include font-size(14, 20);
    color: $black-3;
    margin-bottom: 8px;
  }
  .form-color-select,
  input,
  textarea {
    border-radius: 4px;
    padding: 12px 16px;
    border: 1px solid $white-2;
    width: 100%;
    color: $body-color;
    @include font-size(16, 24);

    &.color-right {
      padding: 6px;
    }
  }
  input,
  textarea {
    &::placeholder {
      color: $gray-2;
    }
    &:focus {
      outline: none;
      border-color: $purple-1;
      box-shadow: $focus-blue-box-shadow;
    }
  }
  textarea {
    resize: none;
    display: none;
  }

  .file-input-btn {
    background-color: #f2f2f2;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
    margin-right: 10px;
    color: $black-1;
    margin-left: -8px;
  }

  input[type='file'] {
    padding: 8px;
    &::file-selector-button,
    &::-webkit-file-upload-button {
      // visibility: hidden;
      display: none;
    }
    &::before {
      // content: 'Update image';
      content: attr(data-buttonlabel);
      display: inline-block;
      background-color: #f2f2f2;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      padding: 6px 12px;
      margin-right: 10px;
      color: $black-1;
    }
  }

  &.multi_select-wrap {
    .custom-select__control {
      height: auto !important;
      .custom-select__value-container {
        > input {
          display: none;
        }
        &.custom-select__value-container--has-value {
          padding: 2px 3px !important;
        }
      }
    }
  }

  &.has-error {
    input {
      border-color: $red-1;
      box-shadow: $focus-red-box-shadow;
    }
  }

  &.has-prepend-text {
    .input-group-wrap {
      position: relative;
      .prepend-txt {
        background-color: transparent;
        position: absolute;
        top: 0px;
        left: 18px;
        width: 30px;
        border-radius: 4px;
        height: 100%;
        @include font-size(16, 24);
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
      }
      .prepend-txt-block {
        background-color: transparent;
        position: absolute;
        top: 0px;
        left: 16px;
        border-radius: 4px;
        height: 100%;
        @include font-size(16, 24);
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 4px;
        ~ input {
          padding-left: 125px;
        }
      }
      input {
        padding-left: 30px;
      }
    }
  }

  .input-group-wrap.small > input {
    padding: 4px 8px;
  }

  &.has-append-text {
    .input-group-wrap {
      position: relative;
      .append-txt {
        background-color: transparent;
        position: absolute;
        top: 0px;
        right: 0px;
        border-radius: 4px;
        height: 100%;
        @include font-size(16, 24);
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 16px;
      }
    }
  }

  &.large-prepend-text {
    .input-group-wrap {
      .prepend-txt {
        width: 40px;
      }
      input {
        padding-left: 40px;
      }
    }
  }

  &.prepend-text-1 {
    .input-group-wrap {
      .prepend-txt {
        width: 30px;
      }
      input {
        padding-left: 30px;
      }
    }
  }
  &.prepend-text-2 {
    .input-group-wrap {
      .prepend-txt {
        width: 36px;
      }
      input {
        padding-left: 46px;
      }
    }
  }
  &.prepend-text-3 {
    .input-group-wrap {
      .prepend-txt {
        width: 42px;
      }
      input {
        padding-left: 54px;
      }
    }
  }
  &.prepend-text-4 {
    .input-group-wrap {
      .prepend-txt {
        width: 48px;
      }
      input {
        padding-left: 60px;
      }
    }
  }

  &-lg {
    .custom-select__control {
      min-height: 48px !important;
      height: 48px;
      @include font-size(16, 24);
      .custom-select__value-container {
        height: 100%;
      }
    }
  }

  &-md {
    margin-bottom: 16px;
    border-radius: 3px;
    .field-label {
      @include font-size(14, 22);
      margin-bottom: 8px;
    }
    input,
    textarea {
      padding: 7px 12px;
    }
    .custom-select__control {
      min-height: 38px !important;
    }
    &.custom-date-range-wrap,
    &.date-input-wrap {
      .date-input-field-wrap {
        width: 100%;
        height: 38px;
        padding: 10px;
        .calendar-text-wrap > input {
          padding: 7px 0;
        }
      }
    }
  }

  &-sm {
    .field-label {
      @include font-size-weight(12, 16, 400);
      margin-bottom: 6px;
    }

    .custom-select__control {
      min-height: 36px !important;
      max-height: 36px !important;

      .custom-select__value-container {
        padding: 2px 9px !important;
      }
    }

    .custom-select__option {
      font-size: 14px !important;
      line-height: 20px !important;
    }

    .custom-select__indicator {
      margin-right: 12px;
    }

    .input-group-wrap > input {
      font-size: 14px !important;
      line-height: 20px !important;
      padding: 7px 11px;
      max-height: 36px;
    }
  }

  &.country-flag-select {
    .prepend-txt-block {
      top: 3px !important;
    }
    .disabled {
      opacity: 0.4;
      cursor: auto;
    }
    &.country-flag-select-disabled {
      .custom-select__indicators {
        display: none;
      }
      .post-select-text {
        padding-left: 8px;
      }
      &.country-1 {
        input[name='business_mobile'],
        input[name='mobile'],
        input[name='mobile_number'] {
          padding-left: 74px;
        }
      }
      &.country-2 {
        input[name='business_mobile'],
        input[name='mobile'],
        input[name='mobile_number'] {
          padding-left: 86px;
        }
      }
      &.country-3 {
        input[name='business_mobile'],
        input[name='mobile'],
        input[name='mobile_number'] {
          padding-left: 96px;
        }
      }
    }
    &.country-flag-select-readonly {
      .input-group-wrap {
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        width: 100%;
        color: $black-1;
        font-size: 16px;
        line-height: 24px;
        padding: 0px 16px;
        height: 50px;

        .prepend-txt-block {
          position: relative;
          height: auto;
          top: 0 !important;
          right: 0;
          bottom: 0;
          left: 0;
          padding: 0;

          .d-flex {
            padding: 0;

            .post-select-text {
              padding: 0;
            }
          }
        }

        input {
          position: relative;
          padding: 0px !important;
          border: none;
          box-shadow: none;
        }
      }
    }
    &.country-1 {
      input[name='business_mobile'],
      input[name='mobile'],
      input[name='mobile_number'] {
        padding-left: 85px;
      }
    }
    &.country-2 {
      input[name='business_mobile'],
      input[name='mobile'],
      input[name='mobile_number'] {
        padding-left: 98px;
      }
    }
    &.country-3 {
      input[name='business_mobile'],
      input[name='mobile'],
      input[name='mobile_number'] {
        padding-left: 108px;
      }
    }
    .input-group-wrap {
      .prepend-txt-block {
        top: 2px;
        left: 2px;
        height: 42px;
        bottom: 2px;
        .d-flex {
          align-items: center;
          padding-top: 2px;
        }
        .form-group {
          margin-bottom: 0px;
          .custom-select__control--is-disabled {
            background-color: $white-1;
          }
          .custom-select__control {
            height: 45px !important;
            min-height: 45px !important;
            overflow: hidden;
            border: 0px !important;
            border-radius: 4px 0px 0px 4px !important;
            display: flex;
            .custom-select__value-container {
              width: 48px;
              padding: 2px 3px 2px 12px !important;
            }
            .custom-select__indicators {
              .custom-select__indicator {
                margin-right: 3px;
              }
            }
          }
        }
      }
    }
    .custom-select__menu {
      min-width: 342px !important;
    }
    .post-select-text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: $black-1;
    }
  }

  .custom-select__control--is-disabled {
    background-color: $white-6;
  }
  &.input-wrap {
    input:disabled {
      background-color: $white-6;
    }
  }

  .custom-select__single-value--is-disabled {
    color: $black-1;
  }
}

.char-limit-text {
  @include font-size-weight(14, 20, 400);
  color: $black-3;
}

.char-input-black {
  .char-limit-text {
    color: $black-1;
  }
}

.MuiFormHelperText-root.field-info {
  margin-top: 9px;
}

.form-color-select {
  cursor: pointer;
  .color-select-value-container,
  .color-select-placeholder {
    display: flex;
    position: relative;
    top: 1px;
  }
  .color-select-value-container {
    .color-box {
      @include content-size(24, 24);
      border-radius: 3px;
      margin-right: 8px;
      background-color: #ffffff;
      border: solid 1px $white-9;
    }
    .color-txt {
      &.color-right {
        width: 56px;
        margin-right: 24px;
        margin-left: 10px;
      }
    }
  }
  .color-select-placeholder {
    color: $gray-2;
  }
  &.has-error {
    border-color: $red-1;
  }
}

.choose-parent-category {
  .field-info {
    color: $red-1;
    @include font-size(14, 20);
  }
}

.form-fake-input {
  @extend .cur-p;
  @include font-size(18, 26);
  color: $black-1;
  width: 100%;
  border: 1px solid $white-2;
  border-radius: 4px;
  padding: 11px 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.placeholder {
    color: $gray-4;
  }

  &.small {
    @include font-size(16, 24);
    max-width: 344px;
  }

  &.disabled {
    background-color: $white-6;
  }
}

.wysiwyg-editor {
  border-radius: 4px;
  padding: 11px 16px;
  padding-left: 8px;
  border: 1px solid $white-2;
  width: 100%;
  color: $body-color;
  @include font-size(16, 24);
  margin-bottom: 24px;
}

.wysiwyg-toolbar {
  padding: 0 !important;
  border: hidden !important;
  // overflow: hidden!important;
}

.rdw-option-wrapper:hover {
  border: solid 1px #d9d9d9;
  box-shadow: none !important;
}

.rdw-option-active {
  border: solid 1px #4d4d4d !important;
  box-shadow: none !important;
}

.rdw-option-wrapper {
  padding: 4px !important;
  height: 25px !important;
}

.custom-radio + .MuiFormControlLabel-label {
  margin-bottom: -2px;
}

.error-text {
  @extend .text-10;
  color: $red-1;
  margin-top: 6px;
  min-height: 16px; //based on line-hight of the error text font
  margin-bottom: -16px;
}

.fake-field {
  background-color: $white-1;
  padding: 12px 16px;
  border-radius: 4px;
  width: 100%;

  > span {
    @include font-size(16, 24);
  }
}

.without-bg-fake-field {
  min-width: 200px;
  padding: 8px 12px;
  width: 100%;
  border: 1px solid $white-2;
  box-sizing: border-box;
  border-radius: 4px;

  > span {
    @include font-size(16, 24);
  }

  &.disabled {
    background: $white-10;
  }
}

.stepper-container {
  border: solid 1px $white-8;
  height: 46px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @extend .noselect;

  .stepper-divider {
    width: 1px;
    height: 100%;
    background-color: $white-8;
  }

  .stepper-btn {
    margin-left: 12px;
    margin-right: 12px;
    outline: none;
    border: none;
    padding: 0 !important;
    @extend .noselect;

    background-color: transparent;
    cursor: pointer;

    &.increment {
      @include content-size(14, 14);

      background-image: url('../images/icons/carets/plus.svg');
      background-repeat: no-repeat;
    }

    &.decrement {
      @include content-size(14, 2);

      background-image: url('../images/icons/carets/minus.svg');
      background-repeat: no-repeat;
    }
  }

  .stepper-value {
    @include font-size-weight(16, 20, 500);
    @extend .d-flex-c-c;
    color: $black-2;
    padding: 11px;
    width: 48px;
    @extend .noselect;
  }
}

.font-color-picker {
  @extend .cur-p;
  width: 42px;
  border: solid 1px $white-8;
  border-radius: 4px;
  padding: 9px 6px;

  p {
    @include font-size(18, 18);
    margin-bottom: 4px;
    text-align: center;
  }

  .font-color-preview {
    height: 4px;
    width: 100%;
    border-radius: 1px;
  }
}

.inventory-form {
  position: relative;
  .inventory-form-helper {
    @extend .cur-p;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.checkbox-sm {
  .MuiCheckbox-root {
    @include content-size(16, 16);
  }
}

.bulk-edit-category-select {
  width: 188px;
  margin-bottom: 0;
  z-index: 10;

  .custom-select__menu {
    margin-top: 8px !important;
  }

  .custom-select__control {
    @include font-size(16, 24);
    max-height: 36px !important;
    min-height: 36px !important;
    .custom-select__indicator {
      margin-right: 8px;
    }
  }

  .custom-select__single-value {
    color: $black-1;
  }

  .custom-select__option {
    @extend .word-break;
    font-size: 14px !important;
    line-height: 1.4 !important;
  }
}

.delivery-dashboard-container {
  .date-input-field-wrap {
    svg {
      margin-left: 4px !important;
    }
  }
  & .card {
    padding-top: 0;
  }
  & .card-padding {
    padding: 8px 16px;
  }
}

.bulk-edit-category-filter {
  .content-grp {
    white-space: normal;
    word-break: break-word;
  }
}

.bulk-edit-warehouse-select {
  width: 165px;
  margin-bottom: 0;
  z-index: 10;

  .custom-select__control {
    @include font-size(16, 24);
    min-height: 36px !important;
    max-height: 36px !important;
    .custom-select__indicator {
      margin-right: 8px;
    }
  }

  .custom-select__menu {
    margin-top: 8px !important;
  }

  &.delivery-dashboard {
    .custom-select__single-value {
      color: $black-3;
    }
    .custom-select__value-container {
      // padding-right: 4px !important;
      padding: 5px 12px;
      .custom-select__single-value {
        margin: 0;
      }
    }
    .custom-select__placeholder {
      color: $black-3 !important;
    }
  }

  &.vendors-select {
    width: 108px;
    .custom-select__menu {
      width: max-content;
      max-width: 280px;
      min-width: 162px;
    }
    .custom-select__option {
      padding: 8px;
    }
  }

  .custom-select__single-value {
    color: $black-1;
  }

  .custom-select__option {
    font-size: 14px !important;
    line-height: 1.4 !important;
    @extend .word-break;
  }

  &.vendors-checked {
    .custom-select__control {
      background: rgba(20, 110, 180, 0.1);
      border: 1px solid $purple-1 !important;
    }
  }
}

.select-sm {
  width: 140px;
  margin-bottom: 0;

  .custom-select__control {
    @include font-size(14, 20);
    max-height: 32px !important;
    min-height: 32px !important;
  }

  .custom-select__single-value {
    color: $black-1;
  }

  .custom-select__option {
    font-size: 14px !important;
    line-height: 1.4 !important;
  }
}

.select-md {
  width: 150px;
  margin-bottom: 0;

  .custom-select__menu {
    width: 220px;
  }

  .custom-select__control {
    @include font-size(14, 20);
    max-height: 32px !important;
    min-height: 32px !important;
  }

  .custom-select__single-value {
    color: $black-1;
  }

  .custom-select__option {
    font-size: 14px !important;
    line-height: 1.4 !important;
  }
}

.select-lg {
  width: 390px;
}

.otp-mobile-verfication-form {
  .otp-input-wrap {
    .form-group {
      margin-bottom: 0;
    }
    .field-label {
      @include display-flex(center, space-between);
    }
    input {
      height: 50px;
      border-radius: 6px;
      border: 1px solid $white-2;
      color: $black-1;
      width: 50px !important;
      @include font-size-weight(32, 38, 500);
      margin-right: 10px;
      padding: 11px 8px;
      &:focus {
        outline: none;
        border-color: $purple-1;
      }
    }
  }
  &.invalid-otp-verfication-form {
    .otp-input-wrap {
      input {
        border-color: $red-1 !important;
        box-shadow: $focus-red-box-shadow;
      }
    }
  }
  .resend-otp-timer,
  .resend-otp-link {
    @include font-size-weight(14, 20, 500);
  }
  .verify-btn {
    padding: 8px 24px;
    font-size: 16px;
    line-height: 24px;
    &.disabled {
      opacity: 0.4;
      cursor: auto;
    }
  }
}
.mailchip-preview {
  border-bottom: solid 1px $white-2;
  cursor: default;
  .fake-field {
    width: 254px;
    background-color: transparent;
    padding: 4px 16px;
    border-radius: 2px;
    border: solid 1px $white-2;
    > span {
      @include font-size(10, 16);
    }
  }
  .mailchip-preview-btn {
    padding: 8px 36px;
    background: $black-1;
    border-radius: 2px;
    font-size: 10px;
    color: white;
    font-weight: 500;
  }
}

.create-password-field {
  position: relative;
  .toggle-button {
    position: absolute;
    right: 12px;
    top: 48px;
  }
  .password-strength-container {
    position: absolute;
    top: 2px;
    right: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 20px;

    &.mobile-height-container {
      height: 16px;
    }
  }
}

.progress-bar-root {
  width: 80px;
  height: 6px;
}

.login-password-field {
  position: relative;
  .toggle-button {
    position: absolute;
    right: 12px;
    top: 48px;
  }
}

.file-input-wrapper {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 16px;
  border: 1px solid $white-2;
  width: 100%;
  color: $body-color;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .file-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 30px;
  }

  .close-icon {
    @extend .cur-p;
    min-width: 20px;
  }

  & > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: end;
    gap: 16px;

    & > button {
      @extend .cur-p;
      box-sizing: border-box;
      background-color: #f2f2f2;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      padding: 6px 16px;
      color: $black-1;
      min-width: fit-content;
    }
  }
}

.form-weight-input-group {
  width: auto !important;
  .form-weight-input-wrap {
    border: 1px solid $white-2;
    border-radius: 4px;
    display: flex;
    height: 40px;
    &.focused {
      border-color: $purple-1;
      box-shadow: $focus-blue-box-shadow;
    }
    &.lg {
      height: 48px !important;
    }
    &.sm {
      height: 40px;
      width: 150px;
    }
    input {
      border: none;
      min-width: 50%;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:disabled {
        background-color: $white-6;
      }
    }
    .weight-input {
      min-width: 50%;
      padding: 11px 0;
      &:focus {
        outline: none;
      }
      &.disabled {
        background-color: $white-6;
      }
      .weight-label {
        @include font-size-weight(16, 24, 400);
        padding-left: 16px;
        cursor: pointer;
      }
      .custom-select__control,
      .weight-label {
        min-height: 24px !important;
        height: 24px;
        border: none !important;
        border-left: 1px solid $white-2 !important;
        border-radius: 0 !important;
        .custom-select__value-container {
          overflow: initial;
          > div {
            &:last-child {
              margin-top: 0;
              margin-bottom: 0;
              padding-bottom: 0;
              padding-top: 0;
            }
          }
        }
        .custom-select__single-value {
          position: relative;
          transform: none;
          top: 0;
          color: $black-1;
        }
      }
    }
  }
}

.weight-unit-menu-item {
  &.active {
    font-weight: 500;
    color: $purple-1;
  }
}

.number-input-arrows {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: space-between;
  gap: 2px;

  & > * {
    cursor: pointer;
  }
}

.has-error {
  .custom-select__control {
    border-color: $red-1 !important;
  }
}

.product-image-container {
  border: 1px solid $white-9;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.img-wrapper {
  border: 1px solid $white-9;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.stock-alert-image-container {
  border: 1px solid $white-9;
  border-radius: 4px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.intl-partner-image-container {
  object-fit: contain;
  border: 1px solid $white-9;
  border-radius: 4px;
  width: 48px;
  height: 48px;
}

.modal-responsive {
  height: calc(80vh - 94px);
  overflow-y: scroll;
}

.percent-discount {
  @include content-size(22, 22);
}

.banner-tag {
  position: absolute;
  top: -12px;
  right: -9px;
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

.attribute-value {
  .MuiTextField-root {
    width: 344px;
  }

  .attribute-dropdown {
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: $white-2;
    }
    &:hover {
      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: $white-2;
      }
    }
    &:hover {
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: $purple-1;
      }
    }
    .MuiInputBase-root {
      .MuiOutlinedInput-input {
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        min-height: 26px;
      }
    }
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: $purple-1;
  }
  .chips-row {
    width: 100% !important;
    max-width: 100% !important;

    .cur-grab {
      border: none;
    }
  }
}

.tagged-input-wrapper {
  @extend .d-flex;
  flex: 0 0;
  .form-group:nth-child(1) {
    width: 174px;
    margin-right: 24px;
  }
  .MuiAutocomplete-root,
  .form-group:nth-child(2) {
    width: 451px;
    margin-right: 12px;
    p {
      @extend .text-8;
      color: $black-3;
    }
  }
  .delete-icon {
    @extend .d-flex, .cur-p;
    align-items: center;
  }

  .has-chips {
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
      .MuiAutocomplete-input {
      padding: 0px 4px;
    }
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: $white-2;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    row-gap: 4px;
    min-height: 50px;
  }
  .variant-tag {
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding-top: 9px;
      padding-bottom: 9px;
      min-height: 48px;
    }
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input:first-child {
    padding-left: 0px;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    @extend .cur-p;
    border-width: 1px;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 6.5px 4px;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    @extend .cur-p;
    border: 1px solid $white-2;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    @extend .cur-p;
    border-color: $purple-1 !important;
    border-width: 1px;
  }
  .MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline {
    @extend .cur-p;
    border-color: $purple-1 !important;
    border-width: 1px;
  }
  .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
    outline: none;
    border-color: $purple-1 !important;
    border-width: 1px;
  }
  input::placeholder {
    @extend .text-2;
  }
}
.abandoned-cart {
  .custom-select__control {
    min-height: inherit !important;
    height: inherit;
  }
  .custom-select__value-container {
    padding: 10px 16px !important;
    .custom-select__single-value {
      text-overflow: initial;
      white-space: initial;
    }
  }
  .custom-select__menu {
    box-shadow: 0px 10px 20px rgba(152, 152, 152, 0.3) !important;
  }
}
.tagged-input-wrapper {
  @extend .d-flex;
  flex: 0 0;

  &.variant-wrapper {
    width: 100%;
    margin-bottom: 24px;

    .form-group {
      margin-bottom: 0;
    }

    .options-group {
      width: 174px;
      margin-right: 24px;
    }

    .form-group:nth-child(1) {
      width: 174px;
      margin-right: 24px;
    }
    .MuiAutocomplete-root,
    .tagged-input-wrapper,
    .form-group:nth-child(2) {
      width: 514px;
      margin-right: 0;
      p {
        @extend .text-8;
        color: $black-3;
      }
    }
    .delete-icon {
      @extend .d-block, .cur-p;
      margin-left: 12px;
      // align-items: center;

      svg {
        margin-top: 40px;
      }
    }
  }

  .form-group:nth-child(1) {
    width: 174px;
    margin-right: 24px;
  }
  .MuiAutocomplete-root,
  .form-group:nth-child(2) {
    width: 451px;
    margin-right: 12px;
    p {
      @extend .text-8;
      color: $black-3;
    }
  }
  .delete-icon {
    @extend .d-flex, .cur-p;
    align-items: center;
  }

  .has-chips {
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
      .MuiAutocomplete-input {
      padding: 0px 4px;
    }
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: $white-2;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    row-gap: 10px;
    min-height: 50px;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input:first-child {
    padding-left: 0px;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    @extend .cur-p;
    border-width: 1px;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 6.5px 4px;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    @extend .cur-p;
    border: 1px solid $white-2;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    @extend .cur-p;
    border-color: $purple-1 !important;
    border-width: 1px;
  }
  .MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline {
    @extend .cur-p;
    border-color: $purple-1 !important;
    border-width: 1px;
  }
  .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
    outline: none;
    border-color: $purple-1 !important;
    border-width: 1px;
  }
  input::placeholder {
    @extend .text-2;
  }
}

.country-selector {
  box-shadow: 0px 10px 20px rgb(152 152 152 / 30%);
  border-radius: 4px;
  margin-top: 4px;
  position: absolute;
  background: white;
  z-index: 1;
  overflow: scroll;
  width: 352px;
  .form-group {
    margin-bottom: 0px;
  }
  .shift-menu {
    margin-bottom: 0;
  }
  .custom-select__control {
    height: auto;
    .search-filter {
      width: 100%;
      input {
        padding-left: 40px;
        font-size: 15px;
        &:focus {
          border-color: #d9d9d9;
        }
      }
    }
    .selected-country-option {
      width: 100%;
      margin: 20px 0px 12px;
    }
    svg {
      transform: none;
    }
    .custom-select__indicators {
      display: none;
    }
    .custom-select__single-value {
      display: none;
    }
    border: none !important;
  }
  .custom-select__menu {
    box-shadow: none !important;
    position: relative;
    left: 0 !important;
    margin-top: 0px !important;
    margin-bottom: 16px;
    padding: 0px;
    padding-right: 16px;
    .custom-select__menu-list {
      max-height: 190px;
    }
    .custom-select__option {
      padding: 8px;
    }
    div::-webkit-scrollbar {
      width: 4px;
    }
    div::-webkit-scrollbar-track {
      background: $white-6;
    }
    div::-webkit-scrollbar-thumb {
      background: $gray-6;
      height: 80px;
    }
  }
  .custom-select__value-container {
    padding: 16px 16px 0px 16px !important;
  }
}

.rotate-icon-180 {
  transform: rotate(180deg);
}

.brand-image-container {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
}

.brand-name-container {
  margin-left: 12px;
  font-size: 16px;
  line-height: 24px;
}

.no-border .custom-select__control {
  border: none !important;
  border-style: none;
}

.country-code-1 .custom-select__control {
  width: 91px !important;
}

.country-code-2 .custom-select__control {
  width: 102px;
}

.country-code-3 .custom-select__control {
  width: 111px;
}

.variants-table-container {
  .form-group-lg .custom-select__control {
    min-height: 40px !important;
    height: 40px;
  }
}

.menu-button-divider {
  height: 24px;
  width: 1px;
  background-color: $white-9;
}

.bulk-edit-category-select {
  .custom-select__menu {
    max-width: 230px !important;
    min-width: 230px !important;
  }
}
