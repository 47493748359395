.order-details-wrapper {
  position: relative;
  @media only screen and (max-width: 1500px) {
    .carousel-btn-right {
      display: none;
    }
    .carousel-btn-left {
      display: none;
    }
  }
  .view-return-details {
    background-color: $black-3;
    color: $white;
    padding: 8px 12px;
    box-shadow: 0px 4px 12px 0px #1a181e1a;
    border: 0;
    border-radius: 32px;
    position: fixed;
    left: 52%;
    bottom: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    z-index: 100;
  }
}

.order-detail-container {
  padding: 32px;

  .activity-section,
  .order-details-section {
    border: 1px solid $white-2;
    border-radius: 4px;
    padding: 16px;
    max-width: 800px;
  }
  .order-details-section {
    min-width: 600px;
  }
  .activity-section {
    height: fit-content;
    .timeline {
      &:last-child {
        .ellipses {
          &:before {
            display: none;
          }

          &.show-last-ellipsis:before {
            height: calc(100% - 6px);
            display: block;
          }
        }
      }
      .ellipses {
        &.active {
          &:after {
            content: '';
            border-radius: 100%;
            border: 4px solid #146eb4;
            z-index: 1;
            display: block;
            position: relative;
            top: 4px;
            height: 16px;
            width: 16px;
            box-sizing: border-box;
            background: white;
          }
        }
        &:after {
          content: '';
          border-radius: 100%;
          background-color: $gray-1;
          z-index: 1;
          display: block;
          position: relative;
          top: 6px;
          height: 12px;
          width: 12px;
          box-sizing: border-box;
        }
        position: relative;
        display: flex;
        justify-content: center;
        &:before {
          content: '';
          width: 2px;
          background: $white-9;
          height: calc(100% + 20px);
          position: absolute;
          top: 6px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .order-attributes {
    .MuiAccordionDetails-root {
      padding: 8px 16px 16px;
    }
  }
  .MuiAccordionDetails-root {
    padding: 20px 24px 24px;
  }
  &.order-details-modal {
    padding: 0px;
    margin: -24px;
    max-width: unset;
    max-height: calc(80vh - 80px);
    overflow-y: scroll;
  }
  &.confirm-delivery-cost {
    margin: 0;
    max-width: unset;

    & > .customer-details {
      padding-top: 0;
      border: none;
    }
  }
  &.confirm-delivery-cost .customer-details .customer-details-container {
    p,
    span {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .customer-details {
    border-top: 1px solid $white-2;
    padding-top: 24px;
    position: relative;
    table {
      margin-top: 16px;
      tr {
        td {
          padding: 4px 0;
          @include font-size(16, 24);
          vertical-align: top;
          &:first-child {
            width: 154px;
            font-weight: 500;
          }
        }
      }
    }

    .customer-details-container {
      margin-top: 20px;

      .row:not(:last-child) {
        margin-bottom: 16px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 2px;
      }
      span {
        font-size: 16px;
        line-height: 24px;
        word-break: break-word;
      }
      .view-text {
        @include font-size-weight(14, 20, 500);
        white-space: nowrap;
      }
    }
    .customer-details-container.vendor-margin {
      margin-top: 0 !important;
    }
    .payment-mode {
      bottom: 8px;
    }

    .additional-info-row:not(:last-child) {
      margin-bottom: 16px;
    }

    .additional-info-file {
      max-width: 344px;
      padding: 10px 16px;
      background-color: $white-6;
      border-radius: 4px;
    }
  }

  .order-details-card {
    padding: 0;
  }

  .order-products {
    .mt-12 {
      margin-top: -12px;
    }
    &.edit-screen {
      border-top: unset;
      padding-top: 0;
    }
    border-bottom: 1px solid $white-3;
    .order-product-info {
      margin-bottom: 20px;
      .prod-item-wrap {
        @include display-flex(flex-end, space-between);
      }

      .prod-item-container {
        flex: 1;
        @include display-flex(flex-start, flex-start);
        .product-img-container {
          @include content-size(116, 96);
        }
        img {
          @include content-size(96, 96);
          border-radius: 6px;
          margin-right: 20px;
          object-fit: contain;
          border: 1px solid $white-9;
          &:hover {
            opacity: 0.8;
          }
        }
        .qty-box {
          color: $purple-1;
          padding: 0 7px 0 8px;
          border-radius: 4px;
          border: 1px solid $purple-1;
          background-color: rgba($purple-1, 0.1);
          min-width: 24px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 24px;
        }

        .size-color {
          @extend .text-4, .mt4;
          color: $black-3;
        }
      }
    }
  }

  .order-bundle-info {
    margin-bottom: 16px;
    display: flex;
    img {
      width: 96px;
      height: 96px;
      border-radius: 6px;
      margin-right: 20px;
      object-fit: contain;
      border: 1px solid #e6e6e6;
    }

    .bundle-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .badge {
        color: $gray-1;
        font-weight: 600 !important;
      }
    }
  }

  .bundle-product {
    padding-left: 20px;
    border-left: 2px solid $white-9;
    .order-product-info {
      margin-bottom: 0px;
      padding-bottom: 20px;
    }
  }

  .order-total:not(.grand-total-row) {
    padding-top: 12px;
    > div {
      &:not(.grand-total-row) {
        @include font-size(16, 24);
        margin-bottom: 8px;
      }
      @include display-flex(center, space-between);
    }
    .grand-total-row {
      @include font-size-weight(18, 26, 500);
    }
  }

  .order-total-shimmer {
    > div {
      height: 24px;
    }
  }
  .order-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    > button {
      &:first-child {
        margin-right: 12px;
      }
    }
  }
}

.variant-color-wrap {
  display: inline-flex;
  align-items: center;
  .variant-color {
    display: inline-flex;
    @include content-size(16, 16);
    border-radius: 2px;
    border: solid 1px $white-9;
  }
}

.align-items-center {
  align-items: center;
}

.rto-risk-graph {
  width: 100px;
  height: 55px;
  overflow-y: hidden;
  div[data-test-id='CircularProgressbarWithChildren__children'] {
    justify-content: flex-start !important;
    padding-top: 18px;
  }
}
.rto-recommended-action {
  flex: 1 1 auto;
  min-width: 297px;
}
.rto-action-btn-wrapper {
  @extend .d-flex, .justify-end, .row;
  width: 204px;

  &.low-risk {
    width: 154px;
  }

  &.top-up {
    width: fit-content;
  }

  .action-btn {
    padding: 5px 15px;
  }
  .total-links-shared {
    @extend .text-10;
    color: $gray-1;
  }
}
.attention-icon {
  @extend .d-flex, .align-center, .justify-center;
  width: 50px;
  height: 50px;
}

.request-partial-payment {
  .form-group {
    margin-bottom: 0;
  }
}

.pending-confirmation-banner {
  background-color: $orange-4;
  padding: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.edit-quantity-modal {
  & > .dialog-content {
    padding-top: 0 !important;
  }
  .sticky-header {
    position: sticky;
    top: 0;
    left: 0;
    padding: 24px 0;
    background-color: $white;
    z-index: 20;
    margin-right: -24px;
    padding-left: 10px;
  }
}

.edit-quantity-modal-list {
  max-height: 400px;
  overflow-y: scroll;
}

.cancellation-reason {
  padding: 8px 12px;
  border: solid 1px $red-1;
  border-radius: 4px;
  background-color: rgba($red-1, 0.1);
}

.shipment-estimate-charge-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    @include font-size-weight(48, 56, 600);
    margin-top: 32px;
    margin-bottom: 4px;
  }

  .divider {
    @include content-size(48, 2);
    background-color: $black-1;
  }

  .cod-description {
    @include font-size-weight(12, 16, 500);
    color: $gray-1;
    margin-bottom: 32px;
  }
}

.ship-order-via-modal-content {
  border: solid 1px $white-9;
  border-radius: 4px;
  padding: 24px 16px;
  &.delivery-partner-table {
    padding: 24px 16px 0;
  }
}

.intl-shipping-track-modal {
  background-color: $white-1;
  border: solid 1px $white-2;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
}

.notes-timeline {
  margin-left: 32px;
  padding-bottom: 16px;
  border-radius: 4px;
  width: 400px;
  height: -moz-fit-content;
  height: fit-content;
  position: sticky;
  top: 32px;
  .add-btn {
    cursor: pointer;
  }
  .form-group {
    margin-bottom: 0px;
  }
  .notes-timeline-inner-container {
    input {
      border: none;
      padding: 0px;
      @extend .text-3;
    }
  }


  .timeline {
    &:last-child {
      .ellipses {
        &:before {
          display: none;
        }

        &.show-last-ellipsis:before {
          height: calc(100% - 6px);
          display: block;
        }
      }
    }
    .ellipses {
      &.active {
        &:after {
          content: '';
          border-radius: 100%;
          border: 4px solid #146eb4;
          z-index: 1;
          display: block;
          position: relative;
          top: 4px;
          height: 16px;
          width: 16px;
          box-sizing: border-box;
          background: white;
          left: -2px;
        }
        &:before {
          left: 6px;
        }
      }
      &:after {
        content: '';
        border-radius: 100%;
        background-color: $gray-1;
        z-index: 1;
        display: block;
        position: relative;
        top: 6px;
        height: 12px;
        width: 12px;
        box-sizing: border-box;
      }
      position: relative;
      display: flex;
      justify-content: center;
      &:before {
        content: '';
        width: 2px;
        background: $white-9;
        height: calc(100% + 20px);
        position: absolute;
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.order-details-sidebar {
  display: grid;
  grid-gap: 24px;
}

.reason-ellipse {
  flex-shrink: 0;
  background-color: $black-1;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

// abandoned
.recovery-msg-container {
  .recovery-msg-dropdown {
    border-radius: 4px;
  }
  .metrics {
    & > div {
      width: 50%;
    }
  }
  .broadcast-button-item {
    width: 100%;
  }

  .custom-select {
    &__menu {
      box-shadow: 0px 10px 20px rgba(152, 152, 152, 0.3) !important;
    }
  }

  .custom-select__placeholder {
    white-space: nowrap;
    color: $black-1 !important;
  }
  .custom-select__indicators {
    padding-left: 24px;
  }
  .payment-method {
    .payment-option-button {
      padding: 12px 16px;
    }
  }

  .templates-body {
    max-height: calc(92vh - 380px);
    overflow: scroll;
    padding-bottom: 60px;
  }

  // custom stepper

  .custom-stepper-step-status-indicator {
    &.abandoned-recovery {
      @extend .section-text-5;
      border-width: 1px;
      width: 32px;
      height: 32px;
      min-height: 32px;
      &.checked {
        color: $white;
      }
    }
  }

  .abandoned-recovery-modal-label {
    @extend .section-text-5;
    margin-left: 12px;
    &.enabled {
      color: $purple-1;
    }
    &.disabled {
      color: $gray-4;
    }
  }
  .abandoned-recovery-modal {
    width: 64px;
    &.active {
      .MuiStepConnector-line {
        border-color: $purple-1;
      }
    }
    &.MuiStepConnector-root {
      flex: none;
    }
  }
  // modal footer

  .footer {
    border-top: 1px solid $white-2;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $white;
  }
  .preview-window {
    padding-bottom: 72px;
  }

  .msg-preview {
    width: 228px;
    height: fit-content;
    margin-left: 24px;
    border-radius: 4px;
    .body {
      margin: 16px;
      white-space: pre-line;
      word-break: break-word;
      strong {
        font-weight: 600;
        font-family: Arial;
      }
    }
    .url {
      @extend .section-text-7;
      border-top: 1px solid $white-2;
    }
    &.whatsapp {
      background-color: $green-5;
    }
    &.sms {
      background-color: $gray-10;
    }
  }
  .medium-btn {
    .broadcast-button-item {
      &:not(:last-child) {
        @extend .mr16;
      }
    }
  }
}

.mobile-header {
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  background-color: $purple-1;
  width: 100%;
  z-index: 2;
}
.mobile-header-content {
  padding: 16px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}
.progress-bar {
  position: absolute;
  top: 100%;
}

.order-payment-provider-logo {
  margin-top: 4px;
  height: 32px;
  width: 58px;
}
.recovery-message {
  .MuiFormControlLabel-root {
    margin-right: 7px;
  }
  .MuiRadio-root.custom-radio {
    margin-left: 6px;
  }
}

.manage-tags-modal {
  .dialog-modal .dialog-content.MuiDialogContent-root {
    max-width: 35vh;
  }
}

.tags-modal-options-container {
  .tags-modal-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 252px);
    gap: 12px;
  }
  @extend .show-scrollbar;
  overflow-y: scroll;
  max-height: calc(92vh - 220px);
  margin-right: -24px;
}

.vendor-order-product-info {
  .prod-item-wrap {
    margin-bottom: 16px !important;
  }
  .order-product-info {
    margin-bottom: 16px !important;
    border-bottom: 1px solid $white-2;
    &:last-of-type {
      border-bottom: none;
      margin-bottom: 24px !important;
    }
  }
}

.top-8 {
  top: -8px;
}

.mt-32 {
  margin-top: -32px;
}

.hr-line {
  &.mt-8 {
    margin-top: -8px;
  }
}

.order-details-section-container .MuiAccordionDetails-root {
  padding-top: 0;
}

.order-details-section-container .MuiPaper-root {
  border-radius: 4px !important;
}

section .MuiPaper-root {
  border-radius: 4px !important;
}

.order-details-section-container .MuiButtonBase-root {
  flex-direction: column;

  .MuiAccordionSummary-content {
    width: 100%;
  }

  .MuiAccordionSummary-content .MuiExpanded {
    margin-bottom: 0;
  }

  .MuiAccordionSummary-expandIcon {
    width: 100%;
    justify-content: flex-end;
  }
}

.card-with-top-banner {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.return-details-total {
  margin-top: 0 !important;
}

.upper-arrow-order-list {
  svg {
    path {
      fill: $purple-1;
    }
  }
}

.table-data-container-item:nth-last-child(1) {
  padding-bottom: 0;
}

.abandoned-order {
  margin: auto;
}

.gray-circle {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: $gray-8;
  margin: 0 8px;
}

.refund-row {
  .MuiTableCell-root.MuiTableCell-body {
    border-bottom: none;
  }
}

.refund-account-details-row {
  .MuiTableCell-root.MuiTableCell-body {
    padding-top: 0;
  }
}

.refund-account-details {
  border: 1px solid $gray-3;
  border-radius: 4px;

  .refund-details {
    @include display-flex(center, flex-start);
    @extend .section-text-14;

    .refund-details-divider {
      width: 1px;
      background-color: $gray-3;
      align-self: stretch;
    }

    .account-details {
      @include display-flex(center, center);
      gap: 16px;
    }
  }
}

.manual-payment-dropdown {
  gap: 8px;
  padding: 7px 15px;
}
