.campaign-stat-cards-grid {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}
.stat-card-title {
  max-width: 128px;
}
.template-messages-grid {
  display: grid;
  grid-column: auto;
  gap: 16px;
  &.whatsapp-template {
    height: calc(100% - 40px);
    overflow: auto;
    @extend .show-scrollbar;
    padding-bottom: 24px;
    padding-right: 3px;
    .template-message-item {
      flex-direction: row-reverse;
    }
  }
}

.whatsapp-container {
  max-height: calc(100vh - 224px);
}

.template-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  .preview-body {
    background-color: #e3ffc7;
    width: 262px;
    white-space: pre-line;
    word-break: break-word;
    border-radius: 4px;
    &.template {
      max-height: calc(100% - 96px);
    }
    overflow: auto;
    span {
      background-color: inherit !important;
    }
    strong {
      font-weight: 600;
    }
  }
}

.template-edit-content {
  .template-body {
    border: 1px solid $white-2;
    border-radius: 4px;
    white-space: pre-line;
    strong {
      font-weight: 600;
    }
  }
  .varibales-container {
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    flex-wrap: wrap;
    > div {
      width: 48.5%;
    }
  }
}

.information-banner-red {
  padding: 12px 16px;
  background-color: rgba($red-1, 0.1);
  border: solid 1px $red-1;
  border-radius: 4px;
}

.credits-fake-input {
  @extend .d-flex-c-s;
  background-color: $white-6;
  border-radius: 4px;
  padding-left: 16px;
  padding: 12px;
}

.template-message-item {
  @extend .cur-p;
  border: solid 1px $white-8;
  border-radius: 4px;
  padding: 10px 16px;
  padding-right: 0;
  flex-direction: row;
  span {
    background-color: inherit !important;
  }

  &.selected {
    background-color: rgba($purple-1, 0.1);
    border-color: $purple-1;
  }
}

.dukaan-credits-modal-header {
  background-color: $purple-1;
  padding: 40px;
  padding-top: 48px;
  position: relative;

  .header-text {
    @include font-size-weight(32, 36, 600);
    max-width: 272px;
    margin-bottom: 24px;
    color: white;
  }

  .seperator {
    @include content-size(60, 4);
    background-color: white;
  }

  .banner-image {
    @include content-size(240, 160);
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.dialog-padding-none {
  .dialog-content {
    padding: 0 !important;
  }
}

.campaign-banner-image {
  @include content-size(204, 194);
}

.campaign-channel-icon {
  @include content-size(20, 20);
  path {
    fill: $purple-1;
  }
}
.campaign-details-refund-strip{
  max-width: 100%;
}
.campaign-name {
  @extend .word-break, .line-clamp-2;
  max-width: calc(100% - 30px);
}

.campaign-status{
  &.completed, &.started,  &.partial {
    background-color: $green-1;
  }
  &.running {
    background-color: $orange-1;
  }
  &.pending {
    background-color: $orange-4;
  }
  &.failed {
    background-color: $red-1;
  }
}

.campaign-type-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  column-gap: 23px;
  row-gap: 24px;
  &.grid-size-two {
    grid-template-columns: 1fr 1fr;
  }
  .campaign-type-item {
    &.selected {
      svg {
        path {
          fill: $purple-1;
        }
      }
    }
  }
}

.campaign-type-item {
  width: 100%;
  border: solid 1px $white-2;
  position: relative;
  color: $black-3;
  padding: 24px 28px;
  border-radius: 4px;
  @extend .d-flex-c-c, .cur-p;
  flex-direction: column;
  text-align: center;

  &:hover {
    background-color: $white-10;
  }

  &.selected {
    border-color: $purple-1;
    background-color: rgba($purple-1, 0.1);
    color: $purple-1;
  }
  &.disabled {
    background-color: $white-10;
    cursor: not-allowed;
  }

  .campaign-type-item-disabled-badge {
    display: flex;
    align-items: center;
    position: absolute;
    top: -10px;
  }

  .campaign-type-item-title {
    @extend .section-text-5;
  }
}

.campaign-orders-table-container {
  margin-left: -12px;
  margin-right: -12px;
}

.campaign-special-filter-btn {
  position: relative;
  cursor: pointer;
  border: solid 1px $white-2;
  font-size: 14px;
  line-height: 20px;
  color: $black-1;
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;
  &.active {
    background: rgba(20, 110, 180, 0.1);
    border: 1px solid $purple-1;
  }
}
.facebook-campaign {
  background-color: $facebook-secondary;
  padding: 0px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .btn {
    padding: 6px 12px;
    @extend .text-10;
    color: $facebook-secondary;
    background-color: $white;
  }
}

.instagram-campaign {
  background: linear-gradient(
    91.28deg,
    #743bbe 0.88%,
    #ef3179 47.62%,
    #f49452 100%
  );
  padding: 0px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .btn {
    padding: 6px 12px;
    @extend .text-10;
    color: $red-5;
    background-color: $white;
  }
}

.app-campaign {
  background-color: $purple-3;
  padding: 0px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .btn {
    padding: 6px 12px;
    @extend .text-10;
    color: $purple-3;
    background-color: $white;
  }
}

.payment-banner-logo {
  @include content-size(133, 126);
}
.MuiSlider-root {
  color: $purple-1 !important;
  padding: 0px !important;
}

.MuiSlider-rail {
  height: 4px !important;
  color: $white-9;
  opacity: 1 !important;
  border-radius: 2px !important;
}

.MuiSlider-mark {
  display: none;
}

.MuiSlider-track {
  height: 4px !important;
  background-color: $purple-1 !important;
  border-radius: 2px !important;
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px rgba($purple-1, 0.16) !important;
}

.campaign-slider {
  .MuiSlider-markLabel {
    top: 13px !important;
    &[data-index='0'] {
      left: 3% !important;
    }
    &[data-index='8'] {
      left: 97% !important;
    }
  }
}

.estimates-card {
  .card-1,
  .card-2,
  .card-3 {
    padding: 16px;
    border-radius: 4px;
    &:not(:last-child) {
      margin-right: 23px;
    }
    svg {
      position: absolute;
    }
  }
}

.run-campaign-btn-wrapper {
  display: flex;
  justify-content: right;
}

.campaign-loader-no-data-container {
  height: calc(100vh - 224px);
  @extend .d-flex-c-c;
}

.transform-90deg {
  transform: rotate(180deg);
}

.whatsapp-template-view {
  word-break: break-word;
  white-space: pre-line;
}

.campaign-id {
  padding-right: 20px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: $black-3;
  }
}

.campaign-details {
  overflow-y: overlay;
  max-height: calc(92vh - 162px);
  margin: 0 -24px;
  padding: 0 24px;
}

.campaign-modal-header-wrap {
  svg {
    margin: 3px 0px;
    path {
      fill: $black-1;
    }
  }
  @include d-grid(20px 1fr auto, 1fr, 8px, flex-start, flex-start);
}

.campaign-stats {
  @include d-grid(1fr 1fr, auto, 18px, flex-start, flex-start);
}
