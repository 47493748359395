.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 24px;
  min-height: 40px;
  position: fixed;
  bottom: 0;
  left: $sidebar-width;
  background: #fff;
  border-top: solid 1px #ebeef1;
  padding-left: 32px;
  padding-bottom: 12px;
  padding-top: 12px;
  right: 0;
  z-index: 2;

  &.inline {
    position: relative;
    left: 0;
    padding-top: 24px;
  }
  @media screen and (max-width: 1240px) {
    .pages-of-container {
      display: none;
    }
  }
}

.pages-of-container {
  left: 32px;
  position: absolute;
}

.page-navigation-container {
  @include d-grid(103px auto 77px, 1fr, 24px, center, center);
  width: 100%;
}

.page-counter-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.page-counter {
  align-items: center;
  border-radius: 4px;
  color: $black-3;
  display: flex;
  font-weight: 500;
  height: 28px;
  justify-content: center;
  width: 28px;
  &:hover {
    background-color: rgba($black-2, 0.4);
    color: $white;
  }
  &.current-page {
    background-color: $black-2;
    color: $white;
  }
  &.extra-size-3 {
    width: 32px;
  }
  &.extra-size-4 {
    width: 40px;
  }
  &.extra-size-5 {
    width: 48px;
  }
  &.extra-size-6 {
    width: 56px;
  }
  &.extra-large-size {
    width: 64px;
  }
}

.pagination-button {
  align-items: center;
  background-color: $white;
  border: 1px solid $white-2;
  border-radius: 4px;
  color: $black-3;
  display: flex;
  font-weight: 500;
  gap: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  &.next {
    padding-right: 6px;
    padding-left: 12px;
  }
  &.previous {
    padding-right: 12px;
    padding-left: 6px;
  }
  &.disabled {
    opacity: 0.4;
  }
  &:hover {
    background-color: #f7f7f7;
  }

  svg {
    path {
      fill: $black-3;
    }
  }
}
