.lds-dual-ring {
  display: inline-block;
  width: 5rem;
  height: 5rem;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 5rem;
  height: 5rem;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #146eb4;
  border-color: #146eb4 transparent #146eb4 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-wrapper {
  position: relative;
  min-height: 250px;
}

.spiner-container {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.spiner-container img {
  position: absolute;
  width: 4rem;
  transform: translate(-50%, -50%);
  top: 55%;
  left: 55%;
}

.staff-accounts-loader {
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generator-loader {
  width: 88px;
  height: 88px;
  @include display-flex;
  border-radius: 50%;
  background: rgba(223, 30, 91, 0.1);
  transform: scale(1.2);
  animation: pulse-pink 2s infinite;
}

.generator-loader-error {
  width: 88px;
  height: 88px;
  @include display-flex;
  border-radius: 50%;
  background-color: $white-6;
}

@keyframes pulse-pink {
  0% {
    transform: scale(0.95);
  }
  
  70% {
    transform: scale(1.2);
  }
  
  100% {
    transform: scale(0.95);
  }
}

