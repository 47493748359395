.coupon-modal-container {
  display: grid;
  margin-bottom: -12px;
  .coupon-modal-item {
    margin: 0 -12px;
    padding: 12px;
    transition: background-color 0.2s ease-in;
    &:hover {
      background-color: $white-6;
      border-radius: 4px;
    }
    .coupon-modal-image {
      height: 72px;
    }
  }
}

.coupon-use-select {
  .custom-select__single-value {
    color: $black-1;
  }
  .select-disabled {
    .custom-select__control {
      background-color: $gray-9;
    }
  }
}

.coupon-select-category {
  .custom-select__single-value {
    color: $black-1;
  }
}

.coupon-select-category-empty {
  .custom-select__single-value {
    color: $black-1;
  }
}

.coupon-switch-container {
  svg {
    margin-left: 6px;
  }
}
.functionality-switch {
  .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track {
    background-color: $white-2;
  }
  .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
    opacity: 0.6;
  }
  .MuiSwitch-switchBase.Mui-disabled {
    color: $white;
  }
}
.coupon-accordion-wrapper {
  .validity-row {
    @include d-grid(1fr 1fr, auto, 24px, center);
  }

  .MuiAccordion-root.custom-accordion {
    border-radius: 4px;
  }
  .MuiAccordionDetails-root {
    padding: 0 24px 24px;
  }
  .coupons-date-picker {
    .date-input-field-wrap {
      height: 48px;
    }
  }
}

.file-image-upload-override {
  min-width: auto;
  width: 96px;
  height: 96px;
}

.uploaded-image-override {
  min-width: auto;
  width: 96px;
  height: 96px;
  .uploaded-img-overlay {
    position: absolute;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    border-radius: 4px;
    background-image: linear-gradient(
      to bottom,
      #333333 0%,
      rgba(0, 0, 0, 0) 48%
    );
  }
  svg {
    width: 16px;
    height: 16px;
  }
  img {
    @include content-size(96, 96);
    border-radius: 4px;
    object-fit: contain;
    border: 1px solid $white-9;
  }
  .remove-uploaded-image {
    position: absolute;
    cursor: pointer;
    top: 4px;
    right: 4px;
    z-index: 2;
  }
}

.coupon-select-option {
  .custom-select__single-value {
    color: $black-1;
  }
}

.coupon-select-option-placeholder {
  .custom-select__single-value {
    color: $gray-4;
  }
}

.add-freebie-input {
  .form-group {
    margin-bottom: 0;
  }
}

.accept-outofstock-product-modal,
.scarce-product-modal,
.buy-get-coupon-modal {
  display: grid;
  gap: 10px;
  overflow-y: scroll;
  img {
    border-radius: 4px;
  }
  .MuiTypography-body1 {
    display: none;
  }
}

.accept-outofstock-modal,
.scarcity-product-modal,
.buy-get-modal-container {
  .MuiDialogActions-root {
    display: flex;
    justify-content: center !important;
    padding-bottom: 16px;
  }
  .MuiDialogContent-root {
    padding-bottom: 12px !important;
  }
}
.accept-outofstock-modal-title,
.product-scarcity-modal-title,
.bxgy-modal-title {
  position: absolute;
  left: 0;
  top: 0;
  background-color: $white;
  z-index: 2;
  padding-top: 24px;
  width: 100%;
}

.accept-outofstock-modal-content,
.product-scarcity-modal-content,
.bxgy-modal-content {
  padding-top: 96px;
}

.bxgy-bottom-divider {
  position: absolute;
  bottom: 0;
  margin-bottom: 62px;
  width: 100%;
  left: 0;
  right: 0;
}
.bxgy-help-text {
  white-space: nowrap;
}

.form-input-cursor {
  caret-color: transparent;
  cursor: pointer !important;
}

.provide-freebie {
  font-size: 16px !important;
  line-height: 24px !important;
  color: $black-1 !important;
}

.bxgy-modal-img {
  margin-bottom: 12.5px;
  border-radius: 50%;
}

.freebie-img {
  border-radius: 4px;
  height: 48px;
  width: 48px;
}

.freebie-existing-modal {
  .MuiIconButton-sizeSmall {
    margin-bottom: 16px;
    margin-right: -4px;
  }

  .freebie-modal-radio {
    .custom-radio {
      padding-right: 16px !important;
    }
  }
}

.existing-freebie-modal {
  .MuiDialog-container .MuiDialog-scrollPaper {
    .dialog-modal .dialog-content.MuiDialogContent-root {
      padding-bottom: 16px !important;
    }
  }
}

.update-inventory-modal:not(:last-child) {
  margin-bottom: 20px;
}

.update-inventory-modal-img {
  border-radius: 4px;
}

.append-text-bg {
  padding-left: 15px;
  background: white !important;
}

.bxgy-img {
  border: 1px solid $white-8;
}

.no-coupon-screen {
  min-height: calc(100% - 225px) !important;
}

.coupon-code-input {
  &.manual {
    .input-group-wrap {
      input {
        padding-right: 108px;
      }
    }
  }
  &.automatic,
  &.file_upload {
    .input-group-wrap {
      input {
        padding-right: 128px;
      }
    }
  }
}

.coupons-filters {
  position: sticky;
  top: 0;
  background-color: $white-1;
  z-index: 99;
  padding-top: 24px;
  margin-left: 1px;
}

.coupons-search-bar {
  .search-bar {
    margin-bottom: 0px;
  }
}

.coupon-checkbox {
  .Mui-disabled {
    rect {
      fill: $gray-8;
    }
  }
}
.flat-discount-helper-text {
  @include font-size-weight(14, 20, 500);
  margin-top: -15px;
  color: $black-3;
  min-height: 20px;
}

.freebie-modal-image {
  justify-content: flex-start;
}

.coupon-minimum-order {
  display: flex !important;
  flex-direction: row !important;
}
.time-picker-div-input{
  max-width: unset !important;
}