.cta-button {
  @extend .btn-no-default;
  @include font-size-weight(10, 16, 500);
  background-color: $white-6;
  padding: 8px 38px;
  border-radius: 2px;
}
.image-portion {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.d2c-story-card-container {
  height: auto;
  width: 100%;
  border: 1px solid $white-8;
  .introduction-text{
    @extend .text-12, .mb8;
  }
  .description{
    word-wrap: break-word;
    width: 100%;
  }
  .cta-button{
    word-wrap: break-word;
    width: 140px;
  }
}

.d-flex {
  .image-container {
    width: 50%;
    height: inherit;
    @extend .d-flex-c-c;
    flex-direction: column;
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    overflow: hidden;
  }
  .text-container {
    height: 342px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .description {
    @extend .text-5;
    // color: white;
    width: 292px;
    margin-bottom: 16px;
    text-align: center;
  }
  .title {
    @include font-size-weight(24, 30, 500);
    margin-bottom: 4px;
  }
  .cta-button {
    @extend .btn-no-default;
    @include font-size-weight(10, 16, 500);
    background: #1a181e;
    color: #ffffff;
    padding: 8px 38px;
    border-radius: 2px;
    &.secondary {
      background-color: transparent;
      border: 1px solid $black-1;
      color: $black-1;
    }
  }
}

.image-overlay-widget {
  .image-overlay-preview {
    min-height: 342px;
    @include display-flex(stretch, center);
    flex-direction: column;
    padding: 112px 132px;
    background-color: rgba($black, 0.7);
    border: 1px solid #e5e5e5;
    text-align: center;
    cursor: default;

    .title {
      @include font-size-weight(24, 30, 500);
      color: white;
      margin-bottom: 4px;
    }

    .description {
      @extend .text-5;
      color: white;
      margin-bottom: 16px;
      text-align: center;
      width: auto;
    }

    .cta-button {
      @extend .btn-no-default;
      @include font-size-weight(10, 16, 500);
      background-color: $white-6;
      // background: #1A181E;
      color: #1a181e;
      padding: 8px 38px;
      border-radius: 2px;
      align-self: center;
      cursor: default;
    }
  }
}
.image-overlay-container {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  // display: flex;
  // height: 342px;
}
.brand-story-widget {
  .brand-story-preview {
    @extend .d-flex-c-c;
    flex-direction: column;
    margin-bottom: 32px;
    cursor: default;

    &.brand-story-background {
      background: $white-6;
    }

    .title {
      @include font-size-weight(24, 30, 500);
      margin-bottom: 4px;
    }
    .title1 {
      @include font-size-weight(16, 24, 500);
      margin-bottom: 4px;
    }

    .description {
      @extend .text-5;
      margin-bottom: 16px;
      text-align: center;
    }

    .cta-button {
      @extend .btn-no-default;
      @include font-size-weight(10, 16, 500);
      background-color: $black-1;
      color: white;
      padding: 8px 38px;
      border-radius: 2px;
      cursor: default;
    }
  }
}

.featured-product-section {
  .featured-product-image-wrapper{
    margin-right: 148px;
    margin-bottom: 49px;
    position: relative;
    img{
      border-radius: 2px;
    }
    .featured-product-img{
      position: absolute;
      top: 25px;
      left: 100px;
      box-shadow: -12px 0 10px rgba(26, 24, 30, 0.2);
    }
  }
  cursor: default;
  .product-description {
    a {
      cursor: default;
      pointer-events: none;
      color: $black-3;
      &:hover {
        color: $black-3;
      }
    }
  }
  .cta-button {
    @extend .btn-no-default;
    @include font-size-weight(10, 16, 500);
    padding: 7px 56px;
    border-radius: 2px;
    color: $black-1;
    background-color: $white;
    border: solid 1px $white-2;
    cursor: default;
  }

  .cta-button-primary {
    @extend .btn-no-default;
    @include font-size-weight(10, 16, 500);
    background-color: $black-1;
    color: white;
    padding: 8px 56px;
    border-radius: 2px;
    cursor: default;
  }
  input {
    cursor: pointer;
  }
}
.featured-brands {
  grid-row-gap: 24px;
}

.placeholder-container {
  position: relative;
  overflow: hidden;
  height: auto;
  padding-bottom: 3px;
  padding-left: 3px;
  .form-group {
    margin-bottom: 0 !important;
  }
  .placeholder-image-container {
    position: relative;
    height: 90px;
    border: 1px solid $white-2;
    border-radius: 4px;

    .featured-brand-image {
      height: auto;
      position: absolute;
      object-fit: contain;
      min-width: 100%;
      max-height: 100%;
    }
    .placeholder-image {
      height: 100%;
    }
    .overlay-text {
      display: none;
    }
    &:hover {
      .overlay {
        animation: {
          name: fade-in;
          fill-mode: both;
          duration: 0.8s;
        }
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        height: 100%;
        width: 100%;
        border-radius: 4px;
        z-index: 1;
      }
      .overlay-delete-btn {
        display: block;
        position: absolute;
        height: 28px;
        top: 14px;
        right: 14px;
        z-index: 2;
      }
      .overlay-text {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        box-sizing: border-box;
        border-radius: 4px;
        white-space: nowrap;
        animation: {
          name: fade-in;
          fill-mode: both;
          duration: 1s;
        }
        .overlay-button {
          height: 28px;
          display: flex;
          align-items: center;
          padding: 0 16px;
        }
        p {
          @include font-size(12, 16);
          font-weight: 500;
        }
      }
    }
  }
  .form-input-container {
    position: relative;
    .image-file-name {
      position: absolute;
      right: 10px;
      top: 15px;
      background-color: white;
      width: calc(100% - 150px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.theme-footer-mobile-input {
  .input-group-wrap input {
    padding-left: 52px !important;
  }
}
