.custom-select {
  &__control {
    color: $black-3;
    min-height: 42px !important;
    @include font-size(14, 22);
    background-color: white;
    border: 1px solid $white-2 !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    cursor: pointer !important;

    &:hover { border-color: $white-2 !important; }

    &--is-focused {
    border-color: $gray-3 !important; 
      &:hover { 
        border-color: $purple-1 !important;
        }
    }
    &--is-disabled {
      border-color: $white-2 !important;
      cursor: default;
    }
    &--menu-is-open {
      border-color: $purple-1 !important; 
      box-shadow: $focus-blue-box-shadow !important;
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__value-container {
    padding: 2px 12px !important;
  }

  &__multi-value {
    border-radius: 3px !important;
    background-color: $white-3 !important;
    &__label {
      font-size: 14px !important;
      line-height: 22px !important;
      color: $body-color !important;
      padding: 4px 8px !important;
    }
    &__remove {
      padding-left: 0px !important;
      padding-right: 8px !important;
      color: $body-color !important;
      img, svg {
        cursor: pointer;
      }
      &:hover {
        background-color: transparent !important;
      }
    }
  }

  &__placeholder {
    color: $gray-2 !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    padding: 0 !important;
    margin-right: 16px;
  }

  &__dropdown-indicator {
    color: $black-3 !important;

    &:hover { color: $black-3 !important }
  }

  &__menu {
    box-shadow: 0 2px 12px 0 rgba(18, 16, 20, 0.1) !important;
    margin-top: 4px !important;
    border-radius: 4px !important;
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 100000 !important;
    &-list{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      @extend .show-scrollbar;
    }
    &-notice--no-options {
      text-align: left !important;
      padding: 8px 14px !important;
      color: $gray-2 !important;
    }
  }


  &__option {
    cursor: pointer !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 4px;
    width: unset !important;
    &--is-focused {
      background-color: $white-4 !important;
    }
    &--is-selected {
      background-color: transparent !important;
      font-weight: 500;
      color: $purple-1 !important;
      &:hover {
       background-color: $white-4 !important;
      }
    }
  }
}

.custom-select-wrap{
  .custom-select__control{
    .custom-select__value-container{
        input{
          box-shadow: none !important;
        }
    }
}
}

.custom-select-bs-none {
  .custom-select__control {
    &--menu-is-open {
      border-color: $white-2 !important;
      box-shadow: none !important;
    }
    &--is-focused {
      border-color: $white-2 !important; 
        &:hover { 
          border-color: $white-2 !important;
          }
      }
  }
}

.search-field-dropdown {
  box-shadow: 0 2px 12px 0 rgb(18 16 20 / 10%) !important;
  margin-top: 4px !important;
  border-radius: 4px 4px 0px 0px !important;
  background-color: hsl(0,0%,100%);
}
