.shimmer {
  background-color: $white-3;
  background-image: linear-gradient(
    to right,
    $white-3 8%,
    $white-7 18%,
    $white-3 33%
  );
  background-repeat: no-repeat;
  animation: shimmering forwards infinite ease-in-out, fadein 0.02s forwards;
  width: 100%;
  background-size: 1000% 100%;
  height: 100%;
  animation-duration: 1s;
  border-radius: 4px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes shimmering {
  from {
    background-position: top right;
  }

  to {
    background-position: top left;
  }
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  padding-right: 10px;
  background-color: $gray-3;
  color: $gray-3;
  box-shadow: 9999px 0 0 -5px $gray-3;
  animation: dotPulse 2s infinite linear;
  animation-delay: 0.4s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: $gray-3;
  color: $gray-3;
}

.dot-pulse::before {
  box-shadow: 9970px 0 0 -5px $gray-3;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::after {
  box-shadow: 10030px 0 0 -5px $gray-3;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 1.3s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9964px 0 0 -5px $gray-3;
  }
  30% {
    box-shadow: 9964px 0 0 2px $gray-3;
  }
  60%,
  100% {
    box-shadow: 9964px 0 0 -5px $gray-3;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 10000px 0 0 -5px $gray-3;
  }
  30% {
    box-shadow: 10000px 0 0 2px $gray-3;
  }
  60%,
  100% {
    box-shadow: 10000px 0 0 -5px $gray-3;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10038px 0 0 -5px $gray-3;
  }
  30% {
    box-shadow: 10038px 0 0 2px $gray-3;
  }
  60%,
  100% {
    box-shadow: 10038px 0 0 -5px $gray-3;
  }
}

.ring-container {
  position: relative;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 23px;

  &.pending {
    background-color: $orange-4;
  }
  &.accepted {
    background-color: $green-1;
  }
  &.shipped {
    background-color: $orange-1;
  }
}

.ringring {
  border: 3px solid;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: 15px;
  top: 15px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  &.pending {
    border-color: $orange-4;
  }
  &.accepted {
    border-color: $green-1;
  }
  &.shipped {
    border-color: $orange-1;
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.spinner,
.spinner-sm {
  margin: 0 auto 0;
  width: 76px;
  text-align: center;
}

.overlay-spinner-container {
  width: 100%;
  height: 100%;
  background-color: $white;
  z-index: 1;
}

.overlay-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner > div {
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-sm > div {
  width: 12px;
  height: 12px;
  background-color: #ccc;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1,
.spinner-sm .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  margin-right: 8px;
}
.spinner .bounce2,
.spinner-sm .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  margin-right: 8px;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
